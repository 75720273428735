import { Controller } from '@hotwired/stimulus';
import { debounce } from 'lodash';

export default class extends Controller {
  static targets = ['newsToggle', 'frame']

  static values = {
    previouslySearched: Boolean
  }

  connect() {
    this.attachSpinnerMonitor()
    this.removeSpinner()
  }

  disconnect() {
    this.detachSpinnerMonitor()
  }

  tabSwitched(e) {
    if (e.detail.identifier === 'posts') {
      this.hideSidebar()
    } else {
      this.showSidebar()
    }
  }

  hideSidebar() {
    document.querySelector('.m-datasets-sidebar').classList.add('hidden')
    document.querySelector('.m-datasets-master-list').classList.add('full-width')
  }

  showSidebar() {
    document.querySelector('.m-datasets-sidebar').classList.remove('hidden')
    document.querySelector('.m-datasets-master-list').classList.remove('full-width')
  }

  attachSpinnerMonitor() {
    this.boundShowSpinner = this.showSpinner.bind(this)
    this.debouncedRemoveSpinner = debounce(this.removeSpinner.bind(this), 200)

    this.getTurboFrameElement().addEventListener('turbo:before-fetch-request', this.boundShowSpinner)
    document.addEventListener('scoutSearch:beforeRequest', this.boundShowSpinner)
    this.getTurboFrameElement().addEventListener('turbo:before-fetch-response', this.debouncedRemoveSpinner)
    document.addEventListener('scoutSearch:beforeResponse', this.debouncedRemoveSpinner)
  }

  detachSpinnerMonitor() {
    this.debouncedRemoveSpinner.cancel()
    this.getTurboFrameElement().removeEventListener('turbo:before-fetch-request', this.boundShowSpinner)
    document.removeEventListener('scoutSearch:beforeRequest', this.boundShowSpinner)
    this.getTurboFrameElement().removeEventListener('turbo:before-fetch-response', this.debouncedRemoveSpinner)
    document.addEventListener('scoutSearch:beforeResponse', this.debouncedRemoveSpinner)
  }

  showSpinner() {
    if (this.hasSpinner()) { return }

    const listElement = this.getListElement()
    const rect = listElement.getBoundingClientRect()
    const newNode = document.createElement('div')

    newNode.classList.add('spinner')
    newNode.classList.add('fa-duotone')
    newNode.classList.add('fa-solid')
    newNode.classList.add('fa-spinner-third')
    newNode.style.left = `${rect.left + (rect.width / 2)}px`
    listElement.insertBefore(newNode, listElement.firstChild)
    listElement.classList.add('loading')
  }

  removeSpinner() {
    if (!this.hasSpinner()) {
      return
    }

    const listElement = this.getListElement()
    listElement.removeChild(listElement.querySelector('.spinner'))
    listElement.classList.remove('loading')
  }

  hasSpinner() {
    return this.getListElement().classList.contains('loading')
  }

  getListElement() {
    return this.getTurboFrameElement().querySelector('.m-datasets-master-list');
  }

  getTurboFrameElement() {
    return this.element.querySelector('turbo-frame#datasets--datasets-and-search-frame_turbo_frame_id');
  }

  searchingChanged(e) {
    if (!this.hasNewsToggleTarget) { return }

    const { searching } = e.detail
    if (searching) {
      this.newsToggleTarget.classList.add('hidden')
    } else {
      this.newsToggleTarget.classList.remove('hidden')
    }

    if (this.previouslySearchedValue) {
      this.frameTarget.scrollIntoView({ behavior: 'instant', block: 'start' })
    } else {
      this.previouslySearchedValue = true
    }
  }
}
