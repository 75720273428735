import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    selectedChoices: {
      type: Array,
      default: undefined
    },
    enableOverflowDetection: {
      type: Boolean,
      default: false
    }
  }

  static targets = ['selection', 'selectionTemplate', 'selectedValue', 'overflowIndicator']

  connect() {
    if (this.enableOverflowDetectionValue) {
      this.monitorOverflow()
    }
    this.element.classList.toggle('overflow-detection-enabled', this.enableOverflowDetectionValue)
  }

  disconnect() {
    if (this.enableOverflowDetectionValue) {
      this.unMonitorOverflow()
    }
  }

  onOverflowClick(event) {
    event.preventDefault()
    this.dispatch('overflow-click')
  }

  applySelectedChoices(event) {
    this.selectedChoicesValue = event.detail.choices
  }

  selectedChoicesValueChanged(value, previousValue) {
    this.updateSelection()
    this.dispatch('selected-choices-changed', {
      detail: { choices: this.selectedChoicesValue }
    })
    if (previousValue) {
      this.dispatch('changed', { detail: { choices: this.selectedChoicesValue } })
    }
  }

  updateSelection() {
    this.selectionTarget.innerHTML = ''
    this.selectedChoicesValue.slice().reverse().forEach((item) => {
      let templateContent = this.selectionTemplateTarget.innerHTML

      templateContent = templateContent.replace(/{{label}}/g, item.label)
      templateContent = templateContent.replace(/{{name}}/g, item.name)
      templateContent = templateContent.replace(/{{value}}/g, item.value)
      templateContent = templateContent.replace(/{{key}}/g, item.key)

      const templateElement = document.createElement('div')
      templateElement.innerHTML = templateContent

      this.selectionTarget.appendChild(templateElement.firstElementChild)
    })
  }

  removeSelection(event) {
    this.selectedChoicesValue = this.selectedChoicesValue
      .filter((choice) => choice.key !== event.params.key)
  }

  updateOverflowIndicator() {
    const overflowingCount = this.selectedValueTargets.filter((child) => child.classList.contains('overflowed')).length
    if (overflowingCount === 0) {
      this.overflowIndicatorTarget.classList.add('hidden')
    } else {
      this.overflowIndicatorTarget.textContent = `+${overflowingCount}`
      this.overflowIndicatorTarget.classList.remove('hidden')
    }
  }

  updateOverflowedElements() {
    const containerRect = this.element.getBoundingClientRect()
    this.selectedValueTargets.forEach((child) => {
      const childRect = child.getBoundingClientRect()
      const overflowed = (
        childRect.right > (containerRect.right - 10)
          || childRect.bottom > (containerRect.bottom)
      )
      child.classList.toggle('overflowed', overflowed)
    })
  }

  monitorOverflow() {
    this.selectionMutationObserver = new MutationObserver(() => {
      this.updateOverflowedElements()
      this.updateOverflowIndicator()
    });
    this.selectionResizeObserver = new ResizeObserver(() => {
      this.updateOverflowedElements()
      this.updateOverflowIndicator()
    })

    this.selectionMutationObserver.observe(this.selectionTarget, {
      attributes: false,
      childList: true,
      subtree: false
    });
    this.selectionResizeObserver.observe(this.selectionTarget)
  }

  unMonitorOverflow() {
    this.selectionMutationObserver.disconnect()
    this.selectionResizeObserver.disconnect()
  }

  clearAll() {
    this.selectedChoicesValue = []
  }

  valueAddedOrRemoved(event) {
    if (event.detail.operation === 'added' && !this.selectedChoicesValue.some((v) => v.key === event.detail.value.key)) {
      this.selectedChoicesValue = [...this.selectedChoicesValue, event.detail.value]
    } else {
      this.selectedChoicesValue = this.selectedChoicesValue
        .filter((v) => v.key !== event.detail.value.key)
    }
  }

  valuesAddedOrRemoved(event) {
    if (event.detail.operation === 'added') {
      this.selectedChoicesValue = [
        ...new Set([...this.selectedChoicesValue, ...event.detail.scope])
      ]
    } else {
      this.selectedChoicesValue = this.selectedChoicesValue.filter(
        (v) => !event.detail.scope.some((s) => s.key === v.key)
      )
    }
  }
}
