import React, {
  forwardRef, useEffect, useRef
} from 'react'
import RichTextFieldHelper from '../../helpers/RichTextFieldHelper'

import 'trix'

const idGenerator = (prefix = 'rich-text-field-') => {
  const dateTimestamp = new Date().getTime();
  const randomSalt = Math.random().toFixed(9).slice(2)
  return `${prefix}${dateTimestamp}-${randomSalt}`
}
const RichTextField = forwardRef(({
  placeholder, onChange, value, disableAttachments = true, disabled = false
}, ref) => {
  const containerRef = useRef()
  const hiddenId = useRef(idGenerator())

  const resetValue = () => {
    if (ref.current?.editor && ref.current.value !== value) {
      ref.current.editor.loadHTML(value)
    }
  }

  const addListeners = () => {
    ref.current.addEventListener('trix-change', onChange)
  }

  useEffect(() => {
    resetValue()
    const helper = new RichTextFieldHelper(containerRef.current)
    helper.configure()

    addListeners()
  })

  return (
    <div className="rich-text-field-component" data-component="shared/rich-text-field" data-disable-attachments={disableAttachments} data-disabled={disabled} ref={containerRef}>
      <input
        type="hidden"
        id={hiddenId.current}
      />
      <trix-editor input={hiddenId.current} placeholder={placeholder} ref={ref} />
    </div>
  )
})
export default RichTextField
