import CompressorLookup from './CompressedCards/CompressorLookup'

/**
 * A higher level function used to provide card compressing functionality.
 * Card compressing is quite simply replacing a number of cards of the same
 * 'grouping' (e.g. of the same type and from the same data provider)
 * with a 'rolled up' card saying 'there are lots more of these, click to
 * see them'.
 *
 * Note that this function is to be used as a function directly and not as a
 * react component.  This is to prevent 'wrapping' of the cards with this component
 * and preventing components higher up the chain from seeing the cards themselves.
 *
 * @example
 *
 * {
 *   CompressedCards(
 *     {
 *       collection: unseenItems,
 *       card: (item) => (<Card key={`${item.type}-${item.id}`} item={item} onShow={cardShown} />),
 *       compressedCard: (storedItems, CardFn) => (
 *         <CardFn key={`${storedItems[0].type}-${storedItems[0].id}`} onShow={cardShown} />
 *       ),
 *       disableCompression: true|false
 *     }
 *   )
 * }
 *
 * The example above shows a snippet from inside a jsx block.  Note how we do NOT
 * use <CompressedCards> which would create a component wrapping the cards.  Instead
 * the function is called directly and returns an array of cards - some of which
 * have been rolled up.
 *
 * This code works with the help of 2 key components :-
 *
 * 1. A 'compressor'
 * 2. A 'compressed card'
 *
 * A compressor is a class that extends 'Watchlist/CompressedCards/BaseCompressor'
 *  its role is to :-
 *  1. Test if an item is 'allowed in' to the existing group using 'canAccept'
 *  2. Allow adding of an item to the group using 'add'
 *  3. Provide the 'card' which is the 'compressed card' when 'card' is called
 *
 * A compressor must be registered against a type in 'Watchlist/CompressedCards/CompressorLookup'
 *
 * A 'compressed card' is the visual representation of the rolled up card.
 *   It must be registered against a type in 'Watchlist/CompressedCards/CardLookup'
 *   It must be wrapped using React.forwardRef for the card monitoring to work
 *   It is passed the children as an array which it must return when expanded
 *   It must just show a call to action to expand the cards when not expanded
 *   It doesn't generally have a 'collapse' call to action - although it could
 *
 * @param {Object} props An object containing the properties below
 * @param {Array<Object>} props.collection A collection of items at the data level
 * @param {Function} props.card A function which is passed an individual item and
 *                               returns the react element.
 * @param {Function} props.compressedCard A function which is passed the stored items and
 *                                         the compressed card function to allow the caller
 *                                         react component to be responsible for rendering.
 * @param {Boolean} props.disableCompression A boolean which can disable compression.
 * @return {Array[ReactElement]} An array of react elements to be rendered.
 */
const CompressedCards = (props) => {
  const {
    collection,
    card: cardRenderer,
    compressedCard: compressedCardRenderer,
    disableCompression
  } = props

  let previousCompressor = null
  const elements = collection.reduce((acc, watchlistItem) => {
    const CompressorClass = CompressorLookup[watchlistItem.item.type]
    /**  If previous compressor is of same type and is happy to accept this items
     *     then add this item to the previous compressor and render nothing */
    if (!watchlistItem.firstInPage && !disableCompression && CompressorClass
      && previousCompressor instanceof CompressorClass
      && previousCompressor.canAccept(watchlistItem)) {
      previousCompressor.add(watchlistItem)
      return acc
    }
    /** If the type has changed OR the previous compressor rejected the new item
     *   then we should ask the previous compressor for its cards that are to be
     *   added to the result.
     */
    const cards = []
    if (previousCompressor) { cards.push(previousCompressor.card()) }
    cards.push(cardRenderer(watchlistItem))
    previousCompressor = CompressorClass
      ? new CompressorClass(watchlistItem, cardRenderer, compressedCardRenderer)
      : null
    return acc.concat(cards)
  }, [])
  /**
   * If at the end we have a previousCompressor this means we got to the end but
   * nothing triggered the rendering of the compressed card so we do it here.
   */
  if (previousCompressor) {
    elements.push(previousCompressor.card())
  }
  return elements
}

export default CompressedCards
