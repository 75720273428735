import instantsearch from 'instantsearch.js';
import AutoCompleteSearch from './AutoCompleteSearch';
/* global HandlebarsTemplates */
export default class DataProviderSearch extends AutoCompleteSearch {
  // eslint-disable-next-line constructor-super
  constructor(controller) {
    super(controller.algoliaDataProviderIndexValue, controller);
  }

  searchListId() {
    return 'dp-results';
  }

  renderListItem({ hits }) {
    return HandlebarsTemplates.dp_autosearch_result({
      // Limit searches of each type to 5 hits
      hits: hits.slice(0, 5).map((hit) => ({
        Title: hit.title,
        highlightedTitle: instantsearch.highlight({ attribute: 'title', hit }),
        Url: hit.url,
        watchlistController: 'add-to-watchlist-button-component--add-to-watchlist-button-component',
        watchlistAction: '#emitToggleEvent',
        searchController: 'search-component--search-component',
        searchAction: '#toggleProviderWatcher',
        providerId: hit.objectID
      })),
      path: this.autosearchResultsPath
    });
  }
}
