import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router';
import conversationReducer from './conversationSlice'
import messageReducer from './messageSlice'
import appReducer from './appSlice'

const reducer = (history) => combineReducers({
  router: connectRouter(history),
  data: conversationReducer,
  messages: messageReducer,
  app: appReducer
})

export const config = (history) => ({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware(history)),
  reducer: reducer(history)
})

export default (history) => configureStore(config(history))
