import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.responsesStatusChanged();
  }

  responsesStatusChanged() {
    const selectValue = this.element.getElementsByClassName('responses-status-select')[0].value;
    const extraFields = this.element.getElementsByClassName('show-if-responses-enabled');

    if (selectValue === 'Disabled') {
      extraFields.forEach((e) => e.classList.add('hidden'));
    } else {
      extraFields.forEach((e) => e.classList.remove('hidden'))
    }
  }
}
