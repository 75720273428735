import React from 'react'

import Tooltip from '../../Shared/Tooltip'

const NewVendorBadge = ({ cardData }) => {
  if (!cardData.new_vendor) {
    return null
  }

  return (
    <Tooltip
      content="Vendors with this tag have participated in AltDating for less than 6 months."
    >
      <span className="new-vendor"><i className="icon fa-solid fa-star" /> New vendor</span>
    </Tooltip>
  )
}

export default NewVendorBadge
