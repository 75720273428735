import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['radioButton']

  changeValue(event) {
    this.submitForm(event)
  }

  submitForm(event) {
    const form = event.target.form || event.target.closest('form')
    form.requestSubmit()
  }
}
