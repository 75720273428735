import { DirectUploadProvider } from 'react-activestorage-provider'

class CustomizedDirectUploadProvider extends DirectUploadProvider {
  constructor(...args) {
    super(...args);
    this.handleBeginUpload = this.customHandleBeginUpload.bind(this)
  }

  async customHandleBeginUpload() {
    if (this.state.uploading) return

    this.setState({ uploading: true })

    // This assignment to signedIds is all that is different from the original code
    //    we need to enrich the promise result with the upload id to make the buttons that
    //    remove attachments before message submit work.
    const signedIds = await Promise.all(
      this.uploads.map((upload) => {
        const promise = upload.start()

        return promise.then((result) => ({ id: upload.id, signed_id: result }))
      })
    )

    this.props.onSuccess(signedIds)
    this.uploads = []
    this.setState({ fileUploads: {}, uploading: false })
  }
}

export default CustomizedDirectUploadProvider
