import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    selectedValues: Array,
    expandedGroups: Array,
    maxChildrenForOneColumn: {
      type: Number,
      default: 10
    }
  }

  static targets = ['modal', 'group', 'field', 'selection', 'selectionTemplate', 'column', 'selectionsComponent']

  saveSelection(event) {
    event.preventDefault()
    this.dispatch('saveSelectionRequested', { prefix: false, detail: { choices: this.selectionsController.selectedChoicesValue } })
    event.target.dispatchEvent(new CustomEvent('carousel:next', { prefix: false, bubbles: true }))
  }

  triggerGroupExpansion(event) {
    event.preventDefault()
    const group = event.currentTarget.closest('.group')
    this.expandGroup(group)
    this.scrollColumnForGroup(group)
  }

  scrollColumnForGroup(group) {
    const allTargets = [...this.groupTargets, ...this.fieldTargets]
    const first = allTargets.find((target) => target.dataset.parentKey === group.dataset.key)
    first.scrollIntoView({
      behavior: 'smooth',
      inline: 'end',
      block: 'start'
    })
  }

  expandGroup(group) {
    const allTargets = [...this.groupTargets, ...this.fieldTargets]
    // Start the scope of changes to all siblings
    function allDescendants(startingScope) {
      const descendants = []

      function findDescendants(nodes) {
        nodes.forEach((node) => {
          const children = allTargets.filter(
            (target) => target.dataset.parentKey === node.dataset.key
          )
          if (children.length > 0) {
            descendants.push(...children)
            findDescendants(children)
          }
        });
      }

      findDescendants(startingScope)
      return descendants
    }

    const scope = allDescendants(allTargets.filter(
      (target) => target.dataset.parentKey === group.dataset.parentKey
    ))
    scope.forEach((target) => {
      if (target.dataset.parentKey === group.dataset.key) {
        target.classList.remove('hidden')
      } else {
        target.classList.add('hidden')
      }
    })
    const currentTarget = this.groupTargets
      .find((target) => target.dataset.key === group.dataset.key)
    this.expandedGroupsValue = this.selfAndAncestors(currentTarget)
      .map((target) => target.dataset.key)
    this.hideEmptyColumns()
  }

  selectionChanged(event) {
    const field = this.fieldFor(event.target)
    const value = this.selectedValueFor(field);
    this.dispatch('value-changed',
      {
        detail:
          {
            value,
            operation: `${event.target.checked ? 'added' : 'removed'}`
          }
      })
  }

  selectedValueFor(field) {
    return {
      label: field.dataset.label,
      name: field.dataset.name,
      value: field.dataset.value,
      key: field.dataset.key
    }
  }

  updateCheckBoxes(value) {
    this.fieldTargets.forEach((fieldTarget) => {
      const checked = value.some(
        (v) => v.value === fieldTarget.dataset.value && v.name === fieldTarget.dataset.name
      )
      fieldTarget.querySelector('input[type=checkbox]').checked = checked
    })
    this.groupTargets.forEach((groupTarget) => {
      const allSiblingsChecked = this.fieldTargets
        .filter((t) => t.dataset.parentKey === groupTarget.dataset.key)
        .every((t) => t.querySelector('input[type=checkbox]').checked)
      groupTarget.querySelector('input[type=checkbox]').checked = allSiblingsChecked
    })
  }

  fieldFor(checkbox) {
    const fieldContainer = checkbox.closest('.field')
    return this.fieldTargets.find(
      (fieldTarget) => fieldTarget.dataset.key === fieldContainer.dataset.key
    )
  }

  clearAll(event) {
    event.preventDefault()
    this.selectionsController.clearAll()
    this.showOnlyTopLevel()
    this.element.dispatchEvent(new CustomEvent('filtersModal:resetDropdown', { bubbles: true }))
  }

  showOnlyTopLevel() {
    const allTargets = [...this.groupTargets, ...this.fieldTargets]
    allTargets.forEach((target) => {
      if (target.dataset.parentKey) {
        target.classList.add('hidden')
      } else {
        target.classList.remove('hidden')
      }
    })
    this.hideEmptyColumns()
  }

  selfAndAncestors(target) {
    const findAncestors = (currentTarget, ancestors = []) => {
      if (!currentTarget) return ancestors
      const nextTarget = this.groupTargets
        .find((groupTarget) => groupTarget.dataset.key === currentTarget.dataset.parentKey)
      return findAncestors(nextTarget, [currentTarget, ...ancestors]);
    };

    return findAncestors(target);
  }

  hideEmptyColumns() {
    let lastNonEmptyColumn = null
    this.columnTargets.forEach((columnTarget) => {
      const visibleChildren = columnTarget.querySelectorAll(':scope > :not(.hidden)')
      const empty = visibleChildren.length === 0
      const tooManyChildren = visibleChildren.length > this.maxChildrenForOneColumnValue
      const containsVisibleGroup = columnTarget.querySelector(':scope > .group:not(.hidden)');
      columnTarget.classList.toggle('empty', empty)
      columnTarget.classList.remove('last-non-empty')
      columnTarget.classList.toggle('contains-visible-groups', containsVisibleGroup)
      columnTarget.classList.toggle('too-many-children', tooManyChildren)
      if (!empty) lastNonEmptyColumn = columnTarget
    })
    lastNonEmptyColumn.classList.add('last-non-empty')
  }

  apply(event) {
    event.preventDefault()
    this.dispatch('applySelectedChoices', { detail: { choices: this.selectionsController.selectedChoicesValue } })
    event.target.dispatchEvent(new Event('modal:close', { prefix: true, bubbles: true }))
    this.element.innerHTML = ''
  }

  close(event) {
    event.preventDefault()
    event.target.dispatchEvent(new Event('modal:close', { prefix: true, bubbles: true }))
    this.element.innerHTML = ''
  }

  expandedGroupsValueChanged(value) {
    this.groupTargets.forEach((groupTarget) => {
      groupTarget.classList.toggle('expanded', value.includes(groupTarget.dataset.key))
    })
  }

  toggleDescendants(event) {
    const { checked } = event.currentTarget
    const scope = this.descendantFields(event.currentTarget.closest('.field,.group'))
    this.dispatch('values-changed',
      {
        detail:
          {
            scope: scope.map((s) => this.selectedValueFor(s)),
            operation: `${checked ? 'added' : 'removed'}`
          }
      })
    this.expandGroup(event.currentTarget.closest('.group'))
  }

  selectChoice(event) {
    const target = [...this.groupTargets, ...this.fieldTargets]
      .find((t) => t.dataset.key === event.detail.key)
    this.showFullPathTo(target)
    this.expandedGroupsValue = this.selfAndAncestors(target).map((t) => t.dataset.key)
    setTimeout(() => {
      target.querySelector('input[type="checkbox"]').focus()
    }, 0)
    this.hideEmptyColumns()
  }

  showFullPathTo(target) {
    const ancestorKeys = this.getAncestorKeys(target)
    const allTargets = [...this.groupTargets, ...this.fieldTargets]
    allTargets.forEach((t) => {
      t.classList.toggle('hidden', !!t.dataset.parentKey && !ancestorKeys.includes(t.dataset.parentKey))
    })
  }

  getAncestorKeys(target, results = []) {
    const allTargets = [...this.groupTargets, ...this.fieldTargets]
    if (target.dataset.parentKey) {
      results.push(target.dataset.parentKey)
      const parent = allTargets.find((t) => t.dataset.key === target.dataset.parentKey)
      this.getAncestorKeys(parent, results)
    }
    return results
  }

  get selectionsController() {
    return this.application.getControllerForElementAndIdentifier(
      this.selectionsComponentTarget, this.selectionsComponentTarget.dataset.controller
    )
  }

  applySelectedChoices(event) {
    this.updateCheckBoxes(event.detail.choices);
  }

  descendantTargets(target) {
    const allTargets = [...this.groupTargets, ...this.fieldTargets]
    const findDescendants = (scope, descendants = []) => {
      if (scope.length === 0) return descendants

      const childTargets = allTargets
        .filter((childTarget) => scope.some((a) => childTarget.dataset.parentKey === a.dataset.key))
      return findDescendants(childTargets, [...childTargets, ...descendants]);
    };

    return findDescendants([target]);
  }

  descendantFields(target) {
    return this.descendantTargets(target).filter((t) => this.fieldTargets.includes(t))
  }
}
