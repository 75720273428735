import React from 'react'
import { useSelector } from 'react-redux'
import FlashMessage from '../Shared/FlashMessage'

const WatchlistFlash = () => {
  const error = useSelector((state) => state.data.cardsError)
  const setAllSeenError = useSelector((state) => state.data.setAllSeenError)
  if ((error == null && setAllSeenError == null)) {
    return null;
  }

  return (
    <FlashMessage message="Sorry, something has gone wrong" />
  )
}
export default WatchlistFlash
