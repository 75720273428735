import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['submit']

  connect() {
    this.submitTarget.disabled = true
  }

  actionChange(e) {
    const val = e.detail.id
    this.submitTarget.disabled = (val === '')
  }
}
