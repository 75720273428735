import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'trigger']

  static values = {
    open: { type: Boolean, default: false },
    disableOutsideClick: { type: Boolean, default: false }
  }

  connect() {
    window.addEventListener('closeDropdown', () => { this.close() })
  }

  toggle() {
    if (this.openValue) {
      this.close()
    } else {
      this.openValue = true
    }
  }

  close() {
    this.openValue = false
    this.dispatch('close')
  }

  openValueChanged(value) {
    this.contentTarget.dataset.open = value
    this.triggerTarget.dataset.open = value
    if (value) {
      this.triggerTarget.classList.add('open')
    } else {
      this.triggerTarget.classList.remove('open')
    }
  }

  clickOutside(event) {
    if (this.disableOutsideClickValue) return
    if (!this.openValue || this.element.contains(event.target)) return

    this.close()
  }
}
