/**
 * This class intercepts the back button and allows you to resume the
 * intercepted action.  Participation in the interception is opt-in and starts by
 * the page that is being navigated from having its link modified to include
 * data-scout-allow-back-button-intercept="true".
 * The link MUST be a turbo enabled link.  This does not work with normal links.
 *
 * To do a similar thing with normal links, see the following:
 * use the 'beforeunload' event which is a standard browser event.
 *
 * The page that is being navigated to must have a listener for the
 * scout:back-button-intercept event and call the resume function that is passed
 * in the event detail if it needs to resume the intercepted action maybe after some
 * confirmation from the user etc..
 *
 * Alternatively, the resume function may never be called, in which case the
 * intercepted action will not be resumed and the user will remain on the page.
 *
 * How It Works
 * ------------
 *
 * It works by pushing two states onto the history stack, and then
 * listening for the popstate event.
 *
 * The two states are needed in order to tell the difference between back and forward navigation.
 *
 * If the popstate event is received and the state is the last of the 2,
 *  it means the user has navigated backwards
 *
 * If the popstate event is received and the state is the first of the 2,
 * it means the user has navigated forwards and
 * it automatically navigates forward 2 in order to go forward
 * to the page that was originally navigated to.
 *
 * Once it is established that the user has navigated backwards, it fires a
 * custom event `scout:back-button-intercept` on the
 * window object, and if the event is not prevented,
 *  it goes back two steps in the history, which is the original page.
 *
 * If the event is prevented, it will not go back two steps in the history,
 * and the user will remain on the page they navigated to.
 *
 * The custom event `scout:back-button-intercept` is fired with the
 * following properties in the 'detail' of the event.
 * - url: the url of the page the user is navigating to
 * - resume: a function that can be called to resume the intercepted action
 *
 * The intercepted action is resumed by calling the resume function.
 *
 */
class ScoutBackButtonIntercept {
  start() {
    window.addEventListener('popstate', this.onPopState.bind(this))
    window.addEventListener('turbo:click', this.beforeClick.bind(this))
  }

  onPopState(event) {
    if (event.state?.scout?.allowBackButtonIntercept) {
      if (event.state.scout.first) {
        window.history.go(2)
      }
      if (event.state.scout.last) {
        window.history.forward()
        const newEvent = new CustomEvent('scout:back-button-intercept', { detail: { url: window.location.href, resume: this.resumeIntercepted.bind(this) }, cancelable: true })
        window.dispatchEvent(newEvent)
        if (!newEvent.defaultPrevented) {
          window.history.go(-3)
        }
      }
    }
  }

  beforeClick(event) {
    if (event.target.dataset.scoutAllowBackButtonIntercept === 'true') {
      this.addDummyStates()
    }
  }

  resumeIntercepted() {
    window.history.go(-3)
  }

  addDummyStates() {
    window.history.pushState({ scout: { allowBackButtonIntercept: true, first: true } }, '')
    window.history.pushState({ scout: { allowBackButtonIntercept: true, last: true } }, '')
  }
}
const interceptor = new ScoutBackButtonIntercept()
interceptor.start()
window.scoutBackButtonInterceptor = interceptor
