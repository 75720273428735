import React, { forwardRef } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Modal from '../Shared/Modal'
import ItemShareableLink from './ItemShareableLink'
import Date from './Date'

dayjs.extend(relativeTime)

const MaxProviders = 3
const showModal = (overflowedDataProviders) => () => {
  const wrappedComponent = (
    <div className="m-watchlist-page">
      <div className="overflow-modal">
        <div className="header">
          Vendors
          <button type="button" className="close-button" onClick={() => Modal.clickCancel()}>
            <i className="icon fa-solid fa-times" />
          </button>
        </div>
        {overflowedDataProviders.map((dataProvider) => (
          <a key={dataProvider.id} href={dataProvider.html_path} target="_blank" rel="noreferrer">
            <div className="data-provider-row">

              <div className="image-column">
                <img
                  src={dataProvider.data_provider_logo}
                  alt={dataProvider.title}
                />
              </div>
              <div className="title-column">
                <span className="title">
                  {dataProvider.title}
                </span>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  )
  Modal.fire({
    html: wrappedComponent,
    width: '30%',
    showCancelButton: false,
    showConfirmButton: false,
    background: '#f4f4f5',
    position: 'centre',
    padding: '0px',
    showClass: {
      popup: 'modal-fade-in'
    },
    hideClass: {
      popup: 'modal-fade-out'
    },
    customClass: {
      popup: 'modal-popup'
    }
  })
}

const DataProvidersOverflow = (props) => {
  const others = props.dataProviders.slice(MaxProviders)
  if (others.length === 0) {
    return null
  }

  return (
    <span>
      &nbsp;and&nbsp;
      <button type="button" className="overflow-button" onClick={showModal(props.dataProviders)}>
        {others.length}
        {others.length === 1 ? ' other' : ' others'}
      </button>
    </span>
  )
}

const DataProviders = (props) => {
  const providers = props.dataProviders.slice(0, MaxProviders);
  return (
    <>
      { providers.map((dataProvider, idx) => (
        <span className="data-provider-name" key={dataProvider.id}>
          <a href={dataProvider.html_path} target="_blank" rel="noreferrer">
            {dataProvider.title}
          </a>
          { idx < (providers.length - 1) ? ', ' : ''}
        </span>
      )) }
      <DataProvidersOverflow dataProviders={props.dataProviders} />
    </>
  )
}

const DataProviderImages = (props) => {
  const dataProvidersWithImages = props.dataProviders.filter(
    (dataProvider) => dataProvider.data_provider_logo
  )
  const additionalProviders = props.dataProviders.length - MaxProviders
  return (
    <>
      {dataProvidersWithImages.slice(0, MaxProviders).map((dataProvider) => (
        <div key={dataProvider.id} className="logo-container">
          <img
            src={dataProvider.data_provider_logo}
            alt={dataProvider.title}
          />
        </div>
      ))}
      {dataProvidersWithImages.length === 0 && (
        <div className="logo-container no-image-fallback" />
      )}
      <span className={`number-badge ${additionalProviders > 0 ? '' : 'hidden'}`}>
        {`+${additionalProviders}`}
      </span>
    </>
  )
}

const Dates = (props) => {
  const publicationDate = dayjs(props.item.publication_date).fromNow();
  const postedDate = dayjs(props.item.original_publication_date).fromNow();
  if (publicationDate === postedDate) {
    return (
      <div className="single-date">
        <Date date={props.item.publication_date} />
      </div>
    )
  }

  return (
    <div className="multiple-dates">
      <span>
        <span className="text">Updated: </span>
        <Date date={props.item.publication_date} />
      </span>
      <span>
        <span className="text">Posted: </span>
        <Date date={props.item.original_publication_date} />
      </span>
    </div>
  )
}

const ComplianceResponseTemplate = (props) => {
  const image = (
    <div className="image-container border">
      <img
        className="inner-image"
        src={props.data_provider.data_provider_logo}
        alt={props.data_provider.title}
      />
    </div>
  )

  return (
    <div className={`compliance-response ${props.type}`}>
      <div className="image">
        {image}
      </div>
      <div className="body">
        <span>
          <DataProviders dataProviders={[props.data_provider]} />
          { ' ' }
          {props.tagline}
        </span>
        {props.timestamp ? <Date date={props.timestamp} /> : ''}
        {/* eslint-disable-next-line react/no-danger */}
        {props.content ? <div className="content" dangerouslySetInnerHTML={{ __html: props.content }} /> : ''}
      </div>
    </div>
  )
}

const ComplianceResponse = (props) => {
  if (props.response.responded) {
    return (
      <ComplianceResponseTemplate
        data_provider={props.response.data_provider}
        type="responded"
        tagline="responded"
        timestamp={props.response.publication_date}
        content={props.response.content}
      />
    )
  } if (props.closed) {
    return (
      <ComplianceResponseTemplate
        data_provider={props.response.data_provider}
        type="closed"
        tagline="did not provide a response"
      />
    )
  }
  return (
    <ComplianceResponseTemplate
      data_provider={props.response.data_provider}
      type="pending"
      tagline="has not yet responded"
    />
  )
}

const ComplianceResponses = (props) => {
  if (props.responses.length === 0) {
    return ''
  }

  const allPending = !props.closed && props.responses.every((r) => !r.responded)

  if (allPending) {
    return (
      <div className="compliance-responses-pending">
        <i className="fas fa-reply" />
        <span>
          Vendor response
          {props.responses.length > 1 ? 's' : ''}
          {' '}
          requested
        </span>
      </div>
    )
  }

  const responses = props.responses.map((r) => (
    <ComplianceResponse closed={props.closed} response={r} key={r.id} />
  ))
  return (
    <div className="compliance-responses">
      {responses}
    </div>
  )
}

const ComplianceAlertCard = forwardRef((props, ref) => (
  <li ref={ref} className={`watchlist-card compliance-alert ${props.watchlistItem.item.high_priority ? 'high-priority' : ''}`}>
    <div className="basic-card-details">
      <div className="info">
        <div className="summary-container">
          <div className={`images ${props.watchlistItem.data_providers.length === 0 ? 'hidden' : ''}`}>
            <DataProviderImages dataProviders={props.watchlistItem.data_providers} />
          </div>
          <span className="summary">
            <span>
              <span className="title">
                <DataProviders dataProviders={props.watchlistItem.data_providers} />
                {!props.watchlistItem.item.title.startsWith(',') && <span>&nbsp;</span> }
                {props.watchlistItem.item.title}
              </span>
            </span>
            <div className="date">
              <Dates item={props.watchlistItem.item} />
            </div>
          </span>
        </div>
      </div>
      <ItemShareableLink item={props.watchlistItem.item} />
    </div>
    <div className="additional-card-details">
      <div className="content">
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: props.watchlistItem.item.content }} />
      </div>
      <ComplianceResponses
        responses={props.watchlistItem.item.compliance_responses}
        closed={props.watchlistItem.item.closed}
      />
    </div>
  </li>
))

export default ComplianceAlertCard
