import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['subscriber']

  submit(event) {
    if (event.target.type === 'text') { return } // We don't auto submit text fields

    this.element.requestSubmit()
  }

  clearFilters() {
    this.subscriberTargets.forEach((element) => {
      element.dispatchEvent(new CustomEvent('clear'))
    })
  }
}
