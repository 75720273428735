import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAdditionalContactEmail,
  setAdditionalContactEmail,
  selectContactId
} from '../../../OneToOne/buysidePickProcessSlice'
import axiosRails from '../../../axiosRails';
import vendorPicksConfig from '../../../OneToOne/vendorPicksConfig';

const AdditionalContactForm = ({ setSuccessMessage, setErrorMessage }) => {
  const additionalContactEmail = useSelector(selectAdditionalContactEmail)
  const buysideContactId = useSelector(selectContactId)
  const [newAdditionalContactEmail, setNewAdditionalContactEmail] = useState(additionalContactEmail)
  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()
    axiosRails.patch(
      vendorPicksConfig.updateBuysideContactUrl({ buysideContactId }),
      JSON.stringify({
        one_to_one_buyside_contact: {
          additional_contact_email: newAdditionalContactEmail
        }
      }),
      {
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
      }
    )
      .then(() => {
        dispatch(setAdditionalContactEmail(newAdditionalContactEmail))
        setSuccessMessage('Additional contact succesfully added')
      })
      .catch((error) => {
        if (error.isAxiosError) {
          return setErrorMessage(error.response.data.errors.map(({ detail }) => detail).join())
        }

        // eslint-disable-next-line no-console
        console.error('Error', error)
        return null
      })
  }

  return (
    <div className="buyside-picks-additional-contact">
      <h2>Additional Contact</h2>
      <p>
        The person to contact if rescheduling is required
      </p>
      <form onSubmit={handleSubmit}>
        <div className="field required">
          <input value={newAdditionalContactEmail || ''} onChange={(e) => { setNewAdditionalContactEmail(e.target.value) }} placeholder="Enter email address" type="text" />
        </div>
        <button name="button" type="submit" className="action-button">Add Contact</button>
      </form>
    </div>
  )
}

export default AdditionalContactForm
