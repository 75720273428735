import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['selectField', 'textField', 'select2Field']

  connect() {
    this.updateFields();
  }

  fieldChange() {
    this.updateFields();
  }

  updateFields() {
    if (this.selectFieldTarget.value === 'webinar') {
      this.textFieldTarget.setAttribute('disabled', 'disabled');
      this.textFieldTarget.value = 'Online';
      this.select2FieldTarget.setAttribute('disabled', 'disabled');
      window.$(this.select2FieldTarget).val(null).trigger('change');
    } else {
      this.textFieldTarget.removeAttribute('disabled');
      this.select2FieldTarget.removeAttribute('disabled');
    }
  }
}
