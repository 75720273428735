import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    dataProviderId: Number
  }

  emitToggleEvent() {
    this.dispatch('watchlist-button-toggled', { detail: { dataProviderId: this.dataProviderIdValue } });
  }

  toggle(e) {
    if (e.detail.dataProviderId === this.dataProviderIdValue) {
      this.element.classList.toggle('watched');
      this.element.classList.toggle('unwatched');
    }
  }
}
