import React from 'react'

const TrackingContainer = ({
  children, track = true, action, object = null, extraInfo = null, testid = 'trackingContainer'
}) => (
  <div
    data-testid={testid}
    data-user-pick-tracking-attribute={track || null}
    data-user-pick-tracking-attribute-action={action}
    data-user-pick-tracking-attribute-object={object}
    data-user-pick-tracking-attribute-extra-info={extraInfo}
    style={{ display: 'contents' }}
  >
    {children}
  </div>
)

export default TrackingContainer
