import { combineReducers, configureStore } from '@reduxjs/toolkit'
import globalReducer from './globalStoreSlice'

const reducer = () => combineReducers({
  global: globalReducer
})

/**
 * A redux store that is used globally.  Accessed using 'globalStoreInstance'
 * @returns Redux store
 */
export default () => configureStore({
  reducer: reducer(),
  devTools: { name: 'globalStore' }
})
