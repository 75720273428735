import React from 'react'

/**
 * A component to show an avatar for a given participant
 * Depending on the value of avatar.type, renders either a logo or a styled letter
 * If the value of the avatar.url in the data is null or undefined, a default is used
 * @param avatar Union type, url and letter may both be null
 *   { type: 'logo', url: 'https://some-url.com/image.jpg' } or
 *   { type: 'letter', letter: 'A' }
 * @returns {JSX.Element}
 * @constructor
 */
const ParticipantAvatar = ({ avatar }) => {
  switch (avatar.type) {
    case 'logo':
      return <LogoAvatar url={avatar.url} />
    case 'letter':
      return <LetterAvatar letter={avatar.letter} />
    case 'neudata':
      return <NeudataAvatar url={avatar.url} />
    default:
      return null
  }
}

const LetterAvatar = ({ letter }) => {
  const styles = {
    font: '20px sans-serif', fill: 'white', lineHeight: '30px', dominantBaseline: 'central', textAnchor: 'middle', userSelect: 'none', textTransform: 'uppercase'
  }
  return (
    <div className="avatar">
      <svg data-testid="Avatar" width="40" height="40">
        <rect width="40" height="40" rx="6" ry="6" fill="#1F73F0" />
        <text style={styles} x="50%" y="50%">{ letter }</text>
      </svg>
    </div>
  )
}

const LogoAvatar = ({ url }) => (
  <div className="avatar">
    <span className="image-container">
      <img data-testid="Avatar" src={url || '/assets/chat/default-profile-image.png'} alt="" />
    </span>
  </div>
)

const NeudataAvatar = ({ url }) => (
  <div className="avatar">
    <span className="image-container-neudata">
      <img data-testid="Avatar" src={url} alt="" />
    </span>
  </div>
)

export default ParticipantAvatar
