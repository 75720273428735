import React, { useState } from 'react'
import Modal from 'react-modal';
import axios from '../../axiosRails';
import vendorPicksConfig from '../../OneToOne/vendorPicksConfig';

const RequestReportModal = ({
  dataProviderName, dataProviderID, isOpen, onClose
}) => {
  const [error, setError] = useState(false)
  const sendRequest = () => {
    axios.post(
      vendorPicksConfig.requestDatasetUrl(),
      JSON.stringify({
        request_dataset: {
          data_provider_id: dataProviderID
        }
      }),
      { headers: { 'Content-Type': 'application/json' } }
    ).then(() => {
      onClose()
    }).catch(() => {
      setError(true)
    })
  }

  const style = {
    overlay: {
      backgroundColor: '#000000D0'
    }
  }

  return (
    <Modal isOpen={isOpen} className="request-report-modal" onRequestClose={onClose} style={style}>
      <h2>Request Scout report</h2>
      <p>Submit your request for a <strong>{dataProviderName}</strong> report.</p>
      <p>The report will be emailed to you within 24 hours</p>
      { error && <p className="error">Sorry, something has gone wrong - please try again or cancel</p> }
      <div className="request-report-modal__actions">
        <button className="cancel-button" type="button" onClick={onClose}>
          Cancel
        </button>
        <button className="send-button" type="button" onClick={sendRequest}>
          Send request
        </button>
      </div>
    </Modal>
  )
}
export default RequestReportModal
