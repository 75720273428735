import './watchlist_content_type_filter.scss'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAllFilters, setFilterSelection } from '../../Watchlist/contentTypeFilterSlice';
import ToggleInput from '../Shared/ToggleInput';

const ContentTypeFilters = () => {
  const contentTypes = useSelector((state) => state.contentTypeFilter.filters)
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.data.cardsLoading)
  return (
    <div className="content-type-filters">
      <span className={`select ${loading ? 'hidden' : ''}`}>
        Show:
        <button type="button" className="select-all" onClick={() => { dispatch(setAllFilters(true)) }}>All</button>
        /
        <button type="button" className="select-none" onClick={() => { dispatch(setAllFilters(false)) }}>None</button>
      </span>
      {contentTypes.map((contentType) => {
        const checkboxId = `content-type-filter-checkbox-${contentType.slug || 'all'}`
        let { visibility } = contentType;
        // enabled && loading -> disabled. otherwise use default.
        visibility = (visibility === 'enabled' && loading) ? 'disabled' : visibility
        const contentTypeIcons = {
          all: 'fa-solid fa-infinity',
          scout_dataset_updates: 'fa-solid fa-file-chart-line',
          ddq_content: 'fa-solid fa-gavel',
          posts: 'fa-solid fa-newspaper',
          sentry_intelligences: 'fa-solid fa-newspaper',
          scout_post_watchlist_items: 'fa-solid fa-newspaper',
          newsflow_articles: 'fa-solid fa-newspaper',
          announcements: 'fa-solid fa-feather-alt',
          compliance_alerts: 'fa-solid fa-chess-rook',
          risk_check_updates: 'fa-solid fa-chess-rook',
          product_updates: 'fa-solid fa-sparkles',
          vendor_updates: 'fa-solid fa-hexagon-check'
        }
        return (
          <div className={`filter-button ${visibility} ${contentType.slug || 'all'}`} key={contentType.slug}>
            <label htmlFor={checkboxId}>
              <i className={`icon ${contentTypeIcons[contentType.slug || 'all']}`} />
              {contentType.title}
            </label>
            <ToggleInput
              disabled={visibility !== 'enabled'}
              id={checkboxId}
              checked={contentType.selected}
              className={`switch ${visibility}`}
              onClick={() => {
                dispatch(setFilterSelection(
                  { slug: contentType.slug, value: !contentType.selected }
                ))
              }}
            />
          </div>
        )
      })}
    </div>
  )
}

export default ContentTypeFilters
