import React, { useState, useRef } from 'react'

import qs from 'qs'
import PreferenceToggle from './PreferenceToggle'
import UserFrequencySelect from './UserFrequencySelect'

const Preview = ({ previewPath, token }) => {
  if (!previewPath) { return '' }

  return (
    <div className="preview-container">
      <a className="button" href={`${previewPath}?${qs.stringify({ token })}`} target="_blank" rel="noreferrer">
        <span>Preview <i className="fas fa-external-link-alt" /></span>
      </a>
    </div>
  )
}

const ResponseFlag = (props) => {
  setTimeout(() => {
    if (props.display) {
      props.setDisplay(false)
    }
  }, 3000)

  if (props.success) {
    return (
      <div className={`preference-toggle-message success ${props.display ? 'fadein' : 'fadeout'}`}>
        <div>
          Saved <i className="fas fa-check" />
        </div>
      </div>
    )
  }
  return (
    <div className={`preference-toggle-message fail ${props.display ? 'fadein' : 'fadeout'}`}>
      <div>
        Error <i className="fas fa-times" />
      </div>
    </div>
  )
}

const PreferenceCard = ({
  shouldSend,
  email,
  mailingList,
  path,
  previewPath,
  frequencies,
  defaultFrequency,
  token,
  highlightedMailingListId
}) => {
  // Maintain toggle state in the parent component, so that we can share it with UserFrequencySelect
  // When toggle is off, the UserFrequencySelect is disabled.
  const [currentShouldSend, setCurrentShouldSend] = useState(shouldSend);
  const [displayResponseFlag, setDisplayResponseFlag] = useState(false);
  const responseFlagStatusRef = useRef(false);

  return (
    <div className={`preference-card ${mailingList.email_sender}-sender`}>
      <div className="col1">
        <div className="title">
          <div>{mailingList.title}</div>
          { mailingList.is_popular && <div className="popular">Popular</div> }
          {
            highlightedMailingListId === mailingList.id && <span className="highlighted">This email brought you here!</span>
          }
        </div>
        <div className="description">
          {mailingList.description}
        </div>
        <div className="button-footer">
          {
            mailingList.has_user_selected_frequency && (
              <UserFrequencySelect
                displayResponseStatusRef={responseFlagStatusRef}
                setDisplayResponseFlag={setDisplayResponseFlag}
                frequencies={frequencies}
                defaultFrequency={defaultFrequency}
                email={email}
                mailingList={mailingList.id}
                path={path}
                shouldSend={currentShouldSend}
                setShouldSend={setCurrentShouldSend}
                token={token}
              />
            )
          }
          <Preview previewPath={previewPath} token={token} />
        </div>
      </div>
      <div className="col2">
        <ResponseFlag
          success={responseFlagStatusRef.current}
          display={displayResponseFlag}
          setDisplay={setDisplayResponseFlag}
        />
        <PreferenceToggle
          displayResponseStatusRef={responseFlagStatusRef}
          setDisplayResponseFlag={setDisplayResponseFlag}
          shouldSend={currentShouldSend}
          setShouldSend={setCurrentShouldSend}
          email={email}
          mailingList={mailingList.id}
          path={path}
          token={token}
        />
      </div>
    </div>
  )
}
export default PreferenceCard
