import { Controller } from '@hotwired/stimulus';
import { getCookie, setCookie } from '../../../javascript/src/utils/cookies';

const cookieName = 'compact-latest-news';

export default class extends Controller {
  static targets = ['content', 'expandElement', 'collapseElement']

  static values = { forceOpen: Boolean, expanded: Boolean }

  connect() {
    if (this.forceOpenValue) {
      this.expandedValue = true
    } else {
      const cookieValue = getCookie(cookieName)
      this.expandedValue = cookieValue == null || cookieValue === 'false'
    }
  }

  expand(event) {
    event.preventDefault()

    this.expandedValue = true
    setCookie(cookieName, !this.expandedValue)
  }

  collapse(event) {
    event.preventDefault()
    if (this.forceOpenValue) { return }

    this.expandedValue = false
    setCookie(cookieName, !this.expandedValue)
  }

  expandedValueChanged() {
    this.element.classList.toggle('expanded', this.expandedValue)
  }
}
