import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  setShowAllFromCheckbox(event) {
    if (event.target.checked) {
      this.element.classList.add('show-all');
      this.fieldsComponentControllers().forEach((controller) => {
        controller.showAll()
      })
    } else {
      this.element.classList.remove('show-all');
      this.fieldsComponentControllers().forEach((controller) => {
        controller.showChangedOnly()
      })
    }
  }

  fieldsComponentControllers() {
    const { application } = this
    return Array.from(this.element.querySelectorAll('.eav-diff--fields-component'))
      .map((field) => application
        .getControllerForElementAndIdentifier(field, field.dataset.controller))
  }
}
