import { Controller } from '@hotwired/stimulus';
import completeChallenge from '../form_with_captcha_component/completeChallenge';

export default class extends Controller {
  static values = {
    siteKey: String
  }

  connect() {
    window.turnstile.render(this.element, {
      sitekey: this.siteKeyValue,
      // eslint-disable-next-line no-unused-vars
      callback: function (_) { completeChallenge(this.element) }.bind(this)
    })
  }
}
