import React from 'react'
import MessageCard from './MessageCard';
import AutoReplyCard from './AutoReplyCard';
import DateGroupHeaderCard from './DateGroupHeaderCard';
import AutoReplyMessageSeenAsSentFromCard from './AutoReplyMessageSeenAsSentFromCard'
/**
 * Chooses which card to render based on the message tyoe
 * @param props
 * @param {Object} props.message The message data
 * @returns {JSX.Element|null}
 * @constructor
 */
const CardFor = (props) => {
  switch (props.message.type) {
    case 'messages':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return (<MessageCard {...props} />)
    case 'auto-replies':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return (<AutoReplyCard {...props} />)
    case 'auto-replies-message-seen-as-sent-from':
      return (<AutoReplyMessageSeenAsSentFromCard />)
    case 'internal-date-group-header':
      // eslint-disable-next-line react/jsx-props-no-spreading
      return (<DateGroupHeaderCard {...props} />)
    default:
      // eslint-disable-next-line no-console
      console.error(`Missing card definition for type '${props.message.type}'`)
      return null
  }
}
export default CardFor
