import './no_messages_page.scss';
import React from 'react'
import { VENDOR_PARTICIPANT_TYPE } from '../../utils/Chat/participants'

/**
 * A static page shown when no conversation is selected in the chat window
 * @returns {JSX.Element}
 * @constructor
 */
const NoMessagesPage = ({ participantType }) => {
  const vendorsNoMessagesText = 'Buyers and the Neudata team make the first move by inviting you to respond to questions and data requests. Find your conversations here.'
  const clientsNoMessagesText = 'Start conversations from the search bar, vendor profiles and dataset pages'
  const noMessagesText = participantType === VENDOR_PARTICIPANT_TYPE
    ? vendorsNoMessagesText
    : clientsNoMessagesText

  const CustomerFooter = () => {
    if (participantType === VENDOR_PARTICIPANT_TYPE) return null

    return (
      <>
        <span className="customer-footer-1" />
        <span className="customer-footer-2" />
      </>
    )
  }
  return (
    <div data-component="chat/no-messages" className="no-messages-page">
      <div className="title">
        <div><i className="fa-duotone fa-solid fa-comments-alt" /></div>
        <div className="text">
          <h4>Messaging</h4>
          <div className="new-badge">
            NEW
          </div>
        </div>
      </div>
      <div className="content">
        <span>
          <i className="icon fa-solid fa-message-lines" />{noMessagesText}
        </span>
      </div>
      <div className="customer-footer">
        <CustomerFooter />
      </div>
    </div>
  )
}
export default NoMessagesPage
