/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react'
import TextAreaCard from './TextAreaCard'

const ReviewFeedCard = forwardRef((props, ref) => (
  <TextAreaCard ref={ref} {...props}>
    {props.asideMessage}
  </TextAreaCard>
))

export default ReviewFeedCard
