import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toggleFilterVisibility } from '../../Watchlist/watchlistSlice'

const ToggleFilterVisibility = () => {
  const showFilters = useSelector((state) => state.data.showFilters)
  const dispatch = useDispatch()
  return (
    <div className="hide-filters">
      <button type="button" onClick={() => { dispatch(toggleFilterVisibility()) }}>
        {showFilters ? 'Hide Nav + Filters' : 'Show Nav + Filters'}
      </button>
    </div>
  )
}

export default ToggleFilterVisibility
