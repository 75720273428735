import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toggleExpandedCards, selectAllSelectedFilters } from '../../../OneToOne/vendorPicksSlice'
import ToggleInput from '../../Shared/ToggleInput'
import TrackingContainer from '../../TrackingContainer'

const ExpandCardsToggle = () => {
  const loading = useSelector((state) => state.data.cardsLoading)
  const expanded = useSelector((state) => state.data.expanded)
  const dispatch = useDispatch()
  const allSelectedFilters = useSelector(selectAllSelectedFilters)

  const checked = !loading && expanded

  const onClick = () => { dispatch(toggleExpandedCards()) }

  return (
    <div className="toggle-container expand-cards-toggle">
      <span>Expand All Profiles</span>
      <TrackingContainer
        action={checked ? 'Hide all profiles' : 'Show all profiles'}
        extraInfo={JSON.stringify(allSelectedFilters)}
      >
        <ToggleInput className="switch" onClick={onClick} checked={checked} />
      </TrackingContainer>
    </div>
  )
}

export default ExpandCardsToggle
