import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    titleFieldName: String
  }

  syncTitleFields(event) {
    const navigationBarTitleId = 'navigation_bar_title'
    const navigationBarTitle = document.getElementById(navigationBarTitleId)

    if (event.target.id === navigationBarTitleId) {
      const formField = document.getElementById(this.titleFieldNameValue)
      const customEvent = new CustomEvent('form-component-modified', { detail: { value: event.target.value }, bubbles: true })

      formField.value = event.target.value
      formField.dispatchEvent(customEvent)
    } else {
      navigationBarTitle.value = event.target.value
    }
  }
}
