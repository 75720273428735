import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    closeOnOutsideClick: Boolean,
    originalOverflow: String
  }

  connect() {
    if (this.element.classList.contains('hidden')) { return }

    this.open()
  }

  disconnect() {
    document.body.style.overflow = this.originalOverflowValue
  }

  open() {
    this.element.classList.remove('hidden')
    this.originalOverflowValue = this.element.closest('body').style.overflow
    document.body.style.overflow = 'hidden'
  }

  close() {
    this.element.classList.add('hidden')
    document.body.style.overflow = this.originalOverflowValue
  }

  closeIfOutside(event) {
    if (event.target !== this.element || !this.closeOnOutsideClickValue) { return }

    this.close()
  }
}
