import React from 'react'

import Tooltip from '../../Shared/Tooltip'

const PickedByVendorBadge = ({ cardData }) => {
  if (!cardData.picked_by_vendor) {
    return null
  }

  return (
    <Tooltip
      content="Vendors have already made their picks for this event. Vendors with this tag have shown a specific interest in meeting you."
    >
      <span className="picked-you"><i className="icon fa-solid fa-thumbs-up" />Wants to meet you</span>
    </Tooltip>
  )
}

export default PickedByVendorBadge
