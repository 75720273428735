import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pick, unpick, selectAllSelectedFilters } from '../../OneToOne/vendorPicksSlice'
import TrackingContainer from '../TrackingContainer'

const PickOrRemoveButton = (props) => {
  const dispatch = useDispatch()
  const allSelectedFilters = useSelector(selectAllSelectedFilters)

  if (props.item.picked_by_buyside) {
    return (
      <TrackingContainer
        action="Manual unpick"
        object={props.item.title}
        extraInfo={JSON.stringify(allSelectedFilters)}
      >
        <button type="button" className={`remove-button fa-regular fa-trash-alt ${props.item._pickSyncState || 'complete'}`} onClick={() => dispatch(unpick(props.item.id))}>Remove meeting</button>
      </TrackingContainer>
    )
  }
  return (
    <TrackingContainer
      action="Manual pick"
      object={props.item.title}
      extraInfo={JSON.stringify(allSelectedFilters)}
    >
      <button type="button" className={`pick-button fa-regular fa-plus ${props.item._pickSyncState || 'complete'}`} onClick={() => dispatch(pick(props.item.id))}>Meet this vendor</button>
    </TrackingContainer>
  )
}

export default PickOrRemoveButton
