import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import {
  getDataProviders, getSharedDataProviders, getPersonalDataProviders
} from '../../Watchlist/selectors'
import { ALL_WATCHLIST_FEED_TYPE, PERSONAL_WATCHLIST_FEED_TYPE, SHARED_WATCHLIST_FEED_TYPE } from '../../constants/watchlist'

const Tab = ({ feedType, name, dataProviders }) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const feedTypeParam = searchParams.get('feedType')
  const isSelected = feedTypeParam === feedType

  const classes = classNames('tab', { selected: isSelected })
  return (
    <Link to={`/feed?feedType=${feedType}`} className={classes}>
      {name} ({dataProviders.length})
    </Link>
  )
}

const Tabs = () => {
  const allDataProviders = useSelector(getDataProviders)
  const personalDataProviders = useSelector(getPersonalDataProviders)
  const sharedDataProviders = useSelector(getSharedDataProviders)

  return (
    <div className="tabs">
      <Tab name="All" feedType={ALL_WATCHLIST_FEED_TYPE} dataProviders={allDataProviders} />
      <Tab name="My" feedType={PERSONAL_WATCHLIST_FEED_TYPE} dataProviders={personalDataProviders} />
      <Tab name="Shared" feedType={SHARED_WATCHLIST_FEED_TYPE} dataProviders={sharedDataProviders} />
    </div>
  )
}

export default Tabs
