/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react'
import PostCard from './PostCard'
import AnnouncementCard from './AnnouncementCard'
import DDQCard from './DDQCard'
import DatasetCard from './DatasetCard'
import NewsflowArticleCard from './NewsflowArticleCard'
import ComplianceAlertCard from './ComplianceAlertCard'
import ProductUpdateCard from './ProductUpdateCard'
import VendorUpdateCard from './VendorUpdateCard'
import RiskCheckUpdateCard from './RiskCheckUpdateCard'
import DeclinedDDQCard from './DeclinedDDQCard'

const Card = forwardRef((props, ref) => {
  switch (props.watchlistItem.item.type) {
    case 'posts':
      return (<PostCard ref={ref} {...props} />)
    case 'announcements':
      return (<AnnouncementCard ref={ref} {...props} />)
    case 'ddqs':
      return (<DDQCard ref={ref} {...props} />)
    case 'dataset-updates':
      return (<DatasetCard ref={ref} {...props} />)
    case 'newsflows':
      return (<NewsflowArticleCard ref={ref} {...props} />)
    case 'compliance-alerts':
      return (<ComplianceAlertCard ref={ref} {...props} />)
    case 'product-updates':
      return (<ProductUpdateCard ref={ref} {...props} />)
    case 'vendor-updates':
      return (<VendorUpdateCard ref={ref} {...props} />)
    case 'risk-check-updates':
      return (<RiskCheckUpdateCard ref={ref} {...props} />)
    case 'declined-ddqs':
      return (<DeclinedDDQCard ref={ref} {...props} />)

    default:
      throw new Error(`Missing card definition for type '${props.watchlistItem.item.type}`)
  }
})

export default Card
