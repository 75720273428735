import './m-buyside-picks-tab-bar.scss';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  RoutedTabbedContainer, Tab, EphemeralTab, BackTab
} from '../Shared/RoutedTabbedContainer'
import LocationSelectionPage from './LocationSelectionPage'
import VendorCollectionsPage from './VendorCollectionsPage'
import RegionSelectionPage from './RegionSelectionPage'
import SelectYourAvailabilityPage from './SelectYourAvailabilityPage';
import VendorPicksPage from './VendorPicksPage';
import { load } from '../../OneToOne/vendorPicksSlice';
import CompletePage from './CompletePage/index'
import {
  getPickProcess, getBuysidePickProcess,
  getInvalidPicks, requireRegionAndAvailability
} from '../../OneToOne/VendorPicksFilter'
/**
 * The form tabs for the pick process - shown in the header bar
 * @param props
 * @param {String} props.existingContainerId The id of the element which contains the tab contents
 * @param {String} props.useQuery The name of the query param to use to represent each tab
 * @returns {JSX.Element}
 * @constructor
 */
const PickProcessFormTabs = (props) => {
  const dispatch = useDispatch()
  /* Dispatch the load action to get the data from the server */
  useEffect(() => (dispatch(load())), [dispatch])
  const invalidPicks = useSelector(getInvalidPicks)
  const hasInvalidPicks = invalidPicks.length > 0
  const { region } = useSelector((state) => state.buysidePickProcess)

  const {
    buyside_process_ends_at: buysideProcessEndsAt,
    inPersonOnly: pickProcessInPersonOnly
  } = useSelector(getPickProcess)
  const { contactInPersonAttendance, contactVirtualAttendance } = useSelector(
    getBuysidePickProcess
  )
  const invalidAttendance = !contactInPersonAttendance && !contactVirtualAttendance
  const isNotEditable = !dayjs().isBefore(dayjs.unix(buysideProcessEndsAt))
  const buysideContactAttending = useSelector(
    (state) => state.buysidePickProcess.contactAttending
  )
  const showRegionAndAvailability = useSelector(requireRegionAndAvailability)

  return (
    <div className="pick-process-form-tabs">
      <RoutedTabbedContainer existingContainerSelector={`#${props.existingContainerId}`} useQuery={props.useQuery} withOwnRouter={false}>
        <BackTab>
          Previous Step
        </BackTab>
        { !pickProcessInPersonOnly && buysideContactAttending && (
        <EphemeralTab className="location" identifier="location" label="Location" hiddenTab={isNotEditable} default={buysideContactAttending}>
          <LocationSelectionPage nextState="vendor-collections" />
        </EphemeralTab>
        )}
        { !pickProcessInPersonOnly && (
        <Tab className="vendor-collections" identifier="vendor-collections" label="Quick Pick" hiddenTab={isNotEditable} disabledTab={invalidAttendance} default={!buysideContactAttending}>
          <VendorCollectionsPage previousState={buysideContactAttending && 'location'} nextState="vendor-picks" />
        </Tab>
        )}
        { pickProcessInPersonOnly && (
        <EphemeralTab className="vendor-collections" identifier="vendor-collections" label="Quick Pick" hiddenTab={isNotEditable || invalidAttendance} disabledTab={invalidAttendance} default>
          <VendorCollectionsPage nextState="vendor-picks" />
        </EphemeralTab>
        )}
        <Tab className="vendor-picks" identifier="vendor-picks" label="Vendor Selection" hiddenTab={isNotEditable} disabledTab={invalidAttendance}>
          <VendorPicksPage nextState={showRegionAndAvailability ? 'region' : 'complete'} previousState="vendor-collections" bannerMessage={props.bannerMessage} useQuery={props.useQuery} />
        </Tab>
        { showRegionAndAvailability && (
        <Tab className="region" identifier="region" label="Region" hiddenTab={isNotEditable} disabledTab={invalidAttendance || hasInvalidPicks}>
          <RegionSelectionPage nextState="availability" previousState="vendor-picks" />
        </Tab>
        ) }
        { showRegionAndAvailability && (
        <Tab className="availability" identifier="availability" label="Virtual Meeting Availability" hiddenTab={isNotEditable} disabledTab={!region || invalidAttendance || hasInvalidPicks}>
          <SelectYourAvailabilityPage previousState="region" nextState="complete" />
        </Tab>
        )}

        <Tab className="complete" identifier="complete" label="Submitted" hiddenTab={isNotEditable} disabledTab={(showRegionAndAvailability && !region) || invalidAttendance || hasInvalidPicks}>
          <CompletePage previousState={showRegionAndAvailability ? 'availability' : 'vendor-picks'} useQuery={props.useQuery} />
        </Tab>
      </RoutedTabbedContainer>
    </div>
  )
}
export default PickProcessFormTabs
