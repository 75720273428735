import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    url: String,
    formAuthenticityToken: String,
    selectedChoices: {
      type: Array,
      default: undefined
    }
  }

  static targets = ['name', 'saveButton']

  cancel(event) {
    event.preventDefault()
    this.dispatch('carousel:back', { prefix: false })
  }

  save(event) {
    const formData = new FormData()
    const query = {}

    event.preventDefault()

    this.selectedChoicesValue.forEach((choice) => {
      const { name, value } = choice;

      if (query[name]) {
        query[name].push(value);
      } else {
        query[name] = [value];
      }
    })

    formData.append('authenticity_token', this.formAuthenticityTokenValue)
    formData.append('saved_filters[name]', this.nameTarget.value)
    formData.append('saved_filters[query]', JSON.stringify(query))

    fetch(this.urlValue, {
      method: 'POST',
      body: formData,
      cache: 'no-store',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json'
      }
    })
      .then((response) => response.json())
      .then((response) => {
        const saveEvent = new CustomEvent('saveSelection:addSavedFilter', { detail: response.data, bubbles: true })

        this.element.dispatchEvent(saveEvent)
        this.resetForm()
      })
  }

  toggleButtonState() {
    const button = this.saveButtonTarget
    const nameField = this.nameTarget.value.trim()

    if (nameField === '' || this.selectedChoicesValue.length === 0) {
      button.classList.add('disabled')
    } else {
      button.classList.remove('disabled')
    }
  }

  resetForm() {
    const button = this.saveButtonTarget
    const nameField = this.nameTarget

    button.classList.remove('submitting')
    nameField.value = ''
    this.dispatch('carousel:back', { prefix: false })
  }

  removeSelection(event) {
    event.preventDefault()

    event.target.closest('.selected-value').remove()
    this.toggleButtonState()
  }

  selectedChoicesValueChanged(value, oldValue) {
    this.toggleButtonState()

    if (oldValue) {
      this.dispatch('selected-choices-changed', { detail: { choices: value } })
    }
  }

  selectedChoicesChanged(event) {
    event.cancelBubble = true
    this.selectedChoicesValue = event.detail.choices
  }
}
