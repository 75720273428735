import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import useSearchParam from '../../hooks/useSearchParam'
import UnseenCount from './UnseenCount'

const WatchedFilterRow = ({ savedFilter }) => {
  const savedFilterIdParam = useSearchParam('savedFilterId')

  const classes = classNames('filter-row', { selected: savedFilterIdParam === savedFilter.id.toString() })

  return (
    <div className={classes}>
      <div className="link-container">
        <Link to={`/feed?savedFilterId=${savedFilter.id}`}>
          <div className="image-container">
            <i className="fa-solid fa-sliders" />
          </div>
          <span className="value">{savedFilter.name}</span>
          <UnseenCount count={savedFilter.unseen} />
        </Link>
      </div>
    </div>
  )
}

export default WatchedFilterRow
