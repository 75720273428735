import { useDispatch, useSelector } from 'react-redux'
import {
  useCallback, useRef, useEffect, useState
} from 'react'

import { setContactInPersonAttendance, setContactVirtualAttendance } from '../../OneToOne/buysidePickProcessSlice'
import axios from '../../axiosRails'
import vendorPicksConfig from '../../OneToOne/vendorPicksConfig'
import { getBuysidePickProcess } from '../../OneToOne/VendorPicksFilter'

const useLocationPreferences = () => {
  const dispatch = useDispatch()
  const isInitialMount = useRef(true)

  const [locationError, setLocationError] = useState(null)

  const { contactInPersonAttendance, contactVirtualAttendance, contactId } = useSelector(
    getBuysidePickProcess
  )
  const isInvalidPreference = !contactInPersonAttendance && !contactVirtualAttendance

  const toggleContactInPersonAttendance = useCallback(() => {
    dispatch(setContactInPersonAttendance(!contactInPersonAttendance))
    setLocationError(null)
  }, [contactInPersonAttendance, dispatch])

  const toggleContactVirtualAttendance = useCallback(() => {
    dispatch(setContactVirtualAttendance(!contactVirtualAttendance))
    setLocationError(null)
  }, [contactVirtualAttendance, dispatch])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else if (contactInPersonAttendance || contactVirtualAttendance) {
      axios.put(
        vendorPicksConfig.updateBuysideContactUrl({ buysideContactId: contactId }),
        {
          one_to_one_buyside_contact: {
            in_person_attendance: contactInPersonAttendance,
            virtual_attendance: contactVirtualAttendance
          }
        },
        {
          headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
        }
      )
    } else {
      setLocationError('Location is required')
    }
  }, [
    contactId,
    contactInPersonAttendance,
    contactVirtualAttendance,
    isInitialMount,
    locationError
  ])

  return {
    isInvalidPreference,
    contactInPersonAttendance,
    contactVirtualAttendance,
    locationError,
    toggleContactInPersonAttendance,
    toggleContactVirtualAttendance
  }
}

export default useLocationPreferences
