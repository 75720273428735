import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    currentIndex: { type: Number, default: 0 },
    totalSlides: { type: Number, default: 0 },
    autoAdvance: Boolean,
    autoAdvanceDelay: Number,
    horizontalScrollEnabled: Boolean
  }

  static targets = ['track', 'viewport', 'subscriber']

  connect() {
    this.cardWidth = 0
    this.visibleCards = 0
    this.#calculateCardMetrics()

    this.boundCalculateCardMetrics = this.#calculateCardMetrics.bind(this)
    window.addEventListener('resize', this.boundCalculateCardMetrics)

    this.#startAutoAdvance()

    this.boundDisableTrackPointerEvents = this.#disableTrackPointerEvents.bind(this)
    this.trackTarget.addEventListener('transitionstart', this.boundDisableTrackPointerEvents)

    this.boundEnableTrackPointerEvents = this.#enableTrackPointerEvents.bind(this)
    this.trackTarget.addEventListener('transitionend', this.boundEnableTrackPointerEvents)

    this.boundHandleHorizontalTrackpadScroll = this.#handleHorizontalTrackpadScroll.bind(this)
    this.viewportTarget.addEventListener('wheel', this.boundHandleHorizontalTrackpadScroll, { passive: false })
  }

  disconnect() {
    window.removeEventListener('resize', this.boundCalculateCardMetrics)
    window.removeEventListener('resize', this.boundDisableTrackPointerEvents)
    window.removeEventListener('resize', this.boundEnableTrackPointerEvents)
    this.#stopAutoAdvance()
  }

  navigateTo({ detail: { slide } }) {
    this.currentIndexValue = slide
  }

  nextSlide({ automated = false } = {}) {
    if (!automated) {
      this.#stopAutoAdvance()
    }

    this.#calculateCardMetrics()

    const remainingCards = this.totalSlidesValue - (this.currentIndexValue + this.visibleCards)
    const moveBy = Math.min(this.visibleCards, remainingCards)

    if (moveBy > 0) {
      this.currentIndexValue += moveBy
    } else {
      this.currentIndexValue = 0
    }
  }

  previousSlide() {
    const moveBy = Math.min(this.visibleCards, this.currentIndexValue)

    if (moveBy > 0) {
      this.currentIndexValue -= moveBy
    }

    this.#stopAutoAdvance()
  }

  #calculateCardMetrics() {
    const card = this.trackTarget.querySelector('.carousel--card-container-component')

    if (card) {
      const cardStyles = window.getComputedStyle(card)
      this.cardWidth = card.offsetWidth + parseInt(cardStyles.marginRight, 10)
    }

    this.visibleCards = Math.floor(this.viewportTarget.offsetWidth / this.cardWidth)
    this.totalSlidesValue = this.trackTarget.children.length

    if (this.currentIndexValue > this.totalSlidesValue - this.visibleCards) {
      this.currentIndexValue = Math.max(0, this.totalSlidesValue - this.visibleCards)
    }
  }

  #stopAutoAdvance() {
    if (!this.autoAdvanceValue) return

    clearInterval(this.autoAdvanceInterval)
  }

  #startAutoAdvance() {
    if (!this.autoAdvanceValue) return

    this.autoAdvanceInterval = setInterval(() => {
      this.nextSlide({ automated: true })
    }, this.autoAdvanceDelayValue)
  }

  currentIndexValueChanged(slide) {
    const detail = {
      slide,
      isLastSlide: this.totalSlidesValue - (this.currentIndexValue + this.visibleCards) <= 0,
      isFirstSlide: this.currentIndexValue === 0
    }

    this.#updateTrackPosition()

    // Ensures the transitions begin before we notify the other components of the change in state.
    // This allows us to disable the carousel track pointer events prior to removing
    // buttons. This ensures the user does not see tooltips etc flickering as they navigate
    // to the final items within the carousel and the one of the navigation buttons
    //  gets removed resulting in them hovering over a moving track and as a
    // consequence moving cards.
    setTimeout(() => {
      this.dispatch('carousel:slideChanged', { detail, prefix: false, bubbles: true })

      this.subscriberTargets.forEach((element) => {
        element.dispatchEvent(new CustomEvent('carousel:slideChanged', { detail }))
      })
    })
  }

  #updateTrackPosition() {
    const offset = this.currentIndexValue * this.cardWidth

    // translateZ(0) Forces GPU Acceleration in Safari to avoid elements flickering
    // within the tracks div as the div moves
    this.trackTarget.style.transform = `translateX(-${offset}px) translateZ(0)`
  }

  #disableTrackPointerEvents(e) {
    if (e.propertyName === 'transform') {
      this.trackTarget.style.pointerEvents = 'none'
    }
  }

  #enableTrackPointerEvents(e) {
    if (e.propertyName === 'transform') {
      this.trackTarget.style.pointerEvents = 'auto'
    }
  }

  #handleHorizontalTrackpadScroll(event) {
    if (!this.horizontalScrollEnabledValue || event.deltaX === 0) return

    event.preventDefault()

    this.#calculateCardMetrics()

    const trackHorizontalPosition = this.trackTarget.getBoundingClientRect().left
    const viewportHorizontalPosition = this.viewportTarget.getBoundingClientRect().left
    const currentHorizontalPosition = trackHorizontalPosition - viewportHorizontalPosition

    let newHorizontalPosition = currentHorizontalPosition - event.deltaX

    newHorizontalPosition = Math.min(0, Math.max(this.#maxScroll(), newHorizontalPosition))

    this.#updateIndex(newHorizontalPosition)
    this.#updateTrackTransition(newHorizontalPosition)

    setTimeout(() => {
      this.trackTarget.style.pointerEvents = 'auto'
    }, 150)
  }

  #maxScroll() {
    return -(this.totalSlidesValue * this.cardWidth - this.viewportTarget.offsetWidth)
  }

  #updateIndex(newPosition) {
    const newIndex = Math.round(-newPosition / this.cardWidth);
    if (this.currentIndexValue !== newIndex) {
      this.currentIndexValue = newIndex;
    }
  }

  #updateTrackTransition(newPosition) {
    const originalTransition = this.trackTarget.style.transition

    this.trackTarget.style.transition = 'none'
    this.trackTarget.style.pointerEvents = 'none'
    this.trackTarget.style.transform = `translateX(${newPosition}px) translateZ(0)`

    // Force browser reflow
    window.getComputedStyle(this.trackTarget).getPropertyValue('height')

    this.trackTarget.style.transition = originalTransition
  }
}
