import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import qs from 'qs'
import { getCardsLoading, getFeedTypeSummaries } from '../../Watchlist/selectors'

const linkTo = (identifier) => (
  (location) => {
    const newSearch = qs.stringify({ feedType: identifier })

    return { ...location, search: `?${newSearch}` }
  }
)

const linkSelected = (identifier, location) => {
  const searchParams = new URLSearchParams(location.search)
  const feedTypeParam = searchParams.get('feedType')

  if (!feedTypeParam) {
    return identifier === 'home'
  }

  return feedTypeParam === identifier
}

const FilterRow = (props) => {
  const location = useLocation()
  const loading = useSelector(getCardsLoading)
  const selected = linkSelected(props.id, location)
  const logoClasses = {
    home: 'fa-solid fa-home',
    watchlists: 'fa-solid fa-signal-stream',
    sentry: 'fa-solid fa-chess-rook',
    'product-updates': 'fa-solid fa-sparkles'
  }

  return (
    <div className={`feed-type feed-type-filter-${props.slug} filter-row${selected ? ' selected' : ''}`}>
      <Link to={linkTo(props.id)} className={((props.count === 0) || loading) ? 'disabled-feed' : ''}>
        <span className="logo"><i className={`icon ${props.slug} ${logoClasses[props.slug]}`} /></span>
        <span className="value">
          <span className="name">{props.value}</span>
          <br />
          <span className="subtitle">{props.subtitle}</span>
        </span>
        <span className={`count${props.unseen === 0 ? ' empty' : ''}`}>{props.unseen}</span>
        <br />
      </Link>
    </div>
  )
}

const FilterRows = (props) => {
  const summaries = useSelector(getFeedTypeSummaries)

  return summaries.map(
    (summary) => (
      <FilterRow
        key={summary.slug}
        logo_src={summary.logo_src}
        value={summary.name}
        slug={summary.slug}
        id={summary.slug}
        count={summary.count}
        unseen={summary.unseen}
        state={props.state}
        subtitle={summary.subtitle}
      />
    )
  )
}
const FeedTypeFilter = () => {
  const showFilters = useSelector((state) => state.data.showFilters)

  return (
    <div className={`watchlist-filter-group feed-type-buttons ${showFilters ? 'show' : 'hidden'}`}>
      <FilterRows />
    </div>
  )
}
export default FeedTypeFilter
