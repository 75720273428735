import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['exit', 'summary']

  goBack(event) {
    /** Emit beforeExit to give the caller a chance to cancel navigation
     in an asynchronous manner (to allow for modal etc..) * */
    const cancel = this.dispatch(
      'beforeExit',
      { detail: { url: event.target.closest('a').href, resume: this.resume(event) }, cancelable: true }
    ).defaultPrevented

    if (cancel) {
      event.preventDefault()
    }
  }

  goBackToSummary(event) {
    event.preventDefault()
    window.location.hash = ''
    // Simply to remove the stray '#' that we get when setting the hash to an empty string
    window.history.replaceState({}, '', window.location.pathname + window.location.search)
  }

  linkToSummary() {
    this.exitTarget.classList.add('hidden')
    this.summaryTarget.classList.remove('hidden')
  }

  linkToExit() {
    this.summaryTarget.classList.add('hidden')
    this.exitTarget.classList.remove('hidden')
  }

  resume(originalEvent) {
    return () => {
      window.location.href = originalEvent.target.closest('a').href
    }
  }
}
