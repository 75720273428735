import instantsearch from 'instantsearch.js'
import AutoCompleteSearch from './AutoCompleteSearch'

/* global HandlebarsTemplates */
export default class FacetSearch extends AutoCompleteSearch {
  constructor(controller) {
    super(controller.algoliaFacetIndexValue, controller);
  }

  searchListId() {
    return 'facet-search-results';
  }

  renderListItem({ hits }) {
    return HandlebarsTemplates.facet_autosearch_result({
      // Limit searches of each type to 5 hits
      hits: hits.slice(0, 5).map((hit) => {
        const facetSlug = hit.facet_slug === 'traditional_dataset_type' ? 'type' : hit.facet_slug

        return {
          facetName: hit.facet_name,
          facetSlug: encodeURIComponent(`${facetSlug}[]`),
          facetTermSlug: encodeURIComponent(hit.facet_term_slug),
          highlightedTitle: instantsearch.highlight({ attribute: 'facet_term_name', hit })
        }
      }),
      path: this.autosearchResultsPath
    })
  }
}
