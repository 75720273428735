import './chat_app.scss';
import React, { useEffect, useState, useRef } from 'react'
import { Provider, useSelector, useStore } from 'react-redux'
import { BrowserRouter, useHistory } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import chatStore from '../../Chat/chatStore'
import {
  setUnseenMessagesCount
} from '../../Chat/conversationSlice'
import { setConfig, setCurrentParticipant } from '../../Chat/appSlice'
import {
  load as loadMessages, setConversationId, unloadMessages, startConversationWithDataProvider
} from '../../Chat/messageSlice'
import ConversationList from './ConversationList'
import ConversationEditor from './ConversationEditor'
import globalStoreInstance from '../../globalStoreInstance'
import { setUnseenChatMessagesCount } from '../../globalStoreSlice'
import { isNewConversation } from '../../utils/Chat/conversation'
import LiveChatProxy from './LiveChatProxy'
import ConnectionFlashMessage from './ConnectionFlashMessage'

const loadMessagesWhenRequired = (store) => () => {
  const conversationId = store.getState().router.location.query.id
  const loadedConversationId = store.getState().messages.conversationId
  if (conversationId === loadedConversationId) { return }

  const newConversation = isNewConversation(conversationId);

  const conversation = store.getState().data.conversations.find((c) => c.id === conversationId)
  if (!conversation && !newConversation && loadedConversationId) {
    store.dispatch(setConversationId(conversationId))
    store.dispatch(unloadMessages())
    return
  }
  if (newConversation) {
    store.dispatch(setConversationId(conversationId))
    store.dispatch(startConversationWithDataProvider())
    store.dispatch(setUnseenMessagesCount({ conversationId, value: 0 }))
  } else if (conversation) {
    const url = conversation.links.messages
    store.dispatch(loadMessages({ url, conversationId }))
    store.dispatch(setUnseenMessagesCount({ conversationId, value: 0 }))
  }
}

const SyncWithGlobalStore = () => {
  const conversations = useSelector((state) => state.data.conversations)
  const messageCount = conversations.reduce(
    (sum, conversation) => (sum + conversation.unseen_messages_count),
    0
  )
  useEffect(() => {
    globalStoreInstance.dispatch(setUnseenChatMessagesCount(messageCount))
  })
  return null
}

const BackButton = ({ onClick }) => {
  const store = useStore()
  const conversationId = store.getState().router.location.query.id
  const conversation = store.getState().data.conversations.find((c) => c.id === conversationId)
  const participantName = conversation ? conversation.participant.name : 'Back'

  return (
    <div className="back-button large-hidden">
      <button type="button" onClick={onClick}>
        <div className="icon"><i className="fas fa-arrow-left" /></div>
        <div>{participantName}</div>
      </button>
    </div>
  )
}

export const ChatContainer = ({ currentParticipant, config }) => {
  const [hiddenColumn, setHiddenColumn] = useState(2)
  const storeLoaded = useRef(false)
  const store = useStore()
  const history = useHistory()

  useEffect(() => {
    if (!storeLoaded.current) {
      storeLoaded.current = true
      store.dispatch(setConfig(config))
      store.dispatch(setCurrentParticipant({
        id: currentParticipant.id,
        type: currentParticipant.type,
        ...currentParticipant.attributes
      }))
    }
  }, [storeLoaded, store, config, currentParticipant])

  const toggleColumn = () => {
    setHiddenColumn(hiddenColumn === 1 ? 2 : 1)
  }

  useEffect(() => (
    store.subscribe(loadMessagesWhenRequired(store))
  ))

  return (
    <ConnectedRouter history={history}>
      <LiveChatProxy />
      <div className="m-chat-page">
        <ConnectionFlashMessage />
        <div className="layout-columns">
          <div className={`column column--1 ${hiddenColumn === 1 ? 'tablet-hidden' : ''}`}>
            <SyncWithGlobalStore />
            <ConversationList onSelect={toggleColumn} />
          </div>
          <div className={`column column--2 ${hiddenColumn === 2 ? 'tablet-hidden' : ''}`}>
            <BackButton onClick={toggleColumn} />
            <ConversationEditor onBack={toggleColumn} participantType={currentParticipant.type} />
          </div>
        </div>
      </div>
    </ConnectedRouter>
  )
}

const InnerChatApp = ({ currentParticipant, config }) => {
  const history = useHistory()
  const store = chatStore(history)

  return (
    <Provider store={store}>
      <ChatContainer currentParticipant={currentParticipant} config={config} />
    </Provider>
  )
}

/**
 * The main app for 'chat'
 * @param currentParticipant - An object passed in from the rails view
 * @param {String} currentParticipant.name The participant name
 * @param {String, Null} currentParticipant.company The company (optional)
 * @param {String, Null} currentParticipant.avatar The avatar object.
 *  See the ParticipantAvatar component for details.
 * @param {String} config.myConversationsUrl The url to fetch the conversations from
 * @param {String} config.markAllMessagesSeenUrl The url to post to in order to mark
 *   all messages for :conversation_id as seen
 * @param {Integer} config.fileSizeLimit The maximum file size in bytes for an load
 * @returns {JSX.Element}
 * @constructor
 */
const ChatApp = ({ currentParticipant, config }) => (
  <BrowserRouter>
    <InnerChatApp currentParticipant={currentParticipant} config={config} />
  </BrowserRouter>
)
export default ChatApp
