import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { modal: Boolean }

  static targets = ['form']

  connect() {
    if (!this.modalValue) {
      this.element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
    }
  }

  onSubmit() {
    if (this.modalValue) {
      const event = new CustomEvent('modal:close', { bubbles: true })
      this.formTarget.dispatchEvent(event)
    }
  }
}
