import { Controller } from '@hotwired/stimulus';

import getCSRFToken from '../../../../javascript/src/getCSRFToken';

export default class extends Controller {
  static values = {
    savedFilters: Array,
    selectedFilterId: Number
  }

  static targets = ['dropdownTitle', 'filters', 'filterTemplate']

  savedFiltersValueChanged() {
    this.filtersTarget.innerHTML = '';

    this.savedFiltersValue = this.savedFiltersValue.sort((a, b) => {
      if (a.attributes.name < b.attributes.name) return -1;
      if (a.attributes.name > b.attributes.name) return 1;
      return 0;
    });

    this.savedFiltersValue.forEach((item) => {
      let templateContent = this.filterTemplateTarget.innerHTML;

      templateContent = templateContent.replace(/{{name}}/g, item.attributes.name);
      templateContent = templateContent.replace(/{{searchLink}}/g, item.attributes.search_link);
      templateContent = templateContent.replace(/{{id}}/g, item.id);

      const templateElement = document.createElement('div');
      templateElement.innerHTML = templateContent;

      this.filtersTarget.appendChild(templateElement.firstElementChild);
    })

    this.toggleDropdownContentState()
  }

  deleteSavedFilter(event) {
    const eventFilterId = event.params.filterId.toString()
    const filter = this.savedFiltersValue.find((f) => f.id === eventFilterId)

    event.preventDefault()

    fetch(filter.attributes.path, {
      method: 'DELETE',
      cache: 'no-store',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': getCSRFToken()
      }
    }).then((response) => {
      if (response.ok) {
        this.removeSavedFilter(filter.id)
      }

      return 'An error has occurred!'
    })
  }

  removeSavedFilter(filterId) {
    this.savedFiltersValue = this.savedFiltersValue.filter(
      (savedFilter) => savedFilter.id !== filterId
    )

    if (filterId === this.selectedFilterIdValue.toString()) {
      this.resetDropdown()
    }
  }

  addSavedFilter(event) {
    this.savedFiltersValue = this.savedFiltersValue.concat(event.detail)
  }

  applySelection(event) {
    event.target.dispatchEvent(new Event('modal:close', { prefix: true, bubbles: true }))
  }

  toggleDropdownContentState() {
    const dropdownContent = this.element.querySelector('.dropdown-content')

    if (this.savedFiltersValue.length === 0) {
      dropdownContent.classList.add('hidden')
    } else {
      dropdownContent.classList.remove('hidden')
    }
  }

  resetDropdown() {
    this.dropdownTitleTarget.textContent = 'Saved filters';
  }
}
