import React, { useEffect, useState } from 'react'
import { useSelector, } from 'react-redux'

const calculateTimeLeft = (pickProcess) => {
  const timeLeft = {
    days: 0,
    hours: 0
  }
  if (!pickProcess) { return timeLeft }

  const endDate = pickProcess.buyside_process_ends_at
  const difference = endDate - Date.now()

  if (difference > 0) {
    timeLeft.days = Math.floor(difference / (1000 * 60 * 60 * 24))
    timeLeft.hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
  }
  return timeLeft;
}

const PickProcessCountdown = () => {
  const pickProcess = useSelector((state) => state.buysidePickProcess.pickProcess)
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(pickProcess))
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(pickProcess));
    }, 60000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  })

  if (!pickProcess) { return null }
  const daysLabel = timeLeft.days === 1 ? 'day' : 'days'
  const hoursLabel = timeLeft.hours === 1 ? 'hour' : 'hours'
  return (
    <div className="countdown-container">
      <div className="buyside-picks-countdown">
        <div className="time-left">
          <span className="info">Submissions close in <strong>{`${timeLeft.days} ${daysLabel} ${timeLeft.hours} ${hoursLabel}`}</strong></span>
        </div>
      </div>
    </div>
  )
}

export default PickProcessCountdown
