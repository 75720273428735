import React from 'react'
import { useSelector } from 'react-redux'
import VendorCard from './VendorCard'
import { filteredData } from '../../OneToOne/VendorPicksFilter'

function VendorCardList() {
  const loading = useSelector((state) => state.data.cardsLoading)

  return (
    <div className={`vendor-card-list ${loading ? 'loading' : 'loaded'}`}>
      {useSelector(filteredData).map((item) => (
        <VendorCard item={item} key={item.id} />
      ))}
    </div>
  )
}

export default VendorCardList
