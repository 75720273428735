import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.adjustTableHeaderTopValue()
    window.addEventListener('resize', this.adjustTableHeaderTopValue)

    this.setTableTopOnActionsBarResize()

    this.element.addEventListener('turbo:submit-end', () => {
      setTimeout(() => {
        this.element.querySelectorAll('input[type="submit"]').forEach((input) => { input.disabled = false })
      }, 400)
    })
  }

  disconnect() {
    window.removeEventListener('resize', this.adjustTableHeaderTopValue)

    if (this.observer) {
      this.observer.disconnect()
      this.observer = null
    }

    this.deselectAll()
  }

  setTableTopOnActionsBarResize() {
    this.observer = new ResizeObserver(() => {
      this.adjustTableHeaderTopValue()
    })

    this.observer.observe(this.element.querySelector('.actions-bar'))
  }

  adjustTableHeaderTopValue() {
    const buttonHeight = this.element.querySelector('.actions-bar').offsetHeight
    this.element.querySelector('thead').style.top = `${buttonHeight - 1}px`
  }

  deselectAll() {
    document.querySelectorAll('input[name="selected_firms[]"]:checked').forEach((input) => {
      input.checked = false
    })
    document.querySelector('#select-all-pickactions').classList.add('hidden')
    document.querySelector('input[name="select_all"]').checked = false
    document.querySelector('#select-all-pickactions').classList.add('hidden')
  }

  bulkProcess(event) {
    event.preventDefault()

    const selectedInputs = document.querySelectorAll('input[name="selected_firms[]"]:checked')
    const selectedFirmIds = Array.from(selectedInputs).map((input) => input.value)

    const { target: { form, name } } = event

    selectedFirmIds.forEach((firmId) => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'picks[one_to_one_buyside_firm_ids][]'
      input.value = firmId
      form.appendChild(input)
    })

    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = 'picks[pick_type]'
    input.value = name
    form.appendChild(input)

    form.requestSubmit()

    document.querySelectorAll('[name="picks[one_to_one_buyside_firm_ids][]"]').forEach((processedInput) => { processedInput.remove() })
  }
}
