import React, { forwardRef } from 'react'
import ItemShareableLink from './ItemShareableLink'
import Date from './Date'

const ProductUpdateCard = forwardRef((props, ref) => (
  <li ref={ref} className="watchlist-card product-update">
    <div className="header">
      <span className="watchlist-type">
        <i className="fas fa-sparkles" />
        Platform Updates
      </span>
      <div className="watchlist-item-details">
        <Date date={props.watchlistItem.item.publication_date} />
        <ItemShareableLink item={props.watchlistItem.item} />
      </div>
    </div>
    <div className="image-container">
      <img data-testid="image" src={props.watchlistItem.item.image_url} alt="" />
    </div>
    <div className="title">{props.watchlistItem.item.title}</div>
    <div className="subtitle">{props.watchlistItem.item.subtitle}</div>
    {/* eslint-disable-next-line react/no-danger */}
    <span className="content wysiwyg" data-testid="content" dangerouslySetInnerHTML={{ __html: props.watchlistItem.item.content }} />
  </li>
))

export default ProductUpdateCard
