import { createSlice } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import { cloneDeep } from 'lodash'
import axios from '../axiosRails'
import { preProcessData, preProcessIncluded } from '../helpers/jsonApiResponseProcessingHelpers'
import { generateFakeId } from '../utils/Chat/conversation'

export const redirectToConversation = (
  dispatch, getState, conversation, { dataProviderId, datasetId, pinToTop }
) => {
  const { location } = getState().router

  if (location.query.id === conversation.id
    && location.query.data_provider_id === dataProviderId
    && location.query.dataset_id === datasetId
    && location.query.pin_to_top === pinToTop) {
    return
  }
  const newLocation = cloneDeep(location)
  newLocation.query.id = conversation.id
  if (dataProviderId) {
    newLocation.query.data_provider_id = dataProviderId
  } else {
    delete newLocation.query.data_provider_id
  }
  if (datasetId) {
    newLocation.query.dataset_id = datasetId
  } else {
    delete newLocation.query.dataset_id
  }
  if (pinToTop) {
    newLocation.query.pin_to_top = '1'
  } else {
    delete newLocation.query.pin_to_top
  }
  newLocation.search = new URLSearchParams(newLocation.query).toString()
  dispatch(push(newLocation))
}

const postProcessForNewConversation = (getState, dispatch, data) => {
  const dataProviderId = getState().router.location.query.data_provider_id
  const datasetId = getState().router.location.query.dataset_id
  if (!dataProviderId) {
    return
  }

  const conversations = data.data
  const { config } = getState().app

  const conversation = conversations.find(
    (c) => (c.meta.data_provider_id?.toString() === dataProviderId)
  )
  if (conversation) {
    redirectToConversation(dispatch, getState, conversation, { pinToTop: true });
    return
  }

  const newConversation = cloneDeep(config.newConversationForDataProvider)

  newConversation.data.id = generateFakeId()

  preProcessIncluded(newConversation.included)
  preProcessData(newConversation)
  conversations.unshift(newConversation.data)
  redirectToConversation(dispatch, getState, newConversation.data, { dataProviderId, datasetId })
}

const postProcessData = (data, dispatch, getState) => {
  postProcessForNewConversation(getState, dispatch, data)
}
export const conversationSlice = createSlice({
  name: 'conversationData',
  initialState: {
    conversations: [],
    nextPage: 1,
    dataProvidersOrganisationId: null,
    currentConversation: null,
  },
  reducers: {
    overWriteConversations: (state, action) => {
      state.conversations = [...action.payload.data]
      state.nextPage = action.payload.meta.next_page
    },
    appendConversations: (state, action) => {
      state.conversations = [...state.conversations, ...action.payload.data]
      state.nextPage = action.payload.meta.next_page
    },
    setCurrentConversation: (state, action) => {
      state.currentConversation = action.payload
    },
    /**
     * Sets the unseen messages count for the given conversation id
     * @param state
     * @param {String} action.payload.conversationId
     * @param {String} action.payload.value
     */
    setUnseenMessagesCount: (state, action) => {
      const { conversationId, value } = action.payload
      const conversationIdx = state.conversations.findIndex(
        (c) => c.id === conversationId && c.unseen_messages_count !== value
      )
      if (conversationIdx < 0) { return }

      const conversation = { ...state.conversations[conversationIdx], unseen_messages_count: value }
      state.conversations = [
        ...state.conversations.slice(0, conversationIdx),
        conversation,
        ...state.conversations.slice(conversationIdx + 1)]
    },
    setDataProvidersOrganisationId: (state, action) => {
      state.dataProvidersOrganisationId = action.payload
    },
    replaceOrInsertConversation: (state, action) => {
      const { conversationId, conversation } = action.payload
      const conversationIdx = state.conversations.findIndex((c) => c.id === conversationId)
      if (conversationIdx < 0) {
        state.conversations = [conversation, ...state.conversations]
        return
      }

      state.conversations = [
        ...state.conversations.slice(0, conversationIdx),
        conversation,
        ...state.conversations.slice(conversationIdx + 1)
      ]
    }
  }
})

const {
  overWriteConversations,
  appendConversations
} = conversationSlice.actions

export const {
  setUnseenMessagesCount,
  replaceOrInsertConversation,
  setDataProvidersOrganisationId,
  setCurrentConversation,
} = conversationSlice.actions

/**
 *
 * load Action - loads the data from the backend
 */
export const load = ({ page = 1 }) => (dispatch, getState) => {
  const dataProviderId = getState().router.location.query.data_provider_id
  const conversationId = getState().router.location.query.id
  const { dataProvidersOrganisationId } = getState().data
  const params = { page }

  if (dataProvidersOrganisationId) {
    params.data_providers_organisation_id = dataProvidersOrganisationId
  }

  if (dataProviderId) {
    params.data_providers_id = dataProviderId
  }

  if (!dataProvidersOrganisationId && conversationId) {
    params.id = conversationId
  }

  axios.get('/chat/conversations', { params, headers: { Accept: 'application/vnd.api+json' } })
    .then((response) => {
      preProcessIncluded(response.data.included)
      preProcessData(response.data)
      // eslint-disable-next-line no-use-before-define
      postProcessData(response.data, dispatch, getState)
      if (page === 1) {
        dispatch(overWriteConversations(response.data))
      } else {
        dispatch(appendConversations(response.data))
      }
    })
    .catch((error) => {
      if (!error.isAxiosError) {
        // eslint-disable-next-line no-console
        throw error
      }
    })
}
export default conversationSlice.reducer
