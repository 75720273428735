import React, { useCallback } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { useSelector } from 'react-redux';

import Tooltip from '../Shared/Tooltip'
import useWatcher from '../../hooks/useWatcher';
import { getDataProviderSummaryById } from '../../Watchlist/selectors';

const RemoveButton = ({ dataProviderId }) => {
  const { destroyWatcher, createWatcher } = useWatcher(dataProviderId, false, false)
  const { name } = useSelector(getDataProviderSummaryById(dataProviderId))

  const onClick = useCallback(() => {
    destroyWatcher()

    const removeToast = () => toast.dismiss(`watchlistRemoval${dataProviderId}`)

    const undo = () => {
      createWatcher()
      removeToast()
    }

    toast(
      <div className="undo-toaster">
        <p><strong>{name}</strong> removed from Watchlist</p>
        <button type="button" aria-label="undo" onClick={undo}>
          <i className="fas fa-undo" />Undo
        </button>
      </div>, { toastId: `watchlistRemoval${dataProviderId}`, position: toast.POSITION.BOTTOM_CENTER }
    )
  }, [createWatcher, destroyWatcher, name, dataProviderId])

  return (
    <>
      <ToastContainer autoClose={8000} hideProgressBar closeOnClick={false} closeButton={false} />
      <Tooltip
        content="Remove from Watchlist"
      >
        <button type="button" aria-label="unwatch" onClick={onClick} className="icon remove dp-icon fa-solid fa-signal-stream-slash">
          <i />
        </button>
      </Tooltip>
    </>
  )
}

export default RemoveButton
