import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['conversation']

  connect() {
    this.isConnected = true
  }

  disconnect() {
    this.isConnected = false
  }

  setActiveConversation() {
    this.conversationTargets.forEach((element) => {
      element.classList.remove('active');
    });

    const selectedConversation = this.conversationTargets.find((element) => window.location.pathname.endsWith(`/${element.querySelector('a').getAttribute('href')}`))

    if (selectedConversation) {
      selectedConversation.classList.add('active');
    }
  }

  conversationTargetConnected(element) {
    if (this.isNewActiveConversation(element)) {
      this.jumpToConversation(element);
      this.setActiveConversation()
    }
  }

  jumpToConversation(element) {
    const pathSegments = window.location.pathname.split('/')
    pathSegments.pop()
    pathSegments.push(element.querySelector('a').getAttribute('href'))
    window.history.pushState({}, '', pathSegments.join('/'))
  }

  isNewActiveConversation(element) {
    return this.isConnected && element.classList.contains('active')
  }
}
