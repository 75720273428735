import React from 'react'
import { useSelector } from 'react-redux'

import Tooltip from '../../Shared/Tooltip'
import { getContactAttending, getPickProcess } from '../../../OneToOne/VendorPicksFilter'

const AttendingVendorBadge = ({ cardData }) => {
  const buysideContactAttending = useSelector(getContactAttending)

  const { inPersonOnly } = useSelector(getPickProcess)

  if (!cardData.attending || !buysideContactAttending || inPersonOnly) {
    return null
  }

  const attendingInPersonTagText = (
    <span>
      Vendors with this tag are attending the event in-person.<br />
      <br />
      Every effort will be made to book this vendor for an in-person meeting at the event.<br />
      <br />
      If booking an in-person meeting at the event is not possible (e.g too much demand)
      we&apos;ll schedule a virtual meeting instead.
    </span>
  )

  return (
    <Tooltip
      content={attendingInPersonTagText}
    >
      <span className="attending-in-person"><i className="icon fa-solid fa-id-badge" />Attending in-person</span>
    </Tooltip>
  )
}

export default AttendingVendorBadge
