import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  changeValue(event) {
    if (event.preventPropagation) event.preventPropagation()
    this.dispatchModifiedEvent(event)
  }

  dispatchModifiedEvent(event) {
    const customEvent = new CustomEvent('form-component-modified', { detail: { value: event.target.value }, bubbles: true })
    this.element.dispatchEvent(customEvent)
  }
}
