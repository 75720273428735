import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router';
import watchlistReducer from './watchlistSlice'
import contentTypeFilterReducer from './contentTypeFilterSlice'

export const reducer = (history) => combineReducers({
  router: connectRouter(history),
  data: watchlistReducer,
  contentTypeFilter: contentTypeFilterReducer
})

export const config = (history) => ({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware(history)),
  reducer: reducer(history),
  devTools: { name: 'watchlistStore' }
})

export default (history) => configureStore(config(history))
