import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import notifier from '../../UserNotifications/notifier'
import { setLiveConnectionState } from '../../Chat/appSlice'
import { replaceMessagesAndConversation } from '../../Chat/messageSlice'

const LiveChatProxy = () => {
  const dispatch = useDispatch()
  useEffect(() => (
    notifier.subscribeConnectionState((state) => {
      dispatch(setLiveConnectionState(state))
    })
  ))

  useEffect(() => (
    notifier.subscribe('conversation_updated', (data) => {
      dispatch(replaceMessagesAndConversation(data))
    }, this)
  ))
  return null
}

export default LiveChatProxy
