import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'legendButton',
    'legend',
  ]

  connect() {
    this.setButtonText();
  }

  legendIsCollapsed() {
    return this.legendTarget.classList.contains('collapse');
  }

  setButtonText() {
    const action = this.legendIsCollapsed() ? 'Show' : 'Hide';
    this.legendButtonTarget.textContent = `${action} Neudata rating legend`;
  }

  toggleLegend() {
    this.legendTarget.classList.toggle('collapse');
    this.setButtonText();
  }
}
