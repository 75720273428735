import './watchlist.scss';
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { useHistory } from 'react-router-dom'
import watchlistStore from '../../Watchlist/watchlistStore'
import { configure, load } from '../../Watchlist/watchlistSlice';
import { setAllFilters } from '../../Watchlist/contentTypeFilterSlice'
import updateGlobalStore from '../../Watchlist/updateGlobalStore'

let store = null
let history = null

const reloadIfRequired = () => {
  let previousDataProvider = store.getState().router.location.query.dataProvider
  let previousFeedType = store.getState().router.location.query.feedType
  let previousSavedFilterId = store.getState().router.location.query.savedFilterId
  let previousFilteredSlugs = ['all']
  return () => {
    const { dataProvider, feedType, savedFilterId } = store.getState().router.location.query
    const { filteredSlugs } = store.getState().contentTypeFilter
    let reloadRequired = false
    let initialLoad = false
    if (previousFeedType !== feedType) {
      previousFeedType = feedType
      reloadRequired = true
      initialLoad = true
    }
    if (previousDataProvider !== dataProvider) {
      previousDataProvider = dataProvider
      reloadRequired = true
      initialLoad = true
    }
    if (previousSavedFilterId !== savedFilterId) {
      previousSavedFilterId = savedFilterId
      reloadRequired = true
      initialLoad = true
    }
    if (reloadRequired && initialLoad) {
      previousFilteredSlugs = ['all']
      store.dispatch(setAllFilters(true))
    } else if (previousFilteredSlugs.join(',') !== filteredSlugs.join(',')) {
      previousFilteredSlugs = filteredSlugs
      reloadRequired = true
    }

    if (reloadRequired) {
      store.dispatch(load({ initialLoad }))
    }
  }
}

/**
 * Perhaps incorrectly named now, this is the app that contains the watchlist page, however
 * the definition of 'watchlist' in this instance has changed - it has now become a list
 * of 'items'.
 * @param props
 * @param {Object} props.configuration - See props for watchlist/FeedApp
 * @returns {JSX.Element}
 * @constructor
 */
const WatchlistApp = (props) => {
  history = useHistory()
  if (store === null) {
    store = watchlistStore(history)
    store.dispatch(configure(props.configuration))
    store.dispatch(load({ initialLoad: true }))
  }

  useEffect(() => (
    store.subscribe(reloadIfRequired())
  ))
  useEffect(() => (
    store.subscribe(updateGlobalStore(store))
  ))

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        { props.children }
      </ConnectedRouter>
    </Provider>
  )
}
export default WatchlistApp
