import React, { useEffect } from 'react'
import {
  useSelector, useDispatch, useStore
} from 'react-redux'
import Modal from '../Shared/Modal'
import FeedItemModalContentApp from './FeedItemModalContentApp'
import { loadItem } from '../../Watchlist/watchlistSlice'
import { selectItem } from '../../Watchlist/selectors'

const FeedItemModalInner = ({ item, onClose }) => {
  const store = useStore()

  useEffect(() => {
    Modal.fire({
      html: <FeedItemModalContentApp store={store} id={item.item.id} type={item.item.type} />,
      width: '746px',
      showCancelButton: false,
      showConfirmButton: false,
      background: '#FFFFFF',
      position: 'center',
      padding: '0',
      showClass: {
        popup: 'modal-fade-in'
      },
      hideClass: {
        popup: 'modal-fade-out'
      },
      customClass: {
        htmlContainer: 'watchlist-item-modal-container',
        popup: 'modal-popup watchlist-item'
      }
    }).then((result) => {
      if (result.isDismissed) {
        onClose()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Render once

  return null
}

const FeedItemModal = ({ id, type, onClose }) => {
  const item = useSelector(selectItem({ id, type }))
  const dispatch = useDispatch()
  useEffect(() => {
    if (!item) {
      dispatch(loadItem({ id, type }))
    }
  }, [item, id, type, dispatch])

  if (item) {
    return (
      <div className="watchlist-item-modal">
        <FeedItemModalInner item={item} onClose={onClose} />
      </div>
    )
  }
  return ''
}

export default FeedItemModal
