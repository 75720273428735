import React, { useState } from 'react'

const FlashMessage = ({ message, type = 'bad', onClose = () => {} }) => {
  const [closed, setClosed] = useState(false)

  const handleClose = (e) => {
    e.preventDefault()
    setClosed(true)
    onClose()
  }

  if (closed) { return null }

  return (
    <div className="m-flash-messages">
      <div className={`message message--${type} message--persist ${closed ? 'closed' : 'open'}`}>
        <div>
          <span>{message}</span>
          <button type="button" onClick={handleClose}>
            <span>Close</span>
          </button>
        </div>
      </div>
    </div>
  )
}
export default FlashMessage
