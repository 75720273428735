import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    sidebarId: String
  }

  open(event) {
    event.preventDefault()
    event.stopPropagation()
    document.getElementById(this.sidebarIdValue).dispatchEvent(new CustomEvent('sidebar:open'))
  }
}
