import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    maxLength: String
  }

  static targets = ['counter']

  updateCount(currentLength) {
    const maxLength = this.maxLengthValue

    this.counterTarget.innerText = `${currentLength}/${maxLength}`
    if (currentLength < maxLength) {
      this.counterTarget.classList.add('valid')
      this.counterTarget.classList.remove('invalid')
    } else {
      this.counterTarget.classList.remove('valid')
      this.counterTarget.classList.add('invalid')
    }
  }
}
