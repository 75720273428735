import './date_group_header_card.scss';
import React from 'react'
import { relativeDay } from '../../../helpers/dateHelpers';

/**
 * A card used to display the date in the messages list (i.e. 'Today', 'Yesterday' etc..)
 * @param message The message data - only the posted_at property is used
 * @returns {JSX.Element}
 * @constructor
 */
const DateGroupHeaderCard = ({ message }) => (
  <div className="date-group-header-card" data-card-type="date-group-header">
    <span className="day">{relativeDay(message.posted_at)}</span>
  </div>
)
export default DateGroupHeaderCard
