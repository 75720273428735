import React, { forwardRef } from 'react'

/**
 * A simple card to show that the user is 'All caught up'
 *   It contains a list of items that are used by the observing code
 *   to identify which items are to be marked as read when this is shown
 *
 * @component
 * @param {Object} props - The react properties
 * @return {ReactElement} The card contents
 */
const AllCaughtUpCard = forwardRef((props, ref) => (
  <li className="all-caught-up" ref={ref}>
    <i className="icon fa-regular fa-check" />
    <p className="heading">You&apos;re all caught up</p>
    <p className="content">since your last session</p>
  </li>
))

export default AllCaughtUpCard
