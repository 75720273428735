import { Controller } from '@hotwired/stimulus';
import getCSRFToken from '../../../javascript/src/getCSRFToken';

// THIS WAS COPY AND PASTED FROM AN ES6 FILE. DO NOT COPY ANY PATTERNS
// IN HERE.
export default class extends Controller {
  static values = {
    maxDatasets: Number
  }

  connect() {
    this.form = this.element.querySelector('.compare-launcher form.dataset-comparison');
    this.checkboxes = document.querySelectorAll('.dataset-result input[type=checkbox][name=toggle_comparison\\[\\]]');

    this.updateFormState();
    this.updateCheckboxes();

    this.checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', (event) => {
        const payload = {};

        if (event.target.checked) {
          payload.add_datasets_to_comparison = [event.currentTarget.value];
        } else {
          payload.remove_datasets_from_comparison = [event.currentTarget.value];
        }

        fetch('/comparison', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': getCSRFToken()
          },
          body: JSON.stringify(payload)
        })
          .then((response) => response.json())
          .then((response) => {
            this.form.querySelector('[name=datasets_in_comparison]').value = response.dataset_ids.length;
            this.form.querySelector('[name=compare]').value = btoa(JSON.stringify(response));
            this.updateCheckboxes();
            this.updateFormState();
            this.updateCount(response);
          })
      });
    })
  }

  updateCheckboxes() {
    const datasetsInComparison = parseInt(this.form.querySelector('[name=datasets_in_comparison]').value, 10);

    if (datasetsInComparison > this.maxDatasetsValue - 1) {
      document.querySelectorAll('.dataset-result input:not(:checked)[type=checkbox][name=toggle_comparison\\[\\]]').forEach((checkbox) => {
        checkbox.disabled = true;
      });
    } else {
      document.querySelectorAll('.dataset-result input:not(:checked)[type=checkbox][name=toggle_comparison\\[\\]]').forEach((checkbox) => {
        checkbox.disabled = false;
      });
    }
  }

  updateFormState() {
    const datasetsInComparison = parseInt(this.form.querySelector('[name=datasets_in_comparison]').value, 10);
    if (datasetsInComparison > 0) {
      this.form.classList.add('is-active');
    } else {
      this.form.classList.remove('is-active');
    }
  }

  updateCount(response) {
    const countSpan = this.element.querySelector('.compare-launcher span.count');

    const count = response.dataset_ids.length;
    const tooltip = this.element.querySelector('.compare-datasets-tooltip')
    const comparisonButton = this.element.querySelector('.comparison-button')

    if (countSpan) {
      countSpan.innerHTML = ` ${count}`;

      if (count > 0) {
        tooltip.classList.add('hidden')
        comparisonButton.classList.remove('hidden')
      } else {
        tooltip.classList.remove('hidden')
        comparisonButton.classList.add('hidden')
      }
    }
  }
}
