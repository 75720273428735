import { assign, isEqual } from 'lodash'
import { createSelector } from '@reduxjs/toolkit'

import {
  PERSONAL_WATCHLIST_FEED_TYPE,
  SHARED_WATCHLIST_FEED_TYPE,
} from '../constants/watchlist'

export const getCanShareWatchlist = (state) => state.data.canShareWatchlist

export const getDataProviders = (state) => (
  state.data.dataProviderSummaries
)

export const getDataProviderById = (dataProviderId) => (state) => (
  state.data.dataProviderFilterOptions.find(({ id }) => dataProviderId === id)
)

export const getDataProviderSummaryById = (dataProviderId) => (state) => (
  state.data.dataProviderSummaries.find(({ id }) => dataProviderId === id)
)

export const getDataProviderSummaryBySlug = (dataProviderSlug) => (state) => (
  getDataProviders(state).find(({ slug }) => dataProviderSlug === slug)
)

export const getPersonalDataProviderSummaryBySlug = (dataProviderSlug) => (state) => (
  getDataProviders(state).find(({ slug, personal }) => dataProviderSlug === slug && personal)
)

export const getDataProviderFilterOptionBySlug = (dataProviderSlug) => (state) => (
  state.data.dataProviderFilterOptions.find(({ slug }) => dataProviderSlug === slug)
)

export const getCardsLoading = (state) => state.data.cardsLoading

export const getSharedDataProviders = (state) => (
  getDataProviders(state).filter((dp) => (dp.shared))
)

export const getPersonalDataProviders = (state) => (
  getDataProviders(state).filter((dp) => (dp.personal))
)

export const getCurrentTab = (state) => state.data.currentTab

export const getCurrentDataProviderSummaries = (state) => {
  const currentTab = getCurrentTab(state)
  const canShareWatchlist = getCanShareWatchlist(state)

  if (!canShareWatchlist) {
    return getPersonalDataProviders(state)
  }

  switch (currentTab) {
    case SHARED_WATCHLIST_FEED_TYPE:
      return getSharedDataProviders(state)
    case PERSONAL_WATCHLIST_FEED_TYPE:
      return getPersonalDataProviders(state)
    default:
      return getDataProviders(state)
  }
}

export const getUnsharedDataProviders = (state) => {
  const isNotShared = ({ shared }) => !shared
  return getPersonalDataProviders(state).filter(isNotShared)
}

export const getFeedTypeSummaries = (state) => (
  state.data.feedTypeSummaries
)

export const selectItem = ({ id, type }) => (state) => (
  state.data.itemsLookup[`feeds.${type}.${id}`]
)

export const selectFeedItems = createSelector(
  (s) => s.data.feedItemMetadata,
  (s) => s.data.itemsLookup,
  (feedItemMetadata, itemsLookup) => feedItemMetadata.map((meta) => (
    assign({}, itemsLookup[meta.lookupKey], meta)
  ))
)

export const selectScopeChanging = (state) => (
  !isEqual(state.data.loadRequestedState, state.data.loadedState)
)
export const selectEmailPreferenceToken = (state) => (
  state.data.emailPreferenceToken
)

export const selectMailingListPreference = (state) => (
  state.data.mailingListPreference
)
