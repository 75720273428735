import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toggleFilterVisibility } from '../../../OneToOne/vendorPicksSlice'
import ToggleInput from '../../Shared/ToggleInput'

const FilterVisibilityToggle = () => {
  const showFilters = useSelector((state) => state.data.showFilters)
  const dispatch = useDispatch()

  const onClick = () => { dispatch(toggleFilterVisibility()) }

  return (
    <div className="toggle-container hide-filters-toggle">
      <span>Show Filters</span>
      <ToggleInput className="switch" onClick={onClick} checked={showFilters} />
    </div>
  )
}

export default FilterVisibilityToggle
