import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useLocation } from 'react-router-dom';
import FeedTypeFilter from './FeedTypeFilter'
import WatchedLists from './WatchedLists'
import ToggleFilterVisibility from './ToggleFilterVisibility'
import WatchlistFlash from './WatchlistFlash'
import FeedItemModal from './FeedItemModal'
import FeedToolbar from './FeedToolbar'
import RemoteCardList from './RemoteCardList';
import { removeModalItem } from '../../Watchlist/watchlistSlice';

const WatchlistPage = () => {
  const showFilters = useSelector((state) => state.data.showFilters)
  const dispatch = useDispatch()

  const searchParams = new URLSearchParams(useLocation().search)
  const itemId = searchParams.get('item_id')
  const itemType = searchParams.get('item_type')

  const removeItemFromSearchParams = () => {
    dispatch(removeModalItem())
  }

  return (
    <div className="m-watchlist-page">
      <WatchlistFlash />
      <div className="layout-columns">
        <div className="column column--1 feed-button-sidebar">
          <ToggleFilterVisibility />
          <FeedTypeFilter />
          <hr className={showFilters ? 'show' : 'hide'} />
          <WatchedLists />
        </div>
        <div className="column column--2">
          {itemId && itemType
            ? <FeedItemModal id={itemId} type={itemType} onClose={removeItemFromSearchParams} />
            : null}
          <FeedToolbar />
          <RemoteCardList />
        </div>
      </div>
    </div>
  )
}

export default WatchlistPage
