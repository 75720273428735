import React, { useState } from 'react'
import Modal from 'react-modal';

const UserConfirmationModal = (props) => {
  const {
    contact, isOpen
  } = props
  const style = {
    overlay: {
      backgroundColor: '#000000D0'
    }
  }
  const bodyLines = [
    'Hi Neudata Support,',
    '',
    'Please can you send me an AltDating link?',
    '',
    'Thank you!',
    '',
    'Best regards,'
  ]
  const body = bodyLines.join('\n')
  const [modalIsOpen, setModalIsOpen] = useState(isOpen)

  return (
    <Modal isOpen={modalIsOpen} className="user-confirmation-modal" ariaHideApp={false} style={style}>
      <div>
        <div className="user-confirmation-modal__header">
          <div className="user-confirmation-modal__welcome-container">
            Welcome {contact.name} {contact.surname}
          </div>
          <p>Welcome to AltDating! Click <strong>Start</strong> to make your selections</p>
          <div className="user-confirmation-modal__actions">
            <button className="start-button" type="button" onClick={() => { setModalIsOpen(false) }}>Start</button>
          </div>
        </div>
        <div className="user-confirmation-modal__footer">
          <p>Not {contact.name} {contact.surname}? Contact <a href={`mailto:altdating-links@neudata.co?subject=Request for AltDating link&body=${encodeURIComponent(body)}`}>altdating-links@neudata.co</a> to get your own personalised link.</p>
        </div>
      </div>
    </Modal>
  )
}

export default UserConfirmationModal
