import React, { forwardRef } from 'react'

const BaseCard = forwardRef((props, ref) => {
  const dataProvider = (
    <span className="data-provider-name">
      <a href={props.watchlistItem.item.vendor_path}>{props.watchlistItem.item.data_provider}</a>
    </span>
  )

  const dataset = (
    <span className="dataset">
      <a href={props.watchlistItem.item.dataset.path}>{props.watchlistItem.item.dataset.title}</a>
    </span>
  )

  const status = props.watchlistItem.item.new_listing ? 'newly listed' : 'updated'
  const logo = props.watchlistItem.item.logo ? <div className="logo"><img alt="Logo" src={props.watchlistItem.item.logo} /></div> : ''
  const changedBy = props.watchlistItem.item.modified_by_neudata ? 'Neudata' : 'the vendor'

  return (
    <li ref={ref} className="watchlist-card dataset feed-card">
      <div className="basic-card-details">
        <div className="info">
          <div className="summary-container">
            { logo }
            <span className="summary">
              <span>
                {props.watchlistItem.item.lite ? 'Lite dataset ' : ''}
                {dataset}
                {' by '}
                {dataProvider}
                {' was '}
                {status} by {changedBy}
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="additional-card-details">
        { props.children }
      </div>
    </li>
  )
})

export default BaseCard
