import { Application } from '@hotwired/stimulus'

const application = Application.start()

// Configure Stimulus development experience based on the environment
application.debug = process.env.NODE_ENV === 'development';

// Need Stimulus globally available for application code since
//   sometimes we call it from arbitrary functions
window.Stimulus = application;

// eslint-disable-next-line import/prefer-default-export
export { application }
