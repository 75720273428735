import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['template', 'confirmation']

  copyToClipboard() {
    navigator.clipboard.writeText(this.templateTarget.innerHTML);
    this.confirmationTarget.classList.remove('hidden');
  }

  hideConfirmation() {
    this.confirmationTarget.classList.add('hidden');
  }
}
