import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['publishButton', 'deferredPublishButton', 'deferralDate', 'form', 'normalPublishSummaryItem', 'deferredPublishSummaryItem', 'deferredPublicationDateValue']

  static values = { deferredPublicationEnabled: Boolean, deferralDate: { type: String, default: '' } }

  setDeferredPublicationVisiblity(event) {
    this.deferredPublicationEnabledValue = event.target.checked
  }

  deferredPublicationEnabledValueChanged() {
    if (this.deferredPublicationEnabledValue) {
      this.deferralDateTarget.classList.remove('hidden')
      this.publishButtonTarget.classList.add('hidden')
      this.deferredPublishButtonTarget.classList.remove('hidden')
    } else {
      this.deferralDateTarget.classList.add('hidden')
      this.deferralDateTarget.querySelector('input[type=date]').value = ''
      this.publishButtonTarget.classList.remove('hidden')
      this.deferredPublishButtonTarget.classList.add('hidden')
      this.setSummaryState()
    }
    this.setFormState()
  }

  setDeferralDate(event) {
    this.deferralDateValue = event.target.value
  }

  deferralDateValueChanged() {
    this.setFormState();
    this.setSummaryState();
  }

  setSummaryState() {
    if (this.deferredPublicationEnabledValue && this.deferralDateValue !== '') {
      this.normalPublishSummaryItemTargets.forEach((item) => item.classList.add('hidden'))
      this.deferredPublishSummaryItemTargets.forEach((item) => item.classList.remove('hidden'))
      this.deferredPublicationDateValueTargets.forEach(
        (item) => { item.textContent = this.deferralDateValue }
      )
    } else {
      this.normalPublishSummaryItemTargets.forEach((item) => item.classList.remove('hidden'))
      this.deferredPublishSummaryItemTargets.forEach((item) => item.classList.add('hidden'))
    }
  }

  setFormState() {
    if (this.isValidForm()) {
      this.publishButtonTarget.classList.remove('disabled')
      this.deferredPublishButtonTarget.classList.remove('disabled')
    } else {
      this.publishButtonTarget.classList.add('disabled')
      this.deferredPublishButtonTarget.classList.add('disabled')
    }
  }

  isValidForm() {
    return !this.deferredPublicationEnabledValue || this.isValidDeferredPublicationDate()
  }

  disableForm() {
    this.formTarget.classList.add('modal--form-component__form-disabled')
  }

  isValidDeferredPublicationDate() {
    const deferredPublicationDate = Date.parse(this.deferralDateValue)
    const minDeferredPublicationDate = Date.parse(this.deferralDateTarget.querySelector('input').min)
    return !Number.isNaN(deferredPublicationDate)
      && deferredPublicationDate >= minDeferredPublicationDate
  }

  closeModal() {
    this.dispatch('modal:close', { prefix: false })
  }
}
