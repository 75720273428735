import React from 'react'
import { useSelector } from 'react-redux'
import FilterRows from './FilterRows'
import { datasetTypeSummaries } from '../../OneToOne/VendorPicksFilter'

const DataTypeFilter = () => {
  const showFilters = useSelector((state) => state.data.showFilters)

  return (
    <div className={`vendor-filter-group ${showFilters ? 'show' : 'hide'}`}>
      <span className="title">Data Types</span>
      <FilterRows selector={datasetTypeSummaries} />
    </div>
  )
}
export default DataTypeFilter
