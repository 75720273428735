import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails';

import getCSRFToken from '../../../javascript/src/getCSRFToken';

// Connects to data-controller="forms--comment-field-component"
export default class extends Controller {
  static values = {
    lockPublishing: Boolean,
    initialDraft: Boolean,
    modified: { type: Boolean, default: false }
  }

  connect() {
    // NOTE: Do not cargo program, see CommentsDropdownComponent stimulus controller
    this.subscribeToDropdownConnectedEvent()
    const commentEditor = this.element.querySelector('trix-editor')

    commentEditor.addEventListener('trix-change', () => {
      this.element.classList.add('comment-edited')
      this.element.querySelector('.draft').disabled = false
      if (!this.lockPublishingValue) {
        this.element.querySelector('.publish').disabled = false
      }

      if (!this.modifiedValue) {
        this.modifiedValue = true
        this.replayModifiedEvents()
      }
    })

    // Hide the publish/draft notice after 5s
    setTimeout(() => {
      const notice = this.element.querySelector('.notice')
      notice.classList.add('hidden')
    }, 5000)
  }

  disconnect() {
    this.unsubscribeFromDropdownConnectedEvent()
  }

  open() {
    if (!this.element.classList.contains('disabled')) {
      this.element.classList.add('commented')
    }
  }

  saveComment(action) {
    const form = new FormData()
    const commentTextParam = this.element.querySelector('input').value
    form.append('comment[comment_text]', commentTextParam)
    if (action.params.draft) {
      form.append('comment[draft]', action.params.draft)
    }

    // Manual XHR to avoid nested HTML forms
    fetch(action.params.href, {
      method: 'PUT',
      body: form,
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': getCSRFToken(),
      }
    }).then((response) => {
      if (response.ok) {
        return response.text()
      }

      return 'An error has occurred!'
    }).then((html) => {
      Turbo.renderStreamMessage(html)
    })
  }

  removeComment(action) {
    // Manual XHR to avoid nested HTML forms
    fetch(action.params.href, {
      method: 'DELETE',
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': getCSRFToken()
      }
    }).then((response) => {
      if (response.ok) {
        return response.text()
      }

      return 'An error has occurred!'
    }).then((html) => {
      Turbo.renderStreamMessage(html)
    })
  }

  replayModifiedEvents() {
    if (this.modifiedValue) {
      this.dispatchDraftAddedEvent()
      if (!this.lockPublishingValue) {
        this.dispatchPublishableDraftAddedEvent()
      }
      if (!this.initialDraftValue) {
        this.dispatchNewDraftAddedEvent()
      }
    }
  }

  dispatchDraftAddedEvent() {
    window.dispatchEvent(new CustomEvent(
      'Datasets::ReviewNavigaionBarComponent::CommentsDropdownComponent#draft-added'
    ))
  }

  dispatchNewDraftAddedEvent() {
    window.dispatchEvent(new CustomEvent(
      'Datasets::ReviewNavigaionBarComponent::CommentsDropdownComponent#new-draft-added'
    ))
  }

  dispatchPublishableDraftAddedEvent() {
    window.dispatchEvent(new CustomEvent(
      'Datasets::ReviewNavigaionBarComponent::CommentsDropdownComponent#publishable-draft-added'
    ))
  }

  // NOTE: Do not cargo program, see CommentsDropdownComponent stimulus controller
  subscribeToDropdownConnectedEvent() {
    this.subscriberFunc = () => { this.replayModifiedEvents() }
    window.addEventListener(
      'Datasets::ReviewNavigaionBarComponent::CommentsDropdownComponent#connected',
      this.subscriberFunc
    )
  }

  unsubscribeFromDropdownConnectedEvent() {
    window.removeEventListener(
      'Datasets::ReviewNavigaionBarComponent::CommentsDropdownComponent#connected',
      this.subscriberFunc
    )
  }
}
