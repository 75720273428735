import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.observer = new MutationObserver(() => {
      this.scrollToView()
    })

    this.observer.observe(this.element, { childList: true, subtree: true })
  }

  disconnect() {
    this.observer.disconnect()
  }

  scrollToView() {
    this.element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}
