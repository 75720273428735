import React, { forwardRef } from 'react'
import { Link } from 'react-router-dom';

const EmptyCard = forwardRef((props, ref) => (
  <li ref={ref} className="watchlist-card empty">
    <div className="icon" />
    <p className="heading">No feed items to show</p>
    <p className="description">
      Try another filter, vendor or the &nbsp;
      <Link to={{ search: '', hash: '' }}>Feed Home</Link>
    </p>
  </li>
))

export default EmptyCard
