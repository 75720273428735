function hasMsoListStyle(node) {
  return node.nodeType === Node.ELEMENT_NODE
         && (
           node.getAttribute('style')
           && node.getAttribute('style').includes('mso-list:Ignore')
         )
}

function isCenterAligned(node) {
  const style = node.getAttribute('style');
  return style && style.replace(' ', '').includes('text-align:center');
}

function processNode(node) {
  if (node.nodeType === Node.COMMENT_NODE || hasMsoListStyle(node)) {
    // Skip all comment nodes and special list formatting spans containing dots or numbers
    return '';
  }

  if (node.nodeType === Node.ELEMENT_NODE) {
    if (node.tagName.toLowerCase() === 'v:shape') {
      // Can't upload shapes so just mark where we skipped them
      return '<br><b>[INSERT IMAGE HERE]</b><br>';
    }

    const centerStyle = isCenterAligned(node) ? ' style="text-align: center;"' : '';
    // Might not exist, but can't define vars in case context so need to do it up here.
    const href = node.getAttribute('href');

    switch (node.tagName.toLowerCase()) {
      case 'a':
        if (href) {
          return `<a target=_blank href="${href}">${Array.from(node.childNodes).map(processNode).join('')}</a>`;
        }

        return Array.from(node.childNodes).map(processNode).join('');
      case 'p':
        if (node.className.includes('MsoListParagraph')) {
          // Process Word list paragraphs
          let textContent = node.textContent || '';
          textContent = textContent.replace(/^[\s\S]*?\s+/, ''); // Strip leading symbols and spaces
          return `<p${centerStyle}>${textContent}</p>`;
        }
        return `<p${centerStyle}>${Array.from(node.childNodes).map(processNode).join('')}</p>`;
      case 'b':
      case 'i':
      case 'em':
      case 'sup':
      case 'sub':
      case 'strong':
        // Allow these tags and process their children
        return `<${node.tagName.toLowerCase()}>${Array.from(node.childNodes).map(processNode).join('')}</${node.tagName.toLowerCase()}>`;
      case 'img':
        // Allow <img> tags and preserve their attributes
        return `<img src="${node.getAttribute('src')}" alt="${node.getAttribute('alt')}">`;
      default:
        // Convert other elements to their text content
        return Array.from(node.childNodes).map(processNode).join('');
    }
  } else if (node.nodeType === Node.TEXT_NODE) {
    return node.nodeValue;
  }

  return '';
}

export default (plugin, args) => {
  const doc = new DOMParser().parseFromString(args.content, 'text/html');

  const nodes = Array.from(doc.body.childNodes).map(processNode).filter((node) => node.trim() !== '');
  args.content = nodes.join('');
}
