/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { useSelector } from 'react-redux'

import { getCanShareWatchlist } from '../../Watchlist/selectors'
import Tooltip from '../Shared/Tooltip'

const WatchlistTitle = () => {
  const canShareWatchlist = useSelector(getCanShareWatchlist)

  return (
    <div className="watchlist-title">
      <Tooltip
        theme="shared-watchlist-tooltip"
        disabled={!canShareWatchlist}
        content={(
          <div>
            <h3>Watchlist</h3>
            <p>
              When you add vendors to your watchlist in Scout,
              they're added to your personal watchlist by default,
              meaning only you can see them.
            </p>
            <p>
              To share a vendor with the whole team,
              hit the overflow menu next to the vendor
              and select 'Shared Watchlist'.
            </p>
            <div className="vendor-image" />
            <p>Vendors can live on both your personal and the shared watchlist at the same time.</p>
            <div className="share-image" />
          </div>
        )}
      >
        <h1 className="title">Watchlist</h1>
      </Tooltip>
    </div>
  )
}
export default WatchlistTitle
