import { Controller } from '@hotwired/stimulus'
import React from 'react';
import ReactDOM from 'react-dom';
import SentryFeedApp from './SentryFeedApp'

export default class extends Controller {
  static values = {
    configuration: Object
  }

  static targets = ['reactApp']

  connect() {
    if (this.hasReactAppTarget) {
      const component = React.createElement(
        SentryFeedApp,
        { configuration: JSON.parse(this.reactAppTarget.dataset.configuration) }
      )
      ReactDOM.render(component, this.reactAppTarget)
    }
  }
}
