/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PickOrRemoveButton from './PickOrRemoveButton'
import VendorCardProfileButton from './VendorCardProfileButton'
import VendorProfile from './VendorProfile'
import { toggleExpandedCard } from '../../OneToOne/vendorPicksSlice'
import VendorBadges from './VendorBadges'
import { getExpandedCards } from '../../OneToOne/VendorPicksFilter'
import { getContactIsScoutUser } from '../../OneToOne/buysidePickProcessSlice'
import RequestInfo from './RequestInfo'

const VendorCard = (props) => {
  const expandedCards = useSelector(getExpandedCards)
  const dispatch = useDispatch()
  const open = expandedCards.includes(props.item.id)
  const contactIsScoutUser = useSelector(getContactIsScoutUser)

  return (
    <div data-testid={props.item.id} className={`vendor-card ${open ? 'open' : ''}`}>
      <div className="vendor-card__details">
        <div className="vendor-card__summary">
          {contactIsScoutUser && (
            <a href={`/data_provider/${props.item.id}`} target="_blank" rel="noreferrer">
              <figure className="vendor-logo">
                {props.item.logo && <img alt="Logo" src={props.item.logo} />}
              </figure>
            </a>
          )}
          {!contactIsScoutUser && (
            <figure className="vendor-logo">
              {props.item.logo && <img alt="Logo" src={props.item.logo} />}
            </figure>
          )}
          <div className="vendor-summary">
            <div>
              <h3>
                {contactIsScoutUser && (
                  <a href={`/data_provider/${props.item.id}`} className="vendor-card__link" target="_blank" rel="noreferrer">
                    {props.item.title}
                  </a>
                )}
                {!contactIsScoutUser && (props.item.title)}
              </h3>
              <span
                className="dataset-type"
              >{props.item.vendor_profile?.dataset_type?.join(', ')}
              </span>
            </div>
            <VendorBadges cardData={props.item} />
          </div>

          {
            props.item.vendor_profile
            && (
              <div className="latest-developments" data-testid="latest-developments">
                <h4>Latest Developments</h4>
                <p>{props.item.vendor_profile.latest_developments}</p>
              </div>
            )
          }

          <RequestInfo dataProviderName={props.item.title} dataProviderID={props.item.id} />
        </div>
        { open && <VendorProfile vendorProfile={props.item.vendor_profile} /> }
      </div>
      <div className="action-buttons">
        <VendorCardProfileButton
          item={props.item}
          onClick={() => {
            dispatch(toggleExpandedCard(props.item.id))
          }}
          open={open}
        />
        <PickOrRemoveButton item={props.item} />
      </div>

    </div>
  )
}

export default VendorCard
