import React, { useRef } from 'react'
import axios from '../../axiosRails'
import ToggleInput from '../Shared/ToggleInput'

const PreferenceToggle = (props) => {
  const { displayResponseStatusRef } = props;
  const { setDisplayResponseFlag } = props;

  // Keeping track of component mounted/unmounted state to prevent async actions,
  // such as the axios Promise below, from trying to update the component state
  // after it's been unmounted.
  // Doesn't seem to come up in practice for this component,
  // but got flagged by the Jest component test.
  const mounted = useRef(false)
  React.useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  })

  const toggleShouldSend = () => {
    // Toggle up front to reduce lag - correct if required on error
    setDisplayResponseFlag(false)
    props.setShouldSend(!props.shouldSend)

    // NOTE: The PreferenceToggle is initialized with should_send, but can only update possible_send
    const payload = {
      token: props.token,
      email_management_mailing_list_preference: {
        email_management_mailing_list_id: props.mailingList,
        possible_send: !props.shouldSend
      }
    }

    axios.post(
      props.path,
      JSON.stringify(payload),
      {
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
      }
    ).then(() => {
      if (mounted.current) {
        displayResponseStatusRef.current = true
        setDisplayResponseFlag(true)
      }
    }).catch(() => {
      if (mounted.current) {
        displayResponseStatusRef.current = false
        setDisplayResponseFlag(true)
        props.setShouldSend(props.shouldSend)
      }
    })
  }

  return (
    <ToggleInput
      className="preference-toggle "
      onClick={toggleShouldSend}
      checked={props.shouldSend}
    />
  )
}
export default PreferenceToggle
