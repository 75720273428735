import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input']

  connect() {
    window.setTimeout(this.updateCount.bind(this), 0)
  }

  changeValue(event) {
    this.updateCount()
    this.dispatchModifiedEvent(event)
  }

  dispatchModifiedEvent(event) {
    const customEvent = new CustomEvent('form-component-modified', { detail: { value: event.target.value }, bubbles: true })
    this.element.dispatchEvent(customEvent)
  }

  updateCount() {
    const maxLength = this.inputTarget.getAttribute('maxlength')
    if (!maxLength) { return }

    this.characterCountController().updateCount(this.inputTarget.value.length)
  }

  characterCountController() {
    const countComponent = this.element.querySelector('.forms--character-count-component')
    return this
      .application
      .getControllerForElementAndIdentifier(countComponent, countComponent.dataset.controller)
  }
}
