import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

export default class extends Controller {
  connect() {
    $(this.element).on('select2:select', function () {
      const event = new Event('change', { bubbles: true })
      this.dispatchEvent(event);
    });
  }

  submit() {
    this.element.requestSubmit();
  }
}
