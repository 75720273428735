/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef } from 'react'
import ItemShareableLink from './ItemShareableLink'
import Date from './Date'

const DatasetCard = forwardRef((props, ref) => {
  const dataProvider = (
    <span className="data-provider-name">
      <a href={props.watchlistItem.item.vendor_path}>{props.watchlistItem.item.data_provider}</a>
    </span>
  )

  const dataset = (
    <span className="dataset">
      <a href={props.watchlistItem.item.dataset.path}>{props.watchlistItem.item.dataset.title}</a>
    </span>
  )

  const watchlistUpdateSummary = props.watchlistItem.item.watchlist_update_summary
  const { description } = props.watchlistItem.item
  const descriptionTruncated = props.watchlistItem.item.description_truncated

  const updateSummaryUrl = (
    `${props.watchlistItem.item.dataset.path}#update`
  )

  const status = props.watchlistItem.item.new_listing ? <strong>newly listed</strong> : 'updated'
  const changedBy = props.watchlistItem.item.modified_by_neudata ? 'Neudata' : 'the vendor'
  const logo = props.watchlistItem.item.logo ? <div className="logo"><img alt="Logo" src={props.watchlistItem.item.logo} /></div> : ''
  return (
    <li ref={ref} className="watchlist-card dataset">
      <div className="basic-card-details">
        <div className="info">
          <div className="summary-container">
            { logo }
            <span className="summary">
              <span>
                {props.watchlistItem.item.lite ? 'Lite dataset ' : ''}
                {dataset}
                {' by '}
                {dataProvider}
                {' was '}
                {status} by {changedBy}
              </span>
              <Date date={props.watchlistItem.item.publication_date} />
            </span>
          </div>
        </div>
        <ItemShareableLink item={props.watchlistItem.item} />
      </div>

      {(watchlistUpdateSummary || description) && (
        <div className="additional-card-details">
          <span className="content" dangerouslySetInnerHTML={{ __html: watchlistUpdateSummary }} />
          <span className="description" dangerouslySetInnerHTML={{ __html: description }} />

          {!props.watchlistItem.item.lite && descriptionTruncated && (
            <div className="read-full-update">
              <a href={updateSummaryUrl}>Read full update</a>
            </div>
          )}
        </div>
      )}
    </li>
  )
})

export default DatasetCard
