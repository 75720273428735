import { cloneElement } from 'react';
import CardLookup from './CardLookup'

/**
 * @private
 * @abstract
 * A base class that all compressors must extend
 */
const BaseCompressor = class {
  /**
   * @constructs
   * @param {Object} initialItem The first rendered component that starts the compression
   * @param {Function} cardRenderer A function which is passed an item and renders a normal
   *                                (uncompressed card) when required.
   * @param {Function} compressedCardRenderer A function which is passed the stored watchlistItems
   *                                           and the compressed card type to allow the caller
   *                                           to render it rather than having the markup in
   *                                           this class.
   */
  constructor(initialItem, cardRenderer, compressedCardRenderer) {
    this.type = null
    this.initialItem = initialItem
    this.watchlistItems = []
    this.cardRenderer = cardRenderer
    this.compressedCardRenderer = compressedCardRenderer
    this.groupAfter = 1
  }

  canAccept(watchlistItem) {
    return watchlistItem.item.type === this.type
  }

  card() {
    if (this.watchlistItems.length === 0) { return null }
    const CardFn = CardLookup[this.watchlistItems[0].item.type];
    if (!CardFn) { throw new Error(`No compressed card defined for type '${this.watchlistItems[0].type}'`) }

    return (
      cloneElement(
        this.compressedCardRenderer(this.watchlistItems, CardFn),
        { watchlistItems: this.watchlistItems, children: this.cardRenderer }
      )
    );
  }

  add(item) {
    this.watchlistItems.push(item)
  }
}
export default BaseCompressor
