import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import RequestReportModal from './RequestReportModal'
import { getContactIsScoutUser } from '../../OneToOne/buysidePickProcessSlice'

const RequestInfo = ({ dataProviderName, dataProviderID }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const contactIsScoutUser = useSelector(getContactIsScoutUser)
  const buttonRef = useRef(null);

  if (contactIsScoutUser) {
    return null
  }

  const closeModal = () => {
    setModalOpen(false);

    setTimeout(() => {
      if (buttonRef.current) {
        buttonRef.current.blur()
      }
    }, 0)
  }

  return (
    <div className="request-info">
      <h4>Request Neudata Research on this vendor</h4>
      <p>
        Neudata has produced an independent assessment of this vendor. <button ref={buttonRef} type="button" className="request-info__open-modal " onClick={() => setModalOpen(true)}>Click here</button> to request this report.
      </p>
      <RequestReportModal
        dataProviderName={dataProviderName}
        dataProviderID={dataProviderID}
        isOpen={modalOpen}
        onClose={closeModal}
      />
    </div>
  )
}

export default RequestInfo
