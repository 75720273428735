import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.boundHandleScroll = this.handleScroll.bind(this)
    window.addEventListener('scroll', this.boundHandleScroll)
  }

  disconnect() {
    window.removeEventListener('scroll', this.boundHandleScroll)
  }

  handleScroll() {
    const header = document.querySelector('#dataset-header')

    if (header.getBoundingClientRect().bottom < 120) {
      this.element.classList.remove('hidden');
    } else {
      this.element.classList.add('hidden');
    }
  }
}
