/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, forwardRef } from 'react'

import ItemShareableLink from './ItemShareableLink'
import Date from './Date'

const DDQCard = forwardRef((props, ref) => {
  const dataProvider = (
    <span className="data-provider-name">
      <a href={props.watchlistItem.item.vendor_path}>{props.watchlistItem.item.data_provider}</a>
    </span>
  )

  const ddq = (
    <span className="ddq-link">
      <a href={props.watchlistItem.item.ddq_path} target="blank">
        Due Diligence Questionnaire
      </a>
    </span>
  )

  const { datasets } = props.watchlistItem.item
  const limitedDatasetsAssociated = props.watchlistItem.item.limited_datasets_associated
  const lockedDatasets = props.watchlistItem.item.locked_datasets

  const [redFlagsOpen, setRedFlagsOpen] = useState(!!props.watchlistItem.expandRedFlags)

  const datasetLinks = datasets.map((dataset) => (
    <li key={dataset.path}>
      <a href={dataset.path}>
        {dataset.title}
      </a>
    </li>
  ))

  const datasetList = (
    <ul className="dataset-links">
      {datasetLinks}
    </ul>
  )

  const limitedWithLockedDatasetDetails = (
    <details>
      <summary>
        This DDQ applies to <span className="datasets-dropdown">certain datasets</span>
        <i className="icon fa-solid fa-caret-right" />
      </summary>
      {datasetList}
      It may also apply to other listings outside of your subscription
    </details>
  )

  const limitedDatasetDetails = (
    <details>
      <summary>
        This DDQ only applies to <span className="datasets-dropdown">certain datasets</span>
        <i className="icon fa-solid fa-caret-right" />
      </summary>
      {datasetList}
    </details>
  )

  const logo = props.watchlistItem.item.logo ? <div className="logo"><img alt="Logo" src={props.watchlistItem.item.logo} /></div> : '';

  const numRedFlags = props.watchlistItem.item.red_flags.length

  const flags = props.watchlistItem.item.red_flags.map(({
    title,
    reasons
  }) => (
    <div key={title} className="flag-wrapper">
      <div className="flag-title">{title}</div>
      {reasons.map(({ number, question, option }) => (
        <div key={number} className="flag-body">
          <div className="flag-question"><span className="number">{number}</span> <span className="title">{question}</span></div>
          <div className="flag-answer"><span className="label">Response:</span> {option}</div>
        </div>
      ))}
    </div>
  ))

  return (
    <li ref={ref} className={`watchlist-card ddq ${(numRedFlags > 0) && 'red-flagged'}`}>
      <div className="basic-card-details">
        <div className="info">
          <div className="summary-container">
            { logo }
            <span className="summary">
              <div>
                {dataProvider}
                {' published a '}
                {ddq}
              </div>
              <Date date={props.watchlistItem.item.publication_date} />
            </span>
          </div>
        </div>
        <ItemShareableLink item={props.watchlistItem.item} />
      </div>
      <div className="additional-card-details">
        <span className="content" dangerouslySetInnerHTML={{ __html: props.watchlistItem.item.watchlist_update_summary }} />
        {(limitedDatasetsAssociated || (!limitedDatasetsAssociated && lockedDatasets)) && (
          <div className="datasets">
            { lockedDatasets ? limitedWithLockedDatasetDetails : limitedDatasetDetails }
          </div>
        )}
        {(!limitedDatasetsAssociated && !lockedDatasets) && (
          <div className="datasets">
            This DDQ applies to all datasets
          </div>
        )}
        <div className="flags-section">
          {(numRedFlags > 0) && (
            <div className="red-flag">
              <details open={redFlagsOpen}>
                <summary onToggle={(e) => setRedFlagsOpen(e.target.open)}>
                  {"Based on the provider's responses, Neudata's automated review process has raised the following "}
                  <span className="flag-label">red flag(s) <i className="icon fa-solid fa-caret-right" /></span>
                </summary>
                <div className="all-flags-wrapper">
                  {flags}
                </div>
              </details>
            </div>
          )}
          {(numRedFlags === 0) && (
            <div className="green-flag">
              {"Based on the provider's responses, Neudata's automated review process has raised "}
              <span className="flag-label">no flags</span>
            </div>
          )}
        </div>
        <div className="download-link">
          <a href={props.watchlistItem.item.ddq_path} target="blank">
            View/Download DDQ
          </a>
        </div>
      </div>
    </li>
  )
})

export default DDQCard
