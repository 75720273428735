import React, { useMemo } from 'react'
// eslint-disable-next-line import/no-unresolved
import WindowedSelect from 'react-windowed-select'

const Label = ({ imgUrl, name }) => (
  <div className="data-providers-organisations-dropdown__label" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    <div className="image-container">
      <img src={imgUrl} alt="" />
    </div>
    {` ${name}`}
  </div>
)

export default ({ organisations, onChange }) => {
  const options = useMemo(() => organisations.map((dataProvider) => ({
    ...dataProvider,
    value: dataProvider.name,
    label: <Label imgUrl={dataProvider.logoUrl} name={dataProvider.name} />,
  })), [organisations])

  return (
    <WindowedSelect
      classNamePrefix="data-providers-organisations-dropdown"
      options={options}
      isSearchable
      isClearable
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      onChange={onChange}
      placeholder="Search vendors"
    />
  )
}
