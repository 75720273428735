import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { sumBy } from 'lodash'
import useSearchParam from '../../../hooks/useSearchParam'

import { getCurrentDataProviderSummaries, getCurrentTab } from '../../../Watchlist/selectors'
import { WATCHLIST_FEED_TYPES } from '../../../constants/watchlist'
import UnseenCount from '../UnseenCount'

const AllFilterRow = () => {
  const currentTab = useSelector(getCurrentTab)
  const currentDataProviderSummaries = useSelector(getCurrentDataProviderSummaries)
  const dataProviderParam = useSearchParam('dataProvider')
  const feedTypeParam = useSearchParam('feedType')

  const watchlistSelected = WATCHLIST_FEED_TYPES.includes(feedTypeParam)

  const dataProviderNotSelected = !dataProviderParam
  const isSelected = watchlistSelected && dataProviderNotSelected

  const unSeenCount = sumBy(currentDataProviderSummaries, 'unseen')

  return (
    <div className={`filter-row${isSelected ? ' selected' : ''}`}>
      <Link to={`/feed?feedType=${currentTab}`}>
        <div className="image-container">
          <i className="fa fa-infinity" />
        </div>
        <span className="value" data-testid="watched-vendor-name">All</span>
      </Link>
      <UnseenCount count={unSeenCount} />
    </div>
  )
}

export default AllFilterRow
