import queryString from 'query-string';

class VendorPicksConfig {
  constructor() {
    if (VendorPicksConfig._instance) {
      return VendorPicksConfig._instance
    }
    VendorPicksConfig._instance = this;

    this.pageUrl = window.location.href
    const { protocol, host } = window.location
    this.rootUrl = `${protocol}//${host}`
    this.pageQuery = queryString.parse(window.location.search)
    this._regionMap = {}
  }

  listBuysidePicksUrl() {
    return queryString.stringifyUrl({ url: `${this.rootUrl}/buyside_picks`, query: { token: this.pageQuery.token, preselected_collection_ids: this.pageQuery.preselected_collection_ids } })
  }

  createBuysidePickUrl({ vendorId, pickProcessId }) {
    return queryString.stringifyUrl({ url: `${this.rootUrl}/buyside_picks`, query: { token: this.pageQuery.token, vendor_id: vendorId, pick_process_id: pickProcessId } })
  }

  deleteBuysidePickUrl({ vendorId, pickProcessId }) {
    return this.createBuysidePickUrl({ vendorId, pickProcessId })
  }

  submitBuysidePicksUrl() {
    return queryString.stringifyUrl({ url: `${this.rootUrl}/buyside_picks/submit`, query: { token: this.pageQuery.token } })
  }

  requestDatasetUrl() {
    return queryString.stringifyUrl({ url: `${this.rootUrl}/buyside_picks/request_dataset`, query: { token: this.pageQuery.token } })
  }

  submitBuysideContactUrl({ buysideContactId }) {
    return queryString.stringifyUrl({ url: `${this.rootUrl}/buyside_contacts/${buysideContactId}/submit`, query: { token: this.pageQuery.token } })
  }

  completedBuysidePicksUrl() {
    return queryString.stringifyUrl({ url: `${this.rootUrl}/buyside_picks/complete`, query: { token: this.pageQuery.token } })
  }

  updateBuysideContactUrl({ buysideContactId }) {
    return queryString.stringifyUrl({ url: `${this.rootUrl}/buyside_contacts/${buysideContactId}`, query: { token: this.pageQuery.token } })
  }

  setRegionMap(regionMap) {
    Object.assign(this._regionMap, regionMap)
  }

  regionMap() {
    return this._regionMap
  }
}

export default new VendorPicksConfig()
