import { filterBy } from './vendorPicksSlice'

// These helpers compute the full list of dataset types / region
// coverages / sector coverages from a list of cards. We don't need such a
// helper for vendor collections, because the API provides them all in the
// included data.
const datasetTypes = (card) => (
  (card.vendor_profile && card.vendor_profile.dataset_type) || []
)
const regionalCoverages = (card) => (
  (card.vendor_profile && card.vendor_profile.regional_coverage) || []
)
const sectorCoverages = (card) => (
  (card.vendor_profile && card.vendor_profile.sector_coverage) || []
)

// Given one of the helpers above, compute the unique list of values that the
// helper computes, when evaluated across all cards
const getDistinctValues = (generator) => (cards) => (
  cards
    .reduce((acc, card) => (
      acc.concat(
        (generator(card) || []).filter((value) => !acc.includes(value))
      )), [])
)

// Each of these gives the total list of filters for each type
const allDatasetTypes = (state) => getDistinctValues(datasetTypes)(state.cardsData)
const allRegionalCoverages = (state) => getDistinctValues(regionalCoverages)(state.cardsData)
const allSectorCoverages = (state) => getDistinctValues(sectorCoverages)(state.cardsData)
const allVendorCollections = (state) => state.allVendorCollections

// Given a card, and a filter value, return true/false according to whether the card has that value
const datasetTypeFilter = (type) => (card) => (
  datasetTypes(card).find((t) => t === type)
)
const regionalCoverageFilter = (region) => (card) => (
  regionalCoverages(card).find((r) => r === region)
)
const sectorCoverageFilter = (sector) => (card) => (
  sectorCoverages(card).find((s) => s === sector)
)
const vendorCollectionFilter = (collection) => (card) => (
  card.vendor_collections.find((col) => col.title === collection)
)

// The list of all filters that have been applied in the current state
const allFilters = (state) => [
  state.datasetTypes.map(datasetTypeFilter),
  state.regionalCoverages.map(regionalCoverageFilter),
  state.sectorCoverages.map(sectorCoverageFilter),
  state.selectedVendorCollections.map(vendorCollectionFilter)
]

export const numSelectedFilters = ({ data }) => (
  allFilters(data).flat().length
)

// Returns true if the card should be shown when the given filters have been applied
const combinedFilters = (filters) => (card) => (
  filters
    .filter((list) => list.length > 0)
    .every((facet) => facet.some((term) => term(card)))
)

const newVendorOnly = (card) => card.new_vendor
const vendorPickedOnly = (card) => card.picked_by_vendor

// The list of all scopes that have been applied in the current state
const allScopes = (state) => [
  state.vendorCardScope === 'newVendorOnly' ? newVendorOnly : null,
  state.vendorCardScope === 'vendorPickedOnly' ? vendorPickedOnly : null
].filter((x) => x)

// Returns true if the card should be shown when the given scopes have been applied
const combinedScopes = (scopes) => (card) => scopes.every((scope) => scope(card))

const filterCount = (cards, filter) => cards.filter(filter).length

export const preScopedData = ({ data }) => (
  data.cardsData.filter(combinedFilters(allFilters(data)))
)

const isAttending = ({ attending }) => attending

export const userIsInPersonOnly = ({ buysidePickProcess }) => {
  const { contactVirtualAttendance, contactInPersonAttendance } = buysidePickProcess

  return contactInPersonAttendance && !contactVirtualAttendance
}

export const filteredData = (state) => {
  const inPersonOnly = userIsInPersonOnly(state)

  const cardsData = preScopedData(state).filter(combinedScopes(allScopes(state.data)))

  return inPersonOnly ? cardsData.filter(isAttending) : cardsData
}

export const vendorCollectionSummaries = (state) => {
  const { data } = state
  return allVendorCollections(data).map((collection) => ({
    name: collection.title,
    checkedSelector: (s) => s.data.selectedVendorCollections.includes(collection.title),
    onCheckThunk: (checked) => (dispatch,) => dispatch(filterBy({ name: collection.title, stateName: 'selectedVendorCollections', filter: checked })),
    count: filterCount(filteredData(state), vendorCollectionFilter(collection.title)),
    type: 'Vendor Collections',
  }))
}

export const sectorCoverageSummaries = (state) => {
  const { data } = state

  return allSectorCoverages(data).map((sector) => ({
    name: sector,
    checkedSelector: (s) => s.data.sectorCoverages.includes(sector),
    onCheckThunk: (checked) => (dispatch,) => dispatch(filterBy({ name: sector, stateName: 'sectorCoverages', filter: checked })),
    count: filterCount(filteredData(state), sectorCoverageFilter(sector)),
    type: 'Sectors',
  }))
}

export const regionalCoverageSummaries = (state) => {
  const { data } = state

  return allRegionalCoverages(data).map((region) => ({
    name: region,
    checkedSelector: (s) => s.data.regionalCoverages.includes(region),
    onCheckThunk: (checked) => (dispatch,) => dispatch(filterBy({ name: region, stateName: 'regionalCoverages', filter: checked })),
    count: filterCount(filteredData(state), regionalCoverageFilter(region)),
    type: 'Regions',
  }))
}

export const datasetTypeSummaries = (state) => {
  const { data } = state

  return allDatasetTypes(data).map((type) => ({
    name: type,
    checkedSelector: (s) => s.data.datasetTypes.includes(type),
    onCheckThunk: (checked) => (dispatch,) => dispatch(filterBy({ name: type, stateName: 'datasetTypes', filter: checked })),
    count: filterCount(filteredData(state), datasetTypeFilter(type)),
    type: 'Data Types',
  }))
}

export const allCount = ({ data }) => preScopedData({ data }).length
export const newVendorOnlyCount = ({ data }) => preScopedData({ data }).filter(newVendorOnly).length
export const vendorPickedOnlyCount = ({ data }) => (
  preScopedData({ data }).filter(vendorPickedOnly).length
)

export const clearFilters = () => (dispatch, getState) => {
  const {
    regionalCoverages: regions, datasetTypes: types,
    sectorCoverages: sectors, selectedVendorCollections: collections
  } = getState().data

  regions.forEach((region) => {
    dispatch(filterBy({ name: region, stateName: 'regionalCoverages', filter: false }))
  })
  types.forEach((type) => {
    dispatch(filterBy({ name: type, stateName: 'datasetTypes', filter: false }))
  })
  sectors.forEach((sector) => {
    dispatch(filterBy({ name: sector, stateName: 'sectorCoverages', filter: false }))
  })
  collections.forEach((collection) => {
    dispatch(filterBy({ name: collection, stateName: 'selectedVendorCollections', filter: false }))
  })
}

export const getBuysidePickProcess = (state) => state.buysidePickProcess
export const getPickProcess = (state) => state.buysidePickProcess.pickProcess
export const getExpandedCards = (state) => state.data.expandedCards

export const getContactAttending = (state) => state.buysidePickProcess.contactAttending

export const getSelectSelectedCards = (state) => (
  state.data.cardsData.filter((item) => item.picked_by_buyside)
)

const isInValidPick = (inPersonOnly) => (pick) => (inPersonOnly ? !pick.attending : false)
const isValidPick = (inPersonOnly) => (pick) => !isInValidPick(inPersonOnly)(pick)

const getCardById = (cardId) => (state) => state.data.cardsData.find(({ id }) => id === cardId)

export const getInvalidPicks = (state) => {
  const inPersonOnly = userIsInPersonOnly(state)
  const contactAttending = getContactAttending(state)
  const pickProcess = getPickProcess(state)

  if (!contactAttending || pickProcess.inPersonOnly) {
    return []
  }

  return getSelectSelectedCards(state).filter(isInValidPick(inPersonOnly))
}

export const requireRegionAndAvailability = (state) => {
  const pickProcess = getPickProcess(state)
  const buysidePickProcess = getBuysidePickProcess(state)
  const contactAttending = getContactAttending(state)

  if (pickProcess.inPersonOnly) {
    return false
  }
  if (!contactAttending) {
    return true
  }
  return buysidePickProcess.contactVirtualAttendance
}

export const getVendorCollections = (state) => {
  const inPersonOnly = userIsInPersonOnly(state)

  const removeInvalidVendors = (collection) => {
    const validVendorIds = collection.vendorIds.filter((vendorId) => {
      const vendorCard = getCardById(vendorId)(state)

      return isValidPick(inPersonOnly)(vendorCard)
    })

    return { ...collection, vendorIds: validVendorIds }
  }

  const hasVendorIds = ({ vendorIds }) => vendorIds.length > 0

  if (inPersonOnly) {
    return state.data.allVendorCollections.map(removeInvalidVendors).filter(hasVendorIds)
  }

  return state.data.allVendorCollections
}

export const getNumberOfVendors = ({ buysidePickProcess, data }) => {
  const { contactInPersonAttendance, contactVirtualAttendance } = buysidePickProcess

  if (contactInPersonAttendance && !contactVirtualAttendance) {
    return data.cardsData.filter(({ attending }) => attending).length
  }
  return data.cardsData.length
}

export const allVendors = ({ data }) => (
  data.cardsData
)
