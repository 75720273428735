import { Controller } from '@hotwired/stimulus';

// Import TinyMCE themes, plugins, and skins
import 'tinymce/tinymce';

import 'tinymce/models/dom'
import 'tinymce/icons/default'
import 'tinymce/skins/ui/oxide/skin'
import 'tinymce/themes/silver'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/help'
import 'tinymce/plugins/help/js/i18n/keynav/en'
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/table'
import 'tinymce/plugins/wordcount'

import getCSRFToken from '../../../javascript/src/getCSRFToken';
import tinymcePastePreprocess from '../../../javascript/src/tinymcePastePreprocess';

export default class extends Controller {
  static targets = ['textArea']

  connect() {
    const { tinymce } = window;
    this.tinymce = tinymce;

    this.element[this.identifier] = this

    this.initializeTinyMCE();
  }

  disconnect() {
    this.destroyTinyMCE();
  }

  initializeTinyMCE() {
    const editorId = this.textAreaTarget.id;
    const { documentHost } = this.textAreaTarget.dataset;

    this.destroyTinyMCE();

    // Initialize TinyMCE
    this.tinymce.init({
      selector: `#${editorId}`,
      menubar: false,
      statusbar: true,
      base_url: `${window.location.origin}/assets/tinymce`,
      toolbar1: 'blocks bold italic underline | bullist numlist | blockquote | alignleft aligncenter alignright | link',
      toolbar2: 'undo redo | forecolor | strikethrough | pastetext removeformat | subscript superscript charmap | outdent indent | image | table | help',
      plugins: 'link table image lists charmap help wordcount autoresize',
      contextmenu: false,
      // make it so that the styles that apply to article content apply inside the editor as well
      body_class: 'm-marketing-article m-marketing-article-from-tinymce',
      content_css: [
        'https://fonts.googleapis.com/css?family=Inter',
        this.textAreaTarget.dataset.marketingAssetPath,
        this.textAreaTarget.dataset.applicationAssetPath
      ],
      font_family_formats: 'Inter=Inter, serif;',
      color_map: [
        'Black', 'black',
        'Red', '#ff0000',
        'Green', '#339966'
      ],
      paste_as_text: false,
      branding: false,
      autoresize_overflow_padding: 15,
      max_height: 950,
      min_height: 300,
      link_default_target: '_blank',
      setup: (editor) => {
        this.editor = editor;

        editor.on('keyup', (event) => {
          this.onInputChange(event);
        });

        editor.on('change', (event) => {
          this.onInputChange(event);
        });
      },
      images_upload_handler: this.imageUploadHandler.bind(this),
      link_title: false,
      link_context_toolbar: true,
      relative_urls: false,
      remove_script_host: true,
      document_base_url: documentHost,
      paste_preprocess: tinymcePastePreprocess,
    });
  }

  destroyTinyMCE() {
    const editorId = this.textAreaTarget.id;
    if (this.tinymce.get(editorId)) {
      this.tinymce.get(editorId).remove();
    }
  }

  imageUploadHandler(blobInfo, progress) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = true; // Include credentials for session management
      xhr.open('POST', this.textAreaTarget.dataset.tinymceUploadsPath);

      xhr.upload.onprogress = (e) => {
        progress(e.loaded / (e.total * 100));
      };

      xhr.onload = () => {
        if (xhr.status === 403) {
          // eslint-disable-next-line
          reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
          return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          // eslint-disable-next-line
          reject('HTTP Error: ' + xhr.status);
          return;
        }

        const json = JSON.parse(xhr.responseText);

        if (!json || typeof json.signed_id !== 'string') {
          // eslint-disable-next-line
          reject('Invalid JSON: ' + xhr.responseText);
          return;
        }

        // Resolve with the direct upload URL
        resolve(json.direct_upload.url);
      };

      xhr.onerror = () => {
        // eslint-disable-next-line
        reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
      };

      const formData = new FormData();
      formData.append('file[filename]', blobInfo.filename());
      formData.append('file[bytesize]', blobInfo.blob().size);
      formData.append('file[content_type]', blobInfo.blob().type);
      formData.append('file[io]', blobInfo.blob());

      // Fetch CSRF token from meta tags and add it to the request header
      const csrfToken = getCSRFToken();
      xhr.setRequestHeader('X-CSRF-Token', csrfToken);

      xhr.send(formData);
    });
  }

  onInputChange(event) {
    this.dispatchModifiedEvent(event);
  }

  dispatchModifiedEvent(event) {
    const customEvent = new CustomEvent('form-component-modified', { detail: { value: event.target.value }, bubbles: true });
    this.element.dispatchEvent(customEvent);
  }
}
