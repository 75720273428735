import instantsearch from 'instantsearch.js';
import AutoCompleteSearch from './AutoCompleteSearch'

/* global HandlebarsTemplates */

export default class ESGSearch extends AutoCompleteSearch {
  constructor(controller) {
    super(controller.algoliaEsgIndexValue, controller);
  }

  searchListId() {
    return 'esg-search-results';
  }

  renderListItem({ hits }) {
    return HandlebarsTemplates.esg_topics_autosearch_result({
      // Limit searches of each type to 5 hits
      hits: hits.slice(0, 5).map((hit) => ({
        slug: hit.slug,
        category: hit.category,
        highlightedTitle: instantsearch.highlight({ attribute: 'name', hit }),
        highlightedCategory: instantsearch.highlight({ attribute: 'category', hit })
      })),
      path: this.autosearchResultsPath
    });
  }
}
