import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static values = {
    url: String, page: Number, maxPage: Number, per: Number
  }

  connect() {
    this.pageValue = this.pageValue || 1
  }

  async loadMore() {
    this.pageValue += 1
    const submitButton = this.element.querySelector('button')
    const initialSubmitContent = submitButton.innerHTML
    submitButton.innerHTML = '<i class=\'fa-spin fa-duotone fa-spinner-third\'></i>'

    const url = new URL(this.urlValue, window.location)
    url.searchParams.set('page', this.pageValue)
    url.searchParams.set('max_page', this.maxPageValue)
    url.searchParams.set('per', this.perValue)

    const response = await fetch(url.toString(), {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })

    switch (response.status) {
      case 200:
        submitButton.innerHTML = initialSubmitContent

        Turbo.renderStreamMessage(await response.text())
        break
      default:
        break
    }
  }
}
