import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WatchlistApp from '../../javascript/src/components/Watchlist/WatchlistApp';
import WatchlistFlash from '../../javascript/src/components/Watchlist/WatchlistFlash';
import RemoteCardList from '../../javascript/src/components/Watchlist/RemoteCardList';
import { UngroupedList } from '../../javascript/src/components/Watchlist/CardList';
import ContentTypeFilterToggle
from '../../javascript/src/components/Watchlist/ContentTypeFilterToggle';
import { selectFeedItems } from '../../javascript/src/Watchlist/selectors';

const expandFirstDDQRedFlag = (items) => {
  const ddq = items.find((item) => item.item.type === 'ddqs')

  if (ddq) {
    ddq.expandRedFlags = true
  }
}

const SentryFeed = () => {
  const items = useSelector(selectFeedItems)

  expandFirstDDQRedFlag(items)

  return (
    <div className="m-watchlist-page">
      <WatchlistFlash />
      <div className="layout-columns">
        <div className="column column--2">
          <div className="toolbar">
            <div className="toolbar-group">
              <ContentTypeFilterToggle />
            </div>
          </div>
          <RemoteCardList>
            { UngroupedList(items) }
          </RemoteCardList>
        </div>
      </div>
    </div>
  )
}

/**
 * Application wrapper for the 'Sentry Compliance Feed' functionality used in the
 * data provider and dataset pages.
 *
 * @param props
 * @param {Object} props.configuration Configuration object
 * @param {String} props.configuration.endpoint_url The base URL to fetch all feed data
 * @param {Boolean} props.configuration.can_share_watchlist True if the user
 *        can use the feed sharing feature
 * @param {Array<Object>} props.configuration.feed_types An array of feed types
 * @param {String} props.configuration.feed_types[n].slug The slug for the feed type
 * @param {String} props.configuration.feed_types[n].title The title of the feed type
 * @param {String} props.configuration.feed_types[n].logo The logo url for the feed type
 * @param {Array<Object>} props.configuration.data_providers An array of data providers
 * @param {Integer} props.configuration.data_providers[n].id A unique id for the data provider
 * @param {String} props.configuration.data_providers[n].logo_src The logo url for the data provider
 * @param {String} props.configuration.data_providers[n].slug A unique slug for the data provider
 * @param {String} props.configuration.data_providers[n].title The title for the data provider
 * @param {Array<Object>} props.configuration.content_scopes An array of content scopes
 * @param {String} props.configuration.content_scopes[n].slug A unique slug for the content type
 * @param {String} props.configuration.content_scopes[n].title The title for the content type
 * @returns {JSX.Element}
 * @constructor
 */
const SentryFeedApp = (props) => (
  <BrowserRouter>
    <WatchlistApp configuration={props.configuration}>
      <SentryFeed />
    </WatchlistApp>
  </BrowserRouter>
)
export default SentryFeedApp
