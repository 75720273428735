import axios from './axiosRails';

export const postTrackingEvent = (data) => {
  axios.post(
    '/track',
    JSON.stringify({ payload: data }),
    {
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
    }
  )
}

const sendTrackingEvent = (node) => {
  const data = node.getAttributeNames().reduce((acc, key) => {
    if (key === 'data-remote-tracking' || !key.startsWith('data-remote-tracking')) { return acc }

    acc[key.replace(/^data-remote-tracking-/, '').replace(/-/g, '_')] = node.getAttribute(key)
    return acc
  }, {})
  postTrackingEvent(data)
}

const onClick = (event) => {
  const target = event.composedPath().find((node) => node.dataset?.remoteTracking)
  if (!target) {
    return
  }
  sendTrackingEvent(target)
}

export const observe = (rootEl) => {
  rootEl.addEventListener('click', onClick, true)
}
