import './m-buyside-picks-complete.scss';
import dayjs from 'dayjs';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { linkTo } from '../../Shared/RoutedTabbedContainer'
import vendorPicksConfig from '../../../OneToOne/vendorPicksConfig';
import AdditionalContactForm from './AdditionalContactForm'
import MeetingsList from './MeetingsList'
import FlashMessage from '../../Shared/FlashMessage'
import { requireRegionAndAvailability } from '../../../OneToOne/VendorPicksFilter'

dayjs.extend(advancedFormat)
const regionMap = vendorPicksConfig.regionMap()

const CompletePage = (props) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  const { allowedSlotIds, region } = useSelector((state) => state.buysidePickProcess)
  const slots = useSelector((state) => state.buysidePickProcess.pickProcess.meeting_slots)
  const buysideProcessEndsAt = useSelector(
    (state) => state.buysidePickProcess.pickProcess.buyside_process_ends_at
  )
  const selectedSlots = slots.filter(({ id }) => allowedSlotIds.includes(id))
  const isEditable = dayjs().isBefore(dayjs.unix(buysideProcessEndsAt))
  const showMyAvailability = useSelector(requireRegionAndAvailability)

  const parsedSlot = (slot) => {
    const timeZone = regionMap[region].timezone
    const startTime = dayjs.utc(slot.meeting_at).tz(timeZone).format('Do MMMM h.mm a')
    const endTime = dayjs(slot.meeting_at).tz(timeZone).add(1, 'h').format('h.mm a')
    return `${startTime} - ${endTime} (${regionMap[region].timezone_label})`
  }

  return (
    <div className="m-buyside-picks-confirmation-page">
      {errorMessage && <FlashMessage message={errorMessage} type="bad" onClose={() => setErrorMessage(null)} />}
      {successMessage && <FlashMessage message={successMessage} type="good" onClose={() => setSuccessMessage(null)} />}
      <div className="buyside-picks-confirmation">
        <i className="fa fa-check" />
        <h2>Thanks for your meeting selections!</h2>
        <p>
          We&apos;ll send you an email with some information on what happens next.
          Included is a link to your picks page where you can make changes until
          submissions close.
        </p>
      </div>
      <AdditionalContactForm
        setSuccessMessage={setSuccessMessage}
        setErrorMessage={setErrorMessage}
      />
      <div className="layout-columns">
        <div className={!showMyAvailability ? 'full-column' : 'column column--1'}>
          <div className="m-buyside-my-picks">
            <div className="header">
              <h2>My Meetings</h2>
              { isEditable && (
              <Link to={linkTo([{ name: props.useQuery, value: 'vendor-collections' }, { name: 'edit_submitted', value: true }])}>
                <span className="label" data-testid="editMeetings">Edit</span>
              </Link>
              )}
            </div>
            <MeetingsList />
          </div>
        </div>
        { showMyAvailability && (
        <div className="column column--2">
          <div className="buyside-picks-my-availability">
            <div className="header">
              <h2>My Availability</h2>
              { isEditable && (
              <Link to={linkTo([{ name: props.useQuery, value: 'availability' }, { name: 'edit_submitted', value: true }])}>
                <span className="label" data-testid="editAvailability">Edit</span>
              </Link>
              )}
            </div>
            <ul className="allowed-slots">
              {
                selectedSlots.length === 0
                && <li>Not available for any meeting slots</li>
              }
              {
                selectedSlots.sort((a, b) => {
                  const dateA = new Date(a.meeting_at);
                  const dateB = new Date(b.meeting_at);
                  return dateA - dateB;
                }).map((slot) => <li className="meeting-slot-date" key={slot.id}>{parsedSlot(slot)}</li>)
              }
            </ul>
          </div>
        </div>
        )}
      </div>
    </div>
  )
}

export default CompletePage
