import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { connectRouter, routerMiddleware } from 'connected-react-router';
import vendorPicksReducer from './vendorPicksSlice'
import buysidePicksReducer from './buysidePickProcessSlice';

const reducer = (history) => combineReducers({
  router: connectRouter(history),
  data: vendorPicksReducer,
  buysidePickProcess: buysidePicksReducer
})

export const config = (history) => ({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware(history)),
  reducer: reducer(history)
})

export default (history) => configureStore(config(history))
