import React, { useState } from 'react'
import { postTrackingEvent } from '../../remoteTracking';
import Modal from '../Shared/Modal';
import Tooltip from '../Shared/Tooltip'
import WatchlistConfig from '../../Watchlist/watchlistConfig'

const ModalContent = (props) => {
  const [visibleSuccessAlert, setVisibleSuccessAlert] = useState(false)

  const shareableLink = () => (
    WatchlistConfig.watchlistItemShareableUrl(props.item.type, props.item.id)
  )

  const copyLink = () => {
    navigator.clipboard.writeText(shareableLink())
    setVisibleSuccessAlert(true)
    postTrackingEvent({
      label: 'Watchlist item shareable link',
      item_type: props.item.type,
      item_id: props.item.id,
      'event-action': 'Copy',
    })
  }

  return (
    <div className="copy-shareable-link-modal">
      <div className="copy-success" style={{ visibility: visibleSuccessAlert ? 'visible' : 'hidden' }}>Link Copied!</div>
      <div className="link">
        <span role="link" tabIndex="0" onClick={copyLink} onKeyPress={copyLink}>{shareableLink()}</span>
        <button type="button" onClick={copyLink}>Copy link</button>
      </div>
    </div>
  )
}

const ItemShareableLink = (props) => {
  const showModal = () => {
    postTrackingEvent({
      label: 'Watchlist item shareable link',
      item_type: props.item.type,
      item_id: props.item.id,
      'event-action': 'View Link',
    })

    Modal.fire({
      html: <ModalContent item={props.item} />,
      width: '646px',
      showCancelButton: false,
      showConfirmButton: false,
      background: 'transparent',
      position: 'center',
      padding: '0px',
      showClass: {
        popup: 'modal-fade-in'
      },
      hideClass: {
        popup: 'modal-fade-out'
      },
      customClass: {
        htmlContainer: 'copy-shareable-link-modal-container',
        popup: 'modal-popup'
      }
    })
  }

  return (
    <div className="shareable-link">
      <Tooltip content="Get shareable link">
        <button data-testid="getShareableLinkButton" type="button" onClick={showModal}>
          <i className="fa-solid fa-link-simple" />
        </button>
      </Tooltip>
    </div>
  )
}

export default ItemShareableLink;
