import PusherAdapter from './PusherAdapter'

/**
 * This module simply creates a singleton of the adapter that we want to use
 * so we have a central adapter without the rest of the code knowing what it is.
 * If it ever needs to do more, it can become it's own object which proxies
 * the simple pass through methods and then adds value to other more
 * complex methods.
 */
export default new PusherAdapter()
