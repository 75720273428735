import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    interval: { type: Number }
  }

  connect() {
    this.interval = setInterval(() => {
      this.element.removeAttribute('complete');
    }, this.intervalValue);
  }

  disconnect() {
    clearInterval(this.interval);
  }
}
