import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentParticipant } from '../../../Chat/appSlice'

const AutoReplyMessageSeenAsSentFromCard = () => {
  const { name, company } = useSelector(selectCurrentParticipant)

  return (
    <div className="auto-reply-card" data-card-type="auto-reply">
      <p className="body">
        Your messages will appear as sent from:
        <span> {name} </span>
        from
        <span> {company}</span>
      </p>
    </div>
  )
}
export default AutoReplyMessageSeenAsSentFromCard
