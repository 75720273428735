import { useDispatch } from 'react-redux'
import { useCallback, useMemo, useState } from 'react'

import axios from '../axiosRails'

import {
  addToWatchlist,
  removeFromPersonal,
  removeFromShared
} from '../Watchlist/watchlistSlice'

const useWatcher = (dataProviderId, initialStatus, shared) => {
  const dispatch = useDispatch()
  const [watcherStatus, setWatcherStatus] = useState(initialStatus)

  const toggleStatus = () => { setWatcherStatus(!watcherStatus) }

  const requestBody = useMemo(() => (
    {
      provider_watcher: {
        data_provider_id: dataProviderId,
        shared
      }
    }
  ), [dataProviderId, shared])

  const createWatcher = useCallback(() => {
    const requestHeaders = { headers: { Accept: 'application/vnd.api+json' } }

    axios.post('/provider_watchers', requestBody, requestHeaders).then((response) => {
      dispatch(addToWatchlist(response.data.data.attributes))
    })
  }, [dispatch, requestBody])

  const destroyWatcher = useCallback(() => {
    axios.delete('/provider_watchers', {
      data: { provider_watcher: { data_provider_id: dataProviderId, shared } }
    }).then(() => {
      if (shared) {
        dispatch(removeFromShared(dataProviderId))
      } else {
        dispatch(removeFromPersonal(dataProviderId))
      }
    })
  }, [dataProviderId, shared, dispatch])

  const handleChangedStatus = useCallback(() => {
    if (watcherStatus === initialStatus) {
      return undefined
    }

    if (watcherStatus) {
      createWatcher({ shared })
    } else {
      destroyWatcher({ shared })
    }

    return undefined
  }, [watcherStatus, initialStatus, shared, createWatcher, destroyWatcher])

  return {
    watcherStatus,
    toggleStatus,
    createWatcher,
    destroyWatcher,
    handleChangedStatus
  }
}

export default useWatcher
