import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setScope } from '../../../OneToOne/vendorPicksSlice'
import ToggleInput from '../../Shared/ToggleInput'

const NewVendorToggle = () => {
  const loading = useSelector((state) => state.data.cardsLoading)
  const newVendorOnly = useSelector((state) => state.data.vendorCardScope === 'newVendorOnly')
  const dispatch = useDispatch()

  const checked = !loading && newVendorOnly

  const onClick = () => {
    if (checked) {
      dispatch(setScope('all'))
    } else {
      dispatch(setScope('newVendorOnly'))
    }
  }

  return (
    <div className="toggle-container expand-cards-toggle">
      <span>Show only New Vendors</span>
      <ToggleInput className="switch" onClick={onClick} checked={checked} />
    </div>
  )
}

export default NewVendorToggle
