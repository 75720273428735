import './content_type_filter_toggle.scss'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ContentTypeFilters from './ContentTypeFilters'
import useOnClickOutside from '../../helpers/useOnClickOutside'

const ContentTypeFilterToggle = () => {
  const [showFilters, setShowFilters] = useState(false)
  const contentTypes = useSelector((state) => state.contentTypeFilter.filters)
  const ref = useRef()
  const toggleShowFilters = () => {
    setShowFilters(!showFilters)
  }
  const allContentTypes = contentTypes.filter((ct) => ct.visibility !== 'hidden')
  const selectedCount = allContentTypes.filter((ct) => ct.selected).length
  useOnClickOutside(ref, () => { setShowFilters(false) })
  return (
    <div ref={ref} className="content-type-filter-toggle">
      <button className="customise" type="button" onClick={toggleShowFilters} aria-label="Customise feed content">
        <i className="icon fa-solid fa-sliders" />
        <div className="group">
          <span className="main">Customise feed content</span>
          <span className="hint">Showing {selectedCount} of {allContentTypes.length} card types</span>
        </div>
      </button>
      {showFilters && (
        <div className="filters">
          <ContentTypeFilters />
        </div>
      )}
    </div>
  )
}

export default ContentTypeFilterToggle
