import { Controller } from '@hotwired/stimulus';
import tippy from 'tippy.js'
import RichTextFieldHelper from '../../../javascript/src/helpers/RichTextFieldHelper'

export default class extends Controller {
  static targets = ['trixEditor']

  connect() {
    const helper = new RichTextFieldHelper(this.element)
    helper.configure()
    window.setTimeout(this.updateCount.bind(this), 0)
    this.element.addEventListener('click', this.showLinkPreviewOnClick);
  }

  showLinkPreviewOnClick(e) {
    const target = e.target.closest('a');
    if (target) {
      e.preventDefault()
      const previewLink = target.cloneNode(true)
      previewLink.target = '_blank'
      previewLink.innerText = previewLink.href
      // truncate to at most 50 chars to avoid escaping the tippy box
      const maxLength = 50
      if (previewLink.innerText.length > maxLength) {
        previewLink.innerText = `${previewLink.innerText.substring(0, maxLength - 3)}...`
      }

      const instance = tippy(target, {
        content: previewLink,
        allowHTML: true,
        interactive: true,
        theme: 'light',
        // Interactive tippy instances in the trix text box appear as content
        // and not tooltips, so you have to place them remotely
        appendTo: document.body,
        placement: 'top-start',
        offset: [e.x - 8, e.y + 16] // these offsets are trial-and-error
      })
      instance.show()

      window.addEventListener('scroll', () => { instance.hide() }, { once: true })
    }
  }

  onInputChange(event) {
    this.updateCount()
    this.dispatchModifiedEvent(event)
  }

  dispatchModifiedEvent(event) {
    const customEvent = new CustomEvent('form-component-modified', { detail: { value: event.target.value }, bubbles: true })
    this.element.dispatchEvent(customEvent)
  }

  updateCount() {
    const maxLength = this.trixEditorTarget.getAttribute('maxlength')

    if (!maxLength) { return }

    this.characterCountController().updateCount(this.trixEditorTarget.innerText.length)
  }

  characterCountController() {
    const countComponent = this.element.querySelector('.forms--character-count-component')
    return this
      .application
      .getControllerForElementAndIdentifier(countComponent, countComponent.dataset.controller)
  }
}
