import React from 'react'
import { useSelector } from 'react-redux'
import FlashMessage from '../Shared/FlashMessage'

const ConnectionFlashMessage = () => {
  const connectionState = useSelector((state) => state.app.liveConnectionState)
  if (connectionState === 'error' || connectionState === 'not_connected') {
    return (
      <FlashMessage message="Live update connection failed - please refresh" />
    )
  }
  if (connectionState === 'reconnecting') {
    return (
      <FlashMessage message="Live update - reconnecting" type="neutral" />
    )
  }
  return null
}

export default ConnectionFlashMessage
