import React, { forwardRef } from 'react'

const ShimmerCard = forwardRef((props, ref) => (
  <li ref={ref} className="watchlist-card shimmer">
    <div className="watchlist-type animate" />

    <div className="summary-container">
      <div className="shimmer-logo animate" />
      <div className="shimmer-summary animate" />
    </div>
    <div className="text-content">
      <div className="shimmer-content animate" />
      <div className="shimmer-content animate" />
    </div>
  </li>
))

export default ShimmerCard
