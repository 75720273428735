import React, { useState, forwardRef } from 'react'

/**
 * A visual representation of 'compressed' or 'rolled up' announcement cards
 * @component
 * @param {Object} props The react properties listed below
 * @param {Function} props.children A function which renders a normal
 *                                    card when called with a passed item
 * @param {Array<Object>} props.watchlistItems An array of card data for a mixture of card types
 * @return {Array<React.Element>} The react elements to render
 */
const AnnouncementCompressedCard = forwardRef((props, ref) => {
  const [expanded, setExpanded] = useState(false)

  if (expanded) {
    return (
      props.watchlistItems.map(props.children)
    );
  }

  const dpNames = [
    ...new Set(
      props.watchlistItems.map((watchlistItem) => watchlistItem.item.data_provider_names[0].title)
    )
  ]
  const summaryText = `Show ${props.watchlistItems.length} more vendor post${props.watchlistItems.length > 1 ? 's' : ''} from `
  return (
    <li className="watchlist-card compressed" ref={ref}>
      <button type="button" onClick={() => { setExpanded(true) }}>
        <span className="count">{summaryText}</span>
        <span className="data-provider-names">{dpNames.join(', ')}</span>
      </button>
    </li>
  )
})

export default AnnouncementCompressedCard
