import React from 'react'

import ExpandableContent from '../Shared/ExpandableContent'
import WatchedFilterRow from './WatchedFilterRow'

const WatchedFilters = ({ expanded, toggleExpanded, savedFilters }) => (
  <ExpandableContent title="Watched filters" expanded={expanded} toggleExpanded={toggleExpanded}>
    {
      savedFilters.length > 0
      && (
        <div className="watched-list">
          { savedFilters.map((savedFilter) => (
            <WatchedFilterRow key={savedFilter.id} savedFilter={savedFilter} />
          )) }
        </div>
      )
    }
    {
      savedFilters.length === 0
      && (
        <p className="no-saved-filters">No saved filters</p>
      )
    }
  </ExpandableContent>
)

export default WatchedFilters
