import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['summary', 'detail', 'navigationBar', 'confirmChangesNavigationBarContainer', 'editingArea', 'expandFieldsets']

  static values = {
    hasClientChanges: Boolean,
    inConfirmationState: Boolean,
    expandFieldsets: Boolean,
  }

  connect() {
    window.setTimeout(this.setCurrentFieldsetFromCurrentUrl.bind(this), 0)
  }

  toggleExpandAllFields({ target: { checked } }) {
    this.element.classList.toggle('expanded-fieldsets', checked)
    this.summaryTarget.classList.toggle('hidden', checked)
    this.detailTarget.classList.toggle('hidden', !checked)
    this.expandFieldsetsValue = checked

    const removeAnchorsFromUrl = () => {
      window.history.replaceState(null, '', window.location.pathname)
    }

    if (!checked) {
      removeAnchorsFromUrl()
    }
  }

  selectFieldset(fs) {
    this.element.dataset.currentFieldset = fs

    // stop page state changing whilsting all fieldsets expanded and user
    // navigates via anchor link. e.g. field error anchors
    if (fs && this.expandFieldsetsValue) {
      return
    }

    if (fs && !this.expandFieldsetsValue) {
      this.expandFieldsetsTarget.classList.add('hidden')

      this.ensureDetailShown(fs)
    } else {
      this.expandFieldsetsTarget.classList.remove('hidden')

      this.ensureSummaryShown()
    }
  }

  setCurrentFieldset(event) {
    const currentFieldset = this.parseFieldsetFromHash(new URL(event.newURL).hash)
    this.selectFieldset(currentFieldset)
  }

  setCurrentFieldsetFromCurrentUrl() {
    const { hash } = window.location
    const currentFieldset = this.parseFieldsetFromHash(hash) || ''
    this.selectFieldset(currentFieldset)

    const id = hash.replace('#', '')

    if (id.length === 0) {
      return
    }

    document.getElementById(id)?.scrollIntoView()
  }

  ensureDetailShown(fs) {
    if (!this.inDetailedView()) {
      this.summaryTarget.classList.add('hidden')
      this.detailTarget.classList.remove('hidden')
      const controller = this.application.getControllerForElementAndIdentifier(this.element.querySelector('.eav-fieldsets-component'), 'forms--eav-fieldsets-component--eav-fieldsets-component')
      controller?.selectFieldset(new CustomEvent('fieldsetSelected', { detail: { fieldset: fs } }))
    }
    this.dispatch('edit-component-detail-shown')
  }

  ensureSummaryShown() {
    if (this.inDetailedView()) {
      this.summaryTarget.classList.remove('hidden')
      this.detailTarget.classList.add('hidden')
    }
    this.dispatch('edit-component-summary-shown')
  }

  inDetailedView() {
    return this.summaryTarget.classList.contains('hidden')
  }

  interceptBackButton(event) {
    if (this.hasClientChangesValue) {
      event.preventDefault()
      this.inConfirmationStateValue = true
      this.resumeIntercepted = event.detail.resume
    }
  }

  formContentChanged() {
    this.hasClientChangesValue = true
  }

  inConfirmationStateValueChanged() {
    if (this.inConfirmationStateValue) {
      this.confirmChangesNavigationBarContainerTarget.classList.remove('hidden')
      this.editingAreaTarget.classList.add('disabled')
      this.navigationBarTarget.classList.add('hidden')
    } else {
      this.confirmChangesNavigationBarContainerTarget.classList.add('hidden')
      this.editingAreaTarget.classList.remove('disabled')
      this.navigationBarTarget.classList.remove('hidden')
    }
  }

  exitWithoutSaving() {
    this.resumeIntercepted()
  }

  stayOnThisPage() {
    this.inConfirmationStateValue = false
    this.resumeIntercepted = null
  }

  parseFieldsetFromHash(hash) {
    const withoutPoundSign = hash.replace('#', '')

    // Crazy hack to let the nested_scout_data_provider.* 'fieldset' id work
    // when a Vendor edits a Listing with an invalid company profile and we
    // let them edit the ScoutDataProvider in the same form as the ScoutDataset.
    if (withoutPoundSign.match('nested_scout_data_provider_attributes') || withoutPoundSign === 'nested_scout_data_provider.*') {
      return 'nested_scout_data_provider.*'
    }

    return withoutPoundSign.split('.')[0]
  }
}
