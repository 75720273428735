import React from 'react'
import { useSelector } from 'react-redux'
import { selectScopeChanging } from '../../Watchlist/selectors';
import ShimmerCard from './ShimmerCard';
import EmptyCard from './EmptyCard';
import CardList from './CardList';

const RemoteCardList = (props) => {
  const scopeChanging = useSelector(selectScopeChanging)
  const loadedEmpty = useSelector((state) => state.data.loadedEmpty)
  if (scopeChanging) {
    return (
      <div className="watchlist-card-list">
        <ul className="cards-list">
          <ShimmerCard />
          <ShimmerCard />
        </ul>
      </div>
    )
  }
  if (loadedEmpty) {
    return (
      <div className="watchlist-card-list">
        <ul className="cards-list">
          <EmptyCard />
        </ul>
      </div>
    )
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <CardList {...props} />
}

export default RemoteCardList
