import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button']

  static values = {
    originalIconClass: String
  }

  connect() {
    this.attachFormMonitor()
  }

  disconnect() {
    this.detachFormMonitor()
  }

  disableSubmit() {
    this.element.classList.add('submitting')
    this.enableSpinner();
    this.disable()
  }

  enableSpinner() {
    const icon = this.iconElement()
    if (!icon) { return }

    this.originalIconClassValue = icon.className
    icon.classList.forEach((className) => {
      if (className.startsWith('fa-')) {
        icon.classList.remove(className)
      }
    })
    icon.classList.add('fa-regular')
    icon.classList.add('fa-spinner-third')
  }

  disableSpinner() {
    const icon = this.iconElement()
    if (!icon) { return }

    icon.className = this.originalIconClassValue
  }

  disable() {
    this.element.classList.add('disabled')
  }

  attachFormMonitor() {
    const { form } = this.buttonTarget
    if (!form) return

    form.addEventListener('turbo:submit-end', this.afterFormSubmit.bind(this))
  }

  detachFormMonitor() {
    const { form } = this.buttonTarget
    if (!form) return

    form.removeEventListener('turbo:submit-end', this.afterFormSubmit.bind(this))
  }

  afterFormSubmit() {
    this.element.classList.remove('submitting')
    this.element.classList.remove('disabled')
    this.disableSpinner()
  }

  iconElement() {
    return this.element.querySelector('.icon')
  }
}
