import './message_card.scss';
import React from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { fullDateWithOptionalYear, timeOnly12Hour } from '../../../helpers/dateHelpers'
import ParticipantAvatar from '../ParticipantAvatar';

dayjs.extend(isToday)

const OptionalCompany = ({ participant }) => {
  if (!participant.company) {
    return null
  }
  return (
    <>
      <span> from </span>
      <span className="company">{participant.company}</span>
    </>
  )
}

const Participant = ({ participant }) => (
  <div className="participant">
    <span className="name">
      {participant.name}
    </span>
    <OptionalCompany participant={participant} />
  </div>
)

const OptionalQuestionHeader = ({ condition }) => {
  if (!condition) { return null }

  return (
    <div className="question-header">
      <img alt="" src="/assets/chat/message-question" /><span>Question</span>
    </div>
  )
}

const OptionalError = ({ message }) => {
  if (!message._error) {
    return null
  }
  return (
    <span className="error">
      <i className="fa-solid fa-exclamation-triangle" />Not sent
    </span>
  )
}

const OptionalReadReceipt = ({ message, show }) => {
  if (!show || !message.first_seen_at) { return null }

  const firstSeenName = message.first_seen_by.name || message.first_seen_by.company
  const firstSeenDate = dayjs(message.first_seen_at).isToday() ? `at ${timeOnly12Hour(message.first_seen_at)}` : `on ${fullDateWithOptionalYear(message.first_seen_at)} at ${timeOnly12Hour(message.first_seen_at)}`
  return (
    <div className="read-receipt" data-attribute="first_seen_at">{`Seen by ${firstSeenName} ${firstSeenDate}`}</div>
  )
}

/**
 * A card to display a normal message
 * @param message The message data
 * @param showReadReceipt If true, the read receipt is shown if the data is present for it
 *   in the message's first_seen_at and first_seen_by properties
 * @returns {JSX.Element}
 * @constructor
 */
const MessageCard = ({ message, showReadReceipt }) => {
  const extraClassNames = []
  if (message._not_saved) { extraClassNames.push('not-saved') }
  if (message.first_seen_at) { extraClassNames.push('seen-by-others') }

  return (
    <div className={`message-card ${extraClassNames.join(' ')}`} data-card-type="message" data-card-id={message.id}>
      <OptionalQuestionHeader condition={message.message_type === 'question'} />
      <div className="message">
        <ParticipantAvatar avatar={message.participant.avatar} />
        <div className="message-content">
          <div className="header">
            <Participant participant={message.participant} />
            <span className="posted-date">
              {dayjs(message.posted_at).format('HH:mm A')}
            </span>
            <OptionalError message={message} />
          </div>
          {/* eslint-disable-next-line react/no-danger */}
          <p className="body" dangerouslySetInnerHTML={{ __html: message.body }} />
          {(message.attached_files?.length || 0) > 0 && (
            <p className="files">
              {message.attached_files.map(({ name, path }) => <a key={path} className="file-download-link" target="_blank" rel="noreferrer" href={path}><i className="fa-solid fa-paperclip" />{name}</a>)}
            </p>
          )}
          <OptionalReadReceipt message={message} show={showReadReceipt} />
        </div>
      </div>
    </div>
  )
}

export default MessageCard
