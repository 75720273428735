import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['nextButton', 'previousButton'];

  next(event) {
    event.target.dispatchEvent(
      new CustomEvent('carousel:next', {
        bubbles: true
      })
    );
  }

  back(event) {
    event.target.dispatchEvent(
      new CustomEvent('carousel:back', {
        bubbles: true
      })
    );
  }

  handleChange(e) {
    this.nextButtonTarget.classList.toggle('hidden', e.detail.isLastSlide)
    this.previousButtonTarget.classList.toggle('hidden', e.detail.isFirstSlide)
  }
}
