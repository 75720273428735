import React from 'react'

const UploadStatusBadge = ({ upload, removeUpload }) => {
  const progressBarStyle = {
    background: `linear-gradient(90deg, #00000008 ${upload.progress}%, transparent ${100 - upload.progress}%)`
  }

  switch (upload.state) {
    case 'waiting':
      return <span className="upload-status-badge" key={upload.id}><i className="fa-solid fa-paperclip" />Waiting to upload {upload.file.name}</span>
    case 'uploading':
      return (
        <span className="upload-status-badge" key={upload.id} style={progressBarStyle}>
          <i className="fa-solid fa-paperclip" />Uploading {upload.file.name}: {Math.ceil(upload.progress)}%
        </span>
      )
    case 'error':
      return (
        <span className="upload-status-badge" key={upload.id}>
          <i className="fa-solid fa-paperclip" />Error uploading {upload.file.name}: {upload.error}
          {removeUpload && <button onClick={removeUpload(upload.id)} type="button">X</button>}
        </span>
      )
    case 'finished':
      return (
        <span className="upload-status-badge" key={upload.id}>
          <i className="fa-solid fa-paperclip" />{upload.file.name}
          {removeUpload && <button onClick={removeUpload(upload.id)} type="button">X</button>}
        </span>
      )
    default:
      return <span className="upload-status-badge" key={upload.id}><i className="fa-solid fa-paperclip" />{upload.file.name}</span>
  }
}

export default UploadStatusBadge
