import React from 'react'
import { useSelector } from 'react-redux'
import FlashMessage from '../Shared/FlashMessage'

const VendorPicksFlash = () => {
  const error = useSelector((state) => state.data.cardsError)
  const submissionError = useSelector((state) => state.data.submissionError)
  if ((error == null && submissionError == null)) {
    return null;
  }

  return (
    <FlashMessage message="Sorry, something has gone wrong" />
  )
}
export default VendorPicksFlash
