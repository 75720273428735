/* eslint-disable react/no-danger */
import React from 'react'
import Select from 'react-select'

const Option = ({ value, label }) => (
  <div className="link-container">
    <a href={value}>{label}</a>
  </div>
)

const Label = ({ flagCode, language }) => (
  <div>
    <span className={`fi fi-${flagCode}`} />{` ${language}`}
  </div>
)

const TranslationDropdownApp = (props) => {
  const options = []
  let defaultOption = null

  props.settings.forEach((setting) => {
    const label = <Label flagCode={setting.flag_code} language={setting.language} />
    const option = { value: setting.path, label }

    if (option.value === props.default) {
      defaultOption = option
    } else {
      options.push(option)
    }
  })

  return (
    <div className="container">
      <Select
        className="dropdown-select"
        classNamePrefix="dropdown"
        options={options}
        defaultValue={defaultOption}
        isSearchable={false}
        components={{ Option }}
      />
    </div>
  )
}
export default TranslationDropdownApp
