import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['overflowingContent', 'content', 'contentScrollable']

  static values = {
    scrollable: Boolean
  }

  connect() {
    if (!this.scrollableValue) { return }

    this.intersectionObserver = new IntersectionObserver(
      this.intersect.bind(this), { threshold: 1.0, root: this.contentScrollableTarget }
    )
    this.intersectionObserver.observe(this.contentTarget)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.element)
  }

  intersect() {
    const { scrollHeight, clientHeight } = this.contentScrollableTarget
    const isScrollable = scrollHeight > clientHeight

    if (isScrollable) {
      this.overflowingContentTarget.classList.remove('hidden')
    } else {
      this.overflowingContentTarget.classList.add('hidden')
    }
  }
}
