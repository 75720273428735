import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['statusText'];

  static classes = ['saving', 'saved'];

  connect() {
    this._addTurboEventListeners();
  }

  disconnect() {
    this._removeTurboEventListeners();
  }

  _addTurboEventListeners() {
    window.addEventListener('turbo:submit-start', this._handleSubmitStart);
    window.addEventListener('turbo:submit-end', this._handleSubmitEnd);
  }

  _removeTurboEventListeners() {
    window.removeEventListener('turbo:submit-start', this._handleSubmitStart);
    window.removeEventListener('turbo:submit-end', this._handleSubmitEnd);
  }

  _handleSubmitStart = (event) => {
    if (this._hasSaveStatus(event)) {
      const saveStatus = JSON.parse(event.target.dataset.saveStatus)
      this.statusTextTarget.textContent = saveStatus.saving_text;
      this.statusTextTarget.classList.remove(this.savedClass);
      this.statusTextTarget.classList.add(this.savingClass);
    }
  }

  _handleSubmitEnd = (event) => {
    if (event.detail.success && this._hasSaveStatus(event)) {
      const saveStatus = JSON.parse(event.target.dataset.saveStatus)
      this.statusTextTarget.textContent = saveStatus.saved_text;
      this.statusTextTarget.classList.remove(this.savingClass);
      this.statusTextTarget.classList.add(this.savedClass);
    }
  }

  _hasSaveStatus(event) {
    return 'saveStatus' in event.target.dataset;
  }
}
