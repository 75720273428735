import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import axios from '../axiosRails'
import { preProcessData, preProcessIncluded } from '../helpers/jsonApiResponseProcessingHelpers'
import { redirectToConversation, replaceOrInsertConversation } from './conversationSlice';
import { isNewConversation } from '../utils/Chat/conversation';
import { isVendorParticipant } from '../utils/Chat/participants';

const generateFakeId = () => (
  `fake-id-${uuidv4()}`
)

function thanksForResponseAutoReply(message) {
  return {
    id: generateFakeId(),
    type: 'auto-replies',
    participant: null,
    body: 'Thanks for your response. You can still add more information here. Neudata might be in touch with further questions via email.',
    posted_at: message.posted_at,
    _auto_reply_type: 'thanks-for-your-response'
  };
}

function respondToBuyerAutoReply(message) {
  return {
    id: generateFakeId(),
    type: 'auto-replies',
    participant: null,
    body: 'This conversation was started by Neudata on behalf of the buyer. Neudata will view your responses and send them on.',
    posted_at: message.posted_at,
    _auto_reply_type: 'respond-to-buyer-here'
  };
}

const startConversationWithDataProviderAutoReply = () => (
  {
    id: generateFakeId(),
    type: 'auto-replies',
    participant: null,
    body: 'Your name and company are visible once you send the first message',
    posted_at: dayjs().toISOString(),
    _auto_reply_type: 'start-conversation-with-data-provider'
  }
)

function respondToBuyerCompanyNoticeAutoReply(message, company) {
  return {
    id: generateFakeId(),
    type: 'auto-replies',
    participant: null,
    body: `This conversation is visible to all users at ${company}`,
    posted_at: message.posted_at,
    _auto_reply_type: 'company-notice'
  };
}

const allUsersCanSeeConversationAutoResponse = (message) => ({
  id: generateFakeId(),
  type: 'auto-replies',
  participant: null,
  body: 'All users from your organisation can see this conversation',
  posted_at: message.posted_at,
  _auto_reply_type: 'messages-visible-to-all-in-organisation'
})

const messageSeenAsSentFromAutoResponse = (message) => ({
  id: generateFakeId(),
  type: 'auto-replies-message-seen-as-sent-from',
  participant: null,
  body: '',
  posted_at: message.posted_at,
  _auto_reply_type: 'messages-display-as-sent-from'
})

function shouldSendAutoResponses(currentParticipant, currentConversation) {
  return currentParticipant.type !== 'participants/client' && currentConversation.participant.type === 'participants';
}

const injectAutoResponses = (data, currentParticipant, currentConversation) => {
  if (isVendorParticipant(currentParticipant)) {
    data.unshift(messageSeenAsSentFromAutoResponse(data[0]))
    data.unshift(allUsersCanSeeConversationAutoResponse(data[0]))
  }

  if (!shouldSendAutoResponses(currentParticipant, currentConversation)) { return }

  const originalSize = data.length
  if (originalSize > 0) {
    const newMessage = respondToBuyerAutoReply(data[0])
    data.splice(0, 0, newMessage)
    const companyMessage = respondToBuyerCompanyNoticeAutoReply(data[0], currentParticipant.company)
    data.splice(1, 0, companyMessage)
  }
  if (originalSize > 1) {
    const newMessage = thanksForResponseAutoReply(data[2])
    data.splice(4, 0, newMessage)
  }
}

const injectVendorNotifiedAutoResponse = (messages, currentParticipant, currentConversation) => {
  const hasNoDataProviderReply = messages.every(
    ({ participant }) => participant && participant.id === currentParticipant.id
  )

  if (hasNoDataProviderReply) {
    const dates = _.map(messages, (m) => m.posted_at)
    const minDate = _.minBy(dates, (d) => dayjs(d).valueOf())

    let body = `${currentConversation.participant.company} has been notified of your message and invited to reply.`

    if (currentConversation.linked_to_unregistered_vendor) {
      body = `${currentConversation.participant.company} is not currently registered with Neudata but we will send them your message and their response will appear here.`
    }

    messages.push({
      id: generateFakeId(),
      type: 'auto-replies',
      participant: null,
      body,
      posted_at: minDate,
      _auto_reply_type: 'vendor-notified'
    })
  }
}

const injectThreadStart = (data) => {
  data.data.filter((m) => m.type === 'messages').forEach((message, idx) => {
    message.thread_start = (idx === 0)
  })
}

const markAllMessagesSeen = (data) => data.reduce((previous, current) => {
  if (current.type !== 'messages' || current.seen_by_me_at) { return previous }

  current.seen_by_me_at = dayjs().toISOString()
  return previous.concat(current)
}, [])

function generateFakeNewPostMessage(fakeId, message, getState) {
  return {
    id: fakeId,
    type: 'messages',
    body: message,
    posted_at: dayjs().toISOString(),
    _not_saved: true,
    participant: getState().app.currentParticipant
  };
}

export const messageSlice = createSlice({
  name: 'messageData',
  initialState: {
    messages: [],
    links: {},
    conversationId: undefined,
    loaded: false
  },
  reducers: {
    privateLoadData: (state, action) => {
      state.loaded = true
      state.messages = action.payload
    },
    privateAddMessage: (state, action) => {
      const { message, currentParticipant, currentConversation } = action.payload
      const extraMessages = [message]

      if (shouldSendAutoResponses(currentParticipant, currentConversation) && state.messages.findIndex((m) => m._auto_reply_type === 'thanks-for-your-response') < 0) {
        extraMessages.push(thanksForResponseAutoReply(message))
      }
      state.messages = state.messages.concat(extraMessages)
    },
    privateReplaceMessage: (state, action) => {
      const { messageId, message } = action.payload
      state.messages = state.messages.map((m) => {
        if (m.id === messageId) {
          return message
        }
        return m
      })
    },
    privateReplaceMessages: (state, action) => {
      const messages = action.payload
      const messagesToAdd = messages.filter((message) => (
        state.messages.findIndex((m) => m.id === message.id) === -1
      ))
      state.messages = state.messages.concat(messagesToAdd)
    },
    privateAddErrorToMessage: (state, action) => {
      const { messageId } = action.payload
      state.messages = state.messages.map((m) => {
        if (m.id === messageId) {
          m._error = true
          delete m._not_saved
          return m
        }
        return m
      })
    },
    privateSetLinks: (state, action) => {
      state.links = action.payload
    },
    privateDeleteMessage: (state, action) => {
      const { id, type } = action.payload
      state.messages = state.messages.filter((m) => m.id !== id || m.type !== type)
    },
    setConversationId: (state, action) => {
      state.conversationId = action.payload
    },
    unloadMessages: (state) => {
      state.loaded = false
      state.messages = []
    },
    startConversationWithDataProvider: (state) => {
      state.loaded = true
      state.messages = [
        startConversationWithDataProviderAutoReply()
      ]
    }
  }
})

const {
  privateLoadData,
  privateAddMessage,
  privateReplaceMessage,
  privateReplaceMessages,
  privateAddErrorToMessage,
  privateSetLinks,
  privateDeleteMessage
} = messageSlice.actions
export const {
  unloadMessages,
  setConversationId,
  startConversationWithDataProvider
} = messageSlice.actions

const markAllMessagesSeenRemote = ({ conversationId }) => (dispatch, getState) => {
  const { config } = getState().app
  const url = config.markAllMessagesSeenUrl.replace(':conversationId', conversationId)
  axios.post(url).catch((error) => {
    if (!error.isAxiosError) {
      // eslint-disable-next-line no-console
      console.error('Error', error)
    }
  })
}
/**
 *
 * load Action - loads the data from the backend
 */
export const load = ({ url, conversationId }) => (dispatch, getState) => {
  const currentConversation = getState().data.conversations.find((c) => c.id === conversationId)
  dispatch(setConversationId(conversationId))
  const { currentParticipant } = getState().app
  axios.get(url, { headers: { Accept: 'application/vnd.api+json' } })
    .then((response) => {
      dispatch(privateSetLinks(response.data.links))
      preProcessIncluded(response.data.included)
      preProcessData(response.data)
      injectAutoResponses(response.data.data, currentParticipant, currentConversation)
      injectVendorNotifiedAutoResponse(response.data.data, currentParticipant, currentConversation)
      injectThreadStart(response.data)
      const unseenMessages = markAllMessagesSeen(response.data.data)
      if (unseenMessages.length > 0) {
        dispatch(markAllMessagesSeenRemote({ conversationId }))
      }
      // eslint-disable-next-line no-use-before-define
      dispatch(privateLoadData(response.data.data))
    })
    .catch((error) => {
      if (!error.isAxiosError) {
        // eslint-disable-next-line no-console
        console.error('Error', error)
      }
    })
}

/**
 * postMessage Action
 * @param {String} message
 * @param {Array<String>} files
 */
export const postMessage = (message, files) => (dispatch, getState) => {
  if (!message && (files?.length || 0) === 0) { return }

  const { conversationId } = getState().messages
  const { currentParticipant } = getState().app
  const newConversation = isNewConversation(conversationId);
  const conversationIdToPost = newConversation ? 'new' : conversationId
  const { currentConversation } = getState().data
  const myPostMessageUrl = getState().app.config.createMessageUrl.replace(':conversationId', conversationIdToPost)
  const query = new URLSearchParams(getState().router.location.search);
  const responseRequired = query.get('response_required')

  const payload = {
    data: {
      type: 'messages',
      attributes: {
        body: message,
        files,
        response_required: responseRequired
      },
      meta: currentConversation.meta
    }
  }
  const fakeId = generateFakeId()
  dispatch(privateAddMessage(
    {
      message: generateFakeNewPostMessage(fakeId, message, getState),
      currentParticipant,
      currentConversation
    }
  ))
  axios.post(
    myPostMessageUrl, JSON.stringify(payload), { headers: { Accept: 'application/vnd.api+json', 'Content-Type': 'application/vnd.api+json' } }
  )
    .then((response) => {
      preProcessIncluded(response.data.included)
      preProcessData(response.data)
      const respMessage = response.data.data;
      if (getState().messages.messages.findIndex(
        (m) => m.id === respMessage.id && m.type === respMessage.type
      ) < 0) {
        dispatch(
          privateReplaceMessage({ messageId: fakeId, message: respMessage })
        )
      } else {
        dispatch(privateDeleteMessage(fakeId))
      }
      if (newConversation) {
        dispatch(replaceOrInsertConversation({
          conversationId,
          conversation: respMessage.conversation
        }))
        redirectToConversation(dispatch,
          getState,
          respMessage.conversation,
          { pinToTop: true })
      }
    })
    .catch((error) => {
      if (!error.isAxiosError) {
        // eslint-disable-next-line no-console
        throw error
      }
      dispatch(privateAddErrorToMessage({ messageId: fakeId }))
    })
}

export const replaceMessagesAndConversation = ({ id }) => (dispatch, getState) => {
  const { conversationUrl } = getState().app.config
  axios.get(`${conversationUrl.replace(':conversationId', id)}?include=messages,messages.participant,participant`, { headers: { Accept: 'application/vnd.api+json' } })
    .then((response) => {
      preProcessIncluded(response.data.included)
      preProcessData(response.data)
      if (getState().messages.conversationId === id) {
        const unseenMessages = markAllMessagesSeen(response.data.data.messages)
        if (unseenMessages.length > 0) {
          dispatch(markAllMessagesSeenRemote({ conversationId: id }))
        }
        dispatch(privateReplaceMessages(response.data.data.messages))
      } else {
        dispatch(replaceOrInsertConversation({
          conversation: response.data.data,
          conversationId: id
        }))
      }
    })
    .catch((error) => {
      if (!error.isAxiosError) {
        // eslint-disable-next-line no-console
        throw error
      }
    })
}
export default messageSlice.reducer
