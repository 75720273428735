import { Controller } from '@hotwired/stimulus';
/* global $, jQuery */
export default class extends Controller {
  connect() {
    this.DEFAULT_CELL_HEIGHT = 100;

    this.form = $('.m-dataset-comparison .dataset-comparison-fields .dataset-field-select form');
    this.containers = $('.m-dataset-comparison .dataset-comparison-container .compared-dataset');
    this.fields = $('.m-dataset-comparison .dataset-comparison-fields .dataset-comparison-current-fields');
    this.dataset_form = $('.m-dataset-comparison .dataset-comparison-container .add-dataset-to-comparison');

    // setup observers
    this.showExpandableFieldControls();
    this.observeExpandableFields();
    this.observeDatasetFormChanges();
    this.observeComparisonFieldsChanges();
    this.observeDatasetColumnRemoveEvents();
    this.observeDatasetChartDataToggle();
    this.observeDeleteComparisonClick();
    this.countDatasets();
    this.observeReadMoreBarChartLinkClicks();
    this.equaliseHeaderHeights();

    $(document).on('ready', () => {
      this.equaliseHeaderHeights();
      this.setAddColumnHeight();
    });

    $(window).on('resize', () => {
      this.equaliseHeaderHeights();
      this.setAddColumnHeight();
    });

    // typeahead select UI
    $(this.fields_select).select2({
      placeholder: 'Add/remove a field',
      width: '100%'
    });

    this.form.on('ajax:success', (ajax, response, message) => {
      this.comparisonSaved(ajax, response, message);
      this.updateAvailableCount(response);
    });

    this.showFieldPlaceholder();
  }

  showFieldPlaceholder() {
    // make sure the select2 placeholder is always visible
    setTimeout(() => {
      $('.select2-search__field').css({ width: '100%' }).attr('placeholder', 'Add/remove a field')
    }, 50);
  }

  toggleChartDataForDataset(index, visible) {
    // create an array of data.length with 0 values so that
    // we can assign that to the toggled dataset.

    // eslint-disable-next-line camelcase
    const { comparison_chart } = window;
    const hidden = !visible;

    const getEmptyData = (n, val) => {
      const arr = [...Array(n)];
      return arr.map(() => val);
    };

    comparison_chart.data.datasets[index]
      ._data = comparison_chart.data.datasets[index].data.slice();

    if (hidden) {
      // if we're hiding the dataset, set its values to 0 and update; chart.js will animate
      // the points and in our setTimeout we'll remove the layer
      window.comparison_chart_data[index] = comparison_chart.data.datasets[index].data.slice();

      comparison_chart.data.datasets[index]
        .data = getEmptyData(comparison_chart.data.datasets[index].data.length, 0);
      comparison_chart.update();

      setTimeout(() => {
        comparison_chart.data.datasets[index].hidden = true;
        comparison_chart.update();
      }, 400)
    } else {
      // when showing a dataset, make it visible with an empty data set,
      // then in a timeout, restore its initial values and update
      window.comparison_chart.data.datasets[index]
        .data = getEmptyData(comparison_chart.data.datasets[index].data.length, 0);
      window.comparison_chart.data.datasets[index].hidden = false;
      comparison_chart.update();

      setTimeout(() => {
        window.comparison_chart.data.datasets[index].data = window.comparison_chart_data[index];
        comparison_chart.update();
      }, 150);
    }

    $(window).trigger('toggleChartDataForDataset', index);
  }

  comparisonSaved(ajax, response) {
    const params = new URLSearchParams(window.location.search);
    params.set('compare', btoa(JSON.stringify(response)));
    const newURL = `${window.location.pathname}?${params.toString()}`;
    window.history.pushState({}, document.title, newURL);
  }

  updateAvailableCount(response) {
    const count = response.dataset_ids.length;
    const countSpan = $('.m-page-header .dataset-configuration-info span.count');

    if (countSpan.length) {
      countSpan.html(count);
    }

    const addCountSpan = $('.m-dataset-comparison .add-dataset-to-comparison .adfd-dataset-info span.count');

    if (addCountSpan.length) {
      const addCount = 5 - count;
      addCountSpan.html(addCount);
    }
  }

  observeDatasetFormChanges() {
    this.datasetForm = $('.m-dataset-comparison .add-dataset-to-comparison form').on('change', () => {
      const currentIds = this.form.find('#dataset_ids').val() || [];
      const checked = this.datasetForm.find('input:checked');

      this.dataset_form.find('input.add-to-comparison').attr('disabled', false);

      if (checked.length > 0) {
        this.dataset_form.find('input.add-to-comparison').show();
      } else {
        this.dataset_form.find('input.add-to-comparison').hide();
      }

      if ((currentIds.length + checked.length) >= 5) {
        this.dataset_form.find('input[type=checkbox]:not(:checked)').attr('disabled', true);
      } else {
        this.dataset_form.find('input[type=checkbox]:not(:checked)').attr('disabled', false);
      }
    });
  }

  observeComparisonFieldsChanges() {
    this.selected_fields = [];

    this.fields_select = $('.m-dataset-comparison .dataset-field-select select#comparison_fields').on('change', (event) => {
      this.form.submit();
      this.selected_fields = $(event.target).val();

      if (!this.selected_fields) {
        // this.selected_fields = [];
      }

      this.showSelectedFields(this.selected_fields);

      this.showFieldPlaceholder();
    });
  }

  observeDatasetColumnRemoveEvents() {
    // remove dataset from comparison events...
    this.containers.find('.dataset-comparison-column-header .remove').on('click', (event) => {
      event.preventDefault();

      const removedId = $(event.currentTarget).closest('.compared-dataset').data().datasetId;
      const ids = this.form.find('#dataset_ids').val();
      const updatedIds = jQuery.grep(ids, (i) => i !== `${removedId}`);
      this.form.find('#dataset_ids').val(updatedIds);

      this.datasetForm.parent().show().css({ display: 'inline-block' });
      this.containers.parent().find(`[data-dataset-id=${removedId}]`).addClass('hidden').hide();

      // remove from the chart
      const removedDataset = $(event.currentTarget).closest('.compared-dataset');
      const removedDatasetIndex = removedDataset.parent().find('.compared-dataset').index(removedDataset);
      $('.m-dataset-comparison .comparison-dataset-list').find(`[data-dataset-id=${removedId}]`).hide();

      this.toggleChartDataForDataset(removedDatasetIndex, false);

      // persist the new dataset list
      this.form.submit();

      // Update the count for css
      this.countDatasets();

      $(window).trigger('removeChartDataForDataset', removedDatasetIndex);

      return false;
    });
  }

  observeDatasetChartDataToggle() {
    // toggle chart data
    $('.dataset-chart-item').on('click', (event) => {
      $(event.currentTarget).toggleClass('is-hidden');
      const visible = !$(event.currentTarget).hasClass('is-hidden');
      const index = $(event.currentTarget).parent().children().index($(event.currentTarget));

      this.toggleChartDataForDataset(index, visible);
    });
  }

  observeDeleteComparisonClick() {
    // delete comparison
    $('.m-page-header .delete-comparison').on('click', (event) => {
      event.preventDefault();
      $('.m-dataset-comparison .compared-dataset').each((i, column) => {
        const removedId = $(column).data().datasetId;

        // remove the comparison list item, spider chart data and then the bar chart
        $('.m-dataset-comparison .comparison-dataset-list').find(`[data-dataset-id=${removedId}]`).hide();
        $(window).trigger('removeChartDataForDataset', i);
        this.toggleChartDataForDataset(i, false);

        // remove the columm
        $(column).remove();
      });
      this.form.find('#dataset_ids').val(['']);
      this.form.submit();

      $(event.currentTarget).hide();

      return false;
    });
  }

  observeReadMoreBarChartLinkClicks() {
    $('.m-dataset-comparison-charts a.metadata-read-more').on('click', (e) => {
      const fieldName = e.currentTarget.href.match(/#([^$]+)/)[1];
      const selector = `.cell [data-field-name=${fieldName}]`;

      this.containers.find(selector).closest('.cell').addClass('is-highlighted');
      setTimeout(() => {
        this.containers.find('.is-highlighted').removeClass('is-highlighted');
      }, 500);
    });
  }

  showSelectedFields(enabledIds) {
    // hide all by default
    // this.containers.find(".m-dataset-info-table").hide();
    this.containers.find('.cell ul li[data-field-name]').hide();
    this.fields.find('.dataset-field').hide();

    const selectors = enabledIds.map((i) => `.cell [data-field-name=${i}]`).join(', ');
    const fieldSelectors = enabledIds.map((i) => `[data-field-name=${i}]`).join(', ');

    $(selectors).show();
    this.fields.find(fieldSelectors).show();
    this.showExpandableFieldControls();
  }

  showExpandableFieldControls() {
    const fieldNames = this.fields.find('>div').map((i, cell) => cell.dataset.fieldName);
    const expandableRows = fieldNames.map((i, name) => {
      const row = this.containers.find(`[data-field-name=${name}]`);
      const expandable = row.map((_, cell) => {
        if ($(cell).find('>div').innerHeight() > this.DEFAULT_CELL_HEIGHT) {
          return cell;
        }
        return null;
      });

      return expandable.length > 0 ? name : null;
    });

    const fieldSelectors = expandableRows.map((i, name) => `[data-field-name=${name}]`).toArray().join(',');
    this.fields.find(fieldSelectors).addClass('is-expandable');
  }

  observeExpandableFields() {
    this.fields.find('a.toggle-expand').on('click', (event) => {
      const container = $(event.target).closest('.dataset-field');
      const expanded = $(container).hasClass('expanded');
      const attribute = $(container).data().fieldName;

      const field = this.fields.find(`[data-field-name=${attribute}]`);
      const containers = this.containers.find(`[data-field-name=${attribute}]`);

      let height = this.DEFAULT_CELL_HEIGHT;
      containers.each((i, c) => {
        const h = $(c).find('>div').innerHeight();
        if (h > height) height = h;
      });

      $(container).toggleClass('expanded');

      if (expanded) {
        field.css({ height: '' });
        containers.css({ height: '' });
      } else {
        field.css({ height });
        containers.css({ height });
      }

      return false;
    });
  }

  equaliseHeaderHeights() {
    const heights = [];

    $('.m-dataset-comparison .dataset-comparison-column-header').each(function () {
      heights.push($(this).outerHeight())
    });
    const largest = Math.max(...heights);
    $('.m-dataset-comparison .dataset-comparison-column-header').css('min-height', `${largest}px`);
  }

  setAddColumnHeight() {
    const desiredHeight = $('.m-dataset-comparison .dataset-comparison-fields').outerHeight() - 3;
    $('.add-dataset-to-comparison').css('height', `${desiredHeight}px`);
  }

  countDatasets() {
    const datasetCount = $('.compared-dataset').not('.hidden').length;
    $('.dataset-comparison-container.datasets').removeClass('dataset-count-1');
    $('.dataset-comparison-container.datasets').removeClass('dataset-count-2');
    $('.dataset-comparison-container.datasets').removeClass('dataset-count-3');
    $('.dataset-comparison-container.datasets').removeClass('dataset-count-4');
    $('.dataset-comparison-container.datasets').removeClass('dataset-count-5');
    $('.dataset-comparison-container.datasets').addClass(`dataset-count-${datasetCount}`);
  }
}
