import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

const RACK_MINI_PROFILER_SEPARATOR = '¬';

// Connects to data-controller="rack-mini-profiler-customization"
// DISCLAIMER: This controller interacts weirdly with using the back button
// after turbo navigations. For instance going
// back and forth on homepage dataset table pages. I don't quite understand why.
// If the mini profiler just bricks out after a back or forward just refresh the page.
// It is what it is.
export default class extends Controller {
  connect() {
    const $profilerResultNode = $(this.element);

    $profilerResultNode.find('.profiler-queries td.profiler-info div:first-child, .profiler-queries .profiler-gap-info .query div:first-child').each((i, e) => $(e).text($(e).text().split(RACK_MINI_PROFILER_SEPARATOR)[0]))

    const $table = $profilerResultNode.find('table.profiler-timings');
    this.table = $table;

    // Show time with children by default
    $table.find('tfoot a.profiler-toggle-duration-with-children').hide();
    $table.find('td.profiler-duration-with-children, th.profiler-duration-with-children').css('display', 'table-footer-group');

    const $rows = $table.find('tbody tr');

    const nodeTemplate = {
      parent: 'TABLE', children: [], tr: undefined, depth: 0, indentSpan: undefined, trace: undefined, trivial: false
    };
    let containerNode;
    let previousNode;

    const tree = {};

    $rows.each((i, e) => {
      const newNode = window.structuredClone(nodeTemplate)

      newNode.tr = $(e);
      newNode.trivial = newNode.tr.hasClass('profiler-trivial');

      newNode.indentSpan = newNode.tr.find('.profiler-indent');
      newNode.indentSpan.text(newNode.indentSpan.text().replace(/[-+|]/g, ''));

      const depth = newNode.indentSpan.text().length;
      newNode.depth = depth;

      const labelSpan = newNode.tr.find('.profiler-label');
      const title = labelSpan.attr('title');

      if (title) {
        [, newNode.trace] = title.split(RACK_MINI_PROFILER_SEPARATOR);
        labelSpan.attr('title', title.replace(RACK_MINI_PROFILER_SEPARATOR, '\n\n'));

        const textNode = labelSpan.get(0).lastChild;
        const [newText] = textNode.nodeValue.split(RACK_MINI_PROFILER_SEPARATOR);

        textNode.nodeValue = newText;
      }

      if (depth === 0) {
        containerNode = newNode;
      } else if (depth === previousNode.depth) {
        containerNode.children.push(newNode);
        newNode.parent = containerNode;
      } else if (depth > previousNode.depth) {
        containerNode = previousNode;

        containerNode.children.push(newNode);
        newNode.parent = containerNode;
      } else if (depth < previousNode.depth) {
        while (depth < previousNode.depth) {
          containerNode = previousNode.parent;
          previousNode = previousNode.parent;
        }

        containerNode = previousNode.parent === 'TABLE' ? previousNode : previousNode.parent;
        containerNode.children.push(newNode);
        newNode.parent = containerNode;
      }

      previousNode = newNode;

      const id = e.dataset.timingId;

      tree[id] = newNode;
    });

    Object.values(tree).forEach((node) => this.generateToggles(node));

    this.onClickHandler = (e) => this.toggleNode(tree[$(e.target).closest('tr')[0].dataset.timingId]);

    $table.on('click', '.has-children td', this.onClickHandler);
  }

  disconnect() {
    if (this.table) {
      this.table.off('click', '.has-children td', this.onClickHandler);
    }
  }

  generateToggles(node) {
    const expandedDepth = 3;
    const defaultExpanded = node.depth < expandedDepth;
    const plusOrMinus = node.depth < expandedDepth - 1 ? '|-' : '|+';

    if (node.children.filter((e) => !e.trivial).length > 0) {
      node.indentSpan.append(plusOrMinus)
      node.tr.addClass('has-children');
    } else {
      node.indentSpan.prepend('&nbsp;&nbsp;')
    }

    if (!defaultExpanded) {
      node.tr.addClass('hidden');
    }
  }

  collapse(node) {
    const $indentSpan = node.indentSpan;
    $indentSpan.text(() => $indentSpan.text().replace('-', '+'));

    node.tr.addClass('hidden');

    node.children.forEach((e) => this.collapse(e));
  }

  toggleNode(node) {
    if (node.children.length === 0) return

    const $indentSpan = node.indentSpan;

    const expanded = $indentSpan.text().trim()[1] === '-';

    if (expanded) {
      $indentSpan.text(() => $indentSpan.text().replace('-', '+'));
      node.children.forEach((e) => this.collapse(e));

      return
    }

    $indentSpan.text(() => $indentSpan.text().replace('+', '-'));
    node.children.forEach((e) => e.tr.removeClass('hidden'));
  }
}
