import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  showChangedOnly() {
    this.element.classList.remove('show-all');
  }

  showAll() {
    this.element.classList.add('show-all');
  }
}
