import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  expandAll() {
    this.element.querySelectorAll('details').forEach((el) => {
      el.open = true
    })
  }

  collapseAll() {
    this.element.querySelectorAll('details').forEach((el) => {
      el.open = false
    })
  }
}
