import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['resolvedStatus']

  checkResolvedStatus() {
    const resolvedStatus = this.resolvedStatusTarget
    const totalRows = this.element.querySelectorAll('tr')
    const resolvedRows = this.element.querySelectorAll('.resolved_row')

    // (totalRows.length - 1) because first row is the header
    if (resolvedRows.length === (totalRows.length - 1)) {
      resolvedStatus.classList.add('one-to-one--pick-processes--admin--buyside-firm-duplicates-component__resolved')
      resolvedStatus.innerHTML = 'Resolved'
      this.element.open = false
    } else {
      resolvedStatus.classList.remove('one-to-one--pick-processes--admin--buyside-firm-duplicates-component__resolved')
      resolvedStatus.innerHTML = 'Not resolved'
      this.element.open = true
    }
  }
}
