const flattenDataAttributesInPlace = (data) => {
  Object.assign(data, data.attributes)
  delete data.attributes
}

const flattenRelationshipsInPlace = (itemData, includedItems) => {
  if (!itemData.relationships) { return }

  Object.keys(itemData.relationships).forEach((key) => {
    const relationshipData = itemData.relationships[key].data
    const relationshipLink = itemData.relationships[key].links?.related

    if (relationshipLink) {
      itemData.links ||= {}
      itemData.links[key] = relationshipLink
    }
    if (Array.isArray(relationshipData)) {
      itemData[key] = relationshipData.map(({ type, id }) => (
        // eslint-disable-next-line no-use-before-define
        includedItems.find((item) => (item.id === id && item.type === type)) || { type, id }
      ))
      itemData[key].forEach((x) => (x ? flattenDataAttributesInPlace(x) : null))
    } else if (relationshipData) {
      itemData[key] = includedItems.find(
        (item) => (item.id === relationshipData.id && item.type === relationshipData.type)
      ) || relationshipData // if lookup fails, preserve existing value data
      if (itemData[key]) {
        flattenDataAttributesInPlace(itemData[key])
      }
    } else {
      itemData[key] = null
    }
  })
  delete itemData.relationships
}

export const preProcessData = (data) => {
  const processData = (itemData) => {
    flattenDataAttributesInPlace(itemData)
    flattenRelationshipsInPlace(itemData, data.included)
  };
  if (Array.isArray(data.data)) {
    data.data.forEach(processData)
  } else {
    processData(data.data)
  }
}

export const preProcessIncluded = (included) => {
  included.forEach((item) => {
    flattenDataAttributesInPlace(item)
    flattenRelationshipsInPlace(item, included)
  })
}
