import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  toggleSendMessage() {
    const isSendMessage = this.element.querySelector('input[id=send_message]').checked
    const messageForm = this.element.querySelector('form')
    const messageInput = this.element.querySelector('input[name="review[message]"]')

    messageForm.classList.toggle('hidden', !isSendMessage)
    messageInput.disabled = !isSendMessage
  }
}
