import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axiosRails from '../../axiosRails'
import { setMailingListPreferencePossibleSend } from '../../Watchlist/watchlistSlice'
import { selectEmailPreferenceToken, selectMailingListPreference } from '../../Watchlist/selectors'
import ToggleInput from '../Shared/ToggleInput'
import Tooltip from '../Shared/Tooltip'

const EmailToggle = () => {
  const emailPreferenceToken = useSelector(selectEmailPreferenceToken)
  const { possibleSend, emailManagementMailingListId } = useSelector(selectMailingListPreference)

  const dispatch = useDispatch()

  const handleToggle = useCallback(() => {
    dispatch(setMailingListPreferencePossibleSend({
      possibleSend: !possibleSend
    }))

    axiosRails.post('/email_preferences/update', {
      token: emailPreferenceToken,
      email_management_mailing_list_preference: {
        possible_send: !possibleSend,
        email_management_mailing_list_id: emailManagementMailingListId,
      }
    })
  }, [possibleSend, emailManagementMailingListId, emailPreferenceToken, dispatch])

  if (!emailManagementMailingListId) {
    return null
  }

  return (
    <div className="watchlist-email__toggle" data-testid="emailToggle">
      <Tooltip content="Get a weekly email rollup containing updates for vendors and saved filters">
        <label htmlFor="watchlist-email-toggle">Weekly watchlist email</label>
      </Tooltip>
      <ToggleInput id="watchlist-email-toggle" className="switch" onClick={handleToggle} checked={possibleSend} />
    </div>
  )
}

export default EmailToggle
