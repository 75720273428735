import React from 'react'
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday)

export default ({ conversation }) => {
  const threadStart = conversation.posted_at

  if (!threadStart) { return null }

  const formattedDate = () => {
    const postedAt = dayjs(threadStart);
    if (postedAt.isToday()) {
      return postedAt.format('HH:mm A')
    }
    return postedAt.format('DD/MM/YYYY')
  }

  return (
    <span className="date">
      {formattedDate()}
    </span>
  )
}
