import './m-buyside-picks-region-selection-page.scss';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import pluralize from 'pluralize'
import { setFormState, setRegion } from '../../OneToOne/buysidePickProcessSlice';
import TrackingContainer from '../TrackingContainer'
import { selectSelectedCards } from '../../OneToOne/vendorPicksSlice'
import axiosRails from '../../axiosRails';
import vendorPicksConfig from '../../OneToOne/vendorPicksConfig';
import FlashMessage from '../Shared/FlashMessage';

/**
 * The region selection page in the one 2 one buyside pick process
 * @param props
 * @param {String} props.nextState The state of the form when Save & Continue is clicked
 * @param {String} props.previousState The state of the form when Back is clicked
 * @returns {JSX.Element}
 * @constructor
 */
const RegionSelectionPage = (props) => {
  const picked = useSelector(selectSelectedCards)
  const { contactId, region } = useSelector((state) => state.buysidePickProcess)
  const [hasError, setHasError] = useState(false)
  const dispatch = useDispatch()

  const saveAndContinue = () => {
    axiosRails.patch(
      vendorPicksConfig.updateBuysideContactUrl({ buysideContactId: contactId }),
      JSON.stringify({
        one_to_one_buyside_contact: {
          region,
        }
      }),
      {
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
      }
    )
      .then(() => {
        setHasError(false)
        dispatch(setFormState(props.nextState))
      })
      .catch(() => setHasError(true))
  }

  return (
    <div className="m-buyside-picks-region-selection-page">
      { hasError && <FlashMessage message="Sorry, something has gone wrong" /> }
      <div className="layout-columns">
        <div className="column column--1">
          <h2>Region</h2>
          <p>
            Confirm the region you’re based in, we’ll use this to
            help schedule any virtual meetings.
          </p>
          <div className="regions">
            <div className="region">
              <input type="radio" id="north-america" value="US" name="region" onChange={() => { dispatch(setRegion('US')) }} checked={region === 'US'} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="north-america">
                <div className="icon north-america fa-duotone fa-solid fa-globe-americas" />
                North America
              </label>
            </div>
            <div className="region">
              <input type="radio" id="europe" value="Europe" name="region" onChange={() => { dispatch(setRegion('Europe')) }} checked={region === 'Europe'} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="europe">
                <div className="icon europe fa-duotone fa-solid fa-globe-europe" />
                UK &amp; Europe
              </label>
            </div>
            <div className="region">
              <input type="radio" id="asia" value="APAC" name="region" onChange={() => { dispatch(setRegion('APAC')) }} checked={region === 'APAC'} />
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="asia">
                <div className="icon asia fa-duotone fa-solid fa-globe-asia" />
                Asia Pacific
              </label>
            </div>
          </div>
          <div className="bottom-toolbar">
            <button className="back" type="button" onClick={() => dispatch(setFormState(props.previousState))}>
              Back
            </button>
            <TrackingContainer action="Save region">
              <button className="submit" type="button" onClick={saveAndContinue} disabled={region === null}>
                Save & Continue
                <i className="icon fa-solid fa-arrow-right" />
              </button>
            </TrackingContainer>
          </div>
          <p className="meetings"><span className="meetings__count">{picked.length}</span> vendor {pluralize('meeting', picked.length)}</p>
        </div>
      </div>
    </div>
  )
}

RegionSelectionPage.propTypes = {
  nextState: PropTypes.string.isRequired,
  previousState: PropTypes.string.isRequired
}

export default RegionSelectionPage
