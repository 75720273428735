import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['body'];

  open() {
    this.bodyTarget.setAttribute('open', true);
  }

  close() {
    this.bodyTarget.removeAttribute('open', false);
  }
}
