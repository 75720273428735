import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  enable() {
    this.element.classList.remove('disabled')
  }

  disable() {
    this.element.classList.add('disabled')
  }
}
