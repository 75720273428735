import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'innerContent', 'showMoreButton', 'showFewerButton']

  static values = {
    enabled: Boolean
  }

  connect() {
    this.observer = new ResizeObserver(this.checkOverflow.bind(this))
    this.observer.observe(this.element)
  }

  disconnect() {
    this.observer.disconnect()
  }

  checkOverflow() {
    if (this.innerContentTarget.scrollHeight > this.contentTarget.clientHeight) {
      this.enable()
    }
  }

  enable() {
    this.enabledValue = true
  }

  showMore() {
    this.element.classList.add('expanded')
  }

  showFewer() {
    this.element.classList.remove('expanded')
  }

  enabledValueChanged() {
    if (this.enabledValue) {
      this.element.classList.add('enabled')
    } else {
      this.element.classList.remove('enabled')
    }
  }
}
