import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    changed: { type: Boolean, default: false },
    submited: { type: Boolean, default: false }
  }

  formIsChanged() {
    this.changed = true
  }

  formIsSubmitted() {
    this.submitted = true
  }

  leavingPage(event) {
    if (!this.changed) return

    // always allow form submission
    if (this.submitted) return

    // eslint-disable-next-line
    if (!window.confirm()) {
      event.preventDefault()
    }
  }
}
