import BaseCompressor from './BaseCompressor'

/**
 * @extends BaseCompressor
 * A compressor class customised for use with announcements.
 *
 * Allows items of the same type
 * */
const AnnouncementCompressor = class extends BaseCompressor {
  constructor(initialItem, cardRenderer, compressedCardRenderer) {
    super(initialItem, cardRenderer, compressedCardRenderer)
    this.type = 'announcements'
    this.groupAfter = 1
  }
}
export default AnnouncementCompressor
