import { Controller } from '@hotwired/stimulus'

import getCSRFToken from '../../../../javascript/src/getCSRFToken';

export default class extends Controller {
  static values = {
    rooftopId: Number,
    entityType: String,
    draftCount: Number
  }

  connect() {
    // NOTE: Do not cargo program!
    //
    // There is close coupling between the dropdown component and the comments
    // rich text editor. This is unavoidable due to dirty tracking in the browser
    //
    // Currently there is a two-way subscription between the dropdown and the
    // RTE, to keep the local state in sync. This handles components mounting
    // and unmounting by turbo.
    //
    // In reflection, a different tool should be used to track local state.
    // After a preliminary look, redux could have been used for this use case
    // as it supports a pub-sub architecture.
    //
    // https://redux.js.org/introduction/getting-started#basic-example
    //
    // ChatGPT agrees:
    // https://github.com/hotwired/stimulus/issues/233#issuecomment-1383105421
    //
    this.subscribeToDraftAddedEvent()
    this.subscribeToNewDraftAddedEvent()
    this.subscribeToPublishableDraftAddedEvent()

    // Ask the comments to replay their events
    this.dispatchCommentDropdownConnectedEvent()

    setTimeout(() => {
      const notice = this.element.querySelector('.notice')
      notice.classList.add('hidden')
    }, 5000)
  }

  disconnect() {
    this.unsubscribeToDraftAddedEvent()
    this.unsubscribeToNewDraftAddedEvent()
    this.unsubscribeToPublishableDraftAddedEvent()
  }

  saveComments(event) {
    event.preventDefault();

    // Build the form from the comments in the page
    const formData = new FormData(event.target)
    const isDraftCommentsForm = event.target.id === 'saveCommentsForm'
    const commentInputs = document.querySelectorAll('input[name*="_eav_comment"]')
    commentInputs.forEach((input) => {
      formData.append(input.name, input.value)
    })
    formData.append('comment[rooftop_id]', this.rooftopIdValue)
    formData.append('comment[entity_type]', this.entityTypeValue)
    formData.append('comment[drafts]', (!!isDraftCommentsForm))

    // Manual XHR to avoid nested HTML forms
    fetch(event.target.action, {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': getCSRFToken(),
      }
    }).then((response) => {
      if (response.ok) {
        return response.text()
      }

      return 'An error has occurred!'
    }).then((html) => {
      // eslint-disable-next-line no-undef
      Turbo.renderStreamMessage(html)
    })
  }

  removeDraftComments(event) {
    event.preventDefault()

    // Build the form from the comments in the page
    const formData = new FormData(event.target)
    const draftCommentFieldNames = Array.from(document.querySelectorAll('.commented trix-editor'))
      .map((commentEditors) => commentEditors.id)
    draftCommentFieldNames.forEach((fieldName) => {
      formData.append('comment[field_names][]', fieldName.replace(/_eav_comment$/, ''))
    })
    formData.append('comment[rooftop_id]', this.rooftopIdValue)
    formData.append('comment[entity_type]', this.entityTypeValue)

    fetch(event.target.action, {
      method: 'DELETE',
      body: formData,
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': getCSRFToken(),
      }
    }).then((response) => {
      if (response.ok) {
        return response.text()
      }

      return 'An error has occurred!'
    }).then((html) => {
      // eslint-disable-next-line no-undef
      Turbo.renderStreamMessage(html)
    })
  }

  dispatchCommentDropdownConnectedEvent() {
    window.dispatchEvent(new CustomEvent(
      'Datasets::ReviewNavigaionBarComponent::CommentsDropdownComponent#connected'
    ))
  }

  // NOTE: Do not cargo program, see full comment
  subscribeToDraftAddedEvent() {
    this.draftAddedEventFunc = () => {
      this.element.querySelector('.save-all').querySelector('button').disabled = false
    }
    window.addEventListener(
      'Datasets::ReviewNavigaionBarComponent::CommentsDropdownComponent#draft-added',
      this.draftAddedEventFunc
    )
  }

  unsubscribeToDraftAddedEvent() {
    window.removeEventListener(
      'Datasets::ReviewNavigaionBarComponent::CommentsDropdownComponent#draft-added',
      this.draftAddedEventFunc
    )
  }

  // NOTE: Do not cargo program, see full comment
  subscribeToNewDraftAddedEvent() {
    this.newDraftAddedEventFunc = () => {
      this.draftCountValue += 1
      document.querySelector('.dropdown-menu-component_trigger button span .comments-count').innerText = this.draftCountValue
    }
    window.addEventListener(
      'Datasets::ReviewNavigaionBarComponent::CommentsDropdownComponent#new-draft-added',
      this.newDraftAddedEventFunc
    )
  }

  unsubscribeToNewDraftAddedEvent() {
    window.removeEventListener(
      'Datasets::ReviewNavigaionBarComponent::CommentsDropdownComponent#new-draft-added',
      this.newDraftAddedEventFunc
    )
  }

  // NOTE: Do not cargo program, see full comment
  subscribeToPublishableDraftAddedEvent() {
    this.publishableDraftAddedEventFunc = () => {
      this.element.querySelector('.publish-all').querySelector('button').disabled = false
    }
    window.addEventListener(
      'Datasets::ReviewNavigaionBarComponent::CommentsDropdownComponent#publishable-draft-added',
      this.publishableDraftAddedEventFunc
    )
  }

  unsubscribeToPublishableDraftAddedEvent() {
    window.removeEventListener(
      'Datasets::ReviewNavigaionBarComponent::CommentsDropdownComponent#publishable-draft-added',
      this.publishableDraftAddedEventFunc
    )
  }
}
