import React from 'react'
import { useSelector } from 'react-redux'
import FilterRows from './FilterRows'
import { vendorCollectionSummaries } from '../../OneToOne/VendorPicksFilter'

const VendorCollectionFilter = () => {
  const showFilters = useSelector((state) => state.data.showFilters)

  return (
    <div className={`vendor-filter-group ${showFilters ? 'show' : 'hide'}`}>
      <span className="title">Collections</span>
      <FilterRows selector={vendorCollectionSummaries} />
    </div>
  )
}
export default VendorCollectionFilter
