import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  dismissHelpText(event) {
    event.preventDefault()
    const cookieValue = true

    document.cookie = `dataset-help-text-dismissed=${cookieValue}; path=/`
    this.element.classList.add('hidden')
  }
}
