import './m-buyside-picks.scss';
import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { BrowserRouter, useHistory } from 'react-router-dom';
import vendorPicksStore from '../../OneToOne/vendorPicksStore'
import vendorPicksConfig from '../../OneToOne/vendorPicksConfig'
import { loadContactPickProcess } from '../../OneToOne/vendorPicksSlice'
import {
  loadPickProcess, setAllowedSlotIds, setContactId,
  setContactAttending, setContactInPersonAttendance, setContactVirtualAttendance,
  setRegion, setAdditionalContactEmail, setContactIsScoutUser
} from '../../OneToOne/buysidePickProcessSlice'
import PickProcessFormTabs from './PickProcessFormTabs'
import UserConfirmationModal from './UserConfirmationModal';

window.TestConfig = vendorPicksConfig

let store = null
let history = null
/** @private */
const UnRoutedVendorPicksApp = (props) => {
  history = useHistory()
  if (store === null) {
    store = vendorPicksStore(history)
  }
  store.dispatch(loadPickProcess(props.pickProcess))
  store.dispatch(loadContactPickProcess(props.contactPickProcess))
  store.dispatch(setContactId(props.contact.id))
  store.dispatch(setContactAttending(props.contact.attending))
  store.dispatch(setContactInPersonAttendance(props.contact.contactInPersonAttendance))
  store.dispatch(setContactVirtualAttendance(props.contact.contactVirtualAttendance))
  store.dispatch(setAllowedSlotIds(props.contact.allowed_slot_ids))
  store.dispatch(setAdditionalContactEmail(props.contact.additional_contact_email))
  store.dispatch(setRegion(props.contact.region))
  store.dispatch(setContactIsScoutUser(props.contact.isScoutUser))
  vendorPicksConfig.setRegionMap(props.config.regionMap)

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <UserConfirmationModal contact={props.contact} isOpen={props.config.showUserConfirmation} />
        <PickProcessFormTabs
          existingContainerId={props.existingContainerId}
          useQuery={props.useQuery}
          bannerMessage={props.bannerMessage}
        />
      </ConnectedRouter>
    </Provider>
  )
}
/**
 * The main vendor picks application - to be used in the header bar to show the tabs there,
 * but with a required existing element to contain the tab contents.
 * @param props
 * @param {String} props.existingContainerId The id of the element which contains the tab contents
 * @param {String} props.useQuery The name of the query param to use to represent each tab
 * @param props.pickProcess
 * @param {number} props.pickProcess.id The id of the pick process that we are using
 * @param {String|null} props.pickProcess.buyside_process_ends_at A string representation
 *  of the date when the process expires
 * @param {Array<Object>} props.pickProcess.meeting_slots An array of slot objects
 *   containing 'id' (integer), 'meeting_at' (ISO9601 representation of meeting time
 *   in UTC) and 'apac', 'europe', 'us' (boolean)
 * @param props.contactPickProcess
 * @param {String|null} props.contactPickProcess.picks_submitted_at A string representation
 *   of the date when the picks were submitted
 * @param props.contact
 * @param {Integer} props.contact.id The buyside contact id
 * @param {Array<Integer>} props.contact.allowed_slot_ids A list of ids that will decide which
 *   slots are pre selected
 * @param {String} props.contact.region The region for the buyside contact
 * @param {Boolean} props.contact.isScoutUser True if this email address is a scout user
 * @param {String} props.contact.name The first name for the buyside contact
 * @param {String} props.contact.surname The surname for the buyside contact
 * @param {String} props.bannerMessage The content snippet to be rendered in vendor picks page
 * @param props.config
 * @param {Object} props.config.regionMap The region map for translation.  The key is the value
 *   used internally and the value is an object with 'timezone_label' and 'timezone' where
 *   timezone_label is what is shown to the user (i.e. GMT, HKT) and the
 *   timezone is for example 'Europe/London'
 * @param {Boolean} props.config.showUserConfirmation When true, the user
 *   confirmation modal is shown
 * @returns {JSX.Element}
 * @constructor
 */
const VendorPicksApp = (props) => (
  <BrowserRouter>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <UnRoutedVendorPicksApp {...props} />
  </BrowserRouter>
)
export default VendorPicksApp
