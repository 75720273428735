import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['line']

  connect() {
    window.addEventListener('scroll', () => this.handleScroll())
  }

  disconnect() {
    window.removeEventListener('scroll', () => this.handleScroll())
  }

  handleScroll() {
    const scrolledPercentage = (
      window.scrollY / (document.documentElement.scrollHeight - window.innerHeight)
    ) * 100
    this.lineTarget.style.width = `${scrolledPercentage}%`
  }
}
