import { Controller } from '@hotwired/stimulus'
import { isEqual } from 'lodash';

export default class extends Controller {
  static targets = ['list', 'resetButton', 'submitButton', 'listItem', 'form', 'checkbox']

  connect() {
    this.#updateOrderInputValues()
    this.initialData = this.#getFormData();

    this.draggingElement = null

    this.listTarget.addEventListener('dragstart', (e) => this.#dragStart(e))
    this.listTarget.addEventListener('dragover', (e) => this.#dragOver(e))
    this.listTarget.addEventListener('drop', (e) => this.#drop(e))
    this.listTarget.addEventListener('dragend', (e) => this.#dragEnd(e))

    this.setButtonsState()
  }

  resetToDefault() {
    const items = this.listItemTargets;

    items.sort((a, b) => {
      const orderA = parseInt(a.dataset.defaultOrder, 10);
      const orderB = parseInt(b.dataset.defaultOrder, 10);
      return orderA - orderB;
    });

    items.forEach((item) => {
      this.listTarget.appendChild(item)
    });

    this.#updateOrderInputValues()

    this.#checkAllCheckboxes()

    this.setButtonsState()
  }

  setButtonsState() {
    this.#setResetButtonState()
    this.#setSubmitButtonState()
  }

  #dragStart(event) {
    if (!event.target.matches('.customise-homepage--modal-component__list-item')) return

    this.draggingElement = event.target

    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('text/html', event.target.outerHTML)
    event.target.classList.add('dragging')
  }

  #dragOver(event) {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
    const target = event.target.closest('.customise-homepage--modal-component__list-item')

    if (target && target !== this.draggingElement) {
      const bounding = target.getBoundingClientRect()
      const offset = bounding.y + bounding.height / 2

      if (event.clientY - offset > 0) {
        this.listTarget.insertBefore(this.draggingElement, target.nextSibling)
      } else {
        this.listTarget.insertBefore(this.draggingElement, target)
      }
    }
  }

  #drop(event) {
    event.preventDefault()
  }

  #dragEnd() {
    if (this.draggingElement) {
      this.draggingElement.classList.remove('dragging')
      this.draggingElement = null
    }
    this.#updateOrderInputValues()
  }

  #updateOrderInputValues() {
    this.listItemTargets.forEach((item, index) => {
      const orderInput = item.querySelector('input[name$="[order]"]')

      if (orderInput) {
        orderInput.value = index
      }
    })
    this.setButtonsState()
  }

  #checkAllCheckboxes() {
    this.checkboxTargets.forEach((input) => { input.checked = true })
  }

  #formChanged() {
    const currentData = this.#getFormData()

    return !isEqual(this.initialData, currentData)
  }

  #getFormData() {
    const data = {};
    new FormData(this.formTarget).forEach((value, key) => {
      data[key] = value;
    })

    this.checkboxTargets.forEach((checkbox) => {
      data[checkbox.name] = checkbox.checked;
    })

    delete data.authenticity_token

    return data;
  }

  #setResetButtonState() {
    const sortByDefaultOrder = (a, b) => (
      Number(a.dataset.defaultOrder) - Number(b.dataset.defaultOrder)
    )

    const sortedListItems = this.listItemTargets.slice().sort(sortByDefaultOrder)

    const orderMatchesDefaultOrder = this.listItemTargets.every(
      (item, index) => item === sortedListItems[index]
    );

    const allChecked = this.checkboxTargets.every((input) => (input.checked))

    this.resetButtonTarget.disabled = orderMatchesDefaultOrder && allChecked
  }

  #setSubmitButtonState() {
    this.submitButtonTarget.classList.toggle('disabled', !this.#formChanged())
  }
}
