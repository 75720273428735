import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  hideSuccessContent() {
    if (this.element.querySelector('.success-content')) {
      this.element.querySelector('.success-content').remove()
      this.element.querySelector('.basic-content').classList.remove('hidden')
    }
  }
}
