// Helper module for using mixins
//
// Adds wrapper methods to provide shorter accessor names.
// This is useful when working with namespaces
//
// NOTE: Outlet callbacks are currently not supported!!!
//
// @example
// const friendlyOutlets = {
//   'friendly-name': 'target-with-long-name-component'
// }
//
// class MyClass extends FriendlyOutletsMixin(Controller, friendlyOutlets) {
// }
//
// component = new MyClass(...)
// component.friendlyNameOutlet => component.targetWithLongNameComponentOutlet
// component.friendlyNameOutlets => component.targetWithLongNameComponentOutlets
// ... etc ...
import { values } from 'lodash'

function camelize(value) {
  return value.replace(/(?:[_-])([a-z0-9])/g, (_, char) => char.toUpperCase())
}

function namespaceCamelize(value) {
  return camelize(value.replace(/--/g, '-').replace(/__/g, '_'))
}

export default (Base, friendlyOutlets) => class extends Base {
  static outlets = values(friendlyOutlets)

  constructor(...args) {
    super(...args)

    Object.entries(friendlyOutlets).forEach(([rawAlias, rawSource]) => {
      const alias = namespaceCamelize(rawAlias)
      const source = namespaceCamelize(friendlyOutlets[rawAlias])

      const hasAlias = namespaceCamelize(`has-${rawAlias}-outlet`)
      const hasSource = namespaceCamelize(`has-${rawSource}-outlet`)

      Object.defineProperty(this, hasAlias, {
        get() { return this[hasSource] }
      })
      Object.defineProperty(this, `${alias}Outlet`, {
        get() { return this[`${source}Outlet`] }
      })
      Object.defineProperty(this, `${alias}Outlets`, {
        get() { return this[`${source}Outlets`] }
      })
      Object.defineProperty(this, `${alias}OutletElement`, {
        get() { return this[`${source}OutletElement`] }
      })
      Object.defineProperty(this, `${alias}OutletElements`, {
        get() { return this[`${source}OutletElements`] }
      })
    })
  }
}
