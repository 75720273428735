import React, {
  Children, useRef, useState, useEffect
} from 'react'
import ViewedCardMonitor from './ViewedCardMonitor'

const ViewedCardsMonitor = ({
  children, cardTypes, watchlistContainerRef, disabled
}) => {
  const [scroll, setScroll] = useState();
  const initialScroll = useRef(null);

  useEffect(() => {
    watchlistContainerRef.current.onscroll = () => {
      setScroll(watchlistContainerRef.current.scrollTop)
    }

    const watchlistContainerElement = watchlistContainerRef.current

    return function cleanup() {
      if (!watchlistContainerElement) {
        return
      }

      watchlistContainerElement.onscroll = null
    }
  })

  const hasScrolled = scroll && scroll !== initialScroll.current;

  // Delay setting the initial scroll position in case the page is being refreshed
  if (scroll && !initialScroll.current) {
    initialScroll.current = scroll
  }

  return Children.map(children, (child) => {
    if (cardTypes.indexOf(child?.type) >= 0) {
      return (
        <ViewedCardMonitor
          disabled={!hasScrolled || disabled}
          watchlistContainerRef={watchlistContainerRef}
        >
          {child}
        </ViewedCardMonitor>
      )
    }
    return child
  })
}
export default ViewedCardsMonitor
