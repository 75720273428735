import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['deferralDate', 'modalTemplate']

  connect() {
    if (!this.element.querySelector('.publish-button-publish').classList.contains('hidden')) {
      setTimeout(() => this.publish(), 0)
    }
  }

  hideSuccessContent() {
    if (this.element.querySelector('.success-content')) {
      this.element.querySelector('.success-content').remove()
      this.element.querySelector('.basic-content').classList.remove('hidden')
    }
  }

  publish() {
    const newNode = this.modalTemplateTarget.content.cloneNode(true)

    document.getElementById('modal').appendChild(newNode)

    const thisElement = this.element
    setTimeout(() => {
      document.querySelector('#modal .modal--close-button-component').addEventListener('click', () => {
        thisElement.querySelector('.publish-button-publish').classList.add('hidden')
        thisElement.querySelector('.publish-button-validate').classList.remove('hidden')
      })
    })
  }
}
