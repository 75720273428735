import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['multiSelectField']

    removeSelection(e) {
      this.getMultiSelectController().removeSelection(e)
    }

    getMultiSelectController() {
      return this.application
        .getControllerForElementAndIdentifier(
          this.multiSelectFieldTarget,
          this.multiSelectFieldTarget.dataset.controller
        )
    }
}
