import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['unchanged']

  showAll() {
    this.unchangedTarget.classList.add('show-all')
  }

  showChangedOnly() {
    this.unchangedTarget.classList.remove('show-all')
  }
}
