import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['messages']

  connect() {
    this.messagesTarget.scrollTo(0, this.messagesTarget.scrollHeight)
  }

  setInProgressStatus(event) {
    this.dispatch('interactionInProgress', { detail: event.detail })
  }
}
