import {
  cloneElement, Children, useEffect, useRef, useCallback,
} from 'react'
import { useInView } from 'react-intersection-observer'

const ViewedCardMonitor = ({ children, disabled, watchlistContainerRef }) => {
  const seenRef = useRef(false);

  Children.only(children)
  const childProps = children.props

  const markAsSeen = () => {
    seenRef.current = true;
    if (childProps.onShow) {
      childProps.onShow(childProps.watchlistItem || childProps.watchlistItems)
    }
  }

  // trackVisibility does not seem to ever mark an element as seen if it is too long
  // for the container. I don't think we need it, trackVisibility is meant to help with
  // overlaid elements(z-index/absolute positioned/modal shenanigans),
  // but we have nothing like that in the feed.

  // Mark card as seen if over 80% of it is visible.
  const [mostOfCardVisibleRef, mostOfCardVisibleInView] = useInView({
    root: watchlistContainerRef.current,
    threshold: 0.8
  })

  useEffect(() => {
    if (!seenRef.current && !disabled && mostOfCardVisibleInView) {
      markAsSeen()
    }
  })

  // The container has, for intersection detection purposes, a negative margin of 250px
  // Small cards will get marked as seen by the earlier intersection observer as they enter
  // the viewport fully. Larger cards will trigger this one, once 250px of them is visible
  const [cardScrolledUpALotRef, cardScrolledUpALotInView] = useInView({
    root: watchlistContainerRef.current,
    rootMargin: '-250px'
  })

  useEffect(() => {
    if (!seenRef.current && !disabled && cardScrolledUpALotInView) {
      markAsSeen()
    }
  })

  const setRefs = useCallback(
    (node) => {
      mostOfCardVisibleRef(node)
      cardScrolledUpALotRef(node)
    },
    [mostOfCardVisibleRef, cardScrolledUpALotRef]
  )

  return (
    cloneElement(children, { ref: setRefs })
  )
}

export default ViewedCardMonitor
