import { Controller } from '@hotwired/stimulus';
import { upperFirst, camelCase } from 'lodash';
import humanize from 'underscore.string/humanize';

export default class extends Controller {
  static targets = ['selected', 'fileTemplate', 'selectedFileTemplate', 'currentFileContainer', 'fileInput']

  connect() {
    this.selectedTarget.addEventListener('form-component-modified', this.fileModifiedHandler.bind(this))
  }

  disconnect() {
    this.selectedTarget.removeEventListener('form-component-modified', this.fileModifiedHandler.bind(this))
  }

  fileModifiedHandler(event) {
    event.stopPropagation()
    this.dispatchModifiedEvent(event)
  }

  fileChosen(event) {
    const fileName = event.currentTarget.files[0].name;
    const fileType = event.currentTarget.files[0].type.split('/').slice(-1)[0]
    this.addChosenFile(fileName, fileType, event.currentTarget.files[0])
    window.setTimeout(this.dispatchModifiedEvent.bind(this, event))
  }

  // This deserves a bit of explanation:
  // When a user uploads a file, we spawn a new widget containing the title,
  // download link, etc. The widget contains nested form fields, so that these
  // fields can be edited. It is generated from the <template> denoted by
  // `selectedFileTemplateTarget`. It contains placeholder IDs which are
  // rewritten by replacePlaceholders below. If you add new fields to
  // Downloads/Screenshots then you will need to change that function too.
  addChosenFile(fileName, fileType, file) {
    const newNode = this.selectedFileTemplateTarget.content.cloneNode(true)
    const humanizedTitle = humanize(fileName.replace(/\.[^/.]+$/, ''))

    const downloadLinkEl = newNode.querySelector('.download')
    downloadLinkEl.href = URL.createObjectURL(file)
    downloadLinkEl.download = fileName

    const titleInputEl = newNode.querySelector('[data-title] input')
    titleInputEl.value = humanizedTitle

    newNode.querySelector('[data-title] > [data-edit-container]').innerHTML = humanizedTitle
    const type = upperFirst(camelCase(this.element.dataset.type))
    const preProcessor = `preProcess${type}`
    if (this[preProcessor]) {
      this[preProcessor].apply(this, [newNode, fileName, fileType, file])
    }
    this.replacePlaceholders(newNode)
    this.moveFiles(newNode)
    this.selectedTarget.appendChild(newNode)
    const currentCount = parseInt(this.selectedTarget.dataset.count, 10)
    this.selectedTarget.dataset.count = (currentCount + 1).toString()
  }

  preProcessFile(newNode, fileName, fileType) {
    if (fileType !== '') {
      newNode.querySelector('[data-file-extension]')?.classList?.add(fileType)
    }
  }

  preProcessScreenshot(newNode, fileName, fileType, file) {
    const src = URL.createObjectURL(file);
    const missingImage = newNode.querySelector('img[data-missing-image]')
    missingImage.src = src;
  }

  replacePlaceholders(newNode) {
    this.replacePlaceholder(newNode.querySelector('[data-title] input'))
    this.replacePlaceholder(newNode.querySelector('[data-file] input'))
    this.replacePlaceholder(newNode.querySelector('input[data-id]'))
    // category is optional since this controller is shared between Downloads
    // (which have categories) and Screenshots (which don't).
    // We could have passed a type to indicate whether this field is mandatory
    // or not, but that seems unnecessarily complicated.
    const frag = newNode.querySelector('[data-category-slug] select');
    if (frag) {
      this.replacePlaceholder(frag);
    }
  }

  // This is a bit of a hack - the "FileComponent" template is generated using
  // Rails form helpers, which don't really have a concept of "variables". We
  // pass it an index of "index_placeholder", and then have to go in afterwards
  // to rewrite all the places that index was used.
  replacePlaceholder(el) {
    el.name = el.name.replace(/\[index_placeholder\]/, `[${this.selectedTarget.dataset.count}]`)
    el.id = el.id.replace(/index_placeholder/, this.selectedTarget.dataset.count)
  }

  moveFiles(newNode) {
    const { files } = this.fileInputTarget
    const dataTransfer = new DataTransfer()
    files.forEach((file) => {
      dataTransfer.items.add(new File([file.slice(0, file.size, file.type)], file.name))
    })
    newNode.querySelector('[data-file] input[type=file]').files = dataTransfer.files

    this.fileInputTarget.value = null
  }

  fileDropped(event) {
    event.preventDefault()
    if (this.isDisabled()) { return }
    this.fileInputTarget.files = event.dataTransfer.files
    const fileName = this.fileInputTarget.files[0].name;
    const fileType = this.fileInputTarget.files[0].type.split('/').slice(-1)[0]
    this.addChosenFile(fileName, fileType, this.fileInputTarget.files[0])
    window.setTimeout(this.dispatchModifiedEvent.bind(this))
  }

  fileDraggedOver(event) {
    event.preventDefault()
  }

  // "modified" as in "the form has been modified".
  // Required to ensure save/publish buttons are enabled on the dataset form.
  dispatchModifiedEvent() {
    const controllers = this.getSelectedFileControllers()
    const values = controllers.reduce((acc, controller) => {
      const value = controller.getValue()
      if (value) {
        acc.push(value)
      }
      return acc
    }, [])
    const customEvent = new CustomEvent('form-component-modified', { detail: { value: values }, bubbles: true })
    this.element.dispatchEvent(customEvent)
  }

  getSelectedFileControllers() {
    return Array.from(this.element.querySelectorAll('.selected-container .selected-file'))
      .map((el) => this.application.getControllerForElementAndIdentifier(el, el.dataset.controller))
  }

  isDisabled() {
    return this.element.classList.contains('disabled')
  }
}
