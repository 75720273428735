import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['tabLink', 'tabContent']

  static values = {
    pageParam: String,
    tabMeta: { type: Object, default: {} },
    currentTabIdentifier: String
  }

  switchTab(e) {
    const identifier = e.detail.identifier || e.currentTarget.dataset.identifier
    const tabNotFound = !this.tabContentTargets.map(
      (tab) => tab.dataset.identifier
    ).includes(identifier)

    if (tabNotFound) {
      const parentControllerElement = $(this.element).parent().closest('.tabbed-results-component')

      if (parentControllerElement.length > 0) {
        const otherController = this.application.getControllerForElementAndIdentifier(parentControllerElement[0], 'tabbed-results-component--tabbed-results-component')

        e.preventDefault()
        otherController.switchTab(e)
      }
      return
    }

    if (e.currentTarget.dataset.framed !== 'true') {
      e.preventDefault()
    } else if (this.tabMetaValue[identifier]?.loaded) {
      e.preventDefault()
      // eslint-disable-next-line no-restricted-globals
      history.replaceState({}, '', e.currentTarget.href)
    }
    this.showTab(identifier)
    this.dispatch('tab-switched', { detail: { identifier } })
    this.currentTabIdentifierValue = identifier
    this.setPageQueryParam()
  }

  showTab(identifier) {
    this.setCurrentTab(identifier)
    this.showContentsForTab(identifier)
  }

  setCurrentTab(identifier) {
    this.tabLinkTargets.forEach((link) => {
      if (link.dataset.identifier === identifier) {
        link.classList.add('current')
      } else {
        link.classList.remove('current')
      }
    })
  }

  showContentsForTab(identifier) {
    this.tabContentTargets.forEach((content) => {
      if (content.dataset.identifier === identifier) {
        content.classList.remove('hidden-tab')
      } else {
        content.classList.add('hidden-tab')
      }
    })
  }

  onFrameRender(event) {
    // Make sure we only run this if our component triggers this event. Otherwise other frames
    //   rendering, such as the async frames, will trigger scrolling as well.
    if (!event.target.classList.contains('tabbed-results-component--tab-contents-component')) return

    const requestUrl = new URL(event.detail.fetchResponse.response.url)
    const urlParams = new URLSearchParams(requestUrl.search)
    const pageParam = this.pageParamValue
    const page = urlParams.get(pageParam) || '1'
    // eslint-disable-next-line no-restricted-globals
    const metaValue = this.tabMetaValue
    metaValue[this.currentTabIdentifierValue] ||= {}
    metaValue[this.currentTabIdentifierValue].page = page
    metaValue[this.currentTabIdentifierValue].loaded = true
    this.tabMetaValue = metaValue
  }

  setPageQueryParam() {
    const meta = this.tabMetaValue[this.currentTabIdentifierValue]
    const page = meta?.page
    if (!page) return

    const pageParams = new URLSearchParams(document.location.search)
    pageParams.set(this.pageParamValue, page)
    // eslint-disable-next-line no-restricted-globals
    history.replaceState({}, '', `${document.location.pathname}?${pageParams.toString()}`)
  }
}
