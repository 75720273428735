import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.setSelectedCount()
  }

  setSelectedCount() {
    const selectedCount = this.element.querySelectorAll('input[type="checkbox"]:checked').length
    this.element.querySelector('#selected_filters_count').innerHTML = selectedCount
  }
}
