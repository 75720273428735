import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { sortBy } from 'lodash'

import { getCanShareWatchlist, getCurrentDataProviderSummaries } from '../../Watchlist/selectors'
import WatchlistTitle from './WatchlistTitle'
import EmailToggle from './EmailToggle'
import Tabs from './Tabs'
import ShareAllButton from './ShareAllButton'
import WatchedVendorRow from './DataProviderFilter/FilterRow'
import AllFilterRow from './DataProviderFilter/AllFilterRow'
import ExpandableContent from '../Shared/ExpandableContent'
import WatchedFilters from './WatchedFilters'

const WatchedLists = () => {
  const showWatchlists = useSelector((state) => state.data.showFilters)
  const { savedFilters, savedFiltersEnabled } = useSelector((state) => state.data)

  const canShareWatchlist = useSelector(getCanShareWatchlist)
  const dataProviderSummaries = useSelector(getCurrentDataProviderSummaries)
  const sortedDataProviderSummaries = sortBy(
    dataProviderSummaries,
    [({ name }) => name.toLowerCase()]
  )
  const [isVendorExpanded, setIsVendorExpanded] = useState(true);
  const [isSavedFilterExpanded, setIsSavedFilterExpanded] = useState(false);

  useEffect(() => {
    if (isVendorExpanded) {
      setIsSavedFilterExpanded(false);
    }
  }, [isVendorExpanded]);

  useEffect(() => {
    if (isSavedFilterExpanded) {
      setIsVendorExpanded(false);
    }
  }, [isSavedFilterExpanded]);

  const toggleVendor = () => {
    setIsVendorExpanded(!isVendorExpanded)
    if (!isVendorExpanded) {
      setIsSavedFilterExpanded(false)
    }
  }

  const toggleSavedFilters = () => {
    setIsSavedFilterExpanded(!isSavedFilterExpanded)
    if (!isSavedFilterExpanded) {
      setIsVendorExpanded(false)
    }
  }

  return (
    <div className={`watchlist-filter-group data-provider-buttons ${showWatchlists ? 'show' : 'hidden'}`}>
      <WatchlistTitle />
      <EmailToggle />
      { canShareWatchlist && <Tabs /> }
      <ShareAllButton />
      <div className={`watched-lists ${!savedFiltersEnabled && 'watched-filters-disabled'}`}>
        <ExpandableContent title="Watched vendors" expanded={isVendorExpanded} toggleExpanded={toggleVendor}>
          <div className="watched-list">
            <AllFilterRow />
            { sortedDataProviderSummaries.map((summary) => (
              <WatchedVendorRow key={summary.id} dataProviderSummary={summary} />
            )) }
          </div>
        </ExpandableContent>
        {
          savedFiltersEnabled
          && (
            <WatchedFilters
              expanded={isSavedFilterExpanded}
              toggleExpanded={toggleSavedFilters}
              savedFilters={savedFilters}
            />
          )
        }
      </div>
    </div>
  )
}

export default WatchedLists
