import AnnouncementCompressedCard from './AnnouncementCompressedCard'

/**
 * Allows looking up of a compressed card type by its data type
 */
const CardLookup = {
  announcements: AnnouncementCompressedCard
}

export default CardLookup
