import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  change(event) {
    const newEvent = new CustomEvent('view-change', { detail: { id: event.target.getAttribute('value') } })
    this.element.dispatchEvent(newEvent)
    if (this.element.dataset.autoSubmit === 'true') {
      this.submit()
    }
  }

  submit() {
    this.element.closest('form').requestSubmit()
  }
}
