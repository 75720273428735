import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['panel']

  connect() {
    this.resize()
    window.addEventListener('resize', () => this.resize())

    window.addEventListener('turbo:before-fetch-request', () => this.close());
  }

  close() {
    this.element.classList.remove('visible')
  }

  open() {
    this.element.classList.add('visible')
  }

  resize() {
    if (window.innerWidth > 768) {
      this.element.classList.remove('visible')
    }
  }

  clickOutside(event) {
    if (this.element.contains(event.target)) return

    this.close()
  }
}
