import { Controller } from '@hotwired/stimulus';
import { debounce } from '../../../javascript/src/debounce';

export default class extends Controller {
  static targets = ['searchButton', 'queryInput', 'clearButton']

  static values = { autoSubmit: Boolean }

  connect() {
    if (this.autoSubmitValue) {
      this.debouncedSubmit = debounce(() => this.search(), 600);
      this.queryInputTarget.addEventListener('input', this.debouncedSubmit);
    }

    this.setClearButtonVisibility()
  }

  search() {
    this.getForm().requestSubmit()
  }

  clear() {
    this.queryInputTarget.value = ''
    this.setClearButtonVisibility()
  }

  getForm() {
    if (this.searchButtonTarget.form) {
      return this.searchButtonTarget.form
    }
    return this.searchButtonTarget.closest('form')
  }

  setClearButtonVisibility() {
    this.clearButtonTarget.classList.toggle('visibility-hidden', this.queryInputTarget.value.length === 0)
  }
}
