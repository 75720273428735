import { Controller } from '@hotwired/stimulus';

import getCSRFToken from '../../../../../javascript/src/getCSRFToken';

export default class extends Controller {
  static targets = ['checkbox']

  static values = {
    url: String
  }

  suppressDuplicates(event) {
    const selectedCheckbox = event.target
    const pickUpdates = []

    this.checkboxTargets.forEach((checkbox) => {
      pickUpdates.push({
        id: checkbox.dataset.buysidePickId,
        suppressed_dedup: (selectedCheckbox.checked && checkbox !== selectedCheckbox)
      })
    })

    fetch(this.urlValue, {
      method: 'POST',
      cache: 'no-store',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': getCSRFToken()
      },
      body: JSON.stringify({ one_to_one_buyside_picks: pickUpdates })
    }).then((response) => {
      if (response.ok) {
        this.updateUI(selectedCheckbox)
        this.dispatch('suppressed-duplicates')
      }

      return 'An error has occurred!'
    })
  }

  updateUI(selectedCheckbox) {
    this.element.classList.toggle('resolved_row', selectedCheckbox.checked)

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = (selectedCheckbox.checked && checkbox === selectedCheckbox)
    })
  }
}
