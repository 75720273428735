import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import WatchlistApp from './WatchlistApp'
import WatchlistPage from './WatchlistPage';

/**
 * Application wrapper for the 'Feed' functionality
 * @param props
 * @param {Object} props.configuration Configuration object
 * @param {String} props.configuration.endpoint_url The base URL to fetch all feed data
 * @param {Boolean} props.configuration.can_share_watchlist True if the user
 *        can use the feed sharing feature
 * @param {Array<Object>} props.configuration.feed_types An array of feed types
 * @param {String} props.configuration.feed_types[n].slug The slug for the feed type
 * @param {String} props.configuration.feed_types[n].title The title of the feed type
 * @param {String} props.configuration.feed_types[n].logo The logo url for the feed type
 * @param {Array<Object>} props.configuration.data_providers An array of data providers
 * @param {Integer} props.configuration.data_providers[n].id A unique id for the data provider
 * @param {String} props.configuration.data_providers[n].logo_src The logo url for the data provider
 * @param {String} props.configuration.data_providers[n].slug A unique slug for the data provider
 * @param {String} props.configuration.data_providers[n].title The title for the data provider
 * @param {Array<Object>} props.configuration.content_scopes An array of content scopes
 * @param {String} props.configuration.content_scopes[n].slug A unique slug for the content type
 * @param {String} props.configuration.content_scopes[n].title The title for the content type
 * @returns {JSX.Element}
 * @constructor
 */
const FeedApp = (props) => (
  <BrowserRouter>
    <WatchlistApp configuration={props.configuration}>
      <WatchlistPage />
    </WatchlistApp>
  </BrowserRouter>
)
export default FeedApp
