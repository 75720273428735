import React from 'react'
import { useSelector } from 'react-redux';
import { selectSelectedCards } from '../../../OneToOne/vendorPicksSlice'
import VendorDetails from './VendorDetails'
import { getBuysidePickProcess } from '../../../OneToOne/VendorPicksFilter';

const MeetingsList = () => {
  const pickedVendors = useSelector(selectSelectedCards)
  const inPersonMeetings = pickedVendors.filter((item) => item.attending)
  const virtualMeetings = pickedVendors.filter((item) => !item.attending)
  const buysideContactAttending = useSelector(
    (state) => state.buysidePickProcess.contactAttending
  )
  const { inPersonOnly: inPersonOnlyPickProcess } = useSelector(
    (state) => state.buysidePickProcess.pickProcess
  )

  const { contactInPersonAttendance, contactVirtualAttendance } = useSelector(
    getBuysidePickProcess
  )

  const categorizeMeetings = buysideContactAttending
    && !inPersonOnlyPickProcess
    && contactInPersonAttendance
    && contactVirtualAttendance

  return (
    <div>
      { categorizeMeetings && (
        <div>
          <h3 className="in-person"><i className="icon fa-regular fa-id-badge" /> In-person </h3>
          <span className="in-person-text">
            Every effort will be made to book vendors for in-person meetings during the event.
            If we cannot schedule then it will be changed to a virtual meeting.
          </span>
          <ul className="buyside-picks-list">
            {
            inPersonMeetings.length === 0
            && <li>No in-person meetings selected</li>
          }
            {inPersonMeetings.map((vendor) => (
              <VendorDetails vendor={vendor} />
            ))}
          </ul>
          <hr />
          <h3 className="virtual"><i className="icon fa-regular fa-laptop" /> Virtual </h3>
          <ul className="buyside-picks-list">
            {
            virtualMeetings.length === 0
            && <li>No virtual meetings selected</li>
          }
            {virtualMeetings.map((vendor) => (
              <VendorDetails vendor={vendor} />
            ))}
          </ul>
        </div>
      )}
      { !categorizeMeetings && (
        <ul>
          {pickedVendors.map((vendor) => (
            <VendorDetails vendor={vendor} />
          ))}
        </ul>
      )}
    </div>
  )
}

export default MeetingsList
