import { Controller } from '@hotwired/stimulus'

/** Connects to data-controller="flash"
 * This is a bridge between the webpack code (where the stimulus
 * controllers are hosted) and the global code for the
 * Flash code.
 *
 * Whilst this code could be re written to be more modular
 * and OO - this is a small step as it was not 100% sure
 * if every page had the webpack javascript loaded.
 */
export default class extends Controller {
  // eslint-disable-next-line class-methods-use-this
  connect() {
    const iframeMode = document.querySelector('.iframe-wrapper') !== null

    const fullScreenMode = document.querySelector('.full-screen-wrapper') !== null

    if (iframeMode) {
      // Do nothing, do not scroll the page
    } else if (fullScreenMode) {
      this.element.scrollIntoView()
    } else {
      document.body.scrollIntoView()
    }

    window.flash.hideAfterTimeout()
  }
}
