/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react'

const ContentCarousel = (props) => {
  const [itemPos, setItemPos] = useState(0.0)
  const [timerPaused, setTimerPaused] = useState(false)
  const [timerDisabled, setTimerDisabled] = useState(false)
  const duration = props.duration || 10000
  const interval = props.interval || 50
  useEffect(() => {
    const timer = setInterval(() => {
      if (timerDisabled || timerPaused) { return }
      const newPos = Math.round((itemPos + (interval / duration)) * 1000) / 1000
      if (newPos >= props.content.length) {
        setItemPos(0.0)
      } else {
        setItemPos(newPos)
      }
    }, interval)

    return () => { clearInterval(timer) }
  })
  const currentItem = props.content[parseInt(itemPos, 10)]
  return (
    <div className="content-carousel">
      <div className="column-1">
        <ul>
          {props.content.map((item) => (
            <li key={item.navigation_title} onMouseEnter={() => (setTimerPaused(true))} onMouseLeave={() => (setTimerPaused(false))} onClick={() => { setTimerDisabled(true); setItemPos(parseFloat(props.content.indexOf(item))) }} className={(item === currentItem) ? 'selected' : ''}>
              <div className="progress" style={{ marginRight: `${(1.0 - (itemPos % 1.0)) * 100.0}%` }} />
              <span className="navigation-title">{item.navigation_title}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="column-2">
        <ul className="content">
          {props.content.map((item) => (
            <li key={item.navigation_title} className={(item === currentItem) ? 'selected' : ''}>
              <img className="case-study-image" src={item.image} alt="" />
              <h2 className="title">{item.title}</h2>
              <div className="content" onMouseEnter={() => (setTimerPaused(true))} onMouseLeave={() => (setTimerPaused(false))} dangerouslySetInnerHTML={{ __html: item.content }} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
export default ContentCarousel
