class RichTextFieldHelper {
  constructor(element) {
    this.element = element
  }

  configure() {
    if (this.element.dataset.disableAttachments === 'true') {
      this.hideFileTools()
      this.disableFileDragAndDrop()
    }
    if (this.element.dataset.disabled === 'true') {
      this.disableEditor()
    }
  }

  hideFileTools() {
    const trixToolbar = this.element.querySelector('trix-toolbar')
    if (!trixToolbar) { return }

    trixToolbar.querySelector('[data-trix-button-group="file-tools"]').style.display = 'none'
  }

  disableFileDragAndDrop() {
    const trixElement = this.element.querySelector('trix-editor')
    trixElement.addEventListener('trix-file-accept', (e) => {
      e.preventDefault();
    });
  }

  disableEditor() {
    const trixElement = this.element.querySelector('trix-editor')
    const trixToolbar = this.element.querySelector('trix-toolbar')
    trixElement.contentEditable = false
    trixToolbar?.querySelectorAll('button')?.forEach((button) => { button.disabled = true })
    this.element.querySelector(`#${trixElement.getAttribute('input')}`).disabled = true
  }
}

export default RichTextFieldHelper
