import { Controller } from '@hotwired/stimulus';
import jQuery from 'jquery';
import select2 from 'select2';

select2();

export default class extends Controller {
  static values = { closeOnSelect: Boolean }

  connect() {
    const containerEl = jQuery(this.element)
    const enableSearch = containerEl.attr('data-search') === 'true'
    const placeholder = containerEl.attr('data-placeholder')
    const el = jQuery(this.element).find('select')
    const options = {
      width: '100%',
      theme: 'scout',
      dropdownCssClass: ':all:',
      closeOnSelect:
      this.closeOnSelectValue,
      dropdownParent: this.element,
      placeholder
    }
    let isUnselecting = false
    if (!enableSearch) {
      options.minimumResultsForSearch = -1
    }
    el.on('select2:select', (e) => {
      const event = new CustomEvent('form-component-modified', { detail: { id: e.params.data.id }, bubbles: true })
      this.element.dispatchEvent(event)

      const bubbleEvent = new Event('change', { bubbles: true })
      this.element.dispatchEvent(bubbleEvent)
    })
    el.select2(options)
    el.on('select2:unselecting', () => {
      isUnselecting = true
    })
    el.on('select2:opening', (e) => {
      if (isUnselecting === true) {
        e.preventDefault()
        isUnselecting = false
      }
    })

    el.on('select2:open', () => {
      document.querySelector('.select2-container--open .select2-search__field').focus();
    });
  }

  disconnect() {
    if (jQuery(this.element).find('select').hasClass('select2-hidden-accessible')) {
      jQuery(this.element).find('select').select2('destroy')
    }
  }

  clearSilently() {
    jQuery(this.element).find('select').val(null).trigger('change', { silent: true })
  }

  disableOption(optionId) {
    jQuery(this.element).find(`option[value=${optionId}]`).attr('disabled', 'disabled')
    this.refreshDropdown()
  }

  refreshDropdown() {
    const selectElement = jQuery(this.element).find('select')
    selectElement.select2('close')
    selectElement.select2('open')
  }

  enableOption(optionId, options = { refreshDropdown: true }) {
    jQuery(this.element).find(`option[value=${optionId}]`).removeAttr('disabled')
    if (options.refreshDropdown) {
      this.refreshDropdown()
    }
  }

  closeDropdown() {
    const selectElement = jQuery(this.element).find('select')
    selectElement.select2('close')
  }

  resetDropdownToDefault() {
    const selectElement = jQuery(this.element).find('select')
    selectElement.val(null).trigger('change')
  }
}
