import React from 'react';

const VendorCollectionCard = ({
  id, title, description, imageClass, selectedVendorCollections, setSelectedVendorCollections
}) => {
  const onClick = () => {
    if (selectedVendorCollections.includes(id)) {
      const newSelectedVendors = selectedVendorCollections.filter((otherId) => otherId !== id)
      setSelectedVendorCollections(newSelectedVendors)
    } else {
      const newSelectedVendors = selectedVendorCollections.concat([id])
      setSelectedVendorCollections(newSelectedVendors)
    }
  }
  const isChecked = selectedVendorCollections.includes(id)

  return (
    <button className="vendor-collection-card" role="checkbox" type="button" aria-checked={isChecked} onClick={onClick}>
      <div className="image">
        <i className={imageClass} />
      </div>
      <div className="body">
        <h2 className="title">{title}</h2>
        <p className="description">{description}</p>
      </div>
      <div className={`selected-image ${isChecked ? '' : 'hidden'}`}><i className="icon fa-solid fa-check" /></div>
    </button>
  )
}

export default VendorCollectionCard
