import qs from 'qs'

import queryString from 'query-string';

class WatchlistConfig {
  constructor() {
    if (WatchlistConfig._instance) {
      return WatchlistConfig._instance
    }
    WatchlistConfig._instance = this;

    this.pageUrl = window.location.href
    const { protocol, host } = window.location
    this.rootUrl = `${protocol}//${host}`
    this.pageQuery = queryString.parse(window.location.search)
  }

  likeAnnouncementUrl() {
    return queryString.stringifyUrl({ url: `${this.rootUrl}/user_announcement_likes` })
  }

  unlikeAnnouncementUrl(id) {
    return queryString.stringifyUrl({ url: `${this.rootUrl}/announcements/${id}/unlike` })
  }

  watchlistUrl({
    feedTypeSlug,
    dataProviderSlug,
    savedFilterId,
    filteredSlugs = [],
    page,
    loadedAt,
    lockedFilters,
    renderingOptions
  }) {
    const query = {}
    if (feedTypeSlug) {
      query.filter ||= {}
      query.filter.feed_type = feedTypeSlug
    }
    if (dataProviderSlug) {
      query.filter ||= {}
      query.filter.watched_data_provider = dataProviderSlug
    }
    if (savedFilterId) {
      query.filter ||= {}
      query.filter.saved_filter_id = savedFilterId
    }
    if (filteredSlugs.length > 0) {
      query.filter ||= {}
      query.filter.content_scopes = filteredSlugs
    }
    if (lockedFilters) {
      query.filter ||= {}
      query.filter.locked_filters = lockedFilters
    }
    if (renderingOptions) {
      query.rendering_options = renderingOptions
    }
    if (page) {
      query.page = { number: page.number }
    }
    if (loadedAt) {
      query.loaded_at = loadedAt
    }

    return `${this.rootUrl}/feed_data${qs.stringify(query, { addQueryPrefix: true, arrayFormat: 'brackets' })}`
  }

  createSeenWatchlistItemsUrl() {
    return `${this.rootUrl}/seen_watchlist_items`
  }

  createSeenAllWatchlistItemsUrl() {
    return `${this.rootUrl}/seen_all_watchlist_items`
  }

  bulkCreateUserAnnouncementViewsUrl() {
    return queryString.stringifyUrl({ url: `${this.rootUrl}/user_announcement_views/bulk_create` })
  }

  watchlistItemUrl(itemType, itemId) {
    return `${this.rootUrl}/feed_item?item_type=${itemType}&item_id=${itemId}`
  }

  watchlistItemShareableUrl(itemType, itemId) {
    return `${this.rootUrl}/feed?item_type=${itemType}&item_id=${itemId}`
  }
}

export default new WatchlistConfig()
