import { Controller } from '@hotwired/stimulus';

const mergeUrlParams = (params) => {
  const urlParams = new URLSearchParams(document.location.search)
  const url = new URL(document.location)
  Object.keys(params).forEach((key) => {
    urlParams.append(key, params[key])
  })
  url.search = urlParams.toString()
  return url
}

export default class extends Controller {
  static targets = ['userEmails', 'providerDatasets', 'providerUpcomingDatasets'];

  loadUserEmails(event) {
    const url = mergeUrlParams({
      replace: this.userEmailsTarget.dataset.replaceUsing,
      organisation_id: event.detail.id
    })
    this.userEmailsTarget.setAttribute('src', url.toString())
  }

  loadProviderDatasets(event) {
    const datasetsUrl = mergeUrlParams({
      replace: this.providerDatasetsTarget.dataset.replaceUsing,
      provider_id: event.detail.id
    })
    const upcomingDatasetsUrl = mergeUrlParams({
      replace: this.providerUpcomingDatasetsTarget.dataset.replaceUsing,
      provider_id: event.detail.id
    })
    this.providerDatasetsTarget.setAttribute('src', datasetsUrl.toString())
    this.providerUpcomingDatasetsTarget.setAttribute('src', upcomingDatasetsUrl.toString())
  }
}
