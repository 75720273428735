import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  cancel(e) {
    e.currentTarget
      .dispatchEvent(
        new CustomEvent('carousel:navigateTo', { detail: { slide: 0 }, bubbles: true })
      )
  }
}
