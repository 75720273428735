import './m-buyside-picks-select-your-availability.scss';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import PropTypes from 'prop-types';
import pluralize from 'pluralize'
import {
  setFormState, toggleAllowSlot
} from '../../OneToOne/buysidePickProcessSlice';
import vendorPicksConfig from '../../OneToOne/vendorPicksConfig';
import TrackingContainer from '../TrackingContainer'
import { selectSelectedCards } from '../../OneToOne/vendorPicksSlice'
import SubmitPicksButton from './SubmitPicksButton';

dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

const regionMap = vendorPicksConfig.regionMap()

const apacPicked = (cards) => (
  cards.findIndex(
    (card) => card.picked_by_buyside && card.vendor_profile?.region === 'apac'
  ) >= 0
)
const createFilterSpec = (cards, buyerRegion) => {
  const apacEurope = { apac: true, europe: true }
  const apacUs = { apac: true, us: true }
  const europeUs = { europe: true, us: true }

  // First level represents Buyer selected region, second level represents Vendor selected region
  // Buyers are guaranteed to have selected a region, vendors may have not completed a profile, so
  // we provide a fallback option in `noVendorRegion`
  const regionMeetingMapping = {
    APAC: {
      apac: [apacEurope, apacUs],
      europe: [apacEurope],
      us: [apacUs],
      noVendorRegion: [apacEurope, apacUs]
    },
    Europe: {
      apac: [apacEurope],
      europe: [europeUs],
      us: [europeUs],
      noVendorRegion: [europeUs]
    },
    US: {
      apac: [apacUs],
      europe: [europeUs],
      us: [europeUs],
      noVendorRegion: [europeUs]
    }
  }

  if (cards.findIndex((card) => card.picked_by_buyside) === -1) {
    return [europeUs]
  }

  return Array.from(cards.reduce((acc, card) => {
    if (!card.picked_by_buyside) {
      return acc
    }
    const vendorRegion = card.vendor_profile?.region || 'noVendorRegion'

    const compatibleTimezones = regionMeetingMapping[buyerRegion][vendorRegion]

    compatibleTimezones.forEach((tz) => acc.add(tz))

    return acc
  }, new Set()))
}

function filterSlots(cards, region, slots) {
  const filterSpec = createFilterSpec(cards, region)
  return slots.filter((slot) => (
    filterSpec.findIndex((spec) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(spec)) {
        if (slot[key] !== spec[key]) { return false }
      }
      return true
    }) >= 0
  ))
}

/**
 * The select your availability page for the buyside one to one process
 * @param props
 * @param {String} props.previousState The state of the form if the back button is clicked
 * @returns {JSX.Element}
 * @constructor
 */
const SelectYourAvailabilityPage = (props) => {
  const picked = useSelector(selectSelectedCards)
  const slots = useSelector((state) => state.buysidePickProcess.pickProcess.meeting_slots)
  const cards = useSelector((state) => state.data.cardsData)
  const { allowedSlotIds, region } = useSelector((state) => state.buysidePickProcess)
  const dispatch = useDispatch()
  const timeZone = regionMap[region].timezone
  const filteredSlots = filterSlots(cards, region, slots);
  const isApacPicked = apacPicked(cards)
  const groupedSlots = filteredSlots.reduce((acc, slot) => {
    const convertedSlot = {
      id: slot.id,
      meeting_at: dayjs.utc(slot.meeting_at).tz(timeZone),
      apac: slot.apac,
      us: slot.us,
      europe: slot.europe
    }
    const dayText = convertedSlot.meeting_at.format('dddd Do MMMM')
    acc[dayText] ||= []
    acc[dayText].push(convertedSlot)
    return acc
  }, {})

  return (
    <div className="m-buyside-picks-select-your-availability-page">
      <div className="layout-columns">
        <div className="column column--1">
          <div className="availability-container">
            <h2>Virtual Meeting Availability</h2>
            <p>
              Virtual meetings will be scheduled during the dates and times shown.
            </p>
            <ul className="hints">
              <li className="meeting-slots">
                <i className="icon fa-solid fa-clock" />
                Multiple meetings can be scheduled during each availability slot
              </li>
              {isApacPicked && (
                <li className="apac with-picks">
                  <i className="icon" />
                  APAC friendly time slots added as you have an Asia Pacific (APAC) vendor selected
                </li>
              )}
              {!isApacPicked && region === 'APAC' && (
                <li className="apac no-picks">
                  <i className="icon" />
                  APAC friendly time slots added as you have an Asia Pacific (APAC) vendor selected
                </li>
              )}
            </ul>
            <div className="toolbar">
              <div className="actions">
                <button className="back" type="button" onClick={() => dispatch(setFormState(props.previousState))}>
                  Back
                </button>
                <TrackingContainer
                  action="Save availability"
                >
                  <SubmitPicksButton filteredSlots={filteredSlots} nextState={props.nextState} />
                </TrackingContainer>
              </div>
              <p className="meetings"><span className="meetings__count">{picked.length}</span> vendor {pluralize('meeting', picked.length)}</p>
            </div>
          </div>
        </div>
        <div className="column column--2">
          <div className="slot-selection">
            <p className="instructions">Deselect any times that are <span className="bold">not suitable</span></p>
            {Object.entries(groupedSlots).map((entry) => (
              <fieldset key={entry[0]}>
                <legend>{entry[0]}</legend>
                <div className="day">
                  {entry[1].map((slot) => (
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                    <div key={slot.id} className={`slot-selector ${allowedSlotIds.indexOf(slot.id) < 0 ? 'unselected' : ''}`} onClick={() => dispatch(toggleAllowSlot(slot.id))}>
                      <label htmlFor={`slot-${slot.id}`}>
                        {slot.meeting_at.format('h.mm a ')}
                        <span className="timezone">({regionMap[region].timezone_label})</span>
                      </label>
                      {slot.apac && <span className="apac-label">APAC</span>}
                      <input
                        type="checkbox"
                        value={slot.id}
                        readOnly
                        id={`slot-${slot.id}`}
                        checked={allowedSlotIds.indexOf(slot.id) >= 0}
                      />
                      <i className={`slot-checked fa-duotone fa-solid ${allowedSlotIds.indexOf(slot.id) >= 0 ? 'fa-check-circle' : 'fa-times-circle'} slot-checked-${allowedSlotIds.indexOf(slot.id) >= 0}`} />
                    </div>
                  ))}
                </div>
              </fieldset>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
SelectYourAvailabilityPage.propTypes = {
  previousState: PropTypes.string.isRequired
}
export default SelectYourAvailabilityPage;
