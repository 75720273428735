import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  processNewRow(event) {
    const { index, nodeToAdd } = event.detail
    nodeToAdd.querySelectorAll('*[name]').forEach((node) => {
      this.replacePlaceholder(node, index)
    })

    nodeToAdd.querySelectorAll('trix-editor').forEach((node) => {
      this.replaceTrixPlaceholder(node, index)
    })
  }

  removeOrHideRow(event) {
    const node = event.detail.nodeToRemove
    const idElement = node.querySelector('.forms--eav-fields-component > input[type=hidden][value][name$="[id]"]:not([value=""])')
    if (idElement) {
      event.preventDefault()
      const baseName = idElement.name.replace(/\[id\]$/, '')
      const element = document.createElement('input')
      element.setAttribute('type', 'hidden')
      element.setAttribute('name', `${baseName}[_destroy]`)
      element.setAttribute('value', '1')
      node.appendChild(element)
      node.classList.add('hidden')
      this.dispatchModifiedEvent()
    }
  }

  replacePlaceholder(el, index) {
    el.name = el.name.replace(/\[index_placeholder\]/, `[${index}]`)
    el.id = el.id.replace(/_index_placeholder/, `_${index}`)
  }

  replaceTrixPlaceholder(el, index) {
    el.setAttribute('input', el.getAttribute('input').replace(/_index_placeholder/, `_${index}`))
    el.id = el.id.replace(/_index_placeholder/, `_${index}`)
  }

  dispatchModifiedEvent() {
    const customEvent = new CustomEvent('form-component-modified', { detail: { value: 'Current state not calculated in nested record fields yet' }, bubbles: true })
    this.element.dispatchEvent(customEvent)
  }
}
