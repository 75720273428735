import { Controller } from '@hotwired/stimulus';
import friendlyOutletsMixin from '../../concerns/friendly_outlets_mixin';

const friendlyOutlets = {
  'new-message-component-form': 'ai--new-message-component--form--form'
}

export default class extends friendlyOutletsMixin(Controller, friendlyOutlets) {
  setInteractionInProgressStatus(event) {
    if (event.detail.inProgress) {
      this.newMessageComponentFormOutlet.disableSending()
    } else {
      this.newMessageComponentFormOutlet.enableSending()
    }
  }
}
