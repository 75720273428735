import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    slide: { type: Number, default: 0 },
    forwardNavigationDisabled: Boolean
  }

  static targets = ['navigationButton']

  slideChangedListener({ detail: { slide } }) {
    this.slideValue = slide
  }

  changeSlide({ target: { value: slide } }) {
    this.dispatch('carousel:navigateTo', { detail: { slide }, prefix: false })
  }

  slideValueChanged() {
    this.navigationButtonTargets.forEach((button) => {
      const buttonSlideValue = parseInt(button.value, 10)

      button.setAttribute('data-active', buttonSlideValue === this.slideValue)

      const isForwardNavigationButton = buttonSlideValue > this.slideValue
      button.disabled = this.forwardNavigationDisabledValue && isForwardNavigationButton
    })
  }
}
