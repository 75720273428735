import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
      searching: { type: Boolean, default: false },
    }

    searchingValueChanged(newValue) {
      this.dispatch('searching-changed', { detail: { searching: newValue } })
    }
}
