import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['state', 'container'];

  connect() {
    this.setState({ params: { id: this.containerTarget.dataset.default } })
  }

  setState(e) {
    this.stateTargets.forEach((state) => {
      state.classList.toggle('hidden', (state.dataset.id !== e.params.id))
    })
  }
}
