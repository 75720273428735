import './m-buyside-picks-location-selection-page.scss';
import React from 'react'
import dayjs from 'dayjs';

import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { setFormState } from '../../OneToOne/buysidePickProcessSlice';
import TrackingContainer from '../TrackingContainer'
import ToggleInput from '../Shared/ToggleInput'
import { formattedDateRange } from '../../helpers/dateHelpers'
import useLocationPreferences from '../../hooks/OneToOne/useLocationPreferences';
import { getPickProcess } from '../../OneToOne/VendorPicksFilter'

/**
 * The location selection page in the one 2 one buyside pick process
 * @param props
 * @param {String} props.nextState The state of the form when Save & Continue is clicked
 * @param {String} props.previousState The state of the form when Back is clicked
 * @returns {JSX.Element}
 * @constructor
 */
const LocationSelectionPage = (props) => {
  const dispatch = useDispatch()
  const {
    contactInPersonAttendance,
    contactVirtualAttendance,
    isInvalidPreference,
    locationError,
    toggleContactInPersonAttendance,
    toggleContactVirtualAttendance,
  } = useLocationPreferences()

  const pickProcess = useSelector(getPickProcess)
  const pickProcessEventDate = pickProcess.event_date
  const inPersonDisplayDate = pickProcess.in_person_display_date
  const inPersonDisplayAddress = pickProcess.in_person_display_address
  const virtualMeetingsStart = pickProcess.virtual_meetings_start
  const virtualMeetingsEnd = pickProcess.virtual_meetings_end

  const virtualDisplayDate = () => {
    if (dayjs(virtualMeetingsStart).isValid() && dayjs(virtualMeetingsEnd).isValid()) {
      return formattedDateRange(virtualMeetingsStart, virtualMeetingsEnd)
    }
    return null
  }

  return (
    <div className="m-buyside-picks-location-selection-page">
      <div className="layout-columns">
        <div className="column column--1">
          <h2>I’d like to attend...</h2>
          <div className="locations">
            <div className="location">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="in-person">
                <div className="iconContainer in-person"><i className="icon fa-regular fa-id-badge" /></div>
                <div className="body">
                  <h3>In-person meetings</h3>
                  <time className="date" dateTime={pickProcessEventDate}>{inPersonDisplayDate}</time>
                  <p className="address">{inPersonDisplayAddress}</p>
                </div>
                <ToggleInput id="in-person" className="switch" onClick={toggleContactInPersonAttendance} checked={contactInPersonAttendance} />
              </label>
            </div>
            <div className="location">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="virtual">
                <div className="iconContainer virtual"><i className="icon fa-regular fa-laptop" /></div>
                <div className="body">
                  <h3>Virtual meetings</h3>
                  <p className="date">{virtualDisplayDate()}</p>
                </div>
                <ToggleInput id="virtual" className="switch" onClick={toggleContactVirtualAttendance} checked={contactVirtualAttendance} />
              </label>
            </div>
          </div>
          {isInvalidPreference ? (
            <div className="errors-component field-errors">
              <ul>
                <li>
                  {locationError}
                </li>
              </ul>
            </div>
          ) : (
            <div className="bottom-toolbar">
              <TrackingContainer action="Save location">
                <button className="submit" type="button" onClick={() => dispatch(setFormState(props.nextState))} disabled={isInvalidPreference}>
                  Save & Continue
                  <i className="icon fa-solid fa-arrow-right" />
                </button>
              </TrackingContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

LocationSelectionPage.propTypes = {
  nextState: PropTypes.string.isRequired
}

export default LocationSelectionPage
