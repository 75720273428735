import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  openUrl(event) {
    event.preventDefault()

    const screenWidth = window.innerWidth
    const screenHeight = window.innerHeight
    const { url } = event.currentTarget.dataset

    if (screenWidth <= 768) {
      window.open(url, '_blank')
    } else {
      const width = 600
      const height = 400
      const left = (screenWidth - width) / 2
      const top = (screenHeight - height) / 2
      const popupParams = `width=${width}, height=${height}, top=${top}, left=${left}`

      window.open(url, 'Share', popupParams)
    }
  }
}
