import './auto_reply_card.scss';
import React from 'react'

/**
 * The auto reply card is used to display auto reply messages which are currently
 * generated by the front end (injected into the data in the slice) as it is
 * expected for these to be eventually be under the control of the backend
 * @param {String} message
 * @returns {JSX.Element}
 * @constructor
 */
const AutoReplyCard = ({ message }) => (
  <div className="auto-reply-card" data-card-type="auto-reply">
    <p className="body">
      {message.body}
    </p>
  </div>
)
export default AutoReplyCard
