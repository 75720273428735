import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  activateActions() {
    this.element.elements.forEach((control) => {
      if (control.type === 'submit') {
        control.disabled = false
      }
    })
  }
}
