import { Controller } from '@hotwired/stimulus';
import { Chart } from 'chart.js/auto';
import { debounce, kebabCase } from 'lodash';

const rgbaColorToString = ({
  red, green, blue, alpha
}) => `rgba(${red}, ${green}, ${blue}, ${alpha})`

export default class extends Controller {
  static targets = ['canvas']

  static values = {
    rgbColors: Array,
    backgroundAlpha: Number,
    scoreBackgroundAlpha: Number,
    labels: Array,
    chartScores: Array,
    scoreMappings: String
  }

  connect() {
    this.scoreMappings = JSON.parse(this.scoreMappingsValue)
    this.renderChart();
  }

  lookupTooltip(label, score) {
    return this.scoreMappings[label][score]
  }

  renderChart() {
    const smartphoneCanvasWidth = 550

    const isSmartphone = (chart) => chart.width <= smartphoneCanvasWidth

    const debouncedResize = debounce((chart) => {
      chart.resize();
    }, 200)

    const labelPosition = (index, chartArea, count = 6, offsetAngle = 300, padding = 70) => {
      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY = (chartArea.top + chartArea.bottom) / 2;
      const radius = Math.min(centerX - chartArea.left, centerY - chartArea.top) + padding;
      const angle = ((index / count) * 360) + offsetAngle;
      const angleInRadians = angle * (Math.PI / 180);

      const x = Math.round(radius * Math.cos(angleInRadians));
      const y = Math.round(radius * Math.sin(angleInRadians));

      return { x, y };
    }

    const rgbColors = this.rgbColorsValue.map(([red, green, blue]) => ({ red, green, blue }))
    const scoreBackgroundAlpha = this.scoreBackgroundAlphaValue

    const ratingChart = new Chart(this.canvasTarget, {
      plugins: [
        {
          id: 'renderLabel',
          afterDraw(chart) {
            const { chartArea } = chart;
            const smartphone = isSmartphone(chart)
            const printing = window.matchMedia('print').matches
            const adjustedPadding = chart.data.datasets.length === 4 ? 90 : 70;
            const padding = smartphone ? 10 : printing ? 40 : adjustedPadding
            const dataPointsContainer = document.getElementById('dataLabelsContainer');
            dataPointsContainer.innerHTML = '';

            chart.data.labels.forEach((label, index) => {
              const offset = labelPosition(
                index, chartArea, chart.data.datasets[0].data.length, 300, padding
              )
              const center = {}
              center.x = (chartArea.left + chartArea.right) / 2;
              center.y = (chartArea.top + chartArea.bottom) / 2;

              // For smartphones shift labels
              if (smartphone) {
                const shift = offset.y === 0 ? 30 : 50
                offset.x += (Math.sign(offset.x) * shift)
              }

              const dataPointElement = document.createElement('div');
              dataPointElement.classList.add('scores');
              dataPointElement.setAttribute('id', kebabCase(label))
              dataPointElement.style.left = `${center.x + offset.x}px`;
              dataPointElement.style.top = `${center.y + offset.y}px`;
              chart.data.datasets.forEach((dataset, datasetIndex) => {
                const firstDiv = document.createElement('div');
                firstDiv.classList.add('score');
                firstDiv.style.backgroundColor = rgbaColorToString({
                  ...rgbColors[datasetIndex],
                  alpha: scoreBackgroundAlpha
                })
                firstDiv.innerHTML = dataset.data[index];
                dataPointElement.appendChild(firstDiv);
              })

              // Add element for the label
              const dataLabelElement = document.createElement('div');
              dataLabelElement.classList.add('scores-label');
              dataLabelElement.innerHTML = `${label}`;
              dataLabelElement.style.left = `${center.x + offset.x}px`;
              dataLabelElement.style.top = `${center.y + offset.y}px`;

              dataPointsContainer.appendChild(dataPointElement);
              dataPointsContainer.appendChild(dataLabelElement);
            });
          }
        }
      ],
      type: 'radar',
      data: {
        labels: this.labelsValue,
        foo: 'bar',
        datasets: this.chartScoresValue.map((chartScores, index) => {
          const rgbColor = rgbColors[index]

          return {
            label: true,
            data: chartScores,
            backgroundColor: rgbaColorToString({ ...rgbColor, alpha: this.backgroundAlphaValue }),
            borderColor: rgbaColorToString({ ...rgbColor, alpha: 1 }),
            borderWidth: 1,
            pointStyle: 'circle',
            pointRadius: 6,
            pointHoverRadius: 8,
            pointBackgroundColor: rgbaColorToString({ ...rgbColor, alpha: 1 }),
            pointBorderColor: rgbaColorToString({ ...rgbColor, alpha: 1 }),
            pointHoverBorderColor: rgbaColorToString({ ...rgbColor, alpha: 0.3 }),
            pointBorderWidth: 1,
            pointHoverBorderWidth: 1
          }
        })
      },

      options: {
        animation: {
          duration: 0
        },
        layout: {
          padding(chart) {
            return isSmartphone(chart.chart) ? 20 : 80
          }
        },
        // NOTE: The aspect ratio needs to be kept in sync with the stylesheets
        // - app/components/datasets/neudata_rating_component/_index.scss
        //
        // This is to prevent the resize bug experienced in #4841
        //
        // A condensed description of the bug is:
        // * Chartjs reads the actual height/width of the parent container,
        // * The parent container is trying to fit content
        //   ("auto" is inherited from the overall page),
        // * Rounding errors cause a subtle change in the new height,
        // * The process now is stuck in a loop.
        //
        // This can be fixed by setting the height of the parent container.
        // In-order to render nicely, the height needs to respect the aspect ratio.
        // This is done in CSS
        aspectRatio: 1.3, // READ NOTE!
        responsive: true,
        onResize(chart) {
          debouncedResize(chart);
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            intersect: true,
            caretPadding: 8,
            mode: 'nearest',
            xAlign: 'center',
            yAlign: 'bottom',
            callbacks: {
              title: (() => null),
              label: (({ label, formattedValue }) => (
                this.lookupTooltip(label, parseInt(formattedValue, 10)))
              )
            }
          },
          renderLabel: {}
        },
        scales: {
          r: {
            angleLines: {
              display: false
            },
            border: {
              dash: [3, 3]
            },
            grid: {
              color: 'rgba(205, 205, 205, 1)',
              circular: true
            },
            pointLabels: {
              display: false,
            },
            ticks: {
              display: false,
              min: 0,
              max: 10,
              stepSize: 2,
              lineWidth: 1
            },
            suggestedMin: 0,
            suggestedMax: 10,
            startAngle: 30
          }
        }
      }
    });

    if (window.matchMedia) {
      const mediaQueryList = window.matchMedia('print');
      mediaQueryList.addEventListener('change', (event) => {
        if (event.matches) {
          ratingChart.resize();
        }
      });
    }
  }
}
