import { Controller } from '@hotwired/stimulus';
import TickerSearch from './TickerSearch';
import DataProviderSearch from './DataProviderSearch';
import ESGSearch from './ESGSearch';
import FacetSearch from './FacetSearch';
import getCSRFToken from '../../javascript/src/getCSRFToken';

export default class extends Controller {
    static targets = ['input', 'clearButton', 'formGroup', 'form', 'dropdownListItem', 'searchButton', 'dpResult']

    static values = {
      algoliaId: String,
      algoliaKey: String,
      algoliaTickerIndex: String,
      algoliaDataProviderIndex: String,
      algoliaEsgIndex: String,
      algoliaFacetIndex: String,
      autoSearchResultsPath: String,
      providerWatcherIds: Array,
      providerWatchersPath: String
    }

    connect() {
      if (this.inputTarget.value.length) {
        this.showClearFormButton()
      }
      this.configureSearches()
    }

    configureSearches() {
      this.dataProviderSearch = new DataProviderSearch(this);
      this.tickerSearch = new TickerSearch(this);
      this.esgSearch = new ESGSearch(this);
      this.facetSearch = new FacetSearch(this);
    }

    clearSearchFieldText() {
      this.inputTarget.value = '';
      this.inputTarget.focus();
      this.hideClearFormButton();
    }

    searchTextChanged(event) {
      if (event.target.value.length > 0) {
        this.showClearFormButton();
        this.getProviderWatcherIds();
      } else {
        this.hideClearFormButton();
      }
    }

    hideClearFormButton() {
      this.formGroupTarget.classList.remove('show-clear-form-button')
    }

    showClearFormButton() {
      this.formGroupTarget.classList.add('show-clear-form-button')
    }

    getProviderWatcherIds() {
      fetch(this.providerWatchersPathValue)
        .then((response) => response.json())
        .then((response) => {
          this.providerWatcherIdsValue = response
        })
    }

    dpResultTargetConnected(element) {
      const providerID = parseInt(element.dataset.value, 10)
      if (this.providerWatcherIdsValue.includes(providerID)) {
        element.classList.add('watched')
        element.classList.remove('unwatched')
      } else {
        element.classList.add('unwatched')
        element.classList.remove('watched')
      }
    }

    toggleProviderWatcher(event) {
      const providerID = parseInt(event.target.dataset.value, 10)
      const data = {
        provider_watcher: {
          data_provider_id: providerID
        }
      }
      let requestMethod = 'POST'
      if (this.providerWatcherIdsValue.includes(providerID)) {
        requestMethod = 'DELETE'
      }
      fetch(this.providerWatchersPathValue, {
        method: requestMethod,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCSRFToken()
        },
        body: JSON.stringify(data)
      })
        .then(() => {
          this.getProviderWatcherIds()
        })
    }

    providerWatcherIdsValueChanged() {
      this.dpResultTargets.forEach((element) => {
        this.dpResultTargetConnected(element)
      })
    }

    choose(event) {
      event.preventDefault()
      const elementsToRemove = []
      const elementsToReEnable = []
      const { searchParams } = new URL(event.currentTarget.href);
      this.addElementsToForm(searchParams, elementsToRemove);
      this.disableElementsInForm(searchParams, elementsToReEnable);
      this.setSearchFieldState(searchParams);
      this.formTarget.requestSubmit(this.searchButtonTarget)
      this.restoreForm(elementsToRemove, elementsToReEnable);
    }

    setSearchFieldState(searchParams) {
      if (!searchParams.has('q')) {
        this.inputTarget.disabled = true
        this.inputTarget.value = ''
      }
    }

    restoreForm(elementsToRemove, elementsToReEnable) {
      this.inputTarget.disabled = false
      elementsToRemove.forEach((element) => {
        this.formTarget.removeChild(element)
      })
      elementsToReEnable.forEach((element) => {
        element.disabled = false
      })
    }

    disableElementsInForm(searchParams, elementsToReEnable) {
      this.formTarget.elements.forEach((input) => {
        if (searchParams.has(input.name)) {
          return
        }

        elementsToReEnable.push(input)
        input.disabled = true
      })
    }

    addElementsToForm(searchParams, elementsToRemove) {
      searchParams.forEach((value, key) => {
        const existingInput = this.formTarget.querySelector(`input[name="${key}"]`);
        if (existingInput) {
          existingInput.value = value
          return
        }

        const newElement = document.createElement('input')
        newElement.type = 'hidden'
        newElement.name = key
        newElement.value = value
        elementsToRemove.push(newElement)
        this.formTarget.appendChild(newElement)
      })
    }

    closeDropdown() {
      this.dropdownListItemTargets.forEach((element) => {
        element.style.display = 'none'
      })
    }

    beforeRequest() {
      this.dispatch('beforeRequest', { prefix: 'scoutSearch' })
    }

    beforeResponse() {
      this.dispatch('beforeResponse', { prefix: 'scoutSearch' })
    }
}
