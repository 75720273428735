import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    window.addEventListener('turbo:before-fetch-request', (event) => {
      if (event.detail.url.pathname === window.location.pathname) {
        this.scrollToView()
      }
    });
  }

  scrollToView() {
    this.element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}
