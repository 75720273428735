import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'

import { getCanShareWatchlist, getDataProviderFilterOptionBySlug, getDataProviderSummaryBySlug } from '../../Watchlist/selectors'
import WatcherMenu from './WatcherMenu'
import useClickOutside from '../../hooks/useClickOutside'
import AddButton from './AddButton'
import RemoveButton from './RemoveButton'

const ToolbarWatch = () => {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const dataProviderSlugParam = searchParams.get('dataProvider')
  const canShare = useSelector(getCanShareWatchlist)
  const domRef = useRef(null)

  const nullDataProviderSummary = { personal: false, shared: false }
  const dataProviderSummary = useSelector(
    getDataProviderSummaryBySlug(dataProviderSlugParam)
  ) || nullDataProviderSummary

  const dataProviderFilterOption = useSelector(
    getDataProviderFilterOptionBySlug(dataProviderSlugParam)
  )

  useClickOutside(domRef, () => setIsOpen(false))

  if (dataProviderSlugParam === null) {
    return null
  }

  if (canShare) {
    return (
      <div ref={domRef} className="toolbar-watch">
        <button type="button" aria-label="watch" onClick={() => setIsOpen(!isOpen)} className="dropdown-button icon add dp-icon fa-solid fa-signal-stream" />
        {
          isOpen
          && (
            <div className="toolbar-watch__content">
              <WatcherMenu
                dataProviderId={dataProviderFilterOption.id}
                isPersonal={dataProviderSummary.personal}
                isShared={dataProviderSummary.shared}
              />
            </div>
          )
        }
      </div>
    )
  }

  return (
    <>
      <AddButton />
      { dataProviderSummary.personal && <RemoveButton dataProviderId={dataProviderSummary.id} /> }
    </>
  )
}

export default ToolbarWatch
