import { Controller } from '@hotwired/stimulus';

const errorStates = ['normal', 'warning', 'error']

export default class extends Controller {
  static targets = ['statusMessage', 'statusIcon']

  static values = {
    errorState: {
      type: Number,
      default: 0
    },
    complete: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    this.startTimer()
  }

  disconnect() {
    this.stopTimer()
  }

  startTimer() {
    this.timer = setTimeout(this.advanceErrorState.bind(this), 30000)
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  advanceErrorState() {
    if (this.errorStateValue < errorStates.length - 1) {
      this.errorStateValue += 1
      this.startTimer()
    }
  }

  errorStateValueChanged() {
    if (!this.hasStatusMessageTarget) { return }

    switch (errorStates[this.errorStateValue]) {
      case 'warning':
        this.setErrorStatus('warning', 'Taking longer than usual...')
        break
      case 'error':
        this.setErrorStatus('error', 'Something went wrong. Please try again later.')
        this.dispatch('inProgress', { detail: { inProgress: false } })
        break
      default:
        this.removeErrorStatus()
    }
  }

  completeValueChanged() {
    if (this.completeValue) {
      this.dispatch('inProgress', { detail: { inProgress: false } })
      this.stopTimer()
    } else {
      this.dispatch('inProgress', { detail: { inProgress: true } })
    }
  }

  setErrorStatus(status, message) {
    this.statusMessageTarget.textContent = message
    this.setStatusIcon(status);
    this.element.classList.remove('warning', 'error')
    this.element.classList.add(status)
  }

  setStatusIcon(status) {
    this.statusIconTarget.classList.forEach((cls) => {
      if (cls !== 'icon') {
        this.statusIconTarget.classList.remove(cls);
      }
    });
    if (status === 'error') {
      this.statusIconTarget.classList.add('fa')
      this.statusIconTarget.classList.add('fa-exclamation-circle')
    }
  }

  removeErrorStatus() {
    if (this.statusMessageTarget.classList.contains('warning') || this.statusMessageTarget.classList.contains('error')) {
      this.statusMessageTarget.textContent = ''
      this.element.classList.remove('warning', 'error')
    }
  }
}
