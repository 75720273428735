import React, { useEffect } from 'react'
import {
  Provider, useDispatch, useSelector, useStore
} from 'react-redux'
import { createMemoryHistory } from 'history'
import Swal from 'sweetalert2';
import Modal from '../Shared/Modal'
import CardList from './CardList'
import watchlistStore from '../../Watchlist/watchlistStore'
import { configure, load, setAllSeen } from '../../Watchlist/watchlistSlice'
import { selectFeedItems } from '../../Watchlist/selectors'

const ModalHeaderContent = () => {
  const onModalClose = () => {
    Swal.close()
  }

  return (
    <div className="header">
      <i className="icon fa-solid fa-sparkles" />
      <div className="content">
        <h2 className="title">Platform Updates</h2>
        <p className="subtitle">Latest updates on the Neudata platform</p>
      </div>
      <button type="button" className="close" onClick={onModalClose}><i className="icon fa-solid fa-times" aria-label="Close dialog" title="Close dialog" /></button>
    </div>
  )
}

const ModalContent = ({ store }) => (
  <Provider store={store}>
    <div className="m-watchlist-modal">
      <ModalHeaderContent />
      <CardList disableViewedCardsMonitor showSinceUserCreation />
    </div>
  </Provider>
)

const ProductUpdatesModalInner = () => {
  const dispatch = useDispatch()
  const store = useStore()
  const cardsLoading = useSelector((state) => state.data.cardsLoading)
  const userCreationDate = useSelector((state) => state.data.userCreationDate)
  const items = useSelector(selectFeedItems)
  const unseenItemsSinceUserCreation = items.filter((i) => (
    (i.item.publication_date > userCreationDate) && !i.item.seen
  ))

  useEffect(() => {
    if (cardsLoading === null) {
      dispatch(load({ initialLoad: true }))
    } else if (!cardsLoading && unseenItemsSinceUserCreation.length > 0) {
      Modal.fire({
        html: ModalContent({ store }),
        width: '746px',
        showCloseButton: false,
        showCancelButton: false,
        showConfirmButton: false,
        background: 'transparent',
        position: 'middle',
        padding: '0px',
        showClass: {
          popup: 'modal-fade-in'
        },
        hideClass: {
          popup: 'modal-fade-out'
        },
        customClass: {
          htmlContainer: 'product-update-modal-container',
          popup: 'modal-popup product-update'
        }
      }).then((result) => {
        if (!result.isDismissed) { return }

        dispatch(setAllSeen({
          dataProviderSlug: null,
          feedTypeSlug: 'product-updates',
          filteredSlugs: []
        }))
      })
    }
  })

  return null
}

/**
 * A modal window to show product updates
 * @param props
 * @param {Object} props.configuration - See props for watchlist/FeedApp
 * @returns {JSX.Element}
 * @constructor
 */
const ProductUpdateModal = ({ configuration }) => {
  const history = createMemoryHistory({ initialEntries: ['/?feedType=product-updates'] })
  const store = watchlistStore(history)
  store.dispatch(configure(configuration))
  return (
    <Provider store={store}>
      <ProductUpdatesModalInner />
    </Provider>
  )
}

export default ProductUpdateModal
