import { useRef, useEffect } from 'react'

const useOnUnmount = (callback) => {
  const fnRef = useRef(callback)

  fnRef.current = callback

  useEffect(() => () => fnRef.current(), [])
}

export default useOnUnmount
