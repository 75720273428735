import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  frameMissing(event) {
    if (event.detail.response.headers.get('Force-Unknown-Turbo-Frame-To-Top') === 'true') {
      const decoder = new TextDecoder()
      const customResponse = {
        statusCode: event.detail.response.status,
        responseHTML: ''
      };
      const reader = event.detail.response.body.getReader()
      reader.read().then(function processText({ done, value }) {
        if (done) {
          Turbo.visit(event.detail.response.url, { action: 'replace', response: customResponse })
          return
        }

        customResponse.responseHTML += decoder.decode(value);
        reader.read().then(processText);
      });
      event.preventDefault();
    }
  }
}
