import React from 'react';

class PostForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: '',
      charCount: 0
    };
    this.handleContentChange = this.handleContentChange.bind(this);
    this.disabled = this.disabled.bind(this);
  }

  handleContentChange(e) {
    const newValue = e.target.value
    // eslint-disable-next-line no-undef
    const links = twttr.txt.extractUrls(newValue)
    let charCount = newValue.length
    this.setState({ content: newValue || '' });

    links.forEach((link) => {
      charCount -= link.length
      charCount += this.props.linkLength
    })

    this.setState({
      charCount
    })
  }

  disabled() {
    return (this.state.charCount <= 0 || this.state.charCount > this.props.contentLimit)
  }

  render() {
    const visibilityOptions = this.props.visibilityOptions.map(
      // eslint-disable-next-line react/no-array-index-key
      (option, index) => <option key={index} value={option.value}>{option.text}</option>
    );

    return (
      <div className="field">
        <select name="announcement[visibility]" className="audience" defaultValue="DEFAULT">
          <option disabled value="DEFAULT">Who can see your post?</option>
          {visibilityOptions}
        </select>
        <textarea
          id="announcement-content"
          name="announcement[content]"
          value={this.state.content}
          onChange={this.handleContentChange}
          placeholder="Share news about your new product launches/updates, new hires or case studies with Neudata's investment manager clients"
        />
        <div className="actions">
          <div className={`announcement-counter ${this.disabled() ? 'disabled' : ''}`}>
            {this.state.charCount}
            /
            {this.props.contentLimit}
          </div>
          <div className="buttons">
            <div className="post-button">
              <input
                type="submit"
                value="Post"
                name="commit"
                data-disable-with="Posting"
                className="button"
                disabled={this.disabled()}
              />
            </div>
          </div>
        </div>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="instructions" dangerouslySetInnerHTML={{ __html: this.props.instructions }} />
      </div>
    );
  }
}
export default PostForm
