import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import axiosRails from '../../axiosRails';

import { setDataProvidersOrganisationId } from '../../Chat/conversationSlice'
import DataProviderOrganisationFilter from '../DataProvidersOrganisationFilter'
import useAsyncEffect from '../../hooks/useAsyncEffect';
import { isVendorParticipant } from '../../utils/Chat/participants';

export default () => {
  const [organisations, setOrganisations] = useState([])
  const { currentParticipant } = useSelector((state) => state.app)
  const isVendor = !!currentParticipant && isVendorParticipant(currentParticipant)

  const dispatch = useDispatch()

  useAsyncEffect(async (isMounted) => {
    axiosRails.get('/chat/conversations/data_providers_organisations', { headers: { Accept: 'application/vnd.api+json' } })
      .then((response) => {
        const mappedResponses = response.data.data.map((dataProviderOrganisation) => ({
          id: dataProviderOrganisation.id,
          logoUrl: dataProviderOrganisation.attributes.avatar.url,
          name: dataProviderOrganisation.attributes.company
        }))

        if (isMounted.current) {
          setOrganisations(mappedResponses)
        }
      })
  }, [setOrganisations])

  const onChange = (event) => {
    dispatch(setDataProvidersOrganisationId(event?.id || null))
  }

  if (isVendor) {
    return null
  }

  return (
    <DataProviderOrganisationFilter
      organisations={organisations}
      onChange={onChange}
    />
  )
}
