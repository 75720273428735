import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static values = {
    selectedChoices: {
      type: Array,
      default: undefined
    },
    formAuthenticityToken: String,
    modalPath: String
  }

  static targets = ['modal', 'hiddenFields', 'clearAllLink', 'selectionsComponent', 'count']

  expand(event) {
    event.preventDefault()
    fetch(this.modalPathValue, {
      method: 'GET',
      cache: 'no-store',
      credentials: 'same-origin',
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      }
    }).then((response) => {
      if (response.ok) {
        return response.text()
      }

      return 'An error has occurred!'
    }).then((html) => {
      Turbo.renderStreamMessage(html)
    })
  }

  applySelectedChoices(event) {
    this.selectionsController.applySelectedChoices(event)
    this.selectedChoicesValue = event.detail.choices
  }

  selectedChoicesValueChanged(value, previousValue) {
    this.updateHiddenFields()
    this.updateClearAllLink()
    this.updateCount()
    if (previousValue) {
      this.dispatch('changed', { detail: { choices: this.selectedChoicesValue } })
    }
  }

  updateHiddenFields() {
    this.hiddenFieldsTarget.innerHTML = ''
    this.selectedChoicesValue.forEach((choice) => {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = `${choice.name}[]`
      input.value = choice.value
      this.hiddenFieldsTarget.appendChild(input)
    });
  }

  updateCount() {
    const count = this.selectedChoicesValue.length;
    this.countTarget.textContent = count > 0 ? `(${count})` : ''
  }

  updateClearAllLink() {
    this.clearAllLinkTarget.classList.toggle('hidden', this.selectedChoicesValue.length === 0)
  }

  clearAll(event) {
    event.preventDefault()
    this.selectionsController.clearAll()
  }

  get selectionsController() {
    return this.application.getControllerForElementAndIdentifier(
      this.selectionsComponentTarget, this.selectionsComponentTarget.dataset.controller
    )
  }
}
