import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dropdown', 'dropdownItem', 'searchField']

  selectChoice(event) {
    const { key } = event.params
    this.clearSearch()
    this.dispatch('selectChoice', { detail: { key } })
  }

  filterDropdown(event) {
    const searchText = event.target.value.toLowerCase();
    this.dropdownItemTargets.forEach((item) => {
      item.classList.toggle('hidden', searchText === '' || !item.innerText.toLowerCase().includes(searchText))
    })
    this.updateDropdownVisibility()
  }

  updateDropdownVisibility() {
    this.dropdownTarget.classList.toggle('empty', this.dropdownItemTargets.every((item) => item.classList.contains('hidden')))
  }

  hideDropdown(event) {
    if (!this.dropdownTarget.contains(event.relatedTarget)) {
      this.dropdownTarget.classList.toggle('empty', true)
    }
  }

  clearSearch() {
    this.dropdownItemTargets.forEach((item) => item.classList.add('hidden'))
    this.updateDropdownVisibility()
    this.searchFieldTarget.value = ''
  }
}
