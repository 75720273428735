import React from 'react'
import { Provider, useSelector } from 'react-redux'
import Card from './Card';
import { selectItem } from '../../Watchlist/selectors'

const FeedItemModalContent = ({ id, type }) => {
  const item = useSelector(selectItem({ id, type }))
  return (
    <div className="m-watchlist-modal">
      <Card
        key={`${item.type}-${item.id}`}
        watchlistItem={item}
      />
    </div>
  )
}

const FeedItemModalContentApp = ({ store, id, type }) => (
  <Provider store={store}>
    <FeedItemModalContent id={id} type={type} />
  </Provider>
)
export default FeedItemModalContentApp
