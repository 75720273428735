import React, { useRef } from 'react'
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import InfiniteScroll from 'react-infinite-scroll-component';
import AllCaughtUpCard from './AllCaughtUpCard'
import { itemShown, loadNextPage } from '../../Watchlist/watchlistSlice'
import { selectFeedItems } from '../../Watchlist/selectors'
import ViewedCardsMonitor from './ViewedCardsMonitor';
import Card from './Card';
import CompressedCards from './CompressedCards';
import AnnouncementCompressedCard from './CompressedCards/AnnouncementCompressedCard';
import DatasetCard from './DatasetCard';
import DDQCard from './DDQCard';
import NewsflowArticleCard from './NewsflowArticleCard';
import ShimmerCard from './ShimmerCard';
import ComplianceAlertCard from './ComplianceAlertCard'
import ProductUpdateCard from './ProductUpdateCard'

dayjs.extend(isToday)
dayjs.extend(isYesterday)

const cardShown = (dispatch) => (itemOrItems) => {
  const myItems = itemOrItems instanceof Array ? itemOrItems : [itemOrItems]
  myItems.forEach((watchlistItem) => {
    dispatch(itemShown({
      seen: watchlistItem.item.seen,
      id: watchlistItem.item.id,
      type: watchlistItem.item.type,
      dataProviderIds: watchlistItem.data_providers.map((d) => (d.id))
    }))
  })
}

const cardRenderer = (dispatch) => (watchlistItem) => (
  <Card key={`${watchlistItem.item.type}-${watchlistItem.item.id}`} watchlistItem={watchlistItem} onShow={cardShown(dispatch)} />
)

const compressedCardRenderer = (dispatch) => (storedItems, CardFn) => (
  <CardFn key={`${storedItems[0].type}-${storedItems[0].id}`} onShow={cardShown(dispatch)} />
)

function GroupedList(showSinceUserCreation) {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.data.cardsLoading)
  const error = useSelector((state) => state.data.cardsError)
  const endOfFeed = useSelector((state) => state.data.endOfFeed)
  const disableCompression = useSelector((state) => state.contentTypeFilter.singleFilterSelected)
  const userCreationDate = useSelector((state) => state.data.userCreationDate)
  const feedItems = useSelector(selectFeedItems)

  let items
  if (showSinceUserCreation === true) {
    items = feedItems.filter((item) => item.item.publication_date > userCreationDate)
  } else {
    items = feedItems
  }

  const unseenItems = items.filter((watchlistItem) => !watchlistItem.item.seen)
  const seenItems = items.filter((watchlistItem) => watchlistItem.item.seen)

  return (
    [
      unseenItems.length > 0 ? <li key="unseen" className="grouped-seen unseen">Unseen</li> : null,
      CompressedCards({
        key: 'unseenItems',
        collection: unseenItems,
        card: cardRenderer(dispatch),
        compressedCard: compressedCardRenderer(dispatch),
        disableCompression
      }),
      !loading && !error && (seenItems.length > 0 || endOfFeed) ? <AllCaughtUpCard key="allCaughtUp" /> : null,
      seenItems.length > 0 ? <li key="seen" className="grouped-seen seen">Seen</li> : null,
      CompressedCards({
        key: 'seenItems',
        collection: seenItems,
        card: cardRenderer(dispatch),
        compressedCard: compressedCardRenderer(dispatch),
        disableCompression
      })
    ]
  )
}

function UngroupedList(items) {
  const dispatch = useDispatch()
  const disableCompression = useSelector((state) => state.contentTypeFilter.singleFilterSelected)
  return (
    CompressedCards({
      collection: items,
      card: cardRenderer(dispatch),
      compressedCard: compressedCardRenderer(dispatch),
      disableCompression
    })
  )
}

/**
 * Renders a list of watch list cards
 *
 * To take control of the rendering of the list itself, a child component can be
 * passed in as a child component, otherwise the standard GroupedList will be
 * used, giving grouping of seen/unseen and an all caught up card.
 *
 * For ungrouped, use a child component like this
 * import { UngroupedList } from './CardList';
 * ....
 * <CardList>
 *   { UngroupedList() }
 * </CardList>
 *
 * @return {React.ComponentElement} The content to render
 */

function CardList({ disableViewedCardsMonitor = false, showSinceUserCreation = false, children }) {
  const loading = useSelector((state) => state.data.cardsLoading)
  const error = useSelector((state) => state.data.cardsError)
  const userCreationDate = useSelector((state) => state.data.userCreationDate)
  const feedItems = useSelector(selectFeedItems)

  let items
  if (showSinceUserCreation === true) {
    items = feedItems.filter((item) => item.item.publication_date > userCreationDate)
  } else {
    items = feedItems
  }

  const endOfFeed = useSelector((state) => state.data.endOfFeed)
  const dispatch = useDispatch()
  let loadingClass
  if (loading === null) {
    loadingClass = 'not-loaded'
  } else {
    loadingClass = loading ? 'loading' : 'loaded'
  }

  const watchlistContainerRef = useRef();
  return (
    <div id="watchlist-card-list" className={`watchlist-card-list ${loadingClass} ${error ? 'error' : ''}`} ref={watchlistContainerRef}>
      <InfiniteScroll
        dataLength={items.length}
        next={() => dispatch(loadNextPage())}
        style={{ overflow: 'initial' }}
        hasMore={!endOfFeed}
        scrollableTarget="watchlist-card-list"
        loader={(
          <ul className="cards-list">
            <ShimmerCard />
            <ShimmerCard />
          </ul>
        )}
        // endMessage={JSX to render when the feed has been exhausted}
      >
        <ul className="cards-list">
          <ViewedCardsMonitor
            disabled={disableViewedCardsMonitor}
            watchlistContainerRef={watchlistContainerRef}
            cardTypes={[
              Card, AllCaughtUpCard, AnnouncementCompressedCard, DatasetCard, DDQCard,
              NewsflowArticleCard, ComplianceAlertCard, ProductUpdateCard
            ]}
          >
            { !children ? GroupedList(showSinceUserCreation) : children }
          </ViewedCardsMonitor>
        </ul>
      </InfiniteScroll>
    </div>
  )
}
export { UngroupedList }
export default CardList
