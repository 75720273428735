import './m-buyside-picks-vendor-collections-page.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniq } from 'lodash'
import pluralize from 'pluralize'
import { setFormState } from '../../OneToOne/buysidePickProcessSlice';
import { idempotentPick, setSelectedVendorCollections, selectSelectedCards } from '../../OneToOne/vendorPicksSlice';
import VendorCollectionCard from './VendorCollectionCard'
import { postTrackingEvent } from '../../picksTracking'
import TrackingContainer from '../TrackingContainer'
import { getVendorCollections, allVendors, getNumberOfVendors } from '../../OneToOne/VendorPicksFilter';
import useSearchParam from '../../hooks/useSearchParam';

const VendorCollectionsPage = (props) => {
  const [vendorCollections, setVendorCollections] = useState([])
  const [isManualSelection, setIsManualSelection] = useState(true)

  const vendors = useSelector(allVendors)
  const dispatch = useDispatch()
  const vendorCollectionsData = useSelector(getVendorCollections);
  const numberOfVendors = useSelector(getNumberOfVendors)
  const picked = useSelector(selectSelectedCards)
  const numberOfSelectedVendors = useMemo(() => {
    const selectedCollections = vendorCollectionsData.filter((collection) => (
      vendorCollections.includes(collection.id)
    ))
    const dupVendorIds = selectedCollections.map((c) => c.vendorIds).flat()
    const vendorIds = [...new Set(dupVendorIds)]
    return vendorIds.length
  }, [vendorCollectionsData, vendorCollections])

  const stageParam = useSearchParam('stage')

  useEffect(() => {
    setVendorCollections([])
  }, [stageParam])

  const onDirectSubmit = () => {
    dispatch(setSelectedVendorCollections({ ids: [] }))
    setVendorCollections([])

    dispatch(setFormState(props.nextState))
  }

  const onQuickPickSubmit = () => {
    const selectedVendorCollections = vendorCollectionsData.filter(
      ({ id }) => vendorCollections.includes(id)
    )

    selectedVendorCollections.forEach(({ title }) => {
      postTrackingEvent({
        action: 'Collection selection',
        object: title
      })
    })
    const findVendorById = (id) => vendors.find((vendor) => id === vendor.id)
    const pickedVendorIds = picked.map((vendor) => vendor.id)
    const collectionVendorIds = selectedVendorCollections.flatMap((({ vendorIds }) => vendorIds))
    const collectionVendorIdsToPick = collectionVendorIds.filter(
      (id) => !pickedVendorIds.includes(id)
    )

    uniq(collectionVendorIdsToPick).map(findVendorById).forEach(({ title }) => {
      postTrackingEvent({
        action: 'Collection pick',
        object: title
      })
    })

    dispatch(setSelectedVendorCollections({ ids: vendorCollections }))
    dispatch(setFormState(props.nextState))
    const collections = vendorCollectionsData.filter((data) => vendorCollections.includes(data.id))
    collections.forEach((collection) => {
      collection.vendorIds.forEach((id) => {
        dispatch(idempotentPick(id))
      })
    })
  }

  const vendorCollectionCards = vendorCollectionsData.map((data) => (
    <VendorCollectionCard
      key={data.id}
      selectedVendorCollections={vendorCollections}
      setSelectedVendorCollections={setVendorCollections}
      id={data.id}
      title={data.title}
      description={data.description}
      imageClass={data.image_class}
    />
  ))

  const handleSelectionChange = (event) => {
    if (event.target.value === 'manual-selection') {
      setIsManualSelection(true)
    } else if (event.target.value === 'collection-selection') {
      setIsManualSelection(false)
    }
  }

  return (
    <div className="m-buyside-picks-vendor-collections-page">
      <div className="container">
        <div className="collections">
          <div className="vendor-cards">
            <h2 className="page-title">How would you like to choose your meetings?</h2>
            <label className="selection-type" htmlFor="manual-selection">
              <input
                type="radio"
                id="manual-selection"
                name="selection-preference"
                value="manual-selection"
                checked={isManualSelection}
                onChange={handleSelectionChange}
              />
              <div className="selection-type__description">
                <h2>Select manually from a list</h2>
                {numberOfVendors > 0 && (<p>Browse and filter {numberOfVendors} vendors</p>)}
              </div>
            </label>

            <label className="selection-type" htmlFor="collection-selection">
              <input
                type="radio"
                id="collection-selection"
                name="selection-preference"
                value="collection-selection"
                checked={!isManualSelection}
                onChange={handleSelectionChange}
              />
              <div className="selection-type__description">
                <h2>Pick from collections of related vendors</h2>
                <p>You can still add and remove vendors manually</p>
              </div>
            </label>
            {!isManualSelection && (
            <div className="vendor-collection-cards">
              {vendorCollectionCards}
            </div>
            )}
          </div>
        </div>
        <div className="button-container">
          <TrackingContainer
            action="Go to vendor selection"
            track={isManualSelection}
          >
            <button
              className="quick-picks-continue"
              type="submit"
              role="link"
              onClick={isManualSelection ? onDirectSubmit : onQuickPickSubmit}
            >
              {
              isManualSelection && 'Continue'
            }
              {
              !isManualSelection && (
                `Continue with ${numberOfSelectedVendors} vendor ${pluralize('meeting', numberOfSelectedVendors)}`
              )
            }
              <i className="icon fa-solid fa-arrow-right" />
            </button>
          </TrackingContainer>
        </div>
      </div>
    </div>
  )
}

export default VendorCollectionsPage
