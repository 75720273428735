/* global Handlebars */

import instantsearch from 'instantsearch.js'
import { connectAutocomplete } from 'instantsearch.js/cjs/connectors'
import { algoliasearch } from 'algoliasearch'

export default class AutoCompleteSearch {
  constructor(indexName, controller) {
    if (typeof algoliasearch !== 'undefined' && typeof instantsearch !== 'undefined') {
      this.registerHandlebarsHelper();
      this.client = algoliasearch(controller.algoliaIdValue, controller.algoliaKeyValue);
      this.search = instantsearch({
        indexName,
        searchClient: this.client
      });

      this.container = controller.element.querySelector('.m-search-autocomplete') || document.createElement('div')
      this.container.classList.add('m-search-autocomplete')
      this.container.setAttribute('data-search-component--search-component-target', 'dropdown')

      controller.element.appendChild(this.container)
      this.autosearchResultsPath = controller.autosearchResultsPathValue

      this.input = controller.inputTarget;
      // Define a instance of the algolia autocomplete
      this.tickerAutocomplete = connectAutocomplete(
        this.bindAutocomplete.bind(this)
      );
      // mount the autocomplete widget in the container
      this.mountAutocomplete();
      this.search.start();
    }
  }

  registerHandlebarsHelper() {
    Handlebars.registerHelper('searchHighlight', (attribute, hit) => new Handlebars.SafeString(
      instantsearch.highlight({ attribute, hit })
    ))
  }

  bindAutocomplete(renderOptions, isFirstRender) {
    const {
      indices, currentRefinement, refine, widgetParams
    } = renderOptions;

    if (isFirstRender) {
      const ul = document.createElement('ul');
      ul.id = this.searchListId();
      ul.setAttribute('data-search-component--search-component-target', 'dropdownListItem')

      this.input.addEventListener('input', (e) => {
        if (e.currentTarget.value === '') {
          ul.style.display = 'none';
        } else {
          refine(e.currentTarget.value);
        }
      });

      this.input.parentElement.querySelector('i.clear-form').addEventListener('click', () => {
        ul.style.display = 'none';
      });

      widgetParams.container.appendChild(ul);
    }

    const resultsList = widgetParams.container.querySelector(`#${this.searchListId()}`);

    this.input.value = currentRefinement;
    if (currentRefinement !== '') {
      resultsList.innerHTML = indices
        .map(this.renderListItem.bind(this))
        .join('');
    }

    resultsList.style.display = resultsList.children.length === 0 ? 'none' : 'block';
  }

  mountAutocomplete() {
    this.search.addWidget(
      this.tickerAutocomplete({
        container: this.container
      })
    );
  }
}
