import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['table']

  connect() {
    window.addEventListener('turbo:before-fetch-request', (event) => this.setLoadingTable(event));
  }

  setLoadingTable(event) {
    if (event.detail.url.pathname === window.location.pathname) {
      this.tableTarget.classList.add('loading');
    }
  }
}
