import React from 'react'
import { useSelector } from 'react-redux'
import { selectAllSelectedFilters } from '../../OneToOne/vendorPicksSlice'
import TrackingContainer from '../TrackingContainer'

const VendorCardProfileButton = (props) => {
  const allSelectedFilters = useSelector(selectAllSelectedFilters)

  if (props.item.vendor_profile == null) {
    return null
  }

  return (
    <TrackingContainer
      action={props.open ? 'Hide profile' : 'Show profile'}
      object={props.item.title}
      extraInfo={JSON.stringify(allSelectedFilters)}
    >
      <button type="button" onClick={props.onClick} className={props.open ? 'collapse-button' : 'expand-button'}><i className={`icon fa-solid ${props.open ? 'fa-chevron-up' : 'fa-chevron-down'}`} /> {props.open ? 'Collapse' : 'Expand'} profile</button>
    </TrackingContainer>
  )
}

export default VendorCardProfileButton
