import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const useSearchParam = (paramName) => {
  const location = useLocation()
  const [paramValue, setParamValue] = useState(null)

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    const value = searchParams.get(paramName)

    setParamValue(value)
  }, [location, paramName])

  return paramValue
}

export default useSearchParam
