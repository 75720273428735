import React from 'react'

const ExpandableContent = ({
  title, children, expanded, toggleExpanded
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleExpanded()
    }
  }

  return (
    <div className="expandable-content">
      <div
        role="button"
        tabIndex="0"
        className="expandable-content__header"
        onClick={toggleExpanded}
        onKeyDown={handleKeyDown}
      >
        <h1 className="expandable-content__title">{title}</h1>
        <i className={`fa-solid fa-chevron-${expanded ? 'up' : 'down'}`} />
      </div>
      {expanded && <div className="expandable-content__content">{children}</div>}
    </div>
  )
}

export default ExpandableContent
