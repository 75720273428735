import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import TripleDotMenu from '../../Shared/TripleDotMenu'
import WatcherMenu from '../WatcherMenu'
import { getCanShareWatchlist, getCurrentTab } from '../../../Watchlist/selectors'
import useSearchParam from '../../../hooks/useSearchParam'
import RemoveButton from '../RemoveButton'
import UnseenCount from '../UnseenCount'

const FilterRow = ({ dataProviderSummary }) => {
  const {
    id,
    slug,
    logo_src: logoSrc,
    name,
    unseen,
    personal,
    shared
  } = dataProviderSummary

  const canShareWatchlist = useSelector(getCanShareWatchlist)
  const dataProviderParam = useSearchParam('dataProvider')

  const isSelected = dataProviderParam === slug

  const currentTab = useSelector(getCurrentTab)

  const classes = classNames('filter-row', { selected: isSelected })

  return (
    <div data-testid={`watchlistItem${id}`} className={classes}>
      <div className="link-container">
        <Link to={`/feed?feedType=${currentTab}&dataProvider=${slug}`}>
          <div className="image-container">
            <img src={logoSrc} alt="" />
          </div>
          <span className="value" data-testid="watched-vendor-name">{name}</span>
        </Link>
      </div>
      {
        !canShareWatchlist && <RemoveButton dataProviderId={id} />
      }

      <UnseenCount count={unseen} />

      {
        canShareWatchlist
          && (
          <TripleDotMenu>
            <WatcherMenu
              dataProviderId={id}
              isPersonal={personal}
              isShared={shared}
            />
          </TripleDotMenu>
          )
      }
    </div>
  )
}

export default FilterRow
