import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['publishListingButton', 'deferredPublishListingButton', 'deferredSwitch']

  selectMajorChange() {
    this.element.querySelector('#major-update-options').classList.remove('hidden')
    this.element.querySelector('#major-update-summary').classList.remove('hidden')
    this.element.querySelector('#minor-update-summary').classList.add('hidden')
    this.element.querySelector('#minor-update-options').classList.add('hidden')

    this.setSubmitDisabledState()
    this.setWhatHappensNextVisible()
    this.blankInputFields()
  }

  selectMinorChange() {
    this.element.querySelector('#major-update-options').classList.add('hidden')
    this.element.querySelector('#minor-update-options').classList.remove('hidden')

    this.element.querySelector('#major-update-summary').classList.add('hidden')
    this.element.querySelector('#minor-update-summary').classList.remove('hidden')

    this.setSubmitDisabledState()
    this.setWhatHappensNextVisible()
    this.setSummaryPublishInfo()
    this.disableDeferred()
  }

  setDeferredPublicationVisiblity() {
    const deferralDataElement = this.element.querySelector('#deferral_date')
    if (this.element.querySelector('input[id=deferred_publication_enabled]').checked) {
      deferralDataElement.classList.remove('hidden')
    } else {
      deferralDataElement.classList.add('hidden')
      this.element.querySelector('input[type=date]').value = ''
    }

    this.setSubmitDisabledState()
    this.setSummaryPublishInfo()
  }

  setPublishButtonVisibility() {
    if (this.deferredSwitchTarget.querySelector('input').checked) {
      this.enableDeferred();
    } else {
      this.disableDeferred();
    }
  }

  enableDeferred() {
    this.deferredPublishListingButtonTarget.classList.remove('hidden')
    this.publishListingButtonTarget.classList.add('hidden')
  }

  disableDeferred() {
    this.deferredPublishListingButtonTarget.classList.add('hidden')
    this.publishListingButtonTarget.classList.remove('hidden')
  }

  setSubmitDisabledState() {
    if (this.isValidForm()) {
      this.publishListingButtonTarget.classList.remove('disabled')
      this.deferredPublishListingButtonTarget.classList.remove('disabled')
    } else {
      this.publishListingButtonTarget.classList.add('disabled')
      this.deferredPublishListingButtonTarget.classList.add('disabled')
    }
  }

  setWhatHappensNextVisible() {
    this.element.querySelector('.modal--form-component__summary .header h2').classList.remove('not-visible')
  }

  blankInputFields() {
    this.resetDeferredPublicationFields()
    this.element.querySelector('textarea').value = ''
  }

  resetDeferredPublicationFields() {
    const deferredPublicationEnabled = this.element.querySelector('input[id=deferred_publication_enabled]')
    if (deferredPublicationEnabled === null) {
      return
    }
    deferredPublicationEnabled.checked = false
    this.element.querySelector('input[type=date]').value = ''
    this.setDeferredPublicationVisiblity()
    this.setSummaryPublishInfo()
  }

  isValidForm() {
    const minorUpdateInput = this.element.querySelector('input[id=minor]')
    const isMinorUpdate = (minorUpdateInput && minorUpdateInput.checked) || false

    return isMinorUpdate || !this.isDeferredPublication() || this.isValidDeferredPublicationDate()
  }

  disableForm() {
    const publicationDetailsForm = this.element.querySelector('.modal--form-component__form')
    publicationDetailsForm.classList.add('modal--form-component__form-disabled')
  }

  isDeferredPublication() {
    const deferredPublicationEnabled = this.element.querySelector('input[id=deferred_publication_enabled]')
    if (deferredPublicationEnabled === null) {
      return false
    }

    return deferredPublicationEnabled.checked
  }

  isValidDeferredPublicationDate() {
    const deferredPublicationDateInput = this.element.querySelector('input[type=date]')
    const deferredPublicationDate = Date.parse(deferredPublicationDateInput.value)
    const minDeferredPublicationDate = Date.parse(deferredPublicationDateInput.min)
    return !Number.isNaN(deferredPublicationDate)
      && deferredPublicationDate >= minDeferredPublicationDate
  }

  setSummaryPublishInfo() {
    const deferredPublicationSummaryElements = this.element.querySelectorAll('.deferred_publication_summary')

    if (deferredPublicationSummaryElements.length === 0) {
      return
    }

    const normalPublicationSummaryElements = this.element.querySelectorAll('.normal_publish_summary')

    if (this.isDeferredPublication() && this.isValidDeferredPublicationDate()) {
      deferredPublicationSummaryElements.forEach((deferredPublicationSummaryElement) => {
        deferredPublicationSummaryElement.classList.remove('hidden')
        deferredPublicationSummaryElement.querySelector('.deferred_publication_date').innerText = this.element.querySelector('input[type=date]').value
      })

      normalPublicationSummaryElements.forEach((normalPublicationSummaryElement) => {
        normalPublicationSummaryElement.classList.add('hidden')
      })
    } else {
      deferredPublicationSummaryElements.forEach((deferredPublicationSummaryElement) => {
        deferredPublicationSummaryElement.classList.add('hidden')
      })
      normalPublicationSummaryElements.forEach((normalPublicationSummaryElement) => {
        normalPublicationSummaryElement.classList.remove('hidden')
      })
    }
  }

  neudataOptInChanged({ target }) {
    document.querySelectorAll(`input[name="${target.name}"]`).forEach((checkbox) => {
      checkbox.checked = target.checked
    })
  }
}
