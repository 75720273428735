import { Controller } from '@hotwired/stimulus'

import { every } from 'lodash';

export default class extends Controller {
  submitPick(event) {
    event.target.form.requestSubmit()
  }

  selectAllChanged({ target: { checked } }) {
    document.querySelectorAll('input[name="selected_firms[]"]').forEach((input) => { input.checked = checked });
  }

  selectedFirmChange({ target: { checked } }) {
    if (checked) {
      if (every(document.querySelectorAll('input[name="selected_firms[]"]'), (input) => input.checked)) {
        document.querySelector('input[name="select_all"]').checked = true;
      }
    } else {
      document.querySelector('input[name="select_all"]').checked = false;
    }
  }

  checkFirmSelection(event) {
    if (event.target.type === 'checkbox') {
      const selectAllContainer = document.querySelector('#select-all-pickactions')
      const firmsCheckboxes = document.querySelectorAll('input[name="selected_firms[]"]:checked')
      if (firmsCheckboxes.length > 0) {
        selectAllContainer.classList.remove('hidden');
        selectAllContainer.querySelector('#count').innerHTML = firmsCheckboxes.length;
      } else {
        selectAllContainer.classList.add('hidden');
      }
    }
  }
}
