import React, { useState, useRef } from 'react'
import useClickOutside from '../../hooks/useClickOutside'
import onKeyDown from '../../utils/onKeyDown'

export default ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)

  const toggleMenu = () => { setIsOpen((currentState) => !currentState) }
  const keyDownToggleMenu = onKeyDown(['Enter', ' '], toggleMenu)

  useClickOutside(ref, () => { setIsOpen(false) })

  return (
    <div className="triple-dot-menu" ref={ref}>
      <div className="triple-dot-menu__toggle" onClick={toggleMenu} role="button" onKeyDown={keyDownToggleMenu} tabIndex="0" aria-expanded={isOpen}>
        <div className="triple-dot-menu__dot">•</div>
        <div className="triple-dot-menu__dot">•</div>
        <div className="triple-dot-menu__dot">•</div>
      </div>
      {isOpen && (
        <div className="triple-dot-menu__menu">
          {children}
        </div>
      )}
    </div>
  )
}
