import React from 'react'
import { Provider, useSelector } from 'react-redux';
import globalStoreInstance from '../globalStoreInstance'
import { setUnseenFeedCount } from '../globalStoreSlice';

const FeedCountBadgeNumber = () => {
  const unseenFeedCount = useSelector((state) => state.global.unseenFeedCount)
  if (unseenFeedCount > 99) {
    return <div className="unseen-feed-count">99+</div>
  }
  if (unseenFeedCount > 0) {
    return <div className="unseen-feed-count">{unseenFeedCount}</div>
  }
  return ''
}

/**
 * Renders a badge showing the unseen feed count and connects to the global store for updates
 * To update, dispatch setUnseenFeedCount to the global store with the new value.
 * @param props
 * @param {Number} props.unseen The initial value for the badge
 * @returns {JSX.Element}
 * @constructor
 */
const FeedCountBadgeApp = (props) => {
  globalStoreInstance.dispatch(setUnseenFeedCount(props.unseen))
  return (
    <Provider store={globalStoreInstance}>
      <FeedCountBadgeNumber />
    </Provider>
  )
}
export default FeedCountBadgeApp
