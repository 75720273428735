import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['formField', 'hiddenFormField', 'image']

  changeFile(event) {
    this.dispatchModifiedEvent(event)
    this.hiddenFormFieldTarget.disabled = true
  }

  removeFile(event) {
    this.formFieldTarget.value = ''
    this.hiddenFormFieldTarget.disabled = false
    this.dispatchModifiedEvent(event)
    if (this.hasImageTarget) { this.imageTarget.remove() }
  }

  dispatchModifiedEvent(event) {
    const data = { detail: { value: null }, bubbles: true }
    if (event.target.files) {
      data.detail.value = { title: event.target.files[0].name }
    }

    const customEvent = new CustomEvent('form-component-modified', data)
    this.element.dispatchEvent(customEvent)
  }
}
