import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { filteredData, numSelectedFilters, clearFilters } from '../../OneToOne/VendorPicksFilter'
import TrackingContainer from '../TrackingContainer'
import { selectAllSelectedFilters } from '../../OneToOne/vendorPicksSlice'

const Description = ({ numFilters, numVendors, numDisplayedVendors }) => {
  if (numFilters > 0) {
    return (
      <p className="description">
        <span className="num">{numFilters}</span>
        <span>{` ${numFilters > 1 ? 'filters' : 'filter'} applied showing `}</span>
        <span className="num">{numDisplayedVendors}</span>
        <span> of </span>
        <span className="num">{numVendors}</span>
        <span> vendors</span>
      </p>
    )
  }
  return (
    <p className="description">
      No filters selected
    </p>
  )
}

const ClearButton = () => {
  const dispatch = useDispatch()
  const onClick = () => dispatch(clearFilters())

  return (
    <button type="button" className="clear-filters" onClick={onClick}>
      Clear Filters
    </button>
  )
}

const FilterSummaryContainer = () => {
  const numVendors = useSelector((state) => (state.data.cardsData.length))
  const numDisplayedVendors = useSelector((state) => (filteredData(state).length))
  const numFilters = useSelector(numSelectedFilters)
  const allSelectedFilters = useSelector(selectAllSelectedFilters)

  return (
    <div className="filter-summary">
      <span className="title">Filters</span>
      <Description
        numFilters={numFilters}
        numDisplayedVendors={numDisplayedVendors}
        numVendors={numVendors}
      />
      <TrackingContainer
        action="Clear filters"
        extraInfo={JSON.stringify(allSelectedFilters)}
      >
        <ClearButton />
      </TrackingContainer>
    </div>
  )
}

const FilterSummary = () => {
  const showFilters = useSelector((state) => (state.data.showFilters))
  if (showFilters) {
    return <FilterSummaryContainer />
  }
  return <></>
}

export default FilterSummary;
