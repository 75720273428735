import { useCallback, useEffect } from 'react'

const useClickOutside = (domRef, callback) => {
  const handleClickOutside = useCallback((event) => {
    if (domRef.current && !domRef.current.contains(event.target)) {
      callback()
    }
  }, [domRef, callback])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])
}

export default useClickOutside
