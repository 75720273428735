import React from 'react'
import Tippy from '@tippyjs/react'

const Tooltip = (props) => (
  <Tippy
    content={props.content}
    placement="bottom"
    disabled={props.disabled || false}
    theme={props.theme || 'translucent'}
    animation="shift-toward-subtle"
  >
    {props.children}
  </Tippy>
)
export default Tooltip
