import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['selectableOptions']

  static values = { uid: String, selectedContainerSelector: String }

  connect() {
    this.createDummyForm()
    this.reconnectInputsToDummyForm()
  }

  createDummyForm() {
    const body = document.querySelector('body')
    const form = document.createElement('form')
    form.setAttribute('id', `multi-select-form-${this.uidValue}`)
    body.append(form)
  }

  removeSelection(event) {
    this.closeDropdown()
    this.resetDropdownToDefault()
    this.enableOption(event.detail.id, { refreshDropdown: false })
  }

  addToSelected(event) {
    const optionId = event.detail.id
    if (optionId === '') {
      event.preventDefault()
      return
    }

    const option = this.selectableOptionsTarget.querySelector(`option[value="${optionId}"]`)
    const optionText = option.innerText
    this.getSelectionController().addSelectedOptionUsingTemplate(optionId, optionText)
    this.disableOption(optionId)
  }

  reconnectInputsToDummyForm() {
    const form = document.querySelector(`#multi-select-form-${this.uidValue}`)
    this.selectableOptionsTarget.querySelectorAll('select').forEach((input) => {
      input.setAttribute('form', form.id)
    })
  }

  disableOption(optionId) {
    this.selectFieldController().disableOption(optionId)
  }

  selectFieldController() {
    const selectField = this.selectableOptionsTarget.querySelector('.forms--select-field-component')
    return this.application
      .getControllerForElementAndIdentifier(selectField, selectField.dataset.controller)
  }

  enableOption(optionId, options = { refreshDropdown: true }) {
    this.selectFieldController().enableOption(optionId, options)
  }

  closeDropdown() {
    this.selectFieldController().closeDropdown()
  }

  resetDropdownToDefault() {
    this.selectFieldController().resetDropdownToDefault()
  }

  getSelectionController() {
    const selectedComponentElement = this.getSelectedComponentElement()
    return this.application
      .getControllerForElementAndIdentifier(
        selectedComponentElement,
        selectedComponentElement.dataset.controller
      )
  }

  getSelectedComponentElement() {
    if (this.selectedContainerSelectorValue) {
      return this.element
        .querySelector(this.selectedContainerSelectorValue)
          || document.querySelector(this.selectedContainerSelectorValue)
    }
    return this.element.querySelector('.forms--multi-select-field-component--selected-component')
  }
}
