import React from 'react';
import useWatcher from '../../hooks/useWatcher';
import useOnUnmount from '../../hooks/useOnUnmount';

export default ({ dataProviderId, isPersonal, isShared }) => {
  const {
    watcherStatus: personalWatcherStatus,
    toggleStatus: togglePersonalWatcherStatus,
    handleChangedStatus: handlePersonalChangedStatus
  } = useWatcher(dataProviderId, isPersonal, false)

  const {
    watcherStatus: sharedWatcherStatus,
    toggleStatus: toggleSharedWatcherStatus,
    handleChangedStatus: handleSharedChangedStatus

  } = useWatcher(dataProviderId, isShared, true)

  useOnUnmount(handlePersonalChangedStatus)
  useOnUnmount(handleSharedChangedStatus)

  return (
    <div className="watcher-menu">
      <div className="watcher-option">
        <label htmlFor="personal">
          <section>
            <h1>Personal</h1>
            <p>Only visible to you</p>
          </section>
          <input type="checkbox" id="personal" name="personal" checked={personalWatcherStatus} onChange={togglePersonalWatcherStatus} />
        </label>
      </div>
      <div className="watcher-option">
        <label htmlFor="organisation">
          <section>
            <h1>Shared watchlist</h1>
            <p>Visible to everyone in your organisation</p>
          </section>
          <input type="checkbox" id="organisation" name="organisation" checked={sharedWatcherStatus} onChange={toggleSharedWatcherStatus} />
        </label>
      </div>
    </div>
  )
}
