import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    multiple: Boolean,
    catchAll: String
  }

  static lastClicked = null

  changeValue(event) {
    // If the input field is disabled, prevent any further processing and return
    if (event.target.disabled) {
      event.preventDefault();
      return;
    }

    if (this.catchAllValue && event.target.checked) {
      this.handleCatchAll(event)
    }

    this.dispatchModifiedEvent(event);
  }

  dispatchModifiedEvent(event) {
    const customEvent = new CustomEvent('form-component-modified', { detail: { value: this.getValue(event) }, bubbles: true })
    this.element.dispatchEvent(customEvent)
  }

  getValue(event) {
    if (this.multipleValue) {
      return Array.from(this.element.querySelectorAll('input[type="checkbox"]:checked')).map((input) => input.value)
    }
    return event.target.value
  }

  handleCatchAll(event) {
    const catchAllElement = Array.from(this.element.querySelectorAll('input[type="checkbox"]')).find((el) => el.value === this.catchAllValue)
    const nonCatchAllElements = Array.from(this.element.querySelectorAll('input[type="checkbox"]')).filter((el) => el.value !== this.catchAllValue)
    const catchAllToast = this.element.querySelector('.catch-all-toast')

    if (event.target.value === this.catchAllValue) {
      nonCatchAllElements.forEach((el) => { this.uncheck(el) })
    } else if (nonCatchAllElements.every((el) => el.checked)) {
      this.check(catchAllElement)
      nonCatchAllElements.forEach((el) => { this.uncheck(el) })
      catchAllToast.classList.remove('hidden')

      setTimeout(() => {
        this.element.querySelector('.catch-all-toast').classList.add('hidden')
      }, 5000)
    } else {
      this.uncheck(catchAllElement)
    }
  }

  check(element) {
    element.checked = true;
    element.setAttribute('checked', 'checked');
  }

  uncheck(element) {
    element.checked = false;
    element.removeAttribute('checked')
  }
}
