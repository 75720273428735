import React from 'react'

const UnseenCount = ({ count }) => {
  if (count === 0) {
    return null
  }

  return (
    <span className="count" data-testid="unseenCount">{count}</span>
  )
}

export default UnseenCount
