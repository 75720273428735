import React from 'react'
import { useSelector } from 'react-redux'
import FilterRow from './FilterRow'

const FilterRows = ({ selector }) => {
  const rows = useSelector(selector)
  return rows.map(
    ({
      name, count, checkedSelector, onCheckThunk, type
    }) => (
      <FilterRow
        key={name}
        name={name}
        count={count}
        checkedSelector={checkedSelector}
        onCheckThunk={onCheckThunk}
        type={type}
      />
    )
  )
}

export default FilterRows
