import React, { forwardRef } from 'react'
import ItemShareableLink from './ItemShareableLink'
import Date from './Date'

const VendorUpdateCard = forwardRef((props, ref) => {
  const logo = props.watchlistItem.item.logo ? <div className="logo"><img className="logo-image" alt="Logo" src={props.watchlistItem.item.logo} /></div> : '';

  return (
    <li ref={ref} className="watchlist-card vendor-update">
      <div className="basic-card-details">
        <div className="info">
          <div className="summary-container">
            { logo }
            <span className="summary">
              <span>
                <span>The profile for </span>
                <span>
                  <a href={props.watchlistItem.item.data_provider_path} className="data-provider-name">
                    {props.watchlistItem.item.data_provider}
                  </a>
                </span>
                <span> was updated</span>
              </span>
              <Date date={props.watchlistItem.item.publication_date} />
            </span>
          </div>
        </div>
        <ItemShareableLink item={props.watchlistItem.item} />
      </div>
    </li>
  );
});

export default VendorUpdateCard
