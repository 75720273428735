import React from 'react'

import AttendingVendorBadge from './AttendingVendorBadge'
import NewVendorBadge from './NewVendorBadge'
import PickedByVendorBadge from './PickedByVendorBadge'
import VirtualMeetingBadge from './VirtualMeetingBadge'

const VendorBadges = ({ cardData }) => (
  <div className="tags">
    <PickedByVendorBadge cardData={cardData} />
    <NewVendorBadge cardData={cardData} />
    <AttendingVendorBadge cardData={cardData} />
    <VirtualMeetingBadge />
  </div>
)

export default VendorBadges
