import './conversation_list.scss';
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import ParticipantAvatar from './ParticipantAvatar';
import { load, setCurrentConversation } from '../../Chat/conversationSlice'
import ConversationDate from './ConversationDate';
import DataProvidersOrganisationFilter from './DataProvidersOrganisationFilter';

/**
 * The left side of the chat window - listing all of the conversations
 * and highlights the currently selected conversation
 * @returns {JSX.Element}
 * @constructor
 */
const ConversationList = ({ onSelect }) => {
  const {
    conversations,
    nextPage,
    dataProvidersOrganisationId
  } = useSelector((state) => state.data)
  const conversationId = useSelector((state) => state.router.location.query.id)
  const dispatch = useDispatch()

  useEffect(() => {
    const currentConversation = conversations.find((c) => c.id === conversationId)

    if (currentConversation) {
      dispatch(setCurrentConversation(currentConversation))
    }
  }, [conversationId, conversations])

  useEffect(() => {
    dispatch(load({ page: 1, dataProvidersOrganisationId }))
  }, [dispatch, dataProvidersOrganisationId])

  const fetchNextPage = () => {
    if (nextPage) {
      dispatch(load({ page: nextPage, dataProvidersOrganisationId }))
    }
  }

  return (
    <div id="conversation-list" className="conversation-list" data-component="chat/conversation-list">
      <DataProvidersOrganisationFilter />
      <h2 className="title"><i className="fa-solid fa-comments-alt" />Messages</h2>
      <InfiniteScroll
        dataLength={conversations.length}
        next={fetchNextPage}
        hasMore
        scrollableTarget="conversation-list"
      >
        {conversations.map((conversation) => (
          <div key={`conversation-${conversation.id}`} data-type="conversation" className={`conversation ${conversation.id.toString() === conversationId ? 'selected' : ''}`}>
            <Link to={`/chat/conversations?id=${conversation.id}`} onClick={onSelect}>

              <div className="avatar">
                <ParticipantAvatar
                  avatar={conversation.participant.avatar}
                />
              </div>
              <div className="summary">
                <span className={`unseen-messages-count ${conversation.unseen_messages_count === 0 ? 'hidden' : ''}`} data-attribute="unseen_messages_count">
                  {conversation.unseen_messages_count}
                </span>
                <p><span className="name"> {conversation.participant.name || conversation.participant.company}</span>
                  <ConversationDate conversation={conversation} />
                </p>
                <p className="subject">{conversation.subject}</p>
              </div>
            </Link>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  )
}

export default ConversationList
