import { Controller } from '@hotwired/stimulus';
import friendlyOutletsMixin from '../concerns/friendly_outlets_mixin'
import getCSRFToken from '../../javascript/src/getCSRFToken'

const friendlyOutlets = {
  'approve-button': 'reviews--approve-button-component--approve-button-component'
}

export default class extends friendlyOutletsMixin(Controller, friendlyOutlets) {
  static targets = [
    'scoutDatasetForm',
    'publishTypoChangesButton',
    'updateTypeForm',
    'updateTypes',
    'watchlistItemSummary',
    'majorUpdateInput'
  ]

  static values = {
    rooftopId: Number,
    watchlistSummary: String,
    typoPath: String
  }

  connect() {
    this.initialFormData = this.serializeScoutDatasetFields();

    if (this.hasPublishTypoChangesButtonTarget) {
      this.element.addEventListener('form-component-modified', this.setPublishTypoChangesButtonDisabledState.bind(this))
    }
  }

  setWatchlistSummary({ target: { value } }) {
    this.watchlistSummaryValue = value
  }

  setPublishTypoChangesButtonDisabledState() {
    if (this.formHasChanged()) {
      this.publishTypoChangesButtonTarget.classList.remove('disabled')
    } else {
      this.publishTypoChangesButtonTarget.classList.add('disabled')
    }
  }

  publishTypoChanges(event) {
    event.preventDefault();

    const scoutDatasetFormData = new FormData(this.scoutDatasetFormTarget)

    fetch(this.typoPathValue, {
      method: this.scoutDatasetFormTarget.method,
      body: scoutDatasetFormData,
      headers: { Accept: 'application/json' }
    }).then(this.afterSubmit.bind(this))
  }

  submit(event) {
    event.preventDefault();
    event.target.classList.add('disabled')

    const reviewFormData = new FormData(event.target)

    const updateTypeFormData = new FormData(this.updateTypeFormTarget)

    reviewFormData.set('update_type', updateTypeFormData.get('update_type'))
    reviewFormData.set('watchlist_summary', this.watchlistSummaryValue)

    if (this.formHasChanged()) {
      const scoutDatasetFormData = new FormData(this.scoutDatasetFormTarget)

      Array.from(scoutDatasetFormData.entries()).forEach(([key, value]) => {
        if (key.includes('scout_dataset')) {
          reviewFormData.append(key, value);
        }
      });
    }

    fetch(event.target.action, {
      method: event.target.method,
      body: reviewFormData,
      headers: { Accept: 'application/json' }
    }).then(this.afterSubmit.bind(this))
  }

  afterSubmit(response) {
    response.json().then((data) => {
      const redirectUrl = data.redirect_url

      const redirectCallback = () => { window.location.href = redirectUrl }

      if (response.status === 303) {
        redirectCallback()
      } else {
        this.submitComments(redirectCallback)
      }
    });
  }

  submitComments(callback) {
    const commentInputs = document.querySelectorAll('input[name*="_eav_comment"]')

    if (commentInputs.length === 0) {
      callback()
    }

    const commentsForm = document.querySelector('.publish-all form')

    const commentFormData = new FormData(commentsForm)

    commentInputs.forEach((input) => {
      commentFormData.append(input.name, input.value)
    })

    commentFormData.append('comment[rooftop_id]', this.rooftopIdValue)
    commentFormData.append('comment[entity_type]', 'ScoutDataset')
    commentFormData.append('comment[drafts]', (true))

    return fetch(commentsForm.action, {
      method: 'POST',
      body: commentFormData,
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': getCSRFToken(),
      }
    }).then(() => {
      callback()
    })
  }

  formHasChanged() {
    const currentFormData = this.serializeScoutDatasetFields();

    return JSON.stringify(this.initialFormData) !== JSON.stringify(currentFormData)
  }

  serializeScoutDatasetFields() {
    const formData = {};

    this.scoutDatasetFormTarget.elements.forEach((element) => {
      if (!element.name || !element.name.includes('scout_dataset')) return;

      formData[element.name] = this.getElementValue(element, formData);
    });

    return formData;
  }

  getElementValue(element, formData) {
    const {
      type, checked, value, tagName, multiple, selectedOptions, name
    } = element;

    if (type === 'checkbox') return this.handleCheckbox(element, formData);
    if (type === 'radio') return checked ? value : formData[name];
    if (tagName === 'SELECT') return multiple ? this.getMultipleSelectValues(selectedOptions) : value;

    return value;
  }

  handleCheckbox(element, formData) {
    const { name, checked, value } = element;

    if (name.endsWith('[]')) {
      formData[name] = formData[name] || [];
      if (checked) formData[name].push(value);
      return formData[name];
    }

    return checked;
  }

  getMultipleSelectValues(selectedOptions) {
    return Array.from(selectedOptions).map((opt) => opt.value);
  }

  setShowAllFromCheckbox(event) {
    const component = this.element.querySelector('.eav-diff--fieldsets-component')
    const controller = this.application
      .getControllerForElementAndIdentifier(component, component.dataset.controller)
    controller.setShowAllFromCheckbox(event)
  }

  updateTypeSelected() {
    this.watchlistItemSummaryTarget.classList.toggle('hidden', !this.majorUpdateInputTarget.checked)

    this.enableApprovalButton()
  }

  enableApprovalButton() {
    this.approveButtonOutlet.enable()
  }
}
