import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  connect() {
    if ($(this.element).attr('data-theme') !== 'default') { return }

    this.setInitialState()
    this.observeChanges()
  }

  setInitialState() {
    const checked = this.checkBox().prop('checked')
    if (checked) {
      this.contentElement().show()
    } else {
      this.contentElement().hide()
    }
  }

  checkBox() {
    return $(this.element.querySelector('[data-toggle-content-component-checkbox]'))
  }

  contentElement() {
    return $(this.element.querySelector('[data-toggle-content-component-content]'))
  }

  observeChanges() {
    const self = this
    this.checkBox().on('change', () => {
      self.contentElement().slideToggle('fast')
    })
  }
}
