import React, { forwardRef } from 'react'
import ItemShareableLink from './ItemShareableLink'
import Date from './Date'

const NewsflowArticleCard = forwardRef((props, ref) => {
  const logo = props.watchlistItem.item.logo ? <div className="logo"><img className="logo-image" alt="Logo" src={props.watchlistItem.item.logo} /></div> : '';

  return (
    <li ref={ref} className="watchlist-card post newsflow">
      <div className="basic-card-details">
        <div className="info">
          <div className="summary-container">
            {logo}
            <div className="summary">
              <div className="newsflow-links">
                <a href={props.watchlistItem.item.data_provider_path} className="data-provider-name">
                  {props.watchlistItem.item.data_provider}
                </a>
                <a href={props.watchlistItem.item.newsflow_url} target="_blank" rel="noreferrer" className="newsflow-link">
                  <div className="text-content">
                    <span>
                      {props.watchlistItem.item.title}
                    </span>
                  </div>
                </a>
              </div>
              <div>
                <Date date={props.watchlistItem.item.publication_date} />
                <span className="category">Newsflow</span>
              </div>
            </div>
          </div>
        </div>
        <ItemShareableLink item={props.watchlistItem.item} />
      </div>
    </li>
  );
});

export default NewsflowArticleCard
