import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['textArea']

  connect() {
    this.resizeCell()
  }

  resizeCell() {
    this.textAreaTarget.style.height = '150px'
    this.textAreaTarget.style.height = `${this.textAreaTarget.scrollHeight + 6}px`
  }
}
