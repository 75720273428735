import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.inputDefault = this.inputTarget.value;
  }

  focus() {
    this.inputTarget.selectionStart = this.inputTarget.value.length;
    this.inputTarget.selectionEnd = this.inputTarget.value.length;
    this.inputTarget.focus();
  }
}
