import { ALL_WATCHLIST_FEED_TYPE } from '../constants/watchlist'
import globalStoreInstance from '../globalStoreInstance'
import { setUnseenFeedCount } from '../globalStoreSlice'

const getWatchlistFeedCount = (store) => {
  const { feedTypeSummaries } = store.getState().data

  return feedTypeSummaries.find((summary) => summary.slug === ALL_WATCHLIST_FEED_TYPE)?.unseen
}

const updateGlobalStore = (store) => {
  let previousUnseenWatchlistFeedCount = getWatchlistFeedCount(store)
  return () => {
    const unseenFeedCount = getWatchlistFeedCount(store)
    if (unseenFeedCount !== previousUnseenWatchlistFeedCount) {
      globalStoreInstance.dispatch(setUnseenFeedCount(unseenFeedCount))
      previousUnseenWatchlistFeedCount = unseenFeedCount
    }
  }
}
export default updateGlobalStore
