import React from 'react'

const VendorDetails = ({ vendor }) => (
  <li key={vendor.id}>
    <span className="title">{vendor.title}</span>
    <div className="logo-container">
      <img className="logo" src={vendor.logo} alt="logo" />
    </div>
  </li>
)
export default VendorDetails
