import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
import axios from '../axiosRails'
import vendorPicksConfig from './vendorPicksConfig'
import { preProcessData } from '../helpers/jsonApiResponseProcessingHelpers'
import { postTrackingEvent } from '../picksTracking'

const findData = (id, state, cb) => {
  const filteredObject = state.cardsData.find((element) => element.id === id)
  if (filteredObject) {
    cb.apply(this, [filteredObject])
  }
  cb.apply(this, [state.cardsData.find((element) => element.id === id)])
}

const processCollections = (cardsData, included) => {
  const collections = included.filter((d) => d.type === 'vendor_collection')
  const knownIds = cardsData.map((d) => d.id)

  return collections.map((collection) => (
    _.merge(
      _.pick(collection, 'title', 'description', 'image_class'),
      {
        id: collection.id,
        vendorIds: collection.relationships
          .vendor_cards.data.map((v) => v.id).filter((id) => knownIds.includes(id))
      }
    )
  ))
}

export const vendorPicksSlice = createSlice({
  name: 'vendorPicksData',
  initialState: {
    cardsData: [],
    cardsError: null,
    cardsLoading: false,
    vendorCardScope: 'all',
    datasetTypes: [],
    regionalCoverages: [],
    sectorCoverages: [],
    selectedVendorCollections: [],
    allVendorCollections: [],
    contactPickProcess: null,
    showFilters: true,
    expandedCards: [],
    expanded: false,
    preselectedCollectionIds: [],
  },
  reducers: {
    dataLoad: (state, action) => {
      preProcessData(action.payload)

      state.cardsData = action.payload.data
      state.allVendorCollections = processCollections(state.cardsData, action.payload.included)
      state.cardsError = null
      state.cardsLoading = false
      state.preselectedCollectionIds = action.payload.meta?.preselected_collection_ids || []
      state.selectedVendorCollections = state.allVendorCollections.filter(
        (e) => state.preselectedCollectionIds.includes(e.id)
      ).map((e) => e.title)

      if (state.preselectedCollectionIds.length > 0) {
        postTrackingEvent({
          action: 'Go to vendor selection'
        })
      }
    },
    loadContactPickProcess: (state, action) => {
      const contactPickProcess = action.payload
      if (contactPickProcess.picks_submitted_at) {
        contactPickProcess.picks_submitted_at = Date.parse(contactPickProcess.picks_submitted_at)
      }
      state.contactPickProcess = contactPickProcess
    },
    setPicksSubmittedAt: (state, action) => {
      state.contactPickProcess.picks_submitted_at = action.payload
    },
    beforeDataLoad: (state) => {
      state.cardsLoading = true
    },
    pickSyncProgress: (state, action) => {
      const { id, progress } = action.payload
      findData(id, state, (obj) => {
        obj._pickSyncState = progress
      })
    },
    localPick: (state, action) => {
      const id = action.payload
      findData(id, state, (obj) => {
        obj.picked_by_buyside = true
        obj._pickSyncState = 'sent'
      })
    },
    localUnPick: (state, action) => {
      const id = action.payload
      findData(id, state, (obj) => {
        obj.picked_by_buyside = false
        obj._pickSyncState = 'sent'
      })
    },
    picksSubmitError: (state, action) => {
      state.submissionError = action.payload
    },
    dataError: (state, action) => {
      state.cardsData = []
      state.cardsError = action.payload
      state.cardsLoading = false
    },
    pickStateError: (state, action) => {
      state.cardsError = action.payload
      state.cardsLoading = false
    },
    setScope: (state, action) => {
      state.vendorCardScope = action.payload
    },
    filterBy: (state, action) => {
      const { name, stateName, filter } = action.payload
      const idx = state[stateName].indexOf(name)
      if (idx === -1 && filter) {
        state[stateName].push(name)
      } else if (idx >= 0 && !filter) {
        state[stateName].splice(idx, 1)
      }
    },
    toggleFilterVisibility: (state) => {
      state.showFilters = !state.showFilters
    },
    toggleExpandedCards: (state) => {
      state.expanded = !state.expanded
      if (state.expanded) {
        state.expandedCards = state.cardsData.map((card) => card.id)
      } else {
        state.expandedCards = []
      }
    },
    toggleExpandedCard: (state, action) => {
      if (state.expandedCards.includes(action.payload)) {
        state.expandedCards = state.expandedCards.filter((id) => id !== action.payload)
      } else {
        state.expandedCards = state.expandedCards.concat([action.payload])
      }
    },
    setLocalFormState: (state, action) => {
      state.formState = action.payload
    },
    setSelectedVendorCollections: (state, action) => {
      state.selectedVendorCollections = state.allVendorCollections.filter((collection) => (
        action.payload.ids.includes(collection.id)
      )).map((collection) => collection.title)
    }
  }
})

const {
  dataError, beforeDataLoad, pickSyncProgress,
  localPick, localUnPick, pickStateError
} = vendorPicksSlice.actions
export const {
  setScope, filterBy, loadContactPickProcess, toggleExpandedCards,
  toggleExpandedCard, toggleFilterVisibility, dataLoad,
  setSelectedVendorCollections, setPicksSubmittedAt
} = vendorPicksSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const load = () => (dispatch) => {
  dispatch(beforeDataLoad())
  axios.get(vendorPicksConfig.listBuysidePicksUrl(), { headers: { Accept: 'application/vnd.api+json' } })
    .then((response) => {
      dispatch(dataLoad(response.data));
    })
    .catch((error) => {
      dispatch(dataError({ message: error.message, name: error.name, stack: error.stack }))
      if (!error.isAxiosError) {
        // eslint-disable-next-line no-console
        console.error('Error', error)
      }
    })
};

export const pick = (id) => (dispatch, getState) => {
  const { cardsData } = getState().data
  const { pickProcess } = getState().buysidePickProcess
  dispatch(localPick(id))
  const obj = cardsData.find((element) => element.id === id)
  axios.post(
    vendorPicksConfig.createBuysidePickUrl({ vendorId: obj.id, pickProcessId: pickProcess.id })
  )
    .then(() => {
      dispatch(pickSyncProgress({ id, progress: 'complete' }))
    })
    .catch((error) => {
      dispatch(pickStateError({ message: error.message, name: error.name, stack: error.stack }))
      dispatch(pickSyncProgress({ id, progress: 'error' }))
    })
}

export const idempotentPick = (id) => (dispatch, getState) => {
  const card = getState().data.cardsData.find((data) => data.id === id)
  if (!card.picked_by_buyside) {
    dispatch(pick(id))
  }
}

export const unpick = (id) => (dispatch, getState) => {
  const { cardsData } = getState().data
  const { pickProcess } = getState().buysidePickProcess
  dispatch(localUnPick(id))
  const obj = cardsData.find((element) => element.id === id)
  axios.delete(
    vendorPicksConfig.createBuysidePickUrl({ vendorId: obj.id, pickProcessId: pickProcess.id })
  )
    .then(() => {
      dispatch(pickSyncProgress({ id, progress: 'complete' }))
    })
    .catch((error) => {
      dispatch(pickStateError({ message: error.message, name: error.name, stack: error.stack }))
      dispatch(pickSyncProgress({ id, progress: 'error' }))
    })
}

export const selectSelectedCards = (state) => (
  state.data.cardsData.filter((item) => item.picked_by_buyside)
)

export default vendorPicksSlice.reducer

const selectDatasetTypes = (state) => state.data.datasetTypes

const selectSectorCoverages = (state) => state.data.sectorCoverages

const selectRegionalCoverages = (state) => state.data.regionalCoverages

const selectedVendorCollections = (state) => state.data.selectedVendorCollections

export const selectAllSelectedFilters = (state) => (
  {
    selectedDatasetTypes: selectDatasetTypes(state),
    selectedSectorCoverages: selectSectorCoverages(state),
    selectedRegionalCoverages: selectRegionalCoverages(state),
    selectedVendorCollections: selectedVendorCollections(state)
  }
)
