import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ContentTypeFilterToggle from './ContentTypeFilterToggle'
import FilterByDataProviderSelect from './FilterByDataProviderSelect'
import { setAllSeen } from '../../Watchlist/watchlistSlice'
import ToolbarWatch from './ToolbarWatch'

const FeedToolbar = () => {
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(useSelector((state) => state.router.location.search))
  const dpSlug = searchParams.get('dataProvider')
  const feedSlug = searchParams.get('feedType')
  const contentTypeFilters = useSelector((state) => state.contentTypeFilter.filteredSlugs)

  return (
    <div className="toolbar">
      <div className="group">
        <ContentTypeFilterToggle />
        <FilterByDataProviderSelect />
      </div>
      <div className="group">
        <ToolbarWatch />
        <button
          className="mark-read"
          type="button"
          onClick={() => dispatch(setAllSeen({
            dataProviderSlug: dpSlug,
            feedTypeSlug: feedSlug,
            filteredSlugs: contentTypeFilters
          }))}
        >
          <i className="icon fa-regular fa-check" />
          Mark all as seen
        </button>
      </div>
    </div>
  )
}

export default FeedToolbar
