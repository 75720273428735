import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import uniqueId from '../../uniqueId'
import TrackingContainer from '../TrackingContainer'

const FilterRow = ({
  name, count, checkedSelector, onCheckThunk, type
}) => {
  const checkboxId = uniqueId()
  const dispatch = useDispatch()
  const onCheck = (e) => dispatch(onCheckThunk(e.target.checked))
  const isChecked = useSelector(checkedSelector)

  return (
    <div className={`filter-row${count === 0 ? ' empty' : ''}`}>
      <span className="selection">
        <TrackingContainer
          action={(isChecked ? 'Deselect filter' : 'Select Filter')}
          object={`${type}: ${name}`}
        >
          <input type="checkbox" id={checkboxId} checked={isChecked} onChange={onCheck} />
        </TrackingContainer>
      </span>
      <span className="value"><label htmlFor={checkboxId}>{name}</label></span>
      <span className="count">{count}</span>
    </div>
  )
}

export default FilterRow
