import axios from 'axios'

const axiosRails = axios.create({
  transformRequest: [(data, headers) => {
    const tokenElement = document.querySelector('[name=csrf-token]')

    if (!tokenElement) {
      return data
    }

    headers.common['X-CSRF-TOKEN'] = tokenElement.content
    return data
  }, ...axios.defaults.transformRequest]
})
export default axiosRails
