import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  gotoQuestion(event) {
    event.preventDefault()

    const base = window.location.toString().split('#').shift()
    const anchor = event.target.href.split('#').pop()
    const dest = `${base}#${anchor}`

    window.location.replace(dest)
  }
}
