import React, { forwardRef } from 'react'

import Date from './Date'

const DeclinedDDQCard = forwardRef((props, ref) => {
  const { item } = props.watchlistItem

  return (
    <li ref={ref} className="watchlist-card declined-ddq">
      <div className="basic-card-details">
        <div className="info">
          <div className="summary-container">
            <div className="logo"><img alt="Logo" src={item.logo} /></div>
            <span className="summary">
              <div>
                <span className="data-provider-name">
                  <a href={item.data_provider_path}>{item.data_provider}</a>
                </span>
                {' declined to provide a Due Diligence Questionnaire'}
              </div>
              <Date date={item.publication_date} />
            </span>
          </div>
        </div>
      </div>
    </li>
  )
})

export default DeclinedDDQCard
