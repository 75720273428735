import Trix from 'trix'

Trix.config.textAttributes.sup = { tagName: 'sup', inheritable: true }
Trix.config.textAttributes.sub = { tagName: 'sub', inheritable: true }

document.addEventListener('trix-initialize', (event) => {
  let buttonHTML = '<button type="button" class="trix-button trix-button--icon trix-button--icon-superscript" data-trix-attribute="sup" title="Superscript">Superscript</button>'
  buttonHTML += '<button type="button" class="trix-button trix-button--icon trix-button--icon-subscript" data-trix-attribute="sub" title="Subscript">Subscript</button>'

  const buttonGroup = event.target.toolbarElement.querySelector('.trix-button-group--text-tools')
  buttonGroup.insertAdjacentHTML('beforeend', buttonHTML)
})

Trix.config.blockAttributes.default.tagName = 'p'
Trix.config.blockAttributes.default.breakOnReturn = true;

Trix.Block.prototype.breaksOnReturn = function () {
  const attr = this.getLastAttribute();
  const config = Trix.getBlockConfig(attr || 'default');
  return config ? config.breakOnReturn : false;
};
Trix.LineBreakInsertion.prototype.shouldInsertBlockBreak = function () {
  if (this.block.hasAttributes() && this.block.isListItem() && !this.block.isEmpty()) {
    return this.startLocation.offset > 0
  }

  return !this.shouldBreakFormattedBlock() ? this.breaksOnReturn : false;
};
