import { Turbo } from '@hotwired/turbo-rails';
// <turbo-stream action="close_modal" selector="#selector-for-modal-wrapper"></turbo-stream>
//
// Given a selector to the modal wrapper component, this action will close the modal.
Turbo.StreamActions.close_modal = function redirect() {
  const selector = this.getAttribute('selector')
  const modal = document.querySelector(selector)
  const application = window.Stimulus
  const controller = application
    .getControllerForElementAndIdentifier(modal, modal.dataset.controller)
  controller.close()
}
