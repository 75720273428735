import axios from './axiosRails';

export const postTrackingEvent = (data) => {
  axios.post(
    '/user_pick_event_logs',
    data,
    {
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
    }
  )
}

const isTrackingNode = (node) => node.dataset?.userPickTrackingAttribute
const onClick = (event) => {
  const node = event.composedPath().find(isTrackingNode)

  if (node) {
    const data = node.getAttributeNames().reduce((acc, key) => {
      if (key === 'data-user-pick-tracking-attribute' || !key.startsWith('data-user-pick-tracking-attribute')) { return acc }

      acc[key.replace(/^data-user-pick-tracking-attribute-/, '').replace(/-/g, '_')] = node.getAttribute(key)
      return acc
    }, {})

    postTrackingEvent(data)
  }
}

export const trackingObserver = (rootEl) => {
  rootEl.addEventListener('click', onClick, true)
}
