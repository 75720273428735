/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState, forwardRef } from 'react'
import axios from '../../../axiosRails'
import BaseCard from './BaseCard'
import RichTextField from '../../Shared/RichTextField'

const TextAreaCard = forwardRef((props, ref) => {
  const [updateDescription, setUpdateDescription] = useState(props.watchlistItem.item.description)
  const [formChanged, setFormChanged] = useState(false)
  const [formSubmit, setFormSubmit] = useState(false)
  const flashMessagesElement = document.getElementById('flash_messages');
  const textAreaRef = useRef()

  const warningMessage = () => {
    if (formChanged) {
      return (
        <aside className={props.asideClass}>
          {props.children}
        </aside>
      )
    } return null
  }

  const submitButton = () => {
    if (formChanged && !props.disable_submit) {
      return (
        <button className="submit" type="submit">
          Submit
        </button>
      )
    } return null
  }

  const spinnerButton = () => {
    if (formSubmit) {
      return (
        <button type="submit" className="spinner" disabled>
          <i className="fa-regular fa-spinner-third" />
          <span>
            Submitting
          </span>
        </button>
      )
    } return null
  }

  const handleUpdate = (e) => {
    setUpdateDescription(e.target.value)
    setFormChanged(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setFormChanged(false)
    setFormSubmit(true)

    axios.put(`/admin/scout_dataset_updates/${props.watchlistItem.item.id}`,
      JSON.stringify(
        {
          scout_dataset_update: {
            description: updateDescription
          }
        }
      ),
      {
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
      },)
      .then((response) => {
        setFormChanged(false)
        setFormSubmit(false)
        flashMessagesElement.innerHTML = response.data.flashHtml
        console.log(response)
      })
      .catch((error) => {
        console.error('Error', error)
        return null
      })
  }

  return (
    <>
      <BaseCard ref={ref} {...props}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="editor-container">
            <RichTextField
              placeholder="Start typing the feed card content"
              value={updateDescription}
              onChange={(e) => { handleUpdate(e) }}
              ref={textAreaRef}
            />
          </div>
          { submitButton() }
          { spinnerButton() }
        </form>
      </BaseCard>
      { warningMessage() }
    </>
  )
})

export default TextAreaCard
