import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  showChangedOnly() {
    this.element.classList.remove('show-all');
    this.labelledFieldComponentControllers().forEach((controller) => {
      controller.showChangedOnly()
    })
    this.filesComponentControllers().forEach((controller) => {
      controller.showChangedOnly()
    })
  }

  showAll() {
    this.element.classList.add('show-all');
    this.labelledFieldComponentControllers().forEach((controller) => {
      controller.showAll()
    })
    this.filesComponentControllers().forEach((controller) => {
      controller.showAll()
    })
  }

  labelledFieldComponentControllers() {
    const { application } = this
    return Array.from(this.element.querySelectorAll('.eav-diff--labelled-field-component'))
      .map((field) => application
        .getControllerForElementAndIdentifier(field, field.dataset.controller))
  }

  filesComponentControllers() {
    const { application } = this
    return Array.from(this.element.querySelectorAll('.eav-diff--files-field-component--container'))
      .map((field) => application
        .getControllerForElementAndIdentifier(field, field.dataset.controller))
  }
}
