import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['titleEditor', 'titleValue', 'idValue']

  renameFile(event) {
    event.preventDefault()
    this.titleEditorTarget.setAttribute('contenteditable', true)
    const selection = window.getSelection()
    const range = document.createRange();
    selection.removeAllRanges();
    range.selectNode(this.titleEditorTarget);
    range.collapse(false);
    selection.addRange(range);
    this.titleEditorTarget.focus()
  }

  titleChanged(event) {
    const errorMessage = this.element.querySelector('.errors')

    if (errorMessage) {
      errorMessage.classList.add('hidden')
    }

    this.titleValueTarget.setAttribute('value', event.currentTarget.innerText)
    this.dispatchModifiedEvent()
  }

  deleteFile(event) {
    event.preventDefault()
    if (this.idValueTarget.value === '') {
      this.element.classList.add('deleted')
      this.dispatchModifiedEvent()
      this.element.remove()

      return
    }

    const baseName = this.idValueTarget.name.replace(/\[id\]$/, '')
    const element = document.createElement('input')
    element.setAttribute('type', 'hidden')
    element.setAttribute('name', `${baseName}[_destroy]`)
    element.setAttribute('value', '1')
    this.element.appendChild(element)
    this.element.classList.add('deleted')
    this.dispatchModifiedEvent()
  }

  getValue() {
    if (this.element.classList.contains('deleted')) { return null }
    return { title: this.titleValueTarget.value, id: this.idValueTarget.value };
  }

  selectCategory() {
    this.dispatchModifiedEvent();
  }

  dispatchModifiedEvent() {
    const customEvent = new CustomEvent('form-component-modified', { detail: { value: this.getValue() }, bubbles: true })
    this.element.dispatchEvent(customEvent)
  }
}
