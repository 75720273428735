import React from 'react'
import { useSelector } from 'react-redux'
import { components } from 'react-select'
// eslint-disable-next-line import/no-unresolved
import WindowedSelect from 'react-windowed-select'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { ALL_WATCHLIST_FEED_TYPE } from '../../constants/watchlist'

const linkTo = (identifier) => (
  (location) => {
    const searchParams = new URLSearchParams(location.search)
    if (identifier) {
      const existingFeedType = searchParams.get('feedType')

      if (existingFeedType && (existingFeedType !== 'sentry' && existingFeedType !== 'home')) {
        searchParams.delete('feedType')
      }

      searchParams.set('dataProvider', identifier)
    } else {
      searchParams.delete('dataProvider')
      searchParams.set('feedType', ALL_WATCHLIST_FEED_TYPE)
    }
    const newSearch = searchParams.toString()
    return { ...location, search: newSearch === '' ? '' : `?${newSearch}` }
  }
)

const Option = (innerProps) => {
  const { label, data } = innerProps
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <components.Option {...innerProps}>
      <Link to={linkTo(data.slug)} className="link-container filter-row">
        {label}
      </Link>
    </components.Option>
  )
  /* eslint-enable react/jsx-props-no-spreading */
}

const OptionalImage = ({ logoSrc, allButton }) => {
  if (allButton) {
    return (
      <div className="image-container">
        <i className="fa fa-infinity" />
      </div>
    )
  }
  if (!logoSrc) { return null }

  return (
    <div className="image-container">
      <img src={logoSrc} alt="" />
    </div>
  )
}

const Label = ({ imgUrl, name }) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
    <OptionalImage logoSrc={imgUrl} allButton={false} />{` ${name}`}
  </div>
)

const onChange = (history) => (selectedOption, e) => {
  if (e.action === 'clear') {
    history.push('/feed')
  } else if (e.action === 'select-option') {
    history.push(`/feed${linkTo(selectedOption.slug)(window.location).search}`)
  }
}

const FilterByDataProviderSelect = () => {
  const options = []
  let selectedOption = ''
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const defaultSlug = searchParams.get('dataProvider')
  const dataProviders = useSelector((state) => state.data.dataProviderFilterOptions) || []

  dataProviders.forEach((dataProvider) => {
    const label = <Label imgUrl={dataProvider.logo_src} name={dataProvider.name} />
    const option = { value: dataProvider.name, label, slug: dataProvider.slug }

    if (dataProvider.slug === defaultSlug) {
      selectedOption = option
    }

    options.push(option)
  })

  const history = useHistory();

  return (
    <div className="container watchlist-filter-group filter-data-provider-select">
      <i className="icon fa-solid fa-store" />
      <WindowedSelect
        className="dropdown-select"
        classNamePrefix="dropdown"
        options={options}
        defaultValue={selectedOption}
        value={selectedOption}
        isSearchable
        isClearable
        components={{ Option, DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        hideSelectedOptions={false}
        onChange={onChange(history, dataProviders)}
        placeholder="Search for a vendor"
      />
    </div>
  )
}

export default FilterByDataProviderSelect
