import React from 'react'
import { useSelector } from 'react-redux';

import Tooltip from '../Shared/Tooltip'
import useWatcher from '../../hooks/useWatcher';
import useSearchParam from '../../hooks/useSearchParam';
import { getDataProviderFilterOptionBySlug, getPersonalDataProviderSummaryBySlug } from '../../Watchlist/selectors';

const AddButton = () => {
  const dataProviderSlug = useSearchParam('dataProvider')

  const dataProviderFilterOption = useSelector(
    getDataProviderFilterOptionBySlug(dataProviderSlug)
  ) || {}

  const personalDataProviderSummary = useSelector(
    getPersonalDataProviderSummaryBySlug(dataProviderSlug)
  )

  const { createWatcher } = useWatcher(dataProviderFilterOption.id, false, false)

  if (personalDataProviderSummary || dataProviderSlug === null) {
    return null
  }

  return (
    <Tooltip
      content="Add to Watchlist"
    >
      <button type="button" aria-label="watch" onClick={createWatcher} className="icon add dp-icon fa-solid fa-signal-stream" />
    </Tooltip>
  )
}

export default AddButton
