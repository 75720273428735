const Config = {
  pusher: {
    key: null,
    authUrl: null,
    forceTLS: false,
    cluster: null,
    debug: false,
    host: null,
    port: null
  }
}

export default Config
