import instantsearch from 'instantsearch.js';
import AutoCompleteSearch from './AutoCompleteSearch';

/* global HandlebarsTemplates */
export default class TickerSearch extends AutoCompleteSearch {
  constructor(controller) {
    super(controller.algoliaTickerIndexValue, controller)
  }

  searchListId() {
    return 'ticker-results';
  }

  renderListItem({ hits }) {
    return HandlebarsTemplates.autosearch_result({
      // Limit searches of each type to 5 hits
      hits: hits.slice(0, 5).map((hit) => ({
        Ticker: hit.ticker,
        highlightedTicker: instantsearch.highlight({ attribute: 'ticker', hit }),
        highlightedName: instantsearch.highlight({ attribute: 'name', hit })
      })),
      path: this.autosearchResultsPath
    });
  }
}
