import React from 'react'
import { useSelector } from 'react-redux'
import FilterRows from './FilterRows'
import { sectorCoverageSummaries } from '../../OneToOne/VendorPicksFilter'

const SectorFilter = () => {
  const showFilters = useSelector((state) => state.data.showFilters)

  return (
    <div className={`vendor-filter-group ${showFilters ? 'show' : 'hide'}`}>
      <span className="title">Sectors</span>
      <FilterRows selector={sectorCoverageSummaries} />
    </div>
  )
}
export default SectorFilter
