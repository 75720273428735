import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="reviews-select"
export default class extends Controller {
  updateOrganisation() {
    const organisationId = new FormData(this.element).get('data_provider_id')

    const url = new URL(window.location)
    url.searchParams.set('data_provider_id', organisationId)

    window.location.href = url.href
  }

  updatePublisher() {
    const publisher = new FormData(this.element).get('publisher')

    const url = new URL(window.location)
    url.searchParams.set('publisher', publisher)

    window.location.href = url.href
  }
}
