import React from 'react'
import Modal from 'react-modal';
import TrackingContainer from '../TrackingContainer'

const ConfirmationModal = ({
  onConfirm, isOpen, onClose, pickedCount
}) => (
  <Modal isOpen={isOpen} className="picks-warning-modal" onRequestClose={onClose} ariaHideApp={false}>
    <div data-testid="picksWarningModal">
      <div className="picks-warning-modal__header">
        <button data-testid="closeButton" className="picks-warning-modal__close-container" type="button" onClick={onClose}>
          <i className="fa-solid fa-xmark picks-warning-modal__close-icon" />
        </button>
        <div className="picks-warning-modal__info-container">
          <i className="fa-solid fa-info picks-warning-modal__info-icon" />
        </div>
      </div>
      <div className="picks-warning-modal__footer">
        <h1 className="picks-warning-modal__title">You have {pickedCount} planned meetings</h1>
        <p className="picks-warning-modal__description">Please confirm you are happy for us to organise these vendor meetings for you.</p>

        <div className="picks-warning-modal__actions">
          <button className="picks-warning-modal__back-button " type="button" onClick={onClose}>
            Go back
          </button>
          <TrackingContainer action="Continue with high meetings count">
            <button className="submit" type="button" onClick={onConfirm}>
              Continue
              <i className="icon fa-solid fa-arrow-right" />
            </button>
          </TrackingContainer>
        </div>
      </div>
    </div>
  </Modal>
)

export default ConfirmationModal
