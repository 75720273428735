import './invalid-picks-modal.scss';
import React, { useMemo } from 'react'
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import TrackingContainer from '../TrackingContainer'
import { getInvalidPicks } from '../../OneToOne/VendorPicksFilter';
import { unpick } from '../../OneToOne/vendorPicksSlice';
import useLocationPreferences from '../../hooks/OneToOne/useLocationPreferences';

const InvalidPicksModal = (props) => {
  const {
    onEnableVirtualMeetings,
    onRemoveVirtualMeetings,
    isOpen,
    onClose
  } = props
  const dispatch = useDispatch()
  const { toggleContactVirtualAttendance } = useLocationPreferences()
  const invalidPicks = useSelector(getInvalidPicks)

  const headerMessage = useMemo(() => {
    if (invalidPicks.length > 1) {
      return `${invalidPicks.length} picks are not available for meeting in-person:`
    }

    return '1 pick is not available for meeting in-person:'
  }, [invalidPicks])

  const enableVirtualMeetings = () => {
    toggleContactVirtualAttendance()
    onEnableVirtualMeetings()
  }

  const removeVirtualMeetings = () => {
    invalidPicks.forEach(({ id }) => dispatch(unpick(id)))
    onRemoveVirtualMeetings()
  }

  // The images within the container overlay each other. A maximum of three images
  // may be displayed. Due to the images overlaying each other I had trouble centering
  // the container dynamically via CSS. This is a hack to get it to style correctly
  // for all possible numbers of vendor logos.
  const invalidPicksContainerWidth = () => {
    switch (invalidPicks.length) {
      case 1:
        return 77
      case 2:
        return 100
      default:
        return 148
    }
  }

  return (
    <Modal isOpen={isOpen} className="invalid-picks-modal" onRequestClose={onClose} ariaHideApp={false}>
      <div>
        <div className="picks-warning-modal__header">
          <button data-testid="closeButton" className="picks-warning-modal__close-container" type="button" onClick={onClose}>
            <i className="fa-solid fa-xmark picks-warning-modal__close-icon" />
          </button>
          <div className="picks-warning-modal__info-container">
            <i className="fa-solid fa-info picks-warning-modal__info-icon" />
          </div>
        </div>
        <div className="invalid-picks-modal__footer">
          <h1 className="invalid-picks-modal__title">{headerMessage}</h1>
          <div className="invalid-picks-container" style={{ width: `${invalidPicksContainerWidth()}px` }}>
            {
              invalidPicks.slice(0, 3).map(({ id, logo, title }) => (
                <figure key={id} className="vendor-logo">
                  <img src={logo} alt={`${title} logo`} />
                </figure>
              ))
            }
            { invalidPicks.length > 3 && <div className="extra-invalid-picks-count">+{invalidPicks.length - 3}</div> }
          </div>

          <div className="invalid-picks-modal__actions">
            <TrackingContainer action="Enable virtual meetings and continue">
              <button type="button" onClick={enableVirtualMeetings}>
                Enable virtual meetings and continue
                <i className="continue fa-regular fa-arrow-right" />
              </button>
            </TrackingContainer>
            <TrackingContainer action="Remove virtual meetings and continue">
              <button type="button" onClick={removeVirtualMeetings}>
                Remove virtual meetings and continue
                <i className="continue fa-regular fa-arrow-right" />
              </button>
            </TrackingContainer>
            <TrackingContainer action="Do nothing, go back">
              <button type="button" onClick={onClose}>
                Do nothing, go back
              </button>
            </TrackingContainer>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default InvalidPicksModal
