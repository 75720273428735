import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setFormState, submit
} from '../../OneToOne/buysidePickProcessSlice';
import { setPicksSubmittedAt } from '../../OneToOne/vendorPicksSlice'

const SubmitPicksButton = ({ filteredSlots, nextState }) => {
  const dispatch = useDispatch()
  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(submit(filteredSlots.map((slot) => slot.id)))
    dispatch(setFormState(nextState))
    dispatch(setPicksSubmittedAt(Date.now()))
  }

  const picksSubmittedAt = useSelector((state) => (
    state.data.contactPickProcess.picks_submitted_at
  ))

  return (
    <button
      className="submit"
      type="button"
      onClick={onSubmit}
    >
      {picksSubmittedAt ? 'Re Submit & Finish' : 'Submit & Finish'}
      <i className="icon fa-solid fa-arrow-right" />
    </button>
  )
}
export default SubmitPicksButton
