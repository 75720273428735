import { createSlice } from '@reduxjs/toolkit'

/**
 * The global slice for the global store.
 * @type {Slice}
 */
export const globalStoreSlice = createSlice({
  name: 'globalStore',
  initialState: {
    unseenFeedCount: 0,
    unseenChatMessagesCount: 0
  },
  reducers: {
    setUnseenFeedCount: (state, action) => {
      state.unseenFeedCount = action.payload
    },
    setUnseenChatMessagesCount: (state, action) => {
      state.unseenChatMessagesCount = action.payload
    }
  }
})

export const {
  setUnseenFeedCount, setUnseenChatMessagesCount
} = globalStoreSlice.actions

export default globalStoreSlice.reducer
