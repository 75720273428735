import Config from './UserNotifications/Config'

/**
 * ScoutConfig is the central configuration object for the entire system's front end code
 * The configuration comes from a script element with type="application/json"
 * with id of 'scout-config-data' which is in the head of every page
 */
const ScoutConfig = {
  UserNotifications: Config,
  AuthenticatedUser: {
    id: null,
    general_user_id: null
  }
}

const configElement = document.getElementById('scout-config-data')
if (configElement) {
  const config = JSON.parse(configElement.textContent)
  Object.assign(ScoutConfig.UserNotifications, config.UserNotifications)
  const { UserNotifications, ...restConfig } = config;
  Object.assign(ScoutConfig, restConfig)
}

export default ScoutConfig
