import React, {
  useEffect, useRef, useState, useCallback
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CustomizedDirectUploadProvider from './CustomizedDirectUploadProvider'
import { postMessage } from '../../Chat/messageSlice'
import FlashMessage from '../Shared/FlashMessage'
import UploadStatusBadge from './PostMessage/UploadStatusBadge'
import RichTextField from '../Shared/RichTextField'

let finishedUploads = []
const Upload = ({
  uploads, setDisableSubmit, ready, handleUpload, fileInputRef,
  setFileIds, fileIds, setErrors, fileSizeLimit
}) => {
  const removeUpload = (id) => () => {
    setFileIds(fileIds.filter((e) => e.id !== id))
    finishedUploads = finishedUploads.filter((e) => e.id !== id)
    // file upload is done when all remaining uploads are marked as finished, rather than errored
    setDisableSubmit(finishedUploads.filter((e) => e.state === 'finished').length !== finishedUploads.length)
  }

  if (uploads.length) {
    const finishedIds = finishedUploads.map((e) => e.id)
    const newlyFinishedUploads = uploads.filter((e) => e.state === 'finished' && !finishedIds.includes(e.id))
    finishedUploads = finishedUploads.concat(newlyFinishedUploads)
  }

  const isAllowedFileSize = (file) => file.size < fileSizeLimit

  const onChange = (e) => {
    const errors = []
    const allowedFiles = []
    // we handle file removals ourselves
    if (!e.currentTarget.files.length) {
      return
    }

    e.currentTarget.files.forEach((file) => {
      if (isAllowedFileSize(file)) { return allowedFiles.push(file) }

      return errors.push(`${file.name} is greater than the ${fileSizeLimit / 1000000}MB limit`)
    })

    setErrors(errors)

    if (allowedFiles.length > 0) { setDisableSubmit(true) }

    handleUpload(allowedFiles)
  }

  // file upload is done when all uploads are marked as finished.
  // If some uploads failed, they will be marked as errored and
  // need to be removed before sending the message
  useEffect(() => {
    if (uploads.length) {
      setDisableSubmit(uploads.filter((e) => e.state === 'finished').length !== uploads.length)
    }
  }, [uploads, setDisableSubmit])

  return (
    <div className="file-upload-container">
      <button className="file-upload-button" type="button">
        <label className="files-upload-label" htmlFor="files-upload">
          <div className="files-upload-text-container">
            <i className="fa-solid fa-paperclip" />
          </div>
          <input
            id="files-upload"
            multiple
            type="file"
            disabled={!ready}
            onChange={onChange}
            ref={fileInputRef}
          />
        </label>

      </button>

      <div className="upload-status-container">
        {finishedUploads.map((e) => <UploadStatusBadge key={`finished-${e.id}`} upload={e} removeUpload={removeUpload} />)}
        {uploads.map((e) => <UploadStatusBadge key={`not-finished-${e.id}`} upload={e} />)}
      </div>
    </div>
  )
}

/**
 * A component allowing the user to post messages to the conversation
 * Also scrolls to the bottom after the list of messages change in any way
 * @returns {JSX.Element}
 * @constructor
 */
const PostMessage = ({ defaultMessage = '' }) => {
  const dispatch = useDispatch()
  const fileSizeLimit = useSelector((state) => state.app.config.fileSizeLimit)
  const [message, setMessage] = useState(null)
  const [fileIds, setFileIds] = useState([])
  const [disableSubmit, setDisableSubmit] = useState(false)
  const fileInputRef = useRef()
  const textAreaRef = useRef()
  const [errors, setErrors] = useState([])
  const previousDefaultMessage = useRef(defaultMessage)
  const messageToSubmit = (message === null) ? defaultMessage : message
  if (previousDefaultMessage.current !== defaultMessage) {
    const previous = previousDefaultMessage.current
    previousDefaultMessage.current = defaultMessage
    if ((message || '') === previous) {
      setMessage(defaultMessage)
    }
  }

  const handleSubmit = useCallback(() => {
    dispatch(postMessage(messageToSubmit, fileIds.map((e) => e.signed_id) || []))
    setMessage('')
    setFileIds([])
    finishedUploads = []
    textAreaRef.current.focus()
  }, [dispatch, setMessage, textAreaRef, fileIds, messageToSubmit])

  const accumulateFileIds = (ids) => {
    const uniqueIds = [...new Set(fileIds.concat(ids))]

    setFileIds(uniqueIds)

    // Once every file is done uploading, clear the HTML file input.
    if (fileInputRef.current?.value) {
      fileInputRef.current.value = ''
    }
  }

  const isSubmitDisabled = () => (
    disableSubmit || (/^\s*$/.test(messageToSubmit) && !fileIds.length)
  )

  const renderUpload = ({ handleUpload, uploads, ready }) => (
    <Upload
      uploads={uploads}
      setDisableSubmit={setDisableSubmit}
      ready={ready}
      handleUpload={handleUpload}
      fileInputRef={fileInputRef}
      fileIds={fileIds}
      setFileIds={setFileIds}
      setErrors={setErrors}
      fileSizeLimit={fileSizeLimit}
    />
  )
  return (
    <div data-component="chat/post-message" className="post-message">
      {errors.length > 0 && <FlashMessage message={errors.join(' ')} />}
      <div className="editor-container">
        <RichTextField placeholder="Type your message" value={messageToSubmit} onChange={(e) => { setMessage(e.target.value) }} ref={textAreaRef} />
      </div>
      <div className="controls-container">
        <CustomizedDirectUploadProvider
          multiple
          onSuccess={accumulateFileIds}
          render={renderUpload}
        />

        <div className="button-container">
          <button disabled={isSubmitDisabled()} onClick={handleSubmit} type="button"><i className="fa-solid fa-paper-plane" />Send</button>
        </div>
      </div>
    </div>
  )
}

export default PostMessage
