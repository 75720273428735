import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const datePicker = this.element.querySelector('input')
    datePicker.addEventListener('focus', () => this.setNoonTime(datePicker))
  }

  changeValue(event) {
    this.dispatchModifiedEvent(event)
  }

  dispatchModifiedEvent(event) {
    const customEvent = new CustomEvent('form-component-modified', { detail: { value: event.target.value }, bubbles: true })
    this.element.dispatchEvent(customEvent)
  }

  setNoonTime(datePicker) {
    if (!datePicker.value) {
      const now = new Date()
      now.setUTCHours(12, 0, 0, 0)
      datePicker.value = now.toISOString().slice(0, 16);
    }
  }
}
