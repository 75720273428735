import { useEffect, useRef } from 'react';

/**
 * A custom hook that runs an asynchronous effect and ensures the effect
 * does not update the state if the component is unmounted.
 *
 * @param {function} asyncCallback - The asynchronous function to run.
 * The function receives a ref object (isMounted) that indicates whether
 * the component is still mounted.
 * @param {Array} dependencies - An array of dependencies that will trigger
 * the effect when changed.
 *
 * This hook provides a safe way to handle async operations within a
 * React component, preventing memory leaks and ensuring that any state
 * updates only occur if the component is still mounted.
 *
 * Example usage:
 *
 * useAsyncEffect(async (isMounted) => {
 *   const response = await fetchData();
 *   if (isMounted.current) {
 *     setState(response.data);
 *   }
 * }, [dependency1, dependency2]);
 *
 */

const useAsyncEffect = (asyncCallback, dependencies) => {
  const isMounted = useRef(true)

  useEffect(() => () => {
    isMounted.current = false
  }, [])

  useEffect(() => {
    const executeAsync = async () => {
      await asyncCallback(isMounted)
    }

    executeAsync()
  }, dependencies)
}

export default useAsyncEffect
