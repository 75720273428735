import React from 'react'
import { Provider, useSelector } from 'react-redux';
import globalStoreInstance from '../../globalStoreInstance'
import { setUnseenChatMessagesCount } from '../../globalStoreSlice';

const MessagesCountBadgeNumber = () => {
  const unseenChatMessagesCount = useSelector((state) => state.global.unseenChatMessagesCount)
  if (unseenChatMessagesCount > 99) {
    return <div className="unseen-chat-messages-count">99+</div>
  }
  if (unseenChatMessagesCount > 0) {
    return <div className="unseen-chat-messages-count">{unseenChatMessagesCount}</div>
  }
  return ''
}

/**
 * Renders a badge showing the unseen feed count and connects to the global store for updates
 * To update, dispatch setUnseenFeedCount to the global store with the new value.
 * @param props
 * @param {Number} props.unseen The initial value for the badge
 * @returns {JSX.Element}
 * @constructor
 */
const MessagesCountBadgeApp = (props) => {
  globalStoreInstance.dispatch(setUnseenChatMessagesCount(props.unseen))
  return (
    <Provider store={globalStoreInstance}>
      <MessagesCountBadgeNumber />
    </Provider>
  )
}
export default MessagesCountBadgeApp
