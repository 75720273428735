import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkboxTemplate']

  optionDragStart(event) {
    event.dataTransfer.setData('text/plain', event.target.dataset.value)
  }

  optionDragOver(event) {
    event.preventDefault()
  }

  optionDrop(event) {
    const pos = this.reorderPosition(event)
    if (pos.direction === 'same') {
      return
    }

    const optionId = event.dataTransfer.getData('text/plain')
    const option = this.element.querySelector(`[data-option-container][data-value="${optionId}"]`)
    if (pos.direction === 'above') {
      pos.container.insertAdjacentElement('beforebegin', option)
    } else {
      pos.container.insertAdjacentElement('afterend', option)
    }
  }

  reorderPosition(event) {
    const optionContainer = event.target.closest('[data-option-container]');
    if (event.dataTransfer.getData('text/plain') === optionContainer.dataset.value) {
      return { direction: 'same', container: optionContainer }
    }

    const rect = optionContainer.getBoundingClientRect()
    const mouseY = event.clientY - rect.top
    const elementHeight = rect.height
    if (mouseY < elementHeight / 2) {
      return { direction: 'above', container: optionContainer }
    }
    return { direction: 'below', container: optionContainer }
  }

  addSelectedOptionUsingTemplate(optionId, optionText) {
    const cloned = this.checkboxTemplateTarget.content.cloneNode(true)
    const containerNode = cloned.querySelector('[data-option-container]');
    containerNode.dataset.value = containerNode.dataset.value.replace(/placeholder-choice$/, optionId)
    const checkbox = cloned.querySelector(`[data-${this.identifier}-target="checkbox"]`)
    checkbox.value = optionId
    checkbox.id = checkbox.id.replace(/placeholder-choice$/, optionId)
    cloned.querySelector(`[data-${this.identifier}-target="labelText"]`).closest('label').setAttribute('for', checkbox.id)
    cloned.querySelector(`[data-${this.identifier}-target="labelText"]`).innerText = optionText
    this.element.appendChild(cloned)
  }

  removeSelection(event) {
    const optionContainer = event.target.closest('[data-option-container]')
    optionContainer.remove()
    this.dispatch('selectionRemoved', { detail: { id: event.target.value } })
  }
}
