import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TrackingContainer from '../../TrackingContainer'
import { unpick, selectAllSelectedFilters } from '../../../OneToOne/vendorPicksSlice'
import { getInvalidPicks } from '../../../OneToOne/VendorPicksFilter'

const MyMeetingsList = ({ picks }) => {
  const dispatch = useDispatch()

  const allSelectedFilters = useSelector(selectAllSelectedFilters)
  const invalidPicks = useSelector(getInvalidPicks)

  const isInValidPick = (pick) => (!!invalidPicks.find(({ id }) => pick.id === id))

  const invalidMeetingsCount = invalidPicks.length

  const preWarningText = invalidMeetingsCount > 1 ? 'meetings are' : 'meeting is'

  return (
    <>
      <ul>
        {
      picks.map((pick) => (
        <li data-pick-id={pick.id} key={pick.id}>
          <span className={(isInValidPick(pick) && 'invalid-pick') || ''}>{pick.title}</span>
          <TrackingContainer
            action="Manual unpick"
            object={pick.title}
            extraInfo={JSON.stringify(allSelectedFilters)}
          >
            <button type="button" data-action="remove" aria-hidden="true" onClick={() => dispatch(unpick(pick.id))}>
              <i className="fa-regular fa-times" />
            </button>
          </TrackingContainer>
        </li>
      ))
    }
      </ul>
      {invalidMeetingsCount > 0 && <p role="alert" className="invalid-pick">{invalidMeetingsCount} {preWarningText} incompatible with your location preferences</p>}
    </>
  )
}

export default MyMeetingsList
