// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
import FeedItemModalContentApp from '../src/components/Watchlist/FeedItemModalContentApp';

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// From original webpacker file
/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'sweetalert2/src/sweetalert2.scss'
import ReactDOM from 'react-dom'
import VendorPicksApp from '../src/components/OneToOne/VendorPicksApp'
import PostForm from '../src/components/PostForm'
import ContentCarousel from '../src/components/ContentCarousel'
import PickProcessFormTabs from '../src/components/OneToOne/PickProcessFormTabs'
import FeedApp from '../src/components/Watchlist/FeedApp'
import FeedCountBadgeApp from '../src/components/FeedCountBadgeApp';
import NotificationBox from '../src/components/NotificationBox'
import globalStoreInstance from '../src/globalStoreInstance'
import PreferenceCard from '../src/components/EmailManagement/PreferenceCard'
import TranslationDropdownApp from '../src/components/TranslationDropdownApp'
import ProductUpdateModal from '../src/components/Watchlist/ProductUpdateModal'
import FeedItemModal from '../src/components/Watchlist/FeedItemModal'
import CentreAlignedContent from '../src/elements/CentreAlignedContent'
import TextAreaCard from '../src/components/Watchlist/Dataset/TextAreaCard'
import ReviewFeedCard from '../src/components/Watchlist/Dataset/ReviewFeedCard'

import React from 'react'
import { observe, postTrackingEvent } from '../src/remoteTracking';
import { trackingObserver } from '../src/picksTracking';
import ChatApp from '../src/components/Chat/ChatApp';
import MessagesCountBadgeApp from '../src/components/Chat/MessagesCountBadgeApp';
import { Turbo } from '@hotwired/turbo-rails'
import '../src/turboCustomActions'
import '../src/scoutBackButtonIntercept'
import '../src/controllers'
import '../src/rackMiniProfilercustomization'
import ScoutConfig from '../src/ScoutConfig'
import Notifier from '../src/UserNotifications/notifier'

// Support component names relative to this directory:
import ReactRailsUJS from 'react_ujs'

import '../src/loadTrix'
import '../src/loadTippy'

import '@rails/actiontext'

console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Turbo drive is disabled as standard - add data-turbo="true" to
// enable turbo drive (see https://github.com/hotwired/turbo-rails)
Turbo.session.drive = false
console.log('Vite application running')
window.ReactPublicComponents = {
  VendorPicksApp,
  PostForm,
  ContentCarousel,
  PickProcessFormTabs,
  FeedApp,
  Watchlist: {
    FeedApp,
    Dataset: {
      ReviewFeedCard,
    },
    FeedItemModalContentApp,
    ProductUpdateModal
  },
  FeedCountBadgeApp,
  NotificationBox,
  PreferenceCard,
  TranslationDropdownApp,
  globalStore: globalStoreInstance,
  ChatApp,
  ChatMessagesCountBadgeApp: MessagesCountBadgeApp,
  ProductUpdatesModal: ProductUpdateModal,
}
window.ScoutConfig = ScoutConfig
window.UserNotifications = {
  Notifier
}

window.ReactDOM = ReactDOM
window.React = React
ReactRailsUJS.getConstructor = (className) => {
  const dig = (parts, node = window.ReactPublicComponents) => {
    if (parts.length == 1) {
      return node[parts[0]]
    }
    return dig(parts.slice(1), node[parts[0]])
  }
  return dig(className.split('/')) || eval(className);
}
window.addEventListener('DOMContentLoaded', () => {
  const body = document.getElementsByTagName('body')[0]
  observe(body)
  trackingObserver(body)
})
window.onbeforeprint = () => {
  postTrackingEvent({ eventAction: 'Print' })
}

// Make sure we initialize react components rendered by async turbo frames
// see https://github.com/reactjs/react-rails/issues/1103
ReactRailsUJS.handleEvent('turbo:frame-load', ReactRailsUJS.handleMount)
ReactRailsUJS.handleEvent('turbo:frame-render', ReactRailsUJS.handleUnmount)
