export const debounce = (func, wait) => {
  let timeout

  return (...args) => {
    const context = this;

    const later = () => {
      timeout = null;
      func.apply(context, args);
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, wait)
  }
}

export const debounceWithState = (func, wait) => {
  let timeout
  let state = []

  return (arg) => {
    const context = this;
    state.push(arg)

    const later = () => {
      timeout = null;
      func.apply(context, [state]);
      state = []
    };

    clearTimeout(timeout);

    timeout = setTimeout(later, wait)
  }
}
