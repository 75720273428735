import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  disableAllFormActions(event) {
    document.querySelectorAll(`.${event.target.className}`).forEach((form) => {
      form.querySelectorAll('button').forEach((el) => { el.disabled = true })
    })
  }
}
