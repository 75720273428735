import React from 'react'
import { useSelector } from 'react-redux'
import FilterRows from './FilterRows'
import { regionalCoverageSummaries } from '../../OneToOne/VendorPicksFilter'

const RegionFilter = () => {
  const showFilters = useSelector((state) => state.data.showFilters)

  return (
    <div className={`vendor-filter-group ${showFilters ? 'show' : 'hide'}`}>
      <span className="title">Regions</span>

      <FilterRows selector={regionalCoverageSummaries} />
    </div>
  )
}
export default RegionFilter
