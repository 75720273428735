import React from 'react'

const ToggleInput = ({
  className, onClick, checked, id = null, disabled = null
}) => {
  const handleClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    if (disabled) { return }

    onClick()
  }

  return (
    <div className={className}>
      <span role="switch" tabIndex="0" aria-checked={checked} className={`switch slider slider__${checked ? 'checked' : 'unchecked'} fa-solid ${checked ? 'fa-check' : 'fa-xmark'}`} onClick={handleClick} onKeyPress={handleClick}>
        <input id={id} tabIndex="-1" type="checkbox" checked={checked} readOnly />
      </span>
    </div>
  )
}
export default ToggleInput
