import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['saveDraftButton', 'saveDraftIcon']

  stayOnThisPage(event) {
    event.preventDefault();
    this.dispatch('stay-on-this-page');
  }

  exitWithoutSaving(event) {
    event.preventDefault();
    this.dispatch('exit-without-saving');
  }

  onSubmitStart(event) {
    if (event.detail.formSubmission.submitter.name !== 'save_draft') { return }

    this.saveDraftButtonTarget.classList.add('loading')
    this.saveDraftIconTarget.classList.add('fa-regular')
    this.saveDraftIconTarget.classList.add('fa-spinner-third')
  }

  onSubmitEnd(event) {
    if (event.detail.formSubmission.submitter.name !== 'save_draft') { return }

    this.saveDraftButtonTarget.classList.remove('loading')
    this.saveDraftIconTarget.classList.remove('fa-regular')
    this.saveDraftIconTarget.classList.remove('fa-spinner-third')
  }
}
