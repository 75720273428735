import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['link', 'observer'];

  connect() {
    this.sections = [
      ...document.querySelectorAll('[data-chapter-hopper-slug]'),
    ];
    this.isScrolling = false;

    this.bindEvents();
    this.highlightActiveSection();
  }

  disconnect() {
    this.unbindEvents();
  }

  bindEvents() {
    this.onScroll = this.onScroll.bind(this);
    this.onClick = this.onClick.bind(this);
    window.addEventListener('scroll', this.onScroll);
    this.linkTargets.forEach((link) => link.addEventListener('click', this.onClick));
  }

  unbindEvents() {
    window.removeEventListener('scroll', this.onScroll);
    this.linkTargets.forEach((link) => link.removeEventListener('click', this.onClick));
  }

  onScroll() {
    if (!this.isScrolling) {
      this.highlightActiveSection();
    }
  }

  highlightActiveSection() {
    const observerRect = this.observerTarget.getBoundingClientRect();

    const matchingSections = this.sections
      .map((section) => ({ section, rect: section.getBoundingClientRect() }))
      .filter(
        ({ rect }) => rect.top <= observerRect.top && rect.bottom >= observerRect.top
      )
      .sort((a, b) => a.section.offsetHeight - b.section.offsetHeight);

    this.linkTargets.forEach((link) => link.classList.remove('active'));

    if (matchingSections.length > 0) {
      const currentSection = matchingSections[0].section;
      const activeLink = this.linkTargets.find(
        (link) => link.getAttribute('href') === `#${currentSection.id}`
      );
      if (activeLink) activeLink.classList.add('active');
    }
  }

  onClick(event) {
    event.preventDefault();

    const targetId = event.currentTarget.getAttribute('href').slice(1);
    const targetSection = document.getElementById(targetId);

    if (targetSection) {
      this.isScrolling = true;
      this.linkTargets.forEach((link) => link.classList.remove('active'));
      event.currentTarget.classList.add('active');

      targetSection.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

      window.history.pushState(null, '', `#${targetId}`);

      setTimeout(() => {
        this.isScrolling = false;
      }, 1000);
    }
  }
}
