import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { shareAllDataProvidersPersonal } from '../../Watchlist/watchlistSlice'
import {
  getCanShareWatchlist,
  getCurrentTab,
  getUnsharedDataProviders
} from '../../Watchlist/selectors'
import axiosRails from '../../axiosRails'
import { PERSONAL_WATCHLIST_FEED_TYPE } from '../../constants/watchlist'

const ShareAllButton = () => {
  const dispatch = useDispatch()
  const canShareWatchlist = useSelector(getCanShareWatchlist)
  const currentTab = useSelector(getCurrentTab)
  const personalUnsharedProviders = useSelector(getUnsharedDataProviders)

  const isNotPersonalWatchlistTab = currentTab !== PERSONAL_WATCHLIST_FEED_TYPE
  const noUnsharedProviders = personalUnsharedProviders.length === 0

  if (!canShareWatchlist || isNotPersonalWatchlistTab || noUnsharedProviders) {
    return null
  }

  const onClick = () => {
    axiosRails.put('/provider_watchers/share_all')
      .then(() => {
        dispatch(shareAllDataProvidersPersonal())
      })
  }

  return (
    <div className="share-all-container">
      <button className="share-all-provider-watchers" type="button" onClick={onClick}>
        Share All
      </button>
    </div>
  )
}

export default ShareAllButton
