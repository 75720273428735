import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'

export default class extends Controller {
  static values = {
    content: String,
    placement: { type: String, default: 'bottom' }
  }

  connect() {
    tippy(this.element, {
      content: this.contentValue, placement: this.placementValue, allowHTML: true, interactive: true
    })
  }
}
