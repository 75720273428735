import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['publishListingButton']

  selectMajorChange() {
    this.element.querySelector('#major-update-summary').classList.remove('hidden')
    this.element.querySelector('#minor-update-summary').classList.add('hidden')

    this.setSubmitDisabledState()
  }

  selectMinorChange() {
    this.element.querySelector('#major-update-summary').classList.add('hidden')
    this.element.querySelector('#minor-update-summary').classList.remove('hidden')

    this.setSubmitDisabledState()
  }

  setSubmitDisabledState() {
    this.publishListingButtonTarget.classList.remove('disabled')
  }
}
