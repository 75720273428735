import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(relativeTime)
dayjs.extend(isYesterday)
dayjs.extend(isToday)
dayjs.extend(advancedFormat)

/**
 * Extends the dayjs 'fromNow' function - returning the following
 * 'Today', 'Yesterday' - or whatever dayjs returns for other days
 * @param dt The date string in ISO format
 * @returns {string} The date to render
 */
const relativeDay = (dt) => {
  const date = dayjs(dt)
  if (date.isToday()) { return 'Today' }
  if (date.isYesterday()) { return 'Yesterday' }
  return date.fromNow()
}

/**
 * Formats the given date string into a 12 hour time such as 9:00am
 * @param {string} dt The date string
 * @returns {string} The date to render
 */
const timeOnly12Hour = (dt) => (
  dayjs(dt).format('h:mma')
)

/**
 * Returns a string formatting the date to something like :-
 *   Monday 22 November 2019
 *   or if the year is this year, just
 *   Monday 22 November
 * @param {string} dt The date as a string
 * @returns {string} The formatted date
 */
const fullDateWithOptionalYear = (dt) => {
  const dateTime = dayjs(dt)
  if (dateTime.isSame(dayjs(), 'year')) {
    return dateTime.format('dddd D MMMM')
  }
  return dateTime.format('dddd D MMMM YYYY')
}

/**
 * Returns a string formatting two dates into a range.
 * The resulting range is considerate of being in the same
 * month or same year. If the day is the same a single date
 * is returned
 * @param {string} dt1 The first date of range as a string
 * @param {string} dt2 The last date of range as a string
 * @returns {string} The formatted date range
 */
const formattedDateRange = (dt1, dt2) => {
  const sameDay = dayjs(dt1).isSame(dt2, 'day')
  const sameMonth = dayjs(dt1).isSame(dt2, 'month')
  const sameYear = dayjs(dt1).isSame(dt2, 'year')

  if (sameDay) { return dayjs(dt1).format('Do MMMM YYYY') }
  if (sameMonth) { return `${dayjs(dt1).format('Do')} - ${dayjs(dt2).format('Do MMMM YYYY')}` }
  if (sameYear) { return `${dayjs(dt1).format('Do MMMM')} - ${dayjs(dt2).format('Do MMMM YYYY')}` }
  return `${dayjs(dt1).format('Do MMMM YYYY')} - ${dayjs(dt2).format('Do MMMM YYYY')}`
}

// eslint-disable-next-line import/prefer-default-export
export {
  relativeDay, timeOnly12Hour, fullDateWithOptionalYear, formattedDateRange
}
