import React, { useState } from 'react'
import PropTypes from 'prop-types';
import DataTypeFilter from './DataTypeFilter';
import MyPicksBasket from './MyPicksBasket';
import RegionFilter from './RegionFilter';
import SectorFilter from './SectorFilter';
import VendorCollectionFilter from './VendorCollectionFilter';
import VendorCardList from './VendorCardList';
import VendorPicksFlash from './VendorPicksFlash';
import FilterSummary from './FilterSummary'
import ExpandCardsToggle from './VendorPicksToggles/ExpandCardsToggle';
import NewVendorToggle from './VendorPicksToggles/NewVendorToggle';
import FilterVisibilityToggle from './VendorPicksToggles/FilterVisibilityToggle';
import LocationPreferencesNotice from './LocationPreferencesNotice';

function VendorPicksPage(props) {
  const [infoBannerHidden, setInfoBannerHidden] = useState(false)

  return (
    <div className="m-buyside-picks-page">
      <VendorPicksFlash />
      <div className="layout-columns">
        <div className="column column--1">
          <div className="vendor-picks-toggles">
            <ExpandCardsToggle />
            <FilterVisibilityToggle />
            <NewVendorToggle />
          </div>
          <FilterSummary />
          <VendorCollectionFilter />
          <DataTypeFilter />
          <SectorFilter />
          <RegionFilter />
        </div>
        <div className="column column--2">
          { props.bannerMessage
            && (
            <div className={`info-banner ${infoBannerHidden ? 'hidden' : 'visible'}`}>
              <div className="message">
                <i className="icon info fa-regular fa-circle-info" />
                {/* eslint-disable-next-line react/no-danger */}
                <span className="text" dangerouslySetInnerHTML={{ __html: props.bannerMessage }} />
              </div>
              <button type="button" className="dismiss" onClick={() => setInfoBannerHidden(true)}>
                <i className="icon dismiss fa-solid fa-times" />
              </button>
            </div>
            )}
          <LocationPreferencesNotice useQuery={props.useQuery} />
          <VendorCardList bannerMessage={props.bannerMessage} />
        </div>
        <div className="column column--3">
          <MyPicksBasket nextState={props.nextState} />
        </div>
      </div>
    </div>
  )
}
VendorPicksPage.propTypes = {
  nextState: PropTypes.string.isRequired,
}

export default VendorPicksPage
