import './conversation_editor.scss';
import React from 'react'
import { useSelector } from 'react-redux'
import NoMessagesPage from './NoMessagesPage'
import MessageList from './MessageList'
import PostMessage from './PostMessage'

const DefaultFirstMessageToVendor = "<div>Hi there, I'm interested in using your dataset. Please contact me</div>"

/**
 * The conversation editor is the right hand side of the chat window
 * which contains the message list and a component to post new messages
 * @returns {JSX.Element}
 * @constructor
 */
const ConversationEditor = ({ participantType }) => {
  const loaded = useSelector((state) => state.messages.loaded)
  const query = useSelector((state) => state.router.location.query)
  const messages = useSelector((state) => state.messages.messages.filter((m) => m.type === 'messages'))
  const conversationId = useSelector((state) => state.messages.conversationId)
  const conversation = useSelector(
    (state) => state.data.conversations.find((c) => c.id === conversationId)
  )
  const defaultMessage = messages.length === 0 && conversation?.participant?.type === 'participants/vendor-organisation' ? DefaultFirstMessageToVendor : ''
  if (!loaded && !query.id && !query.data_provider_id) {
    return (
      <NoMessagesPage participantType={participantType} />
    )
  }

  return (
    <div className="conversation-editor full-height--passthrough">
      <MessageList />
      <PostMessage defaultMessage={defaultMessage} />
    </div>
  )
}
export default ConversationEditor
