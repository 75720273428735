import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'appData',
  initialState: {
    currentParticipant: null,
    config: {},
    liveConnectionState: 'unknown'
  },
  reducers: {
    setCurrentParticipant: (state, action) => {
      state.currentParticipant = action.payload
    },
    setConfig: (state, action) => {
      state.config = action.payload
    },
    setLiveConnectionState: (state, action) => {
      state.liveConnectionState = action.payload
    }
  }
})

export const {
  setCurrentParticipant, setConfig, setLiveConnectionState
} = appSlice.actions

export const selectCurrentParticipant = (state) => state.app.currentParticipant
export default appSlice.reducer
