import React, { forwardRef } from 'react'
import ItemShareableLink from './ItemShareableLink'
import Date from './Date'

const PostCard = forwardRef((props, ref) => (
  <li ref={ref} className="watchlist-card post">
    <div className="basic-card-details">
      <div className="info">
        <div className="summary-container">
          <div className="analyst-image" style={{ backgroundImage: `url(${props.watchlistItem.item.analyst.image_url})` }} />
          <span className="summary">
            <div className="text-content">
              <a href={props.watchlistItem.item.post_path}>
                <h3>{props.watchlistItem.item.title}</h3>
              </a>
              <div className="analyst">
                {props.watchlistItem.item.analyst.name}, {props.watchlistItem.item.analyst.position}
              </div>
              <div>
                <Date date={props.watchlistItem.item.publication_date} />
                <span className="category">{props.watchlistItem.item.category}</span>
              </div>
            </div>
          </span>
        </div>
      </div>
      <ItemShareableLink item={props.watchlistItem.item} />
    </div>
    <div className="additional-card-details">
      <a href={props.watchlistItem.item.post_path}>
        <div className="text-content">
          <div className="description">
            <p>{props.watchlistItem.item.summary}</p>
          </div>
        </div>
        <div className="watchlist-image">
          <img src={props.watchlistItem.item.list_image_src} alt={props.watchlistItem.item.title} />
        </div>
      </a>
    </div>
  </li>
))

export default PostCard
