import { Turbo } from '@hotwired/turbo-rails';
// <turbo-stream action="proxied_stream" token="...unique-token....."></turbo-stream>
//
// Instead of providing a large turbo stream where the transport is limited such as pusher.
// This action will fetch the turbo stream from the backend service where it retrieves it
// from the cache.
Turbo.StreamActions.proxied_stream = function proxiedStream() {
  const token = this.getAttribute('token')

  const url = '/broadcast_stream_service/stream'
  fetch(url, { headers: { Authorization: `Bearer ${token}` } })
    .then((response) => {
      if (response.ok) {
        return response.text()
      }
      throw new Error(`An error occurred fetching the stream in the proxied_stream turbo action: ${response.status}`)
    }).then((html) => {
      Turbo.renderStreamMessage(html)
    })
    .catch((error) => {
      throw new Error(`An error occurred configuring the request in the proxied_stream turbo action: ${error}`)
    })
}
