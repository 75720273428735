import './m-buyside-picks-location-selection-notice.scss';
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { linkTo } from '../Shared/RoutedTabbedContainer'
import { getPickProcess, getBuysidePickProcess } from '../../OneToOne/VendorPicksFilter'

const LocationPreferencesNotice = (props) => {
  const { inPersonOnly: pickProcessInPersonOnly } = useSelector(
    getPickProcess
  )
  const { contactInPersonAttendance, contactVirtualAttendance } = useSelector(
    getBuysidePickProcess
  )
  const attending = useSelector(
    (state) => state.buysidePickProcess.contactAttending
  )

  const notice = () => {
    if (contactInPersonAttendance && !contactVirtualAttendance) {
      return 'in-person meetings only'
    }
    if (!contactInPersonAttendance && contactVirtualAttendance) {
      return 'virtual meetings only'
    }
    return 'in-person and virtual meetings'
  }

  return (
    <div>
      { !pickProcessInPersonOnly && attending && (
        <div className="m-buyside-picks-location-preferences-notice">
          <div className="inner">
            <div className="notice">You have chosen {notice()}.</div>
            <div className="buttons">
              <Link to={linkTo([{ name: props.useQuery, value: 'location' }])}>
                Change location preferences
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default LocationPreferencesNotice
