/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { forwardRef } from 'react'
import LikeButton from './LikeButton'
import ItemShareableLink from './ItemShareableLink'
import Date from './Date'

const AnnouncementCard = forwardRef((props, ref) => {
  const logo = props.watchlistItem.item.logo ? <div className="logo"><img alt="Logo" src={props.watchlistItem.item.logo} /></div> : '';

  let messageLink;
  if (props.watchlistItem.item.chattable) {
    messageLink = (
      <div>
        <a href={props.watchlistItem.item.chat_path} className="message-vendor">
          <i className="fa-regular fa-message-lines" />
          Message vendor
        </a>
      </div>
    )
  } else {
    messageLink = (
      <div className="m-show-contact-details">
        <a href="#" className="message-vendor">
          Message vendor
          <i className="fa-regular fa-message-lines" />
        </a>
        <div className="details">
          <form className="data-provider-contact-message" action={props.watchlistItem.item.message_vendor_path} acceptCharset="UTF-8" data-remote="true" method="post">
            <input name="utf8" type="hidden" value="✓" />
            <p>In addition to this message, we will also share your email address.</p>
            <div className="field">
              <textarea name="message" id="message" required="required" defaultValue={props.watchlistItem.item.message_vendor_default} />
            </div>
          </form>
        </div>
      </div>
    )
  }

  return (
    <li ref={ref} className="watchlist-card announcement" data-testid={`announcementCard${props.watchlistItem.id}`}>
      <div className="basic-card-details">
        <div className="info">
          <div className="summary-container">
            {logo}
            <div className="summary">
              <span className="data-provider-name">
                <a href={props.watchlistItem.item.vendor_path}>
                  {props.watchlistItem.item.data_provider_names[0].title}
                </a>
              </span>
              <Date date={props.watchlistItem.item.publication_date} />
            </div>
          </div>
        </div>
        <ItemShareableLink item={props.watchlistItem.item} />
      </div>
      <div className="additional-card-details">
        <span className="content" dangerouslySetInnerHTML={{ __html: props.watchlistItem.item.content }} />
        <div className="links">
          <div className="message">
            {messageLink}
          </div>
          <LikeButton item={props.watchlistItem.item} />
        </div>
      </div>
    </li>
  )
})

export default AnnouncementCard
