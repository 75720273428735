/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { useDispatch } from 'react-redux'
import { likeAnnouncement, unlikeAnnouncement } from '../../Watchlist/watchlistSlice'

const LikeButton = (props) => {
  const dispatch = useDispatch()
  const liked = props.item.liked_by_current_user

  let button = null
  if (liked) {
    button = (
      <button
        type="submit"
        className={`unlike-link ${liked ? '' : 'hidden'}`}
        data-testid="feed-modal-unlike-button"
        onClick={() => dispatch(unlikeAnnouncement({ id: props.item.id }))}
      >
        <i className="fas fa-thumbs-up" />
      </button>
    )
  } else {
    button = (
      <button
        type="submit"
        className={`like-link ${liked ? 'hidden' : ''}`}
        data-testid="feed-modal-like-button"
        onClick={() => dispatch(likeAnnouncement({ id: props.item.id }))}
      >
        <i className="fas fa-thumbs-up" />
      </button>
    )
  }

  return (
    <div className="icons">
      <div className="m-announcement-statistics">
        <div className="views">
          <i className="far fa-eye eye" />
          <span className="views-count">{props.item.views}</span>
        </div>
        <div className="likes">
          {button}
          <span className="likes-count">{props.item.likes}</span>
        </div>
      </div>
    </div>
  )
}

export default LikeButton;
