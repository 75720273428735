import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails'
import notifier from '../../javascript/src/UserNotifications/notifier'

export default class extends Controller {
  static values = { eventType: String, generalUserId: Number }

  connect() {
    this.connectStreamSource()
    this.subscribeToPusher()
  }

  disconnect() {
    this.disconnectStreamSource()
    this.unsubscribeFromPusher()
  }

  subscribeToPusher() {
    this.subscriptionUnsubscribe = notifier.subscribe(this.eventTypeValue, (data) => {
      this.dispatchMessageEvent(data)
    }, this, { generalUserId: this.generalUserIdValue })
  }

  unsubscribeFromPusher() {
    if (!this.subscriptionUnsubscribe) return

    this.subscriptionUnsubscribe()
  }

  dispatchMessageEvent(data) {
    const event = new MessageEvent('message', { data })
    return this.element.dispatchEvent(event)
  }

  connectStreamSource() {
    Turbo.connectStreamSource(this.element)
  }

  disconnectStreamSource() {
    Turbo.disconnectStreamSource(this.element)
  }
}
