import Pusher from 'pusher-js/with-encryption'
import Config from './Config'
import ScoutConfig from '../ScoutConfig'
import axios from '../axiosRails'

const ChannelPrefix = 'private-user-notifications'
const StateConversion = {
  initialized: 'unknown',
  connecting: 'connecting',
  connected: 'connected',
  unavailable: 'reconnecting',
  failed: 'error',
  disconnected: 'not_connected'
}

const PusherAdapter = class {
  constructor(config = Config, scoutConfig = ScoutConfig) {
    this.config = config
    this.scoutConfig = scoutConfig
    this.client = null
    this.errorCallbacks = []
    this.connectionStateCallbacks = []
    this.connectionState = 'unknown'
  }

  /**
   * @private
   */
  _ensureConnected() {
    if (this.client) { return }
    Pusher.logToConsole = this.config.pusher.debug
    const opts = {
      cluster: this.config.pusher.cluster,
      forceTLS: this.config.pusher.forceTLS,
      channelAuthorization: {
        customHandler: this._authorizationHandler.bind(this)
      }
    }
    if (this.config.pusher.host) { opts.httpHost = this.config.pusher.host }
    if (this.config.pusher.port) { opts.httpPort = this.config.pusher.port }
    if (this.config.pusher.wsHost) { opts.wsHost = this.config.pusher.wsHost }
    if (this.config.pusher.wsPort) { opts.wsPort = this.config.pusher.wsPort }
    if (this.config.pusher.enabledTransports) {
      opts.enabledTransports = this.config.pusher.enabledTransports
    }
    this.client = new Pusher(this.config.pusher.key, opts)
    this.client.connection.bind('error', this._fireErrors.bind(this))
    this.client.connection.bind('state_change', this._recordConnectionState.bind(this))
  }

  /**
   * Subscribe to receive all events of the type specified
   * @param {String} eventType The type of event to subscribe to e.g. 'conversation_updated'
   * @param {function()} cb The function to receive the events as they arrive
   * @param thisObj The object that 'this' will be bound to in the callback function above
   * @returns {(function(): void)|*} An unsubscribe function - call this to unsubscribe
   */
  subscribe(eventType, cb, thisObj = null, options = {}) {
    this._ensureConnected()
    const channel = this.client.subscribe(`${ChannelPrefix}-${options.generalUserId || this.scoutConfig.AuthenticatedUser.general_user_id}`)
    const cbWrapper = (data) => {
      cb.apply(thisObj, [data])
    }
    channel.bind(eventType, cbWrapper, thisObj)
    return () => {
      channel.unbind(eventType, cbWrapper, thisObj)
    }
  }

  /**
   * Subscribe to any errors from pusher
   * Warning:  These errors are completely untranslated so using them in the app is
   *  not recommended unless for debugging purposes.  If in the future, errors do need
   *  to come through into the app, they will need to be normalized to fit into a standard
   *  interface instead of passing pusher errors back into the app.
   * @param {function()} cb The callback function to receive the errors
   * @param thisObj The object that 'this' will be bound to in the callback function above
   * @returns {(function(): void)|*} An unsubscribe function - call this to unsubscribe
   */
  subscribeErrors(cb, thisObj) {
    const subscription = { cb, thisObj }
    this.errorCallbacks.push(subscription)
    return () => {
      const idx = this.errorCallbacks.indexOf(subscription)
      if (idx > -1) { this.errorCallbacks.splice(idx, 1) }
    }
  }

  /**
   * Subscribe to connection state changes for the notifier.  The possible values are
   * 'unknown' State is currently unknown
   * 'connecting' Not ready yet but on its way
   * 'reconnecting' Disconnected but trying to reconnect
   * 'not_connected' Intentionally disconnected
   * 'error' Not connected due to a permanent error
   *
   * @param {function()} cb The function to receive the connection state changes
   * @param thisObj The object that 'this' will be bound to in the callback function above
   * @returns {(function(): void)|*} An unsubscribe function - call this to unsubscribe
   */
  subscribeConnectionState(cb, thisObj) {
    const subscription = { cb, thisObj }
    this.connectionStateCallbacks.push(subscription)
    return () => {
      const idx = this.connectionStateCallbacks.indexOf(subscription)
      if (idx > -1) { this.connectionStateCallbacks.splice(idx, 1) }
    }
  }

  /**
   * @private
   */
  _fireErrors(err) {
    this.errorCallbacks.forEach(({ cb, thisObj }) => {
      cb.apply(thisObj, [err])
    })
  }

  /**
   * @private
   */
  _authorizationHandler({ socketId, channelName }, cb) {
    const payload = {
      user_notifications: {
        socket_id: socketId,
        channel_name: channelName
      }
    }
    axios.post(
      this.config.pusher.authUrl, JSON.stringify(payload), { headers: { Accept: 'application/json', 'Content-Type': 'application/json' } }
    ).then((response) => {
      cb.apply(null, [null, response.data])
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error)
      cb.apply(null, [new Error(`The url ${Config.pusher.authUrl} returned an error`)])
    })
  }

  _recordConnectionState({ current }) {
    this.connectionState = StateConversion[current]
    this.connectionStateCallbacks.forEach(({ cb, thisObj }) => {
      cb.apply(thisObj, [this.connectionState])
    })
  }
}

export default PusherAdapter
