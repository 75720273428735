import { createSlice } from '@reduxjs/toolkit'
import { push } from 'connected-react-router'
import axios from '../axiosRails'
import vendorPicksConfig from './vendorPicksConfig'

function _allowSlot(state, action) {
  state.allowedSlotIds = state.allowedSlotIds.concat(action.payload)
}

function _disallowSlot(state, action) {
  state.allowedSlotIds = state.allowedSlotIds.filter((id) => (id !== action.payload))
}

export const buysidePickProcessSlice = createSlice({
  name: 'buysidePickProcess',
  initialState: {
    pickProcess: null,
    contactId: null,
    additionalContactEmail: '',
    submissionError: null,
    formState: 'vendor-picks',
    allowedSlotIds: [],
    region: 'US',
    contactIsScoutUser: false
  },
  reducers: {
    loadPickProcess: (state, action) => {
      const pickProcess = JSON.parse(JSON.stringify(action.payload))
      pickProcess.buyside_process_ends_at = Date.parse(pickProcess.buyside_process_ends_at)
      state.pickProcess = pickProcess
    },
    picksSubmitError: (state, action) => {
      state.submissionError = action.payload
    },
    setLocalFormState: (state, action) => {
      state.formState = action.payload
    },
    setAllowedSlotIds: (state, action) => {
      state.allowedSlotIds = action.payload
    },
    allowSlot: (state, action) => {
      _allowSlot(state, action)
    },
    disallowSlot: (state, action) => {
      _disallowSlot(state, action)
    },
    toggleAllowSlot: (state, action) => {
      if (state.allowedSlotIds.indexOf(action.payload) >= 0) {
        _disallowSlot(state, action)
      } else {
        _allowSlot(state, action)
      }
    },
    setContactId: (state, action) => {
      state.contactId = action.payload
    },
    setContactAttending: (state, action) => {
      state.contactAttending = action.payload
    },
    setContactInPersonAttendance: (state, action) => {
      state.contactInPersonAttendance = action.payload
    },
    setContactVirtualAttendance: (state, action) => {
      state.contactVirtualAttendance = action.payload
    },
    setAdditionalContactEmail: (state, action) => {
      state.additionalContactEmail = action.payload
    },
    setRegion: (state, action) => {
      state.region = action.payload
    },
    setContactIsScoutUser: (state, action) => {
      state.contactIsScoutUser = action.payload
    }
  }
})

const { picksSubmitError, setLocalFormState } = buysidePickProcessSlice.actions

export const {
  loadPickProcess,
  setAllowedSlotIds,
  allowSlot,
  disallowSlot,
  toggleAllowSlot,
  setContactId,
  setContactAttending,
  setContactInPersonAttendance,
  setContactVirtualAttendance,
  setAdditionalContactEmail,
  setRegion,
  setContactIsScoutUser
} = buysidePickProcessSlice.actions

export const submit = (slotIds = []) => (dispatch, getState) => {
  const {
    // eslint-disable-next-line camelcase
    contactId, allowedSlotIds, region, contactVirtualAttendance, contactInPersonAttendance
  } = getState().buysidePickProcess
  const slotIdsToSubmit = allowedSlotIds.filter((slotId) => slotIds.indexOf(slotId) >= 0)

  const errorHandler = (error) => {
    dispatch(picksSubmitError({ message: error.message, name: error.name, stack: error.stack }))
  }
  dispatch(picksSubmitError(null))
  dispatch(setAllowedSlotIds(slotIdsToSubmit))
  axios.put(
    vendorPicksConfig.submitBuysideContactUrl({ buysideContactId: contactId }),
    JSON.stringify({
      one_to_one_buyside_contact: {
        allowed_slot_ids: slotIdsToSubmit,
        region,
        in_person_attendance: contactInPersonAttendance,
        virtual_attendance: contactVirtualAttendance
      }
    }),
    {
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
    }
  )
    .then(() => {
      axios.post(
        vendorPicksConfig.submitBuysidePicksUrl()
      ).catch(errorHandler)
    })
    .catch(errorHandler)
}

export const setFormState = (newFormState) => (dispatch, getState) => {
  dispatch(setLocalFormState(newFormState))
  const { location } = getState().router
  const newLocation = JSON.parse(JSON.stringify(location))
  newLocation.query.stage = newFormState
  newLocation.search = new URLSearchParams(newLocation.query).toString()
  dispatch(push(newLocation))
}

export const selectAdditionalContactEmail = (state) => (
  state.buysidePickProcess.additionalContactEmail
)
export const selectContactId = (state) => state.buysidePickProcess.contactId
export const getContactIsScoutUser = (state) => state.buysidePickProcess.contactIsScoutUser
export default buysidePickProcessSlice.reducer
