import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import isYesterday from 'dayjs/plugin/isYesterday'
import _ from 'lodash'
import CardFor from './card/CardFor'
import { relativeDay } from '../../helpers/dateHelpers'

dayjs.extend(relativeTime)
dayjs.extend(isYesterday)

const sortMessages = (unsorted) => (
  _.sortBy(unsorted, (m) => dayjs(m.posted_at).valueOf())
)

const addDateHeaders = (messages) => {
  let previousDay = null

  return messages.reduce((result, message) => {
    const day = relativeDay(message.posted_at)
    if (day !== previousDay) {
      result.push({
        id: `internal-date-group-header-${day}`, type: 'internal-date-group-header', body: null, posted_at: message.posted_at
      })
      previousDay = day
    }
    result.push(message)
    return result
  }, [])
}

/**
 * Lists all messages for the currently selected conversation
 * @returns {JSX.Element}
 * @constructor
 */
const MessageList = () => {
  const unsortedMessages = useSelector((state) => state.messages.messages)
  const messages = sortMessages(unsortedMessages)
  const messagesWithDateHeaders = addDateHeaders(messages)
  const lastSeenMessage = [...messages].reverse().find((m) => m.first_seen_at)

  // Code below ensures the message list is scrolled to the bottom after a new message is entered.
  const messagesEndRef = useRef(null)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView(false)
  }
  useEffect(scrollToBottom, [messages]);

  return (
    <div data-component="chat/message-list" className="message-list">
      <div className="list" data-testid="chat-message-list">
        {messagesWithDateHeaders.map((message) => (
          <CardFor key={`${message.type}-${message.id}`} message={message} showReadReceipt={message === lastSeenMessage} />
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  )
}

export default MessageList
