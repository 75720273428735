import { createSlice } from '@reduxjs/toolkit'

const buildInitialFilters = (config) => {
  const filters = JSON.parse(JSON.stringify(config.content_scopes))
  filters.forEach((filter) => {
    filter.selected = true
    filter.visibility = 'hidden'
  })
  return filters
}

const setFilteredSlugs = (state) => {
  if (state.filters.filter((f) => f.visibility === 'enabled').every((f) => f.selected)) {
    state.filteredSlugs = ['all']
    return
  }

  state.filteredSlugs = state.filters
    .filter((filter) => filter.selected && filter.visibility === 'enabled')
    .map((filter) => filter.slug)
    .sort()
}

const setSingleFilterSelected = (state) => {
  state.singleFilterSelected = state.filters.filter((f) => f.visibility === 'enabled' && f.selected).length === 1
}

export const contentTypeFilterSlice = createSlice({
  name: 'contentTypeFilter',
  initialState: {
    filters: [],
    filteredSlugs: ['all'],
    singleFilterSelected: false
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload
    },
    setFilterSelection: (state, action) => {
      const contentTypeFilter = state.filters.find(
        (filter) => filter.slug === action.payload.slug
      )
      if (!contentTypeFilter) { return }

      contentTypeFilter.selected = action.payload.value
      setFilteredSlugs(state)
      setSingleFilterSelected(state)
    },
    setAllFilters: (state, action) => {
      state.filters = state.filters.map((f) => {
        if (f.visibility !== 'enabled') { return f }

        return (
          { ...f, selected: action.payload }
        )
      })
      setFilteredSlugs(state)
      setSingleFilterSelected(state)
    },
    reconfigure: (state, action) => {
      const { filters } = state
      action.payload.forEach((contentTypeState) => {
        const contentTypeFilter = filters.find(
          (filter) => filter.slug === contentTypeState.slug
        )
        if (!contentTypeFilter) { return }

        contentTypeFilter.visibility = contentTypeState.visibility
        if (contentTypeState.visibility !== 'enabled') {
          contentTypeFilter.selected = false
        }
      })
    }
  }
})

const {
  setFilters,
} = contentTypeFilterSlice.actions
export const {
  setFilterSelection, setAllFilters, reconfigure
} = contentTypeFilterSlice.actions

export const configure = (config) => (dispatch) => {
  dispatch(setFilters(buildInitialFilters(config)))
}
export default contentTypeFilterSlice.reducer
