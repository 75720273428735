import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['newRowTemplate', 'collection']

  removeRow(event) {
    event.preventDefault()
    const nodeToRemove = event.currentTarget.closest('*[data-row]')
    const result = this.dispatch('beforeRemoveRow', { cancelable: true, detail: { index: this.collectionTarget.querySelectorAll('*[data-row]').length, nodeToRemove } })
    if (!result.defaultPrevented) {
      nodeToRemove.remove()
    }
  }

  addRow(event) {
    event.preventDefault()
    const newNode = this.newRowTemplateTarget.content.cloneNode(true)

    // We must allow the beforeAddRow handler to process the newNode BEFORE it is added to the dom,
    // otherwise other JS handlers might run before it has replaced the index_placeholders.
    // Mainly seems to affect inserting trix editors.
    // Just waiting for a result seems to be enough to fix the issue.
    const result = this.dispatch('beforeAddRow', { cancelable: true, detail: { index: this.collectionTarget.querySelectorAll('*[data-row]').length, nodeToAdd: newNode } })
    if (!result.defaultPrevented) {
      this.collectionTarget.appendChild(newNode)
    }
  }
}
