import './m-notification-box.scss';
import React, { useState } from 'react'

import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import dayjs from 'dayjs'
import TrackingContainer from './TrackingContainer'

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'A few seconds',
    m: 'A minute',
    mm: '%d minutes',
    h: 'An hour',
    hh: '%d hours',
    d: 'A day',
    dd: '%d days',
    M: 'A month',
    MM: '%d months',
    y: 'A year',
    yy: '%d years'
  }
})

const cookieKeyValue = (cookieValue) => (`seen_notification_${cookieValue}=1`)
const writeNotificationSeenCookieValue = (cookieValue) => {
  // max-age is set to 10 years
  document.cookie = `${cookieKeyValue(cookieValue)};max-age=315600000`
}

const hasCookieValue = (cookieValue) => (
  document.cookie.split(';').some((item) => item.trim() === cookieKeyValue(cookieValue))
)

const BaseNotificationBox = ({
  identifier, className, description, linkText, linkRef, start, end
}) => {
  const [hideButtonClicked, setHideButtonClicked] = useState(false)

  if (hasCookieValue(identifier) || hideButtonClicked) {
    return ''
  }

  const hideBoxAndSetCookie = () => {
    writeNotificationSeenCookieValue(identifier)

    setHideButtonClicked(true)
  }

  const now = dayjs()
  if (start && now.isBefore(dayjs(start))) { return '' }
  if (end && now.isAfter(dayjs(end))) { return '' }
  if (!linkRef) { return '' }

  return (
    <div className={`m-notification-box ${className}`}>
      <div className="content-container-component">
        <div className="description">
          {description}
        </div>
        <div className="buttons">
          <TrackingContainer testid="featureLinkTracking" track={className === 'pick-process'} action="Navigate to picks process via banner">
            <a href={linkRef} className="feature-link">{linkText}</a>
          </TrackingContainer>
          <TrackingContainer testid="hideButtonTracking" track={className === 'pick-process'} action="Hide picks process banner">
            <button type="button" className="dont-show-again" onClick={hideBoxAndSetCookie}>Don&apos;t show again</button>
          </TrackingContainer>
        </div>
      </div>
    </div>
  )
}

const PickProcessNotificationBox = ({
  linkRef, start, end, iconPath
}) => {
  const identifier = `pick-process-${start}`
  const timeDescription = <span className="time-remaining">{dayjs(end).fromNow(true)}</span>
  const textDescription = 'to make your picks for the upcoming AltDating event. Select which vendors you want to meet now!'
  const description = (
    <>
      <span className="svg-icon"><img alt="" src={iconPath} /></span>
      <span>{timeDescription} {textDescription}</span>
    </>
  )
  return (
    <BaseNotificationBox
      className="pick-process"
      description={description}
      linkText="Make my picks"
      linkRef={linkRef}
      identifier={identifier}
      start={start}
      end={end}
    />
  )
}

const NotificationBox = ({ type, ...other }) => {
  switch (type) {
    case 'pick-process':
      return PickProcessNotificationBox(other)
    case 'base-box':
      return BaseNotificationBox(other)
    default:
      throw new Error('No Notification Box defined')
  }
}

export {
  writeNotificationSeenCookieValue, hasCookieValue
}

export default NotificationBox
