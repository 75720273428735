import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  selectAll() {
    this.element.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checkbox.checked = true
    })

    this.element.dispatchEvent(new Event('change', { bubbles: true }))
  }

  deselectAll(event) {
    event.preventDefault()
    this.element.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      checkbox.checked = false
    })
    this.element.dispatchEvent(new Event('change', { bubbles: true }))
  }
}
