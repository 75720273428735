import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['textarea', 'sendButton']

  static values = {
    disabled: {
      type: Boolean,
      default: false
    },
    autoSubmit: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    this.updateElementsDisabled()
    this.updateEmptyClass()
    this.autoSubmitIfRequired()
  }

  autoGrow() {
    // collapses the textarea, allowing the scrollHeight to
    // be recalculated based on the current content
    this.textareaTarget.style.height = '52px'
    this.textareaTarget.style.height = `${this.textareaTarget.scrollHeight}px`
  }

  // Workaround for https://github.com/hotwired/turbo/issues/577
  correctFrameHeader(event) {
    const targetFrame = event.target.dataset.turboFrame
    if (!targetFrame || targetFrame === '_top') return

    event.detail.fetchOptions.headers['Turbo-Frame'] = targetFrame
  }

  setActiveButtonStatus() {
    this.sendButtonTarget.disabled = this.disabledValue || this.textareaTarget.value.length === 0;
  }

  keyPressed(event) {
    if (!this.disabledValue && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      this.submitForm();
    }
  }

  updateEmptyClass() {
    this.element.classList.toggle('empty', this.textareaTarget.value.length === 0)
  }

  submitForm() {
    this.element.requestSubmit()
    this.disabledValue = true
  }

  disableSending() {
    this.disabledValue = true
  }

  enableSending() {
    this.disabledValue = false
  }

  disabledValueChanged() {
    this.updateElementsDisabled()
  }

  updateElementsDisabled() {
    this.setActiveButtonStatus()
    this.textareaTarget.disabled = this.disabledValue
  }

  autoSubmitIfRequired() {
    if (!this.autoSubmitValue || this.textareaTarget.value.length === 0) { return }

    setTimeout(() => {
      this.submitForm()
    }, 100)
  }
}
