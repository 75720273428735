import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['expandableRow', 'innerContent']

  static values = {
    enabled: Boolean
  }

  connect() {
    this.observer = new ResizeObserver(this.checkOverflow.bind(this))
    this.observer.observe(this.expandableRowTarget)
  }

  disconnect() {
    this.observer.disconnect()
  }

  checkOverflow() {
    this.innerContentTargets.forEach((innerContent) => {
      if (innerContent.scrollHeight > this.expandableRowTarget.clientHeight) {
        innerContent.parentElement.classList.add('widgets--datasets-comparison-table--field-component__cell--expandable-content')
        this.enable()
      }
    });
  }

  enable() {
    this.enabledValue = true
  }

  showMore() {
    this.expandableRowTarget.classList.add('widgets--datasets-comparison-table--field-component__row--expanded')
  }

  showLess() {
    this.expandableRowTarget.classList.remove('widgets--datasets-comparison-table--field-component__row--expanded')
  }

  enabledValueChanged() {
    this.expandableRowTarget.classList.toggle('widgets--datasets-comparison-table--field-component__row--expandable-row', this.enabledValue)
  }
}
