import { Controller } from '@hotwired/stimulus'

// Any form with:
// - data-controller="submitted-forms"
// - data-action="submit->submitted-forms#disable"
// will disable *all* their submit buttons, *anywhere in the page* when the form is submitted.

// For re-enabling, it is assumed that the form response either reloads the page or
// refreshes the appropriate buttons with a turbo stream.
export default class extends Controller {
  disable() {
    this.element.elements.forEach((control) => {
      if (control.type === 'submit' || control.type === 'button') {
        control.disabled = true;
      }
    })
  }
}
