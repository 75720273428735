import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['closeModalButton', 'nextButton']

  setButton(event) {
    if (event.detail.isLastSlide) {
      this.nextButtonTarget.classList.add('hidden')
      this.closeModalButtonTarget.classList.remove('hidden')
    } else {
      this.nextButtonTarget.classList.remove('hidden')
      this.closeModalButtonTarget.classList.add('hidden')
    }
  }
}
