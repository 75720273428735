import { createSlice } from '@reduxjs/toolkit'
import lodash, { isEqual } from 'lodash'
import { push } from 'connected-react-router'
import axios from '../axiosRails'
import watchlistConfig from './watchlistConfig'
import { debounceWithState } from '../debounce'
import { configure as contentTypeFilterConfigure, reconfigure, setAllFilters } from './contentTypeFilterSlice'
import { preProcessData, preProcessIncluded } from '../helpers/jsonApiResponseProcessingHelpers'
import { ALL_WATCHLIST_FEED_TYPE, WATCHLIST_FEED_TYPES } from '../constants/watchlist'

const buildInitialFeedSummaries = (config) => {
  const summaries = []

  config.feed_types.forEach(
    (item) => {
      summaries.push({
        id: item.id,
        name: item.title,
        slug: item.slug,
        subtitle: item.subtitle,
        visible: item.visible,
        count: 0,
        unseen: 0
      })
    }
  )
  return summaries
}

const buildInitialDataProviderSummaries = (config) => {
  const summaries = []

  config.data_providers.forEach(
    (item) => {
      summaries.push({
        id: item.id,
        name: item.title,
        logo_src: item.logo_src,
        slug: item.slug,
        count: 0,
        unseen: 0,
        personal: item.personal,
        shared: item.shared
      })
    }
  )
  return summaries
}

const buildInitialDataProviderOptions = (config) => {
  const options = []

  config.data_provider_filter_options.forEach(
    (item) => {
      options.push({
        id: item.id,
        name: item.title,
        logo_src: item.logo_src,
        slug: item.slug,
        count: 0,
        unseen: 0,
        personal: item.personal,
        shared: item.shared
      })
    }
  )
  return options
}

const populateSummaries = (state, meta) => {
  const {
    dataProviderSummaries,
    feedTypeSummaries,
    savedFilters,
  } = state;

  (meta.data_providers_counts || []).forEach((countObject) => {
    const { id } = countObject

    const dataProviderSummary = dataProviderSummaries.find(
      (summary) => (summary.id === id)
    )

    if (dataProviderSummary) {
      dataProviderSummary.count = countObject.count
      dataProviderSummary.unseen = countObject.unseen_count
    }
  });

  (meta.saved_filters_counts || []).forEach((countObject) => {
    const { id } = countObject

    const savedFilter = savedFilters.find(
      (summary) => (summary.id === id)
    )

    if (savedFilter) {
      savedFilter.count = countObject.count
      savedFilter.unseen = countObject.unseen_count
    }
  });

  (meta.feed_counts || []).forEach((countObject) => {
    const feedTypeSummary = feedTypeSummaries.find(
      (summary) => (summary.slug === countObject.slug)
    )

    if (feedTypeSummary) {
      feedTypeSummary.count = countObject.count
      feedTypeSummary.unseen = countObject.unseen_count
    }
  });
}

// We have to find the announcement just in the items now
const findAnnouncements = (id, state) => (
  state.itemsLookup[`feeds.announcements.${id}`]
)

// Every time we see an announcement, we want to add it to a temporary stack
// After the debounce completes, we send a bulk request and empty the stack
const viewAnnouncements = (items) => {
  const data = items.map((item) => (
    {
      announcement_id: item.id,
      source: 'watchlist'
    }
  ))

  axios.post(
    watchlistConfig.bulkCreateUserAnnouncementViewsUrl(),
    JSON.stringify({ data }),
    {
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
    }
  ).catch((error) => {
    if (!error.isAxiosError) {
      // eslint-disable-next-line no-console
      console.error('Error', error)
    }
  })
}

const deferAnnouncementView = debounceWithState((items) => viewAnnouncements(items), 1000)

const sendSeenItems = (items) => {
  const data = items.map((item) => (
    {
      type: 'seen_watchlist_items',
      relationships: {
        item: {
          id: item.id,
          type: item.type
        }
      }
    }
  ))
  axios.post(
    watchlistConfig.createSeenWatchlistItemsUrl(),
    JSON.stringify({ data }),
    {
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
    }
  ).catch((error) => {
    if (!error.isAxiosError) {
      // eslint-disable-next-line no-console
      console.error('Error', error)
    }
  })
}

const deferSendSeenItem = debounceWithState((items) => sendSeenItems(items), 1000)

const isSharedOrPersonal = ({ shared, personal }) => shared || personal

export const watchlistSlice = createSlice({
  name: 'watchlistData',
  initialState: {
    cardsError: null,
    cardsLoading: null,
    loadRequestedState: {},
    loadedState: {},
    dataProviderSummaries: [],
    savedFilters: [],
    savedFiltersEnabled: false,
    dataProviderFilterOptions: [],
    currentTab: ALL_WATCHLIST_FEED_TYPE,
    feedTypeSummaries: [],
    itemsLookup: {},
    feedItemMetadata: [],
    showFilters: true,
    loadedEmpty: null,
    setAllSeenError: null,
    feedType: null,
    dataProvider: null,
    endOfFeed: false,
    loadedAt: null,
    emailPreferenceToken: '',
    mailingListPreference: {
      possibleSend: null,
      emailManagementMailingListId: null,
    }
  },
  reducers: {
    dataLoad: (state, action) => {
      const firstNewIndex = state.feedItemMetadata.length

      preProcessIncluded(action.payload.included)
      preProcessData(action.payload)

      action.payload.data.forEach((itemData) => {
        itemData.item.publication_date = Date.parse(itemData.item.publication_date)
        itemData.firstInPage = false
        if (itemData.item.original_publication_date) {
          itemData.item.original_publication_date = Date.parse(
            itemData.item.original_publication_date
          )
        }
      })

      // The cache is being reloaded - probably?
      if (!action.payload.append) {
        state.feedItemMetadata = []
      }

      action.payload.data.forEach((item) => {
        state.itemsLookup[`${item.type}.${item.id}`] ||= item
      })

      const newItemMetadata = action.payload.data.map((item) => ({
        firstInPage: false,
        lookupKey: `${item.type}.${item.id}`
      }))

      // Filter duplicates in the metadata listing
      state.feedItemMetadata = state.feedItemMetadata.concat(
        lodash.differenceBy(newItemMetadata, state.feedItemMetadata, (i) => i.lookupKey)
      )

      if (firstNewIndex < state.feedItemMetadata.length) {
        state.feedItemMetadata[firstNewIndex].firstInPage = true
      }

      if (action.payload.meta) {
        populateSummaries(state, action.payload.meta)
        state.loadedAt = action.payload.meta.loaded_at
      }
      state.cardsError = null
      state.likeAnnouncementError = null
      state.unlikeAnnouncementError = null
      state.cardsLoading = false
      state.loadedEmpty = state.feedItemMetadata.length === 0
      state.endOfFeed = action.payload.meta.pagination.end_of_feed || false
    },
    itemDataLoad: (state, action) => {
      preProcessIncluded(action.payload.included)
      preProcessData(action.payload)

      const item = action.payload.data
      const lookupKey = `${item.type}.${item.id}`
      state.itemsLookup[lookupKey] = item
    },
    beforeDataLoad: (state, action) => {
      state.cardsLoading = true
      if (action.payload.append === false) {
        state.loadedAt = null
      }
    },
    setLoadRequestedState: (state, action) => {
      state.loadRequestedState = action.payload
    },
    setLoadedState: (state, action) => {
      state.loadedState = action.payload
    },
    dataError: (state, action) => {
      state.cardsError = action.payload
      state.cardsLoading = false
    },
    setLocalAllSeenError: (state, action) => {
      const newState = { ...state }
      newState.setAllSeenError = action.payload
      return newState
    },
    setLikedAnnouncementError: (state, action) => {
      state.likeAnnouncementError = action.payload
    },
    setUnlikedAnnouncementError: (state, action) => {
      state.unlikeAnnouncementError = action.payload
    },
    toggleFilterVisibility: (state) => {
      state.showFilters = !state.showFilters
    },
    localLikeAnnouncement: (state, action) => {
      const announcement = findAnnouncements(action.payload.id, state)
      announcement.item.liked_by_current_user = true;
      announcement.item.likes += 1
    },
    localUnlikeAnnouncement: (state, action) => {
      const announcement = findAnnouncements(action.payload.id, state)
      announcement.item.liked_by_current_user = false;
      announcement.item.likes -= 1
    },
    removeFromPersonal: (state, action) => {
      const dataProviderId = action.payload

      state.dataProviderSummaries = state.dataProviderSummaries.map((dp) => {
        if (dp.id === dataProviderId) {
          return { ...dp, personal: false }
        }
        return dp
      }).filter(isSharedOrPersonal)
    },
    removeFromShared: (state, action) => {
      const dataProviderId = action.payload

      state.dataProviderSummaries = state.dataProviderSummaries.map((dp) => {
        if (dp.id === dataProviderId) {
          return { ...dp, shared: false }
        }
        return dp
      }).filter(isSharedOrPersonal)
    },
    addToWatchlist: (state, action) => {
      const { data_provider: dataProvider } = action.payload
      const isMatchingDataProvider = ({ id }) => id === dataProvider.id
      const dataProviderIndex = state.dataProviderSummaries.findIndex(isMatchingDataProvider)

      if (dataProviderIndex !== -1) {
        state.dataProviderSummaries = state.dataProviderSummaries.map((dp, index) => {
          if (index === dataProviderIndex) {
            return dataProvider
          }

          return dp
        })
      } else {
        state.dataProviderSummaries = [...state.dataProviderSummaries, dataProvider]
      }
    },
    shareAllDataProvidersPersonal: (state) => {
      state.dataProviderSummaries.forEach((s) => { s.shared = true })
    },
    itemShown: (state, action) => {
      const newItem = {
        id: action.payload.id, type: action.payload.type
      }
      if (action.payload.type === 'announcements') {
        deferAnnouncementView(newItem)
      }

      if (!action.payload.seen) {
        deferSendSeenItem(newItem)

        const homeSummary = state.feedTypeSummaries.find((s) => (s.id === 'home'))
        const watchlistSummary = state.feedTypeSummaries.find(
          (s) => s.id === ALL_WATCHLIST_FEED_TYPE
        )
        // The majority of the summaries should be defined
        // if-statements are used out of an abundance of caution

        if (homeSummary) {
          homeSummary.unseen -= 1
        }

        let inWatchlist = false
        action.payload.dataProviderIds.forEach((id) => {
          const summary = state.dataProviderSummaries.find((s) => (s.id === id))

          if (summary) {
            summary.unseen -= 1
            inWatchlist = true
          }
        });

        if (inWatchlist) {
          if (watchlistSummary) {
            watchlistSummary.unseen -= 1
          }
        }
      }
    },
    setDataProviderSummaries: (state, action) => {
      state.dataProviderSummaries = action.payload
    },
    setSavedFilters: (state, action) => {
      state.savedFilters = action.payload.saved_filters
      state.savedFiltersEnabled = action.payload.saved_filters_enabled
    },
    setContentTypeFilters: (state, action) => {
      state.contentTypeFilters = action.payload
    },
    setDataProviderFilterOptions: (state, action) => {
      state.dataProviderFilterOptions = action.payload
    },
    setFeedTypeSummaries: (state, action) => {
      state.feedTypeSummaries = action.payload
    },
    setLastRequestedPage: (state, action) => {
      state.lastRequestedPage = action.payload
    },
    clearItems: (state) => {
      state.itemsLookup = {}
      state.feedItemMetadata = []
    },
    setEmailPreferenceToken: (state, action) => {
      state.emailPreferenceToken = action.payload.email_preference_token
    },
    setCanShareWatchlist: (state, action) => {
      state.canShareWatchlist = action.payload.can_share_watchlist
    },
    setUserCreationDate: (state, action) => {
      state.userCreationDate = Date.parse(action.payload.user_creation_date)
    },
    setMailingListPreference: (state, action) => {
      state.mailingListPreference.possibleSend = action.payload
        .mailing_list_preference
        .possible_send
      state.mailingListPreference.emailManagementMailingListId = action.payload
        .mailing_list_preference
        .mailing_list_id
    },
    setMailingListPreferencePossibleSend: (state, action) => {
      state.mailingListPreference.possibleSend = action.payload.possibleSend
    },
    setLockedFilters: (state, action) => {
      state.lockedFilters = action.payload.locked_filters || {}
    },
    setRenderingOptions: (state, action) => {
      state.renderingOptions = action.payload.rendering_options || {}
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload
    }
  }
})

const {
  dataError,
  beforeDataLoad,
  setLocalAllSeenError,
  localLikeAnnouncement,
  localUnlikeAnnouncement,
  setLikedAnnouncementError,
  setUnlikedAnnouncementError,
  setDataProviderSummaries,
  setSavedFilters,
  setDataProviderFilterOptions,
  setFeedTypeSummaries,
  setLoadedState,
  setLoadRequestedState,
  setLastRequestedPage,
  setEmailPreferenceToken,
  setCanShareWatchlist,
  setUserCreationDate,
  setMailingListPreference,
  setLockedFilters,
  setRenderingOptions,
} = watchlistSlice.actions

export const {
  toggleFilterVisibility,
  dataLoad,
  itemShown,
  removeFromPersonal,
  removeFromShared,
  addToWatchlist,
  shareAllDataProvidersPersonal,
  setMailingListPreferencePossibleSend,
  setCurrentTab,
} = watchlistSlice.actions
const {
  itemDataLoad, clearItems
} = watchlistSlice.actions

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const load = ({ page = 1, initialLoad = false }) => (dispatch, getState) => {
  const append = (page > 1)
  const reload = !append && !initialLoad
  dispatch(beforeDataLoad({ append }))
  const { lockedFilters = {}, renderingOptions = {} } = getState().data
  const { filteredSlugs } = getState().contentTypeFilter
  const searchParams = new URLSearchParams(getState().router.location.search)
  const dataProvider = lockedFilters.watched_data_provider || searchParams.get('dataProvider')
  const feedType = lockedFilters.feed_type || searchParams.get('feedType')
  const savedFilterId = searchParams.get('savedFilterId')
  const requestState = { dataProvider, filteredSlugs, feedType }
  const { loadedAt } = getState().data

  if (WATCHLIST_FEED_TYPES.includes(feedType)) {
    dispatch(setCurrentTab(feedType))
  } else {
    dispatch(setCurrentTab(ALL_WATCHLIST_FEED_TYPE))
  }

  dispatch(setLoadRequestedState(requestState))
  if (filteredSlugs.length === 0) {
    dispatch(dataLoad({
      data: [],
      included: [],
      meta: { pagination: { per_page: 20, page_number: 1 } }
    }))
    dispatch(setLoadedState(requestState))
    dispatch(setLastRequestedPage(1))
    return
  }

  axios.get(watchlistConfig.watchlistUrl({
    feedTypeSlug: feedType,
    dataProviderSlug: dataProvider,
    savedFilterId,
    filteredSlugs: isEqual(filteredSlugs, ['all']) ? [] : filteredSlugs,
    page: { number: page },
    loadedAt,
    lockedFilters,
    renderingOptions
  }), { headers: { Accept: 'application/vnd.api+json' } })
    .then((response) => {
      // We assume that if we're asking for page 2, page 1 is already loaded and we should append
      if (reload) {
        dispatch(clearItems())
      }
      dispatch(dataLoad({ ...response.data, append }));
      if (response.data.meta?.content_type_states) {
        dispatch(reconfigure(response.data.meta.content_type_states))
      }
      dispatch(setLoadedState(requestState))
      dispatch(setLastRequestedPage(page))
      if (initialLoad) {
        dispatch(setAllFilters(true))
      }
    })
    .catch((error) => {
      dispatch(dataError({ message: error.message, name: error.name, stack: error.stack }))
      dispatch(setLoadRequestedState({}))
      dispatch(setLoadedState({}))
      if (!error.isAxiosError) {
        // eslint-disable-next-line no-console
        console.error('Error', error)
      }
    })
};

export const loadNextPage = () => (dispatch, getState) => {
  const currPage = getState().data.lastRequestedPage || 0
  dispatch(load({ page: currPage + 1 }))
};

export const configure = (config) => (dispatch) => {
  dispatch(setFeedTypeSummaries(buildInitialFeedSummaries(config)))
  dispatch(setDataProviderSummaries(buildInitialDataProviderSummaries(config)))
  dispatch(setDataProviderFilterOptions(buildInitialDataProviderOptions(config)))
  dispatch(contentTypeFilterConfigure(config))
  dispatch(setEmailPreferenceToken(config))
  dispatch(setCanShareWatchlist(config))
  dispatch(setUserCreationDate(config))
  dispatch(setMailingListPreference(config))
  dispatch(setLockedFilters(config))
  dispatch(setRenderingOptions(config))
  dispatch(setSavedFilters(config))
}

export const likeAnnouncement = ({ id }) => (dispatch) => {
  dispatch(localLikeAnnouncement({ id: id.toString() }))

  const data = {
    announcement_id: id,
    source: 'watchlist'
  }

  axios.post(
    watchlistConfig.likeAnnouncementUrl(),
    JSON.stringify(data),
    {
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
    }
  ).catch((error) => {
    dispatch(setLikedAnnouncementError({
      message: error.message,
      name: error.name,
      stack: error.stack
    }))
    if (!error.isAxiosError) {
      // eslint-disable-next-line no-console
      console.error('Error', error)
    }
  })
}

export const unlikeAnnouncement = ({ id }) => (dispatch) => {
  dispatch(localUnlikeAnnouncement({ id: id.toString() }))

  axios.delete(
    watchlistConfig.unlikeAnnouncementUrl(id),
    {
      headers: { Accept: 'application/json' }
    }
  ).catch((error) => {
    dispatch(setUnlikedAnnouncementError({
      message: error.message,
      name: error.name,
      stack: error.stack
    }))
    if (!error.isAxiosError) {
      // eslint-disable-next-line no-console
      console.error('Error', error)
    }
  })
}

export const setAllSeen = ({
  feedTypeSlug,
  dataProviderSlug,
  filteredSlugs = []
}) => (dispatch) => {
  const query = {}
  if (feedTypeSlug) {
    query.filter ||= {}
    query.filter.feed_type = feedTypeSlug
  }
  if (dataProviderSlug) {
    query.filter ||= {}
    query.filter.watched_data_provider = dataProviderSlug
  }
  if (filteredSlugs.length > 0) {
    query.filter ||= {}
    query.filter.content_scopes = filteredSlugs
  }

  (
    axios.post(
      watchlistConfig.createSeenAllWatchlistItemsUrl(),
      JSON.stringify(query),
      {
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
      }
    )
  ).then(() => {
    dispatch(load({}))
  }).catch((error) => {
    dispatch(setLocalAllSeenError({ message: error.message, name: error.name, stack: error.stack }))
    if (!error.isAxiosError) {
      // eslint-disable-next-line no-console
      console.error('Error', error)
    }
  })
}

export const loadItem = ({ id, type }) => (dispatch) => (
  axios.get(watchlistConfig.watchlistItemUrl(type, id), { headers: { Accept: 'application/vnd.api+json' } })
    .then((response) => {
      dispatch(itemDataLoad(response.data))
    })
    .catch((error) => {
      if (!error.isAxiosError) {
        // eslint-disable-next-line no-console
        console.log('Error', error);
      }
    })
)

export const removeModalItem = () => (dispatch, getState) => {
  const { location } = getState().router
  const searchParams = new URLSearchParams(location.search)
  searchParams.delete('item_id')
  searchParams.delete('item_type')
  const newSearch = `?${searchParams.toString()}`
  dispatch(push({ pathname: location.pathname, search: newSearch }))
}

export default watchlistSlice.reducer
