import { Controller } from '@hotwired/stimulus'

/** Connects to data-controller="dataset-edit-field-in-place"
 * This is a bridge between the webpack code (where the stimulus
 * controllers are hosted) and the global code for the
 * 'DatasetEditFieldInPlace' code.
 *
 * Whilst this code could be re written to be more modular
 * and OO - we might not end up using it forever as rails
 * now has its own wysiwyg editor.  So, I chose to not
 * spend any more time on it.
 */
export default class extends Controller {
  connect() {
    const field = this.element;
    window.setupEditFieldInPlace(field)
  }
}
