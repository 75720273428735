/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import TrackingContainer from '../TrackingContainer'

/**
 * A flexible routed tabbed container for use with react components as child components
 * OR existing content that is already on the page.
 *
 * Functionality includes:
 * - The use of the query parameters to load and save the current tab to allow bookmarking
 *   of the tab or simple reloading of the page.
 * - Count of items to be displayed next to the label
 * - Default tab specification
 * - Loading of dynamic content on first click
 * - Retention of dynamic content after first click (when clicking away) - saves
 *   reloading of data when switching back.
 *
 * Expansion Ideas:
 * - Loading of remote content using XHR
 * - Disposal of content on tab change if browser resources are an issue
 *
 *
 * @example
 * Given the following elements in the dom
 * <div id="tabs-content">
 *   <div id="tab-1">
 *     <span>Tab 1 content already present in the dom</span>
 *   </div>
 * </div>
 *
 * and using the following jsx
 *
 * import React from 'react'
 * import { TabbedContainer, Tab, ExistingTab } from './TabbedContainer'
 *
 * <TabbedContainer existingContainerSelector="#tabs-content" useQuery="home_tab">
 *   <ExistingTab identifier="tab1" label="Tab 1" count="1" selector="#tab-1" default />
 *   <Tab identifier="tab2" label="Tab 2" count="2">
 *     <div>Dynamic content - can be react components</div>
 *   </Tab>
 * </TabbedContainer>
 *
 * This will show 2 tabs called "Tab 1" and "Tab 2" - the first being the default.
 * The first tab will control (hide and show) the "#tab-1" element
 * Whilst the second tab will add a new child to the "#tabs-content" (using react Portal)
 *   when clicked and it will control this child as the user clicks backwards and forwards
 *
 * The url will have "?tab=tab1" or "?tab=tab2" appended as the user clicks the tabs.
 * This is defined using 'useQuery="home_tab"'
 * You can use any query parameter to carry the current tab.
 * Reloading after clicking will therefore load the correct tab
 */

/**
 * @private
 */
const ContentContainer = (props) => {
  if (props.existingContainerSelector) {
    const existingContainer = document.querySelector(props.existingContainerSelector)

    return ReactDOM.createPortal(
      props.children,
      existingContainer
    )
  }
  return (
    <div>{props.children}</div>
  )
}
const Tab = (props) => {
  const [initialised, setInitialised] = useState(props.selected)
  useEffect(() => {
    if (props.selected) {
      setInitialised(true)
    }
  }, [props.selected])
  if (!initialised) {
    return null
  }
  return (
    <div className={`tab-content ${props.selected ? 'selected' : 'hidden'}`}>{props.children}</div>
  )
}

const ExistingTab = (props) => {
  const node = document.querySelector(props.selector)
  if (props.selected) {
    node.classList.remove('hidden')
    node.classList.add('selected')
  } else {
    node.classList.remove('selected')
    node.classList.add('hidden')
  }

  return null
}

const EphemeralTab = (props) => {
  if (props.selected) {
    return <div className={`tab-content ${props.selected ? 'selected' : 'hidden'}`}>{props.children}</div>
  }
  return ''
}

const BackTab = (props) => <span>{props.children}</span>
BackTab.defaultProps = { backTab: true }

export const linkTo = (paramsList = []) => (
  (location) => {
    if (paramsList.length === 0) { return location }

    const searchParams = new URLSearchParams(location.search)
    paramsList.forEach((params) => {
      searchParams.set(params.name, params.value)
    })
    const newSearch = searchParams.toString()
    return { ...location, search: newSearch === '' ? '' : `?${newSearch}` }
  }
)
const OptionalCount = (props) => (
  // Counts of 0 should not be displayed in the tab
  props.count === null || typeof props.count === 'undefined' || props.count === 0 ? null : <span className="result-count">{props.count}</span>
)
/**
 * A routed tabbed container - where each tab has its own link and the tab state is stored
 * using the routers query params.
 * The child components are to be either of type 'Tab' or 'ExistingTab'
 * @param props
 * @param {String} props.useQuery=tab Specifies which query param to use to store the current tab
 * @param {String} props.existingContainerSelector=null A css selector specifying the dom element
 *   where the contents  of the tab are to be rendered.  This allows completely separate contents
 *   from the tab bar.  If unspecified or null, the content is rendered alongside the tab bar in
 *   the html
 * @returns {JSX.Element}
 * @constructor
 */
const RoutedTabbedContainer = (props) => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const tabFromSearch = props.useQuery ? searchParams.get(props.useQuery) : null
  const defaultTab = props.children.filter(Boolean).find(
    (child) => (child.props.default)
  ) || props.children[0]
  const currentTab = tabFromSearch || defaultTab.props.identifier

  const backTab = props.children.filter(Boolean).find((child) => child.props.backTab)
  const tabsForList = props.children.filter(Boolean).filter(
    (child) => child && !child.props.backTab
  )
  const currentTabIdx = tabsForList.findIndex((child) => child.props.identifier === currentTab)
  const previousTab = tabsForList[currentTabIdx - 1]
  const selectedTab = (identifier) => (identifier === 'complete' ? null
    : `${currentTab === identifier ? 'selected' : ''}`)

  return (
    <div className="tabbed-container">
      { previousTab && backTab && (
        <TrackingContainer action="Tab back navigation" object={previousTab.props.identifier}>
          <Link className="previous-tab" to={linkTo([{ name: props.useQuery, value: previousTab.props.identifier }])}>
            <i className="icon fa-solid fa-arrow-left" />
            { backTab }
          </Link>
        </TrackingContainer>
      )}
      <ol className="results-tabs">
        {tabsForList.map((child) => {
          if (child.props.hiddenTab) return null

          return (
            <li key={child.props.identifier} className={`${child.props.className} ${selectedTab(child.props.identifier)}`}>
              <div className="iconContainer"><i className="icon fa-solid fa-check" /></div>
              {child.props.disabledTab ? (
                <div className="disabled">
                  <i className="icon" />
                  <span className="label">{child.props.label}</span>
                  <OptionalCount count={child.props.count} />
                </div>
              ) : (
                <TrackingContainer action="Tab navigation" object={child.props.identifier}>
                  <Link to={linkTo([{ name: props.useQuery, value: child.props.identifier }])}>
                    <i className="icon" />
                    <span className="label">{child.props.label}</span>
                    <OptionalCount count={child.props.count} />
                  </Link>
                </TrackingContainer>
              )}
              <div className="connector-left" />
              <div className="connector-right" />
            </li>
          )
        })}
      </ol>
      <ContentContainer existingContainerSelector={props.existingContainerSelector}>
        {
          tabsForList.map(
            (child) => (
              React.cloneElement(
                child,
                { selected: currentTab === child.props.identifier, key: child.props.identifier }
              )
            )
          )
        }
      </ContentContainer>
    </div>
  )
}
RoutedTabbedContainer.defaultProps = {
  useQuery: 'tab',
  existingContainerSelector: null
}
RoutedTabbedContainer.propTypes = {
  useQuery: PropTypes.string,
  existingContainerSelector: PropTypes.string
}
export {
  RoutedTabbedContainer, Tab, ExistingTab, EphemeralTab, BackTab
}
