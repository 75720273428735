// We want to monitor the DOM for when rack-mini-profiler renders results and then
// wire that result DOM node to our RackMiniProfilerController.
function rackOverrideMutationCallback(mutationsList) {
  mutationsList.forEach((mutation) => {
    if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
      mutation.addedNodes.forEach((node) => {
        // Check if the node is eligible and doesn't already have the data-controller attribute set
        if (node.nodeType === Node.ELEMENT_NODE && node.classList.contains('profiler-result') && !node.hasAttribute('data-controller')) {
          node.setAttribute('data-controller', 'rack-mini-profiler-customization');
        }
      });
    }
  });
}

// Setup the MutationObserver to listen for changes in the DOM
const observer = new MutationObserver(rackOverrideMutationCallback);
const config = { childList: true, subtree: true };
observer.observe(document, config);
