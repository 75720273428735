import './m-buyside-picks-my-picks.scss';
import React, { useState, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import pluralize from 'pluralize'
import { selectSelectedCards, setPicksSubmittedAt } from '../../OneToOne/vendorPicksSlice'
import PickProcessCountdown from './PickProcessCountdown'
import TrackingContainer from '../TrackingContainer'
import ConfirmationModal from './ConfirmationModal'
import {
  setFormState, submit
} from '../../OneToOne/buysidePickProcessSlice';
import {
  requireRegionAndAvailability, getInvalidPicks
} from '../../OneToOne/VendorPicksFilter'
import MyMeetingsList from './MyMeetingsList'
import InvalidPicksModal from './InvalidPicksModal'

const MyPicksBasket = (props) => {
  const [isOpenPicksLimitConfirmationModal, setIsOpenPicksLimitConfirmationModal] = useState(false)
  const [isOpenInvalidModal, setIsOpenInvalidModal] = useState(false)

  const dispatch = useDispatch()

  const picked = useSelector(selectSelectedCards)
  const showRegionAndAvailability = useSelector(requireRegionAndAvailability)

  const invalidPicks = useSelector(getInvalidPicks)
  const picksSubmittedAt = useSelector((state) => (
    state.data.contactPickProcess.picks_submitted_at
  ))

  const picksSelectionLimitExceeded = picked.length >= 20

  const nextStep = useCallback(() => {
    dispatch(setFormState(props.nextState))
    setIsOpenPicksLimitConfirmationModal(false)
  }, [dispatch, props.nextState])

  const onEnableVirtualMeetings = useCallback(() => {
    setIsOpenInvalidModal(false)

    if (picked.length - invalidPicks.length >= 20) {
      return setIsOpenPicksLimitConfirmationModal(true)
    }

    dispatch(setFormState('region'))

    return null
  }, [dispatch, picked.length, invalidPicks])

  const onRemoveVirtualMeetings = useCallback(() => {
    setIsOpenInvalidModal(false)

    if (picked.length - invalidPicks.length >= 20) {
      return setIsOpenPicksLimitConfirmationModal(true)
    }

    dispatch(submit([]))
    dispatch(setPicksSubmittedAt(Date.now()))
    dispatch(setFormState(props.nextState))

    return null
  }, [dispatch, picked.length, props.nextState, invalidPicks])

  const save = useCallback(() => {
    if (invalidPicks.length > 0) {
      return setIsOpenInvalidModal(true)
    }

    if (picksSelectionLimitExceeded) {
      return setIsOpenPicksLimitConfirmationModal(true)
    }

    setIsOpenPicksLimitConfirmationModal(false)

    if (!showRegionAndAvailability) {
      dispatch(submit([]))
      dispatch(setPicksSubmittedAt(Date.now()))
    }

    dispatch(setFormState(props.nextState))

    return null
  }, [
    invalidPicks.length,
    picksSelectionLimitExceeded,
    showRegionAndAvailability,
    dispatch, props.nextState
  ])

  const submitButtonText = useMemo(() => {
    const continueText = `Continue with ${picked.length} ${pluralize('meeting', picked.length)}`
    const submitText = picksSubmittedAt ? 'Re Submit & Finish' : 'Submit & Finish'

    if (showRegionAndAvailability) {
      return continueText
    }
    return submitText
  }, [picked.length, picksSubmittedAt, showRegionAndAvailability])

  return (
    <div className="buyside-picks-basket">
      <div className="m-buyside-my-picks">
        <InvalidPicksModal
          onEnableVirtualMeetings={onEnableVirtualMeetings}
          onRemoveVirtualMeetings={onRemoveVirtualMeetings}
          isOpen={isOpenInvalidModal}
          onClose={() => { setIsOpenInvalidModal(false) }}
        />
        <ConfirmationModal
          onConfirm={nextStep}
          isOpen={isOpenPicksLimitConfirmationModal}
          pickedCount={picked.length}
          onClose={() => { setIsOpenPicksLimitConfirmationModal(false) }}
        />
        <h2>My Meetings</h2>
        <span data-attr="picks-count"><i className="icon fa-regular fa-check" />{picked.length}</span>

        <MyMeetingsList picks={picked} />

        <PickProcessCountdown />
        <TrackingContainer action="Save selection">
          <button className="submit" type="button" onClick={save} disabled={picked.length === 0}>
            {submitButtonText}
            <i className="icon fa-solid fa-arrow-right" />
          </button>
        </TrackingContainer>
      </div>
    </div>
  )
}

export default MyPicksBasket
