import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['fieldset']

  selectFieldset(event) {
    const required = event.detail.fieldset
    this.fieldsetTargets.forEach((fieldset) => {
      if (fieldset.dataset.fieldset === required) {
        fieldset.classList.remove('hidden')
      } else {
        fieldset.classList.add('hidden')
      }
    })
  }
}
