import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    window.addEventListener('turbo:before-fetch-request', (event) => this.setLoading(event));
  }

  setLoading(event) {
    if (event.detail.url.pathname === window.location.pathname) {
      this.element.classList.add('loading');
    }
  }
}
