import React, { useState, useRef } from 'react'
import axios from '../../axiosRails'

const RenderedUserFrequencySelect = (props) => {
  const [frequency, setFrequency] = useState(props.defaultFrequency);
  let oldSelectedFrequency = frequency

  const { displayResponseStatusRef } = props;
  const { setDisplayResponseFlag } = props;

  // Keeping track of component mounted/unmounted state to prevent async actions,
  // such as the axios Promise below, from trying to update the component state
  // after it's been unmounted.
  // Doesn't seem to come up in practice for this component,
  // but got flagged by the Jest component test.
  const mounted = useRef(false)
  React.useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  })

  const updateFrequency = (e) => {
    // Toggle up front to reduce lag - correct if required on error
    setDisplayResponseFlag(false)
    // Can't just use frequency since, after calling setFrequency, the state updates asynchronously
    const newFrequency = e.target.value
    setFrequency(newFrequency)

    const payload = {
      token: props.token,
      email_management_mailing_list_preference: {
        email_management_mailing_list_id: props.mailingList,
        // possible_send: props.shouldSend,
        frequency: newFrequency
      }
    }

    axios.post(
      props.path,
      JSON.stringify(payload),
      {
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' }
      }
    ).then(() => {
      if (mounted.current) {
        displayResponseStatusRef.current = true
        oldSelectedFrequency = newFrequency
        setDisplayResponseFlag(true)
      }
    }).catch(() => {
      if (mounted.current) {
        displayResponseStatusRef.current = false
        setDisplayResponseFlag(true)
        setFrequency(oldSelectedFrequency)
      }
    })
  }

  const options = props.frequencies.map((e) => (<option key={e[1]} value={e[1]}>{e[0]}</option>))

  return (
    <div className="user-selected-frequency-container">
      <div className="user-selected-frequency">
        <select className={`button ${props.shouldSend ? 'clickable' : ''}`} onChange={updateFrequency} value={frequency} disabled={!props.shouldSend}>
          {options}
        </select>
      </div>
    </div>
  )
}

const UserFrequencySelect = (props) => (
  props.shouldSend ? RenderedUserFrequencySelect(props) : ''
)

export default UserFrequencySelect
