import React from 'react'

const VendorProfile = (props) => {
  if (props.vendorProfile == null) {
    return null
  }

  return (
    <div className="vendor-profile">
      <h4>Main Product Overview</h4>
      <p>{props.vendorProfile.main_product_overview}</p>
      <h4>Coverage Universe</h4>
      <p>{props.vendorProfile.coverage_universe}</p>
      <h4>Data History</h4>
      <p>{props.vendorProfile.data_history}</p>
    </div>
  )
}
export default VendorProfile
