import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['closeModalButton', 'exploreButton', 'nextButton']

  setButtonVisibility(event) {
    const { slide, isLastSlide } = event.detail

    this.exploreButtonTarget.classList.toggle('hidden', !(slide === 0))
    this.nextButtonTarget.classList.toggle('hidden', !(slide > 0 && !isLastSlide))
    this.closeModalButtonTarget.classList.toggle('hidden', !isLastSlide)
  }
}
